.announcementDateText {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 0.85vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .announcementDateText {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #666;
    font-size: 1.6vw;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .announcementDateText {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #666;
    font-size: 3.2vw;
    margin-top: 1.5vh;
    margin-bottom: 3vh;
  }
}
/* Container styles */
.IconCircle {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    padding: 1.2rem; /* Tailwind's `p-8` equals 2rem padding */
    border-radius: 50%; /* Tailwind's `rounded-full` */
    background-color: #F9F9F9; /* Tailwind's `bg-[#F9F9F9]` */
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15); /* Tailwind's shadow */
  }
  
  /* Section container */
.section {
    margin-top: 5rem; /* Tailwind's mt-20 */
  }
  
  /* Icon grid for larger screens */
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 1rem;
  }
  
  .icon-grid-2 {
    grid-template-columns: repeat(2, 1fr);
    margin-top: -5rem; /* Tailwind's -mt-20 */
  }
  
  .icon-item {
    justify-self: center;
  }
  
  .icon-item.start {
    justify-self: start;
  }
  
  .icon-item.end {
    justify-self: end;
  }
  
  .icon-item.margin-bottom {
    margin-bottom: 5rem;
  }
  
  /* Hidden for medium and above */
  .hidden-md {
    display: none;
  }
  
  /* Text section */
  .text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    gap: 1rem;
    padding: 1rem;
  }
  
  @media (min-width: 640px) {
    .text-section {
      margin-top: 3rem;
      gap: 1.5rem;
    }
  }
  
  .main-heading {
    font-size: 2rem; /* Tailwind's text-[32px] */
    font-family: 'Montserrat', sans-serif;
    color: #1EB8BC;
    font-weight: bold;
    text-align: center;
  }
  
  @media (min-width: 640px) {
    .main-heading {
      font-size: 2.5rem; /* Tailwind's sm:text-[40px] */
    }
  }
  
  @media (min-width: 1024px) {
    .main-heading {
      font-size: 3.5rem; /* Tailwind's lg:text-[56px] */
    }
  }
  
  .sub-heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 1rem; /* Tailwind's text-base */
    text-align: center;
    max-width: 90%;
  }
  
  @media (min-width: 640px) {
    .sub-heading {
      font-size: 1.25rem; /* Tailwind's sm:text-xl */
      max-width: 75%;
    }
  }
  
  @media (min-width: 768px) {
    .sub-heading {
      font-size: 1.5rem; /* Tailwind's md:text-2xl */
      max-width: 60%;
    }
    .hidden-md{
        display: grid;
    }
  }
  
  @media (min-width: 1024px) {
    .sub-heading {
      max-width: 50%;
    }
  }
  
  /* Button styles */
  .order-button {
    background-color: #FF7C1D;
    color: #fff;
    padding: 0.5rem 1.5rem; /* Tailwind's py-2 px-6 */
    font-size: 1.125rem; /* Tailwind's text-lg */
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    border-radius: 9999px; /* Tailwind's rounded-full */
    transition: background-color 0.3s;
  }
  
  @media (min-width: 640px) {
    .order-button {
      padding: 0.75rem 2rem; /* Tailwind's sm:py-3 sm:px-8 */
      font-size: 1.25rem; /* Tailwind's sm:text-xl */
    }
  }
  
  @media (min-width: 768px) {
    .order-button {
      padding: 1rem 2.5rem; /* Tailwind's md:py-4 md:px-10 */
    }
  }
  
  .order-button:hover {
    background-color: #D96814; /* Tailwind's hover:bg-[#D96814] */
  }
  
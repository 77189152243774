.usersFunctionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.userLanguageFilterContainer {
  width: 15vw;
  margin-right: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.userNameFilter {
  color: #333;
  background-color: transparent;
  border-bottom: 1px solid rgba(102, 102, 102, 1);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 0.9vw;
  margin-top: 0;
  margin-bottom: 0;
  height: 2.3vw;
  width: 100%;
  padding-left: 0;
}

.userNameFilter:focus {
  outline: none;
}

.usersHeaderColumn {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.usersLineColumn {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.usersIDColumn {
  width: 20%;
}

.usersNameColumn {
  width: 20%;
}

.usersRoleColumn {
  width: 10%;
}

.usersLanguageColumn {
  width: 15%;
}

.usersRateColumn {
  width: 8%;
}

.usersStatusColumn {
  width: 10%;
}

.usersDeleteColumn {
  width: 5%;
  justify-content: center;
}

.usersExtraColumn {
  width: 4%;
  justify-content: center;
  overflow: visible;
  position: relative;
}

.usersExtraOverlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #085f63;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  width: auto;
  height: auto;
  padding-right: 4vw;
  padding-left: 1vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 400;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.usersExtraColumn:hover .usersExtraOverlay {
  opacity: 1;
  pointer-events: all;
}

.usersExtraText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  color: #fff;
  margin-top: 0.7vh;
  margin-bottom: 0.7vh;
}

.userModalUnderlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.3);
}

.userModal {
  z-index: 1000;
  position: fixed;
  top: 45%;
  left: 50%;
  width: 40vw;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-right: 1vw;
  padding-left: 1vw;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 2px 2px 20px rgba(0,0,0,0.1);
}

.userModalTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.4vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 3vh;
}

.userModalLine {
  width: 100%;
  margin-bottom: 1vh;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.userModalHalf {
  width: 46%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.userModalHalfText {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.userModalLanguageLine {
  margin-top: 2vh;
}

.userModalButtonContainer {
  width: auto;
  height: auto;
  align-self: flex-end;
  margin-top: 3vh;
}

.userModalRoleSelect {
  margin-bottom: 1vh;
}

.usersDeleteButton {
  width: 1.1vw;
  height: 1.1vw;
  color: #f44336;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.usersDeleteButton:hover {
  filter: brightness(0.7);
}

.loadMoreUsersButton {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  color: #333;
  margin-top: 3vh;
  margin-bottom: 2vh;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  align-self: flex-start;
  margin-left: 2%;
}

.loadMoreUsersButton:hover {
  opacity: 0.7;
}

.userStatusSelect {
  width: 80%;
  height: 3vh;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #666;
  font-family: 'Montserrat';
  font-size: 0.8vw;
  line-height: 2vw;
  font-weight: 400;
  color: #666;
}

.userStatusSelect:focus {
  outline: none;
}

.userStatusText {
  padding-left: 0.3vw;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .userLanguageFilterContainer {
    width: auto;
    margin-right: 4vw;
  }
  
  .userNameFilter {
    font-size: 1.6vw;
    line-height: 1.6vw;
    height: 5vw;
  }
  
  .usersHeaderColumn {
    font-size: 1.7vw;
  }
  
  .usersLineColumn {
    font-size: 1.8vw;
  }
  
  .usersIDColumn {
    width: 35%;
  }
  
  .usersNameColumn {
    width: 27%;
  }
  
  .usersRoleColumn {
    width: 13%;
  }
  
  .usersLanguageColumn {
    display: none;
  }
  
  .usersRateColumn {
    display: none;
  }
  
  .usersStatusColumn {
    width: 15%;
  }
  
  .usersDeleteColumn {
    width: 5%;
    justify-content: center;
  }
  
  .usersExtraColumn {
    width: 4%;
    justify-content: center;
    overflow: visible;
    position: relative;
  }
  
  .usersExtraOverlay {
    border-radius: 3px;
    padding-right: 4vw;
    padding-left: 1vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }
  
  .usersExtraText {
    font-size: 1.5vw;
  }
  
  .userModal {
    z-index: 1000;
    position: absolute;
    top: 37%;
    left: 50%;
    width: 60vw;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-right: 4vw;
    padding-left: 4vw;
  }

  .userModalLine {
    margin-bottom: 0.7vh;
  }
  
  .userModalLanguageLine {
    margin-top: 1vh;
  }
  
  .userModalButtonContainer {
    margin-top: 2vh;
  }
  
  .usersDeleteButton {
    width: 2.1vw;
    height: 2.1vw;
    color: #f44336;
    cursor: pointer;
    transition: filter 0.3s ease-out;
  }
  
  .usersDeleteButton:hover {
    filter: brightness(1);
  }
  
  .loadMoreUsersButton {
    font-size: 1.8vw;
  }
  
  .loadMoreUsersButton:hover {
    opacity: 1;
  }
  
  .userStatusSelect {
    width: 80%;
    height: 3vh;
    font-size: 1.7vw;
    line-height: 3.2vw;
  }
  
  .userStatusSelect:focus {
    outline: none;
  }
  
  .userStatusText {
    padding-left: 0.3vw;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .userLanguageFilterContainer {
    display: none;
  }
  
  .usersHeaderColumn {
    font-size: 3vw;
  }
  
  .usersLineColumn {
    font-size: 3vw;
  }
  
  .usersIDColumn {
    width: 65%;
  }
  
  .usersNameColumn {
    display: none;
  }
  
  .usersRoleColumn {
    display: none;
  }
  
  .usersLanguageColumn {
    display: none;
  }
  
  .usersRateColumn {
    display: none;
  }
  
  .usersStatusColumn {
    width: 30%;
  }
  
  .usersDeleteColumn {
    width: 25%;
    display: none;
  }

  .userNameFilter {
    font-size: 8px;
    line-height: 1.6vw;
    height: 5vw;
  }
  
  .usersExtraColumn {
    display: none;
  }
  
  .usersExtraOverlay {
    display: none;
  }
  
  .userModal {
    z-index: 1000;
    position: absolute;
    top: 37%;
    left: 50%;
    width: 85vw;
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  
  .userModalLine {
    width: 100%;
    margin-bottom: 0.5vh;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .userModalLanguageLine {
    margin-top: 1vh;
  }
  
  .userModalButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
  }
  
  .userModalRoleSelect {
    margin-bottom: 1vh;
  }

  .loadMoreUsersButton {
    font-size: 3.5vw;
  }
  
  .userStatusSelect {
    width: 100%;
    height: 3vh;
    font-size: 3vw;
    line-height: 5vw;
  }
  
  .userStatusSelect:focus {
    outline: none;
  }
  
  .userStatusText {
    padding-left: 0.3vw;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 3vw;
  }

  .usersDeleteButton {
    width: 15px;
    height: 15px;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .userLanguageFilterContainer {
    width: auto;
  }
  
  .usersIDColumn {
    width: 22%;
  }
  
  .usersNameColumn {
    width: 15%;
  }
  
  .usersRoleColumn {
    width: 10%;
  }
  
  .usersLanguageColumn {
    width: 10%;
  }
  
  .usersRateColumn {
    width: 8%;
  }
  
  .usersStatusColumn {
    width: 10%;
  }
  
  .usersDeleteColumn {
    width: 5%;
    justify-content: center;
  }
  
  .usersExtraColumn {
    width: 4%;
    justify-content: center;
    overflow: visible;
    position: relative;
  }
  
  .userModalLine {
    width: 100%;
    margin-bottom: 1.5vh;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .userModalLanguageLine {
    margin-top: 3vh;
  }
  
  .userModalButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 3vh;
  }
  
  .userModalRoleSelect {
    margin-bottom: 2vh;
  }
  
  .usersDeleteButton {
    width: 12px;
    height: 12px;
  }
  
  .usersDeleteButton:hover {
    filter: brightness(1);
  }
  
  .loadMoreUsersButton:hover {
    opacity: 1;
  }

  .userStatusSelect {
    width: 80%;
    height: 5vh;
    font-size: 0.8vw;
    line-height: 2vw;
  }
}
.accountFooterContainer {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8vh;
  padding-bottom: 2vh;
  border-top: 1px solid rgba(102,102,102,0.2);
}
.sideBar{
    width: 10% !important;
}

.toggle--checked .react-toggle-track {
    background-color: #ff4546 !important;
    width:44px;
    height:18px;
  } 
  .toggle--checked.react-toggle--checked .react-toggle-track {
    background-color: #11d111 !important;
  } 
  .toggle--checked.react-toggle--checked .react-toggle-thumb{
    border-color: #11d111 !important;
  }
  .toggle--checked .react-toggle-thumb{
    border-color: #ff4546 !important;
    width: 16px;
    height:16px;
  }
  
  
  .quoteHeaderLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    border-bottom: 1.5px solid rgba(102,102,102,0.6);
  }
  
  .quoteDetailsHeaderColumn {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.1vw;
    color: #18b7bb;
    margin-top: 0;
    margin-bottom: 0;
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .quoteLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .quoteDetailsLineColumn {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 1vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    overflow-wrap: break-word;
    white-space: initial;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .quoteStatusHeaderColumn {
    width: 25%;
  }
  
  .quoteTotalHeaderColumn {
    width: 15%;
    font-weight: 700;
  }
  
  .loadingQuotes {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .quoteStatusContainer {
    width: auto;
    height: auto;
    padding: 1vh 2vw 1vh 2vw;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.75vw;
    color: #fff;
    border-radius: 5px;
    min-width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .loadMoreQuotesButton {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    text-decoration: underline;
    color: rgba(102,102,102,0.3);
    margin-top: 2vh;
    margin-bottom: 0;
    cursor: pointer;
    transition: filter 0.3s ease-out;
  }
  
  .loadMoreQuotesButton:hover {
    filter: brightness(0.1);
  }
  
  .quoteDetailsContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .quoteDetailsTitle {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    color: #18b7bb;
    margin-top: 0;
    margin-bottom: 1vh;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
  }
  
  .optionTitle {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 1vh;
    transition: opacity 0.3s ease-out;
  }
  
  .quoteDetailsTitle:hover {
    opacity: 0.6;
  }
  
  .quoteDetailsSub {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }

  .referenceText{
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
  @media (orientation: portrait) and (max-width: 1024px) {
    .sideBar{
        width: 7% !important;
    }
    .toggle--checked .react-toggle-track {
      width: 40px;
      height:14px;
    } 
    .toggle--checked .react-toggle-thumb{
      width: 12px;
      height: 12px;
    }
  
    .quoteDetailsHeaderColumn {
      font-size: 1.8vw;
    }
    
    .quoteDetailsLineColumn {
      font-size: 1.8vw;
    }
    
    .quoteStatusHeaderColumn {
      width: 25%;
    }
    
    .quoteTotalHeaderColumn {
      width: 15%;
      font-weight: 700;
    }
    
    .loadingQuotes {
      font-size: 2.2vw;
    }
    
    .quoteStatusContainer {
      padding: 0.5vh 2vw 0.5vh 2vw;
      font-size: 1.2vw;
    }
    
    .loadMoreQuotesButton {
      font-size: 2vw;
      color: rgba(102,102,102,0.8);
    }
    
    .quoteDetailsTitle {
      font-size: 1.6vw;
    }
    .optionTitle {
      font-size: 1.6vw;
    }
    
    .quoteDetailsTitle:hover {
      opacity: 1;
    }
    
    .quoteDetailsSub {
      font-size: 1.5vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }

    .referenceText{
       font-size: 1.5vw;
    }
  }
  
  @media (orientation: portrait) and (max-width: 767px) {
    .sideBar{
        width: 100% !important;
    }
    .toggle--checked .react-toggle-track {
      width: 40px;
      height:14px;
    } 
    .toggle--checked .react-toggle-thumb{
      width: 12px;
      height: 12px;
    }
    
    .quoteDetailsHeaderColumn {
      font-size: 3.5vw;
      width: 50%;
    }
    
    .quoteDetailsLineColumn {
      width: 50%;
      font-size: 3.5vw;
    }
    .nameText {
      width: 28%;
    }
    
    .quoteStatusHeaderColumn {
      width: 28%;
    }
    
    .quoteTotalHeaderColumn {
      width: 17%;
      font-weight: 700;
    }
    
    .loadingQuotes {
      font-size: 4vw;
    }
    
    .quoteStatusContainer {
      padding: 0.8vh 3vw 0.8vh 3vw;
      font-size: 2.2vw;
    }
    
    .loadMoreQuotesButton {
      font-size: 3.8vw;
      color: rgba(102,102,102,0.8);
    }
    
    .quoteDetailsTitle {
      font-size: 3.2vw;
    }
    .optionTitle {
      font-size: 3.2vw;
    }
    
    .quoteDetailsTitle:hover {
      opacity: 1;
    }
    
    .quoteDetailsSub {
      font-size: 3.2vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }

    .referenceText{
        font-size: 3.2vw;
     }
  }
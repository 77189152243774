.headingReviews {
  font-size: 2.3vw;
}

.subheadingReviews {
  font-size: 1vw;
  font-weight: 500;
}

.infoButtonReviews {
  color: #18b7bb;
  margin-right: 1vw;
}

.reviewsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 5vh;
}

.reviewsTitle {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #18b7bb;
  font-size: 1vw;
  margin-top: 10vh;
  margin-bottom: 0;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  text-align: left;
}

.loadingReviews {
  width: 100%;
  height: 30vh;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 1vw;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loadMoreReviewsButton {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 1vw;
  margin-top: 7vh;
  margin-bottom: 3vh;
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 0.3s ease-out;
}

.loadMoreReviewsButton:hover {
  opacity: 0.7;
}

.reviewLine {
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.reviewScoreContainer {
  width: 7vw;
  height: 7vw;
  border-radius: 3.5vw;
  background-color: rgba(24, 183, 187, 0.15);
  font-family: 'Montserrat';
  font-weight: 700;
  color: #18b7bb;
  font-size: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reviewInnerContainer {
  width: calc(100% - 10vw);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.reviewName {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #18b7bb;
  font-size: 1.3vw;
  margin-top: 0;
  margin-bottom: 1vh;
}

.reviewLanguages {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 0.9vw;
  margin-top: 0;
  margin-bottom: 4vh;
}

.reviewText {
  font-family: 'Montserrat';
  font-weight: 300;
  color: #666;
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 0;
  margin-bottom: 4vh;
  white-space: pre-line;
  font-style: italic;
}

.reviewDate {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 0.85vw;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .headingReviews {
    font-size: 2.8vw;
    line-height: 4.5vw;
  }
  
  .subheadingReviews {
    font-size: 1.6vw;
    font-weight: 500;
  }
  
  .infoButtonReviews {
    color: #18b7bb;
    margin-right: 2vw;
  }
  
  .reviewsTitle {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #18b7bb;
    font-size: 1.5vw;
    margin-top: 6vh;
    margin-bottom: 0;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }
  
  .loadingReviews {
    width: 100%;
    height: 20vh;
    font-size: 1.8vw;
  }
  
  .loadMoreReviewsButton {
    font-size: 1.8vw;
    margin-top: 4vh;
  }
  
  .loadMoreReviewsButton:hover {
    opacity: 1;
  }
  
  .reviewLine {
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }
  
  .reviewScoreContainer {
    width: 10vw;
    height: 10vw;
    border-radius: 5vw;
    font-size: 3vw;
  }
  
  .reviewInnerContainer {
    width: calc(100% - 15vw);
  }
  
  .reviewName {
    font-size: 2.3vw;
    margin-bottom: 1vh;
  }
  
  .reviewLanguages {
    font-size: 1.6vw;
    margin-bottom: 3vh;
  }
  
  .reviewText {
    font-size: 1.6vw;
    line-height: 3.2vw;
    margin-bottom: 3vh;
  }
  
  .reviewDate {
    font-size: 1.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .headingReviews {
    font-size: 5.5vw;
    line-height: 9vw;
  }
  
  .subheadingReviews {
    font-size: 4vw;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .infoButtonReviews {
    color: #18b7bb;
    margin-right: 3vw;
    width: 20px;
    height: 20px;
  }
  
  .reviewsTitle {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #18b7bb;
    font-size: 3.5vw;
    margin-top: 6vh;
    margin-bottom: 0;
    padding-top: 1.8vh;
    padding-bottom: 1.8vh;
  }
  
  .loadingReviews {
    width: 100%;
    height: 30vh;
    font-size: 3.7vw;
  }
  
  .loadMoreReviewsButton {
    font-size: 3.7vw;
    margin-top: 4vh;
  }
  
  .reviewLine {
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }
  
  .reviewScoreContainer {
    width: 15vw;
    height: 15vw;
    border-radius: 7.5vw;
    font-size: 4vw;
  }
  
  .reviewInnerContainer {
    width: calc(100% - 23vw);
  }
  
  .reviewName {
    font-size: 4vw;
    margin-bottom: 1vh;
  }
  
  .reviewLanguages {
    font-size: 3.3vw;
    margin-bottom: 3vh;
    color: #666;
  }
  
  .reviewText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 3vh;
  }
  
  .reviewDate {
    font-size: 3.3vw;
  }
}
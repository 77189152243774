.formInputContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .formInputLabel {
    margin-bottom: 1vh;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    line-height: 1vw;
    color: #666;
  }
  
  .formInput {
    color: #666;
    background-color: transparent;
    border-bottom: 1px solid rgba(102, 102, 102, 0.7);
    border-right: none;
    border-top: none;
    border-left: none;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    line-height: 1vw;
    margin-top: 0;
    margin-bottom: 1vh;
    height: 2vw;
    width: 100%;
    padding-left: 0;
  }
  
  .formInput::placeholder {
    color: rgba(102, 102, 102, 0.8);
  }
  
  .formInput:focus {
    outline: none;
  }
  
  @media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {
  
    .formInputLabel {
      margin-bottom: 1vh;
      font-size: 1.8vw;
      line-height: 1.8vw;
    }
    
    .formInput {
      font-size: 2vw;
      line-height: 2vw;
      height: 4vw;
    }
  }
  
  @media (orientation: portrait) and (max-width: 767px) {
  
    .formInputLabel {
      margin-bottom: 1vh;
      font-size: 2.7vw;
      line-height: 2.7vw;
    }
    
    .formInput {
      font-size: 3.6vw;
      line-height: 3.6vw;
      height: 8vw;
    }
  }
.servicesDisplayContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.servicesDisplayHeadingContainer {
  width: 45%;
  height: 33vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.servicesDisplayHeading {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
}

.servicesDisplaySubheading {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  width: 85%;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.servicesDisplayTitlesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 7vh;
}

.servicesDisplayTitle {
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 1.4vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1vh;
}

.servicesDisplayTitleLine {
  height: 7vh;
  width: 100%;
  border-bottom: 0.5px solid #c1c1c1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.servicesDisplayTitleInfoContainer {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.servicesDisplayHelpIcon {
  position: relative;
  overflow: visible;
}

.servicesDisplayHelpTooltip {
  position: absolute;
  top: 50%;
  left: 100%;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  height: auto;
  width: auto;
  min-width: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 1.5vw 1.5vh 1.5vw;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #444;
  font-size: 0.85vw;
  line-height: 1.8vw;
  opacity: 0;
  z-index: 100 !important;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.servicesDisplayHelpIcon:hover  .servicesDisplayHelpTooltip{
  opacity: 1;
  pointer-events: all;
}

.servicesDisplayTitleInfoText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
}

.servicesDisplayTitlesOffset {
  width: 100%;
  height: 25vh;
}

.servicesDisplayColumnsContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.servicesDisplayColumn {
  width: 20vw;
  height: 100%;
  border-radius: 20px;
  margin-left: 3vw;
  background-color: rgba(24, 183, 187, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.servicesDisplayColumnTitleContainer {
  width: 100%;
  height: 33vh;
  border-radius: 20px;
  background-color: #18b7bb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.servicesDisplayColumnTitle {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  color: #fff;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.servicesDisplayColumnSub {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #fff;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4vh;
  text-align: center;
}

.servicesDisplayColumnPrice {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  color: #fff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.servicesDisplayValue {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.servicesDisplayValueText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.servicesDisplayButtonsContainer {
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.servicesDisplayButtonsMore {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 700;
  margin-top: 3vh;
  margin-bottom: 0;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-out; 
}

.servicesDisplayButtonsMore:hover {
  opacity: 0.7;
}

.mobileServicesShortDescription {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .servicesDisplayContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
  
  .servicesDisplayHeadingContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .servicesDisplayHeading {
    font-size: 3.7vw;
  }
  
  .servicesDisplaySubheading {
    font-size: 2vw;
    line-height: 4.3vw;
    margin-bottom: 3vh;
  }
  
  .servicesDisplayTitlesContainer {
    display: none;
  }
  
  .servicesDisplayTitlesOffset {
    display: none;
  }
  
  .servicesDisplayColumnsContainer {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .servicesDisplayColumn {
    width: 48%;
    margin-left: 0;
  }
  
  .servicesDisplayColumnTitleContainer {
    width: 100%;
    height: 25vh;
    border-radius: 20px;
    background-color: #18b7bb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .servicesDisplayColumnTitle {
    font-family: 'Montserrat';
    font-size: 2.8vw;
    color: #fff;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1.5vh;
  }
  
  .servicesDisplayColumnSub {
    font-size: 1.8vw;
    line-height: 3.6vw;
    margin-bottom: 4vh;
  }
  
  .servicesDisplayColumnPrice {
    font-size: 2.3vw;
  }
  
  .servicesDisplayButtonsContainer {
    width: 100%;
    height: 15vh;
  }
  
  .servicesDisplayButtonsMore {
    font-size: 2vw;
    margin-top: 3vh;
  }
  
  .servicesDisplayButtonsMore:hover {
    opacity: 1;
  }

  .mobileServicesShortDescription {
    display: flex;
    width: 85%;
    height: 20vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 2vw;
    line-height: 4vw;
    text-align: center;
    color: #666;
    font-weight: 400;
    margin-top: 4vh;
    margin-bottom: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .servicesDisplayContainer {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
  
  .servicesDisplayHeading {
    font-size: 6.5vw;
  }
  
  .servicesDisplaySubheading {
    font-size: 4vw;
    line-height: 9.3vw;
    margin-bottom: 3vh;
  }
  
  .servicesDisplayTitlesContainer {
    display: none;
  }
  
  .servicesDisplayTitlesOffset {
    display: none;
  }
  
  .servicesDisplayColumnsContainer {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .servicesDisplayColumn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 5vh;
  }
  
  .servicesDisplayColumnTitleContainer {
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #18b7bb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .servicesDisplayColumnTitle {
    font-size: 5vw;
    margin-top: 3vh;
    margin-bottom: 1.5vh;
  }
  
  .servicesDisplayColumnSub {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }
  
  .servicesDisplayColumnPrice {
    font-size: 4.5vw;
    margin-bottom: 3vh;
  }
  
  .servicesDisplayButtonsContainer {
    width: 100%;
    height: auto;
  }
  
  .servicesDisplayButtonsMore {
    font-size: 4vw;
    margin-top: 3vh;
    margin-bottom: 4vh;
  }
  
  .servicesDisplayButtonsMore:hover {
    opacity: 1;
  }

  .mobileServicesShortDescription {
    display: flex;
    width: 85%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 3.8vw;
    line-height: 7vw;
    text-align: center;
    color: #666;
    font-weight: 400;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
}
.languagesDisplayContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.languagesDisplayInfo {
  height: 46.87vw;
  width: 46.87vw;
  margin-right: -3vw;
  background-image: url('../assets/balloonRight.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.languagesDisplayListContainer {
  margin-left: 4.3vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .languagesDisplayContainer {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  
  .languagesDisplayInfo {
    height: 52vw;
    width: 52vw;
    margin-right: -5vw;
    margin-bottom: 5vh;
    padding: 15px;
  }
  
  .languagesDisplayListContainer {
    margin-left: 0;
  }

  .mobileTableColumnDocumentsDisplay {
    margin-right: 5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .languagesDisplayContainer {
    margin-top: 4vh;
    margin-bottom: 4vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .languagesDisplayInfo {
    height: 112vw;
    width: 112vw;
    margin-right: -4vw;
    margin-bottom: 5vh;
  }
  
  .languagesDisplayListContainer {
    margin-left: 4.3vw;
  }
}
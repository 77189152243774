.SectionMargin {
    margin-top: 7rem;
}

.languages-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: center;
    gap: 1.25rem;
    margin-top: 2rem;
}

.language-item {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    gap: 0.5rem;
    background-color: #D2F2F3;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-family: 'Montserrat';
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
    --tw-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    --tw-shadow-colored: 0 12px 25px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.popular-languages {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: center;
    gap: 7rem;
    margin-top: 7rem;
}

.responsive-paragraph {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 1rem;
    max-width: 70%;
    margin: 0 auto;
}

.desktop-screen-languages {
    display: none;
}

.popular-languages-container {
    display: grid;
    gap: 1rem;
    justify-content: center;
}

.grid-container-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-container-4 {
    grid-template-columns: repeat(4, 1fr);
}

.popular-languages-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    justify-self: center;
    background-color: #d2f2f3;
    width: fit-content;
    border-radius: 9999px;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.75rem;
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

.language-span {
    padding: 0.5rem 1.5rem 0.5rem 0;
}

.space-y-8>*:not(:last-child) {
    margin-bottom: 2rem;
    /* 2rem = 32px, equivalent to Tailwind's spacing scale of 8 */
}

.mobile-screen-languages {
    display: block;
}

.mobile-screen-languages-container {
    display: grid;
    gap: 2rem;
    align-items: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}


@media (min-width: 640px) {
    .responsive-paragraph {
        font-size: 1.25rem;
        /* 20px for sm screens */
    }

}


@media (min-width: 768px) {
    .SectionMargin {
        margin-top: 10rem
    }

    .languages-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .popular-languages {
        margin-top: 10rem
    }

    .mobile-screen-languages {
        display: none;
    }

    .desktop-screen-languages {
        display: block;
        --tw-space-y-reverse: 0;
        margin-top: calc(2rem
                /* 32px */
                * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2rem
                /* 32px */
                * var(--tw-space-y-reverse));
    }

    .grid-container-md-3 {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid-container-md-4 {
        grid-template-columns: repeat(4, 1fr);

    }
}

@media (min-width: 1024px) {
    .languages-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .popular-languages {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 20rem;
        gap: 1rem
    }

}

@media (min-width: 1280px) {
    .SectionMargin {
        margin-top: 20rem
    }

    .languages-container {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (min-width: 1536px) {
    .languages-container {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}
.quoteContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.quoteHeader {
  width: 85vw;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #18b7bb;
}

.quoteLogo {
  height: 60%;
  width: auto;
  object-fit: cover;
  cursor: pointer;
}

.quoteHeaderExtra {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.quoteHeaderExtraBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  cursor: pointer;
}

.quoteHeaderIcon {
  width: 20px;
  height: 20px;
  color: #fff;
}

.quoteHeaderExtraBlockText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  font-weight: 400;
  color: #fff;
  margin-top: 1.2vh;
  margin-bottom: 0;
}

.quoteHeaderConnector {
  width: 5vw;
  height: 0;
  border-bottom: 0.5px solid #fff;
  opacity: 0.3;
  margin-left: 1.3vw;
  margin-right: 1.3vw;
}

.quoteMainContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.quoteMainPrimaryContainer {
  width: 60%;
  height: auto;
  min-height: 100vh;
}

.quotePrimaryInner {
  width: calc(100% - 7.5vw);
  margin-left: 7.5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.quotePrimaryTitle {
  font-family: 'Montserrat';
  font-size: 1.15vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 6vh;
  margin-bottom: 1.5vh;
}

.quotePrimarySub {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 1.4vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 5vh;
}

.quoteSignInButton {
  border-bottom: 1px solid #18b7bb;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.quoteSignInButton:hover {
  background-color: rgba(24, 183, 187, 0.2);
}

.quoteForgotPassword {
  align-self: flex-end;
  margin-right: 3vw;
  font-family: 'Montserrat';
  font-size: 0.8vw;
  font-weight: 400;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 5vh;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.quoteForgotPassword:hover {
  filter: brightness(0.5);
}

.quotePrimaryForm {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2vh;
}

.quotePrimaryFormElement {
  width: 45%;
  height: auto;
  margin-right: 4%;
}

.quotePrimaryCheckboxContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 2vh;
  margin-bottom: 4vh;
}

.quoteCheckbox {
  width: 1.2vw;
  height: 1.2vw;
  border-radius: 3px;
  margin-right: 1vw;
  border: 1px solid #666;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.quoteCheckboxText {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.quoteExtraMessage {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 1.6vw;
  font-weight: 400;
  color: #333;
  margin-top: 1vh;
  margin-bottom: 3vh;
  width: 85%;
}

.quoteFormButton {
  margin-right: 3vw;
  align-self: flex-end;
}

.quoteMainSecondaryContainer {
  width: 35%;
  height: auto;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.quoteSecondaryTitle {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 6vh;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quoteSecondarySub {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 1.6vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 5vh;
  width: 90%;
}

.quoteSecondarySub a {
  color: #666;
  transition: color 0.3s ease-out;
}

.quoteSecondarySub a:hover {
  color: #333;
}

.quoteSecondaryLine {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3vh;
}

.quoteSecondaryLineText {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.2vw;
}

.quoteSecondaryExtraInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 3vh;
  width: calc(100% - 5vw);
}

.quoteSecondaryEncryptionImg {
  margin-top: 0vh;
  width: 30%;
  height: auto;
  object-fit: cover;
  margin-bottom: 8vh;
}

.quoteFooter {
  width: 100vw;
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  border-top: 1px solid rgba(102,102,102,0.3);
}

.quoteFooterLinksContainer {
  margin-right: 7.5vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2vh;
}

.quoteFooterLink {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5vw;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.quoteFooterLink:hover {
  filter: brightness(0.3);
}

.quoteFooterLinkSeparator {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  margin-top: 0.5vh;
  background-color: rgba(102,102,102,0.2);
  margin-left: 0.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .quoteHeader {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
    height: 8vh;
  }
  
  .quoteLogo {
    height: 50%;
  }
  
  .quoteHeaderExtraBlockText {
    font-size: 1.4vw;
    margin-top: 0.8vh;
  }
  
  .quoteMainPrimaryContainer {
    width: 52%;
    height: auto;
    min-height: 60vh;
  }
  
  .quotePrimaryInner {
    width: calc(100% - 5vw);
    margin-left: 5vw;
    margin-top: 3vh;
  }
  
  .quotePrimaryTitle {
    font-size: 2.4vw;
    line-height: 3.5vw;
    margin-top: 2vh;
    margin-bottom: 1.3vh;
  }
  
  .quotePrimarySub {
    font-size: 1.7vw;
    line-height: 2.6vw;
    margin-bottom: 3vh;
    width: calc(100% - 5vw);
  }
  
  .quoteSignInButton:hover {
    background-color: transparent;
  }
  
  .quoteForgotPassword {
    margin-right: 5vw;
    font-size: 1.7vw;
    margin-top: 0vh;
    margin-bottom: 4vh;
  }
  
  .quoteForgotPassword:hover {
    filter: brightness(1);
  }
  
  .quotePrimaryForm {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2vh;
  }
  
  .quotePrimaryFormElement {
    width: calc(100% - 5vw);
    height: auto;
    margin-right: 5vw;
  }
  
  .quotePrimaryCheckboxContainer {
    margin-top: 1vh;
    margin-bottom: 4vh;
  }
  
  .quoteCheckbox {
    width: 2.4vw;
    height: 2.4vw;
    border-radius: 3px;
    margin-right: 2vw;
  }
  
  .quoteCheckboxText {
    font-size: 1.8vw;
  }
  
  .quoteExtraMessage {
    font-size: 1.6vw;
    line-height: 3.2vw;
    width: calc(100% - 5vw);
  }
  
  .quoteFormButton {
    margin-right: 5vw;
    align-self: flex-end;
  }
  
  .quoteMainSecondaryContainer {
    width: 47%;
    min-height: 70vh;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  
  .quoteSecondaryTitle {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    font-weight: 700;
    color: #18b7bb;
    margin-top: 5vh;
    margin-bottom: 1.3vh;
  }
  
  .quoteSecondarySub {
    font-size: 1.7vw;
    line-height: 3.4vw;
    margin-bottom: 5vh;
    width: 100%;
  }
  
  .quoteSecondaryLine {
    margin-bottom: 2vh;
    width: calc(100% - 5vw);
  }
  
  .quoteSecondaryLineText {
    font-size: 1.8vw;
    margin-left: 1.2vw;
  }
  
  .quoteSecondaryExtraInfo {
    margin-top: 0vh;
  }
  
  .quoteSecondaryEncryptionImg {
    width: 40%;
  }
  
  .quoteFooterLinksContainer {
    margin-right: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2vh;
  }
  
  .quoteFooterLink {
    font-size: 1.8vw;
    line-height: 1.8vw;
    margin-left: 1vw;
  }
  
  .quoteFooterLink:hover {
    filter: brightness(1);
  }
  
  .quoteFooterLinkSeparator {
    margin-top: 0vh;
    margin-left: 1vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .quoteHeader {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
    height: 10vh;
  }
  
  .quoteLogo {
    height: 40%;
  }
  
  .quoteHeaderExtraBlockText {
    font-size: 3vw;
    margin-top: 0.7vh;
  }
  
  .quoteHeaderConnector {
    width: 7vw;
  }
  
  .quoteMainContainer {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .quoteMainPrimaryContainer {
    width: 100%;
    height: auto;
    min-height: auto;
    margin-bottom: 5vh;
  }
  
  .quotePrimaryInner {
    width: 90%;
    margin-left: 5vw;
  }
  
  .quotePrimaryTitle {
    font-family: 'Montserrat';
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 700;
    color: #18b7bb;
    margin-top: 4vh;
    margin-bottom: 1.5vh;
  }
  
  .quotePrimarySub {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    line-height: 6vw;
    margin-bottom: 3vh;
  }
  
  .quoteSignInButton:hover {
    background-color: transparent;
  }
  
  .quoteForgotPassword {
    margin-right: 5vw;
    font-size: 3vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
  
  .quoteForgotPassword:hover {
    filter: brightness(1);
  }
  
  .quotePrimaryForm {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }
  
  .quotePrimaryFormElement {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 2vh;
  }
  
  .quotePrimaryCheckboxContainer {
    margin-top: 2vh;
    margin-bottom: 4vh;
  }
  
  .quoteCheckbox {
    width: 6vw;
    height: 6vw;
    border-radius: 3px;
    margin-right: 3vw;
    border: 1px solid rgba(102,102,102,0.7);
  }
  
  .quoteCheckboxText {
    font-size: 3.5vw;
  }
  
  .quoteExtraMessage {
    font-size: 3.2vw;
    line-height: 6.4vw;
    margin-top: 1vh;
    margin-bottom: 3vh;
    width: 100%;
  }
  
  .quoteFormButton {
    margin-right: 0;
    align-self: flex-end;
  }
  
  .quoteMainSecondaryContainer {
    width: 90%;
    height: auto;
    min-height: auto;
    background-color: transparent;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .quoteSecondaryTitle {
    font-size: 4vw;
    margin-top: 3vh;
    margin-bottom: 1.5vh;
  }
  
  .quoteSecondarySub {
    font-size: 3.5vw;
    line-height: 6vw;
    margin-bottom: 5vh;
    width: 100%;
  }
  
  .quoteSecondaryLine {
    margin-bottom: 3vh;
  }
  
  .quoteSecondaryLineText {
    font-size: 3.5vw;
    margin-left: 4vw;
  }
  
  .quoteSecondaryExtraInfo {
    margin-top: 3vh;
  }
  
  .quoteSecondaryEncryptionImg {
    margin-top: 0vh;
    margin-bottom: 4vh;
    width: 45%;
    height: auto;
    object-fit: cover;
  }
  
  .quoteFooter {
    width: 100vw;
    height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-top: 1px solid rgba(102,102,102,0.3);
  }
  
  .quoteFooterLinksContainer {
    margin-right: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3vh;
  }
  
  .quoteFooterLink {
    font-size: 3.5vw;
    line-height: 3.5vw;
    margin-left: 2vw;
  }
  
  .quoteFooterLink:hover {
    filter: brightness(1);
  }
  
  .quoteFooterLinkSeparator {
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    margin-top: 0vh;
    background-color: rgba(102,102,102,0.2);
    margin-left: 2vw;
  }
}

@media (orientation: landscape) and (max-width: 1024px) {
  .quoteHeaderIcon {
    width: 12px;
    height: 12px;
    color: #fff;
  }
}
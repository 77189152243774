.topBar{
    position: relative;
    width: 100%;
    background-color: #D83D1E;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    color: #fff;
  }
  
  .topBar img{
    width: 25px;
    height: 25px;
    margin-left: 20px;
  }

  .topBar p{
    font-family: 'Montserrat';
    font-size: 0.85rem;
    font-weight: 700;
    padding: 0 10px 0 20px;
  }

  .topBar .closeBtn{
    cursor: pointer;
    margin-right: 20px;
  }
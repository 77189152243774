.simpleSubMenu {
    display: none;
    position: absolute;
    top: 100%;
    /* Position below the button */
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 1rem;
    z-index: 10;
}

.group:hover .simpleSubMenu {
    display: block;
}


.custom-dashed-line {
    border-top: 1px dashed;
    border-color: #FFFFFF;
    border-style: dashed;
    border-width: 1px;
    border-image: repeating-linear-gradient(to right,
            #FFFFFF 0,
            #FFFFFF 2%,
            transparent 2%,
            transparent 4%) 100% 1;
}

.custom-dashed-line-gray {
    border-top: 1px dashed;
    border-color: #969696;
    border-style: dashed;
    border-width: 1px;
    border-image: repeating-linear-gradient(to right,
            #969696 0,
            #969696 2%,
            transparent 2%,
            transparent 4%) 100% 1;
}

@keyframes up-down-right {

    0%,
    100% {
        transform: translate(0px, 0px) scale(1);
    }

    50% {
        transform: translate(20px, 60px) scale(1);
    }

}

@keyframes up-down-left {

    0%,
    100% {
        transform: translate(-100px, -20px) scale(1);
    }

    50% {
        transform: translate(-80px, 80px) scale(1);
    }

}

@keyframes scale-in-out {

    0%,
    100% {
        transform: translate(0px, 0px) scale(1);
    }

    50% {
        transform: translate(0px, 20px) scale(1.2);
    }

}

.animated-left-down-scale {
    animation: up-down-left-scale 2s ease-in-out infinite;
}


@keyframes fade-in-out {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@keyframes up-down-left-fade {
    0% {
        transform: translate(-100px, -20px) scale(1);
        opacity: 1;
    }

    50% {
        transform: translate(-80px, 80px) scale(1);
        opacity: 0;
    }

    100% {
        transform: translate(-100px, -20px) scale(1);
        opacity: 1;
    }
}

.animated-sync {
    animation: up-down-left-fade 2s ease-in-out infinite;
}

.animated-circle {
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


.orange-button {
    background-color: #ff7c1d;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 500;
    /* Medium font weight */
    padding: 0.5rem 2.5rem;
    /* Vertical and horizontal padding */
    border-radius: 9999px;
    /* Full rounded corners */
    width: fit-content;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.orange-button:hover {
    background-color: #d96814;
    /* Hover background color */
}
.orderOptionalExtraContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.orderOptionalPrice {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .orderOptionalExtraContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    margin-top: 1.5vh;
  }
  
  .orderOptionalPrice {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.8vw;
    color: #18b7bb;
    margin-top: 1vh;
    margin-bottom: 0;
    margin-right: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .orderOptionalExtraContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    margin-top: 1.5vh;
  }
  
  .orderOptionalPrice {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 3.5vw;
    color: #18b7bb;
    margin-top: 1.5vh;
    margin-bottom: 0;
    margin-right: 0;
  }
}
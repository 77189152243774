.singleOrderActionButtonsContainer {
  width: calc(100% - 5vw);
  height: 5vh;
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10vh;
}

.revisionModalBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 200;
}

.revisionModalOuter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  max-height: 90%;
  overflow: auto;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.3);
  z-index: 2000;
  display: block;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.revisionModalOuter::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.revisionModalOuter {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.singleOrderTextButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleOrderRichTextButton {
  width: 15px;
  height: 15px;
  margin-right: 0.5vw;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
  background-color: rgba(255,255,255,0);
  padding: 3px;
  border-radius: 3px;
}

.singleOrderRichTextButton:hover {
  background-color: rgba(255,255,255,0.8);
  color: #666;
}

.singleOrderRevisionButtons {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleOrderRevisionButtonsSeparator {
  width: 0.5vw;
}

.confirmationModalOuter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: 35%;
  height: auto;
  max-height: 90%;
  overflow: auto;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.3);
  z-index: 2000;
  display: block;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.confirmationModalOuter::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.confirmationModalOuter {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.confirmationModalCheckContainer {
  width: 12vw;
  height: 12vw;
  border-radius: 6vw;
  background-color: #18b7bb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 6vh;
}

.confirmationModalCheck {
  width: 7.5vw;
  height: 7.5vw;
  color: #fff;
}

.confirmationModalTitle {
  align-self: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 2vw;
  color: #666;
  margin-top: 7vh;
  margin-bottom: 7vh;
}

.confirmationModalText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  line-height: 2vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 2vh;
}

.confirmationModalSubtitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  color: #18b7bb;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.confirmationModalCloseContainer {
  margin-top: 3vh;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleOrderReviewSeparator {
  height: 2vh;
}

.singleOrderReviewSelectContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vh;
}

.singleOrderReviewSliderContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
}

.singleOrderReviewSliderResult {
  margin-left: 2vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.singleOrderReviewSlider::-webkit-slider-thumb {
  background: #18b7bb;
}

.singleOrderReviewSlider::-moz-range-thumb {
  background: #18b7bb; /* Green background */
}

.singleOrderReviewMessage {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 5vh;
}

.singleOrderSubText {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 0.9vw;
  line-height: 2vw;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

.singleOrderHardCopySeparator {
  height: 3vh;
}

.checkboxContainer{
  padding: 0.5rem 0;
}

.checkboxWrap{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  cursor: default;
}



.nameText{
  margin-bottom: 1vh !important;
  line-height: 1.4 !important;
}

.contentWidth{
  width: 100% !important;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleOrderActionButtonsContainer {
    width: calc(100% - 3vw);
    height: 10vh;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5vh;
  }
  
  .revisionModalOuter {
    width: 80%;
    height: auto;
    max-height: 60%;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  
  .singleOrderRichTextButton:hover {
    background-color: rgba(255,255,255,0);
  }
  
  .singleOrderRevisionButtonsSeparator {
    width: 1vw;
  }

  .confirmationModalOuter {
    width: 60%;
    height: auto;
    max-height: 60%;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  
  .confirmationModalCheckContainer {
    width: 15vw;
    height: 15vw;
    border-radius: 7.5vw;
    margin-top: 2vh;
  }
  
  .confirmationModalCheck {
    width: 10vw;
    height: 10vw;
    color: #fff;
  }
  
  .confirmationModalTitle {
    font-size: 3vw;
    color: #666;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .confirmationModalText {
    font-size: 1.6vw;
    line-height: 3.6vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 1.5vh;
  }
  
  .confirmationModalSubtitle {
    font-size: 1.8vw;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }
  
  .confirmationModalCloseContainer {
    margin-top: 3vh;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .singleOrderReviewSelectContainer {
    width: 100%;
  }
  
  .singleOrderReviewSliderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5vh;
    width: 100%;
  }
  
  .singleOrderReviewSliderResult {
    margin-left: 2vw;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.8vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .singleOrderReviewSlider::-webkit-slider-thumb {
    background: #18b7bb;
  }
  
  .singleOrderReviewSlider::-moz-range-thumb {
    background: #18b7bb; /* Green background */
  }

  .singleOrderReviewMessage {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 1.6vw;
    line-height: 3vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 2vh;
  }

  .singleOrderSubText {
    font-size: 1.8vw;
    line-height: 3.7vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleOrderActionButtonsContainer {
    width: calc(100% - 3vw);
    height: 16vh;
    margin-top: 0;
    margin-bottom: 10vh;
  }
  
  .revisionModalOuter {
    width: 86%;
    height: auto;
    max-height: 85%;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 2vh;
    padding-top: 2vh;
  }

  .singleOrderRichTextButton:hover {
    background-color: rgba(255,255,255,0);
  }
  
  .singleOrderRevisionButtonsSeparator {
    width: 1vw;
  }

  .confirmationModalOuter {
    width: 85%;
    height: auto;
    max-height: 70vh;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 2vh;
    padding-top: 2vh;
  }
  
  .confirmationModalCheckContainer {
    width: 18vw;
    height: 18vw;
    border-radius: 9vw;
    margin-top: 2vh;
  }
  
  .confirmationModalCheck {
    width: 12vw;
    height: 12vw;
    color: #fff;
  }
  
  .confirmationModalTitle {
    font-size: 4.8vw;
    color: #666;
    margin-top: 3vh;
    margin-bottom: 4vh;
  }
  
  .confirmationModalText {
    font-size: 3.2vw;
    line-height: 7.5vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 2.5vh;
  }
  
  .confirmationModalSubtitle {
    font-size: 3.5vw;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }
  
  .confirmationModalCloseContainer {
    margin-top: 3vh;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .singleOrderReviewSlider {
    width: 100%;
  }

  .singleOrderReviewSliderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5vh;
    width: 100%;
  }
  
  .singleOrderReviewSliderResult {
    margin-left: 3vw;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 3.5vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .singleOrderReviewSlider::-webkit-slider-thumb {
    background: #18b7bb;
  }
  
  .singleOrderReviewSlider::-moz-range-thumb {
    background: #18b7bb; /* Green background */
  }

  .singleOrderReviewMessage {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 3.5vw;
    line-height: 7vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 2vh;
  }

  .singleOrderSubText {
    font-size: 3.5vw;
    line-height: 7.5vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  .singleOrderActionButtonsContainer {
    width: calc(100% - 3vw);
    height: 17vh;
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10vh;
  }
  
  .singleOrderRichTextButton {
    width: 11px;
    height: 11px;
  }

  .singleOrderRichTextButton:hover {
    background-color: rgba(255,255,255,0);
  }
}
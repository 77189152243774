.accountContainer {
  width: 82vw;
  padding-left: 9vw;
  padding-right: 9vw;
  padding-top: 7vh;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid rgba(102,102,102,0.2);
  margin-top: 3vh;
  margin-bottom: 10vh;
}

.accountSidebar {
  width: 23%;
  padding-right: 2%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.accountSidebarTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.7vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 5vh;
}

.accountSidebarLink {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  color: rgba(102,102,102,0.5);
  margin-top: 0;
  margin-bottom: 2.5vh;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.accountSidebarLink:hover {
  filter: brightness(0.6);
}

.accountMainContainer {
  width: 45%;
  padding-left: 2%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.accountMainTitleRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}

.accountMainTitle {
  font-family: 'Montserrat';
  font-weight: 900;
  font-size: 2.5vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 0;
}

.accountProfileFormLine {
  width: 100%;
  height: auto;
  margin-bottom: 2vh;
}

.accountProfileFormButton {
  width:400px;
  align-self: flex-end;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .accountContainer {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 5vh;
    margin-top: 2vh;
    margin-bottom: 10vh;
  }
  
  .accountSidebar {
    width: 23%;
    padding-right: 2%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .accountSidebarTitle {
    font-size: 2.2vw;
  }
  
  .accountSidebarLink {
    font-size: 1.8vw;
    margin-bottom: 2vh;
  }
  
  .accountSidebarLink:hover {
    filter: brightness(1);
  }
  
  .accountMainContainer {
    width: 55%;
    padding-left: 2%;
  }
  
  .accountMainTitleRow {
    margin-bottom: 3.5vh;
  }
  
  .accountMainTitle {
    font-size: 2.5vw;
  }
  
  .accountProfileFormLine {
    width: 100%;
    height: auto;
    margin-bottom: 2vh;
  }
  
  .accountProfileFormButton {
    align-self: flex-end;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .accountContainer {
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .accountSidebar {
    width: 100%;
    padding-right: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3vh;
    background-color: #f5f5f5;
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-bottom: 1px solid rgba(102,102,102,0.2);
    overflow: hidden;
  }
  
  .accountSidebarTitle {
    font-size: 4.5vw;
  }
  
  .accountSidebarLink {
    font-size: 3.8vw;
    margin-bottom: 2.5vh;
  }
  
  .accountMainContainer {
    width: 86%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .accountMainTitleRow {
    width: 98%;
    margin-bottom: 3.5vh;
  }
  
  .accountMainTitle {
    font-size: 5vw;
  }
  
  .accountProfileFormLine {
    width: 100%;
    height: auto;
    margin-bottom: 2vh;
  }
  
  .accountProfileFormButton {
    align-self: flex-end;
  }
}
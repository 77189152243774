.useContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.useCaseBlock {
  width: 15vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 2vw;
}

.useCaseImgContainer {
  width: 100%;
  height: 43vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  background-color: rgba(24, 183, 187, 0.05);
  border: 1px solid #e1e1e1;
  transition: all 0.3s ease-out;
}

.useCaseImgContainer:hover {
  background-color: rgba(24, 183, 187, 0.4);
  border: 1px solid #c1c1c1;
}

.useCaseImg {
  height: auto;
  width: 80%;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

.useCaseImgContainer:hover .useCaseImg {
  transform: scale(1.2);
}

.useCaseBlockText {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 0;
}

.useCaseInfoContainer {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2vw;
}

.useCaseInfoTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
}

.useCaseInfoSub {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.useCaseInfoButtonContainer {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.useCaseInfoButtonText {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.3vh;
  margin-right: 0.7vw;
  cursor: pointer;
  transition: margin-right 0.3s ease-out;
}

.useCaseInfoButtonText:hover {
  margin-right: 1.2vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .nonMobileBlock {
    display: none;
  }

  .useContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .useCaseBlock {
    width: 25%;
    margin-right: 2vw;
  }
  
  .useCaseImgContainer {
    width: 100%;
    height: 25vh;
  }
  
  .useCaseImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #e1e1e1;
  }
  
  .useCaseBlockText {
    font-size: 1.8vw;
  }
  
  .useCaseInfoContainer {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2vw;
  }
  
  .useCaseInfoTitle {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1vh;
  }
  
  .useCaseInfoSub {
    width: 100%;
    font-family: 'Montserrat';
    font-size: 2vw;
    line-height: 4vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .useCaseInfoButtonContainer {
    margin-top: 3vh;
  }
  
  .useCaseInfoButtonText {
    font-size: 2.5vw;
    margin-bottom: 0.7vh;
  }
  
  .useCaseInfoButtonText:hover {
    margin-right: 1.8vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .nonMobileBlock {
    display: none !important;
  }
  
  .useContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    display: inline-block;
  }
  
  .useCaseBlock {
    display: inline-block;
    width: 45%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-bottom: 4vh;
  }
  
  .useCaseImgContainer {
    width: 100%;
    height: 30vh;
  }
  
  .useCaseImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #e1e1e1;
  }
  
  .useCaseBlockText {
    font-size: 3vw;
  }
  
  .useCaseInfoContainer {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2vw;
  }
  
  .useCaseInfoTitle {
    font-family: 'Montserrat';
    font-size: 6.5vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .useCaseInfoSub {
    font-size: 4vw;
    line-height: 9.3vw;
  }
  
  .useCaseInfoButtonContainer {
    margin-top: 4vh;
  }
  
  .useCaseInfoButtonText {
    font-size: 4.5vw;
    margin-bottom: 0.4vh;
    margin-right: 1.5vw;
  }
  
  .useCaseInfoButtonText:hover {
    margin-right: 4vw;
  }
}
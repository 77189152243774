.homeContainer {
  width: 79.5vw;
  height: auto;
  padding-left: 9vw;
  padding-right: 9vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.home-Container {
  /* width: 79.5vw; */
  height: auto;
  padding-left: 9vw;
  padding-right: 9vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.homeHeaderBg {
  position: absolute;
  top: 0;
  right: 0;
  height: 93.5vh;
  width: 80vw;
  z-index: 0;
  pointer-events: none;
}

.headerContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 13vh;
}

.headerInfoContainer {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.heading {
  font-family: 'Montserrat';
  font-size: 2.6vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.subheading {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 1.2vw;
  line-height: 2.4vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 2vh;
}

.subheadingFootnote {
  align-self: flex-end;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 5vh;
}

.headerImg {
  width: 45%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.extraInfoContainer {
  margin-top: 10vh;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.extraInfoTextContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2vh;
  cursor: pointer;
}

.extraInfoText {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1vw;
  transition: margin-right 0.3s ease-out;
}

.extraInfoTextContainer:hover .extraInfoText {
  margin-right: 1.5vw;
}

.extraInfoButton {
  width: 20px;
  height: 20px;
  color: #666;
}

.extraInfoUnderline {
  width: 10vw;
  height: 0;
  border-bottom: 3px solid #18b7bb;
  border-radius: 100px;
}

.certificationContainer {
  margin-top: 15vh;
  margin-bottom: 10vh;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  align-self: flex-start;
}

.certificationItemContainer {
  width: auto;
  max-width: 14vw;
  height: auto;
  margin-right: 7vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.certificationLogo {
  width: auto;
  height: 4.5vh;
  object-fit: cover;
  margin-bottom: 2vh;
}

.certificationText {
  width: 100%;
  height: auto;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .homeContainer {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .homeHeaderBg {
    height: 40.5vh;
    width: 88vw;
    display: none;
  }

  .headerInfoContainer {
    width: 52%;
  }

  .headerImg {
    width: 45%;
    height: auto;
    margin-right: 0;
    object-fit: cover;
    z-index: 10;
    align-self: flex-start;
  }

  .headerContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 7vh;
  }

  .heading {
    font-family: 'Montserrat';
    font-size: 4vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1vh;
  }

  .subheading {
    width: 95%;
    font-size: 1.8vw;
    line-height: 4vw;
    margin-bottom: 1.5vh;
  }

  .subheadingFootnote {
    font-size: 1.4vw;
    margin-bottom: 4vh;
    margin-right: 5%;
  }

  .extraInfoContainer {
    margin-top: 4vh;
  }

  .extraInfoTextContainer {
    margin-bottom: 0.8vh;
  }

  .extraInfoText {
    font-size: 1.4vw;
  }

  .extraInfoUnderline {
    width: 8vw;
    border: 1px solid #18b7bb;
  }

  .certificationContainer {
    margin-top: 7vh;
    margin-bottom: 7vh;
  }

  .certificationItemContainer {
    max-width: 18vw;
    margin-right: 5vw;
  }

  .certificationLogo {
    height: 2.8vh;
    margin-bottom: 1.5vh;
  }

  .certificationText {
    font-size: 1.3vw;
    line-height: 2.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .homeHeaderBg {
    display: none;
  }

  .headerInfoContainer {
    width: 100%;
  }

  .headerImg {
    display: none;
  }

  .headerContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 7vh;
  }

  .heading {
    font-size: 7.5vw;
    margin-bottom: 2vh;
  }

  .subheading {
    font-size: 4.7vw;
    line-height: 10vw;
    margin-bottom: 4vh;
  }

  .subheadingFootnote {
    font-size: 3.5vw;
    margin-bottom: 7vh;
  }

  .extraInfoContainer {
    margin-top: 7vh;
  }

  .extraInfoTextContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1vh;
  }

  .extraInfoText {
    font-size: 4vw;
    line-height: 6vw;
    margin-right: 3vw;
  }

  .extraInfoButton {
    width: 30px;
    height: 30px;
  }

  .extraInfoUnderline {
    width: 20vw;
    border: 1px solid #18b7bb;
  }

  .certificationContainer {
    display: none;
  }
}
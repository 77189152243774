.translationServiceSection {
    margin-top: 7rem;
}

.ServiceHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
}

.Service-section-subtitle {
    text-align: center;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
}

.service-card-container {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: center;
    gap: 2rem;
}

.bg-blue {
    background-color: #1EB8BC;
}

.bg-white {
    background-color: #fff;
}

.text-white {
    color: #fff;
}

.service-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.service-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 32px;
    --tw-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    --tw-shadow-colored: 0 12px 25px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 2rem 2.5rem;
}

.service-card-title {
    font-weight: bold;
    font-family: "Montserrat";
    font-size: 24px;
    text-align: center;
}

.service-card-subtitle {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Montserrat";
    line-height: 1.5rem;
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    /* Adjust gap between items */
    margin-top: 0.5rem;
}

.price-tag {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    padding: 0.25rem 1rem;
    border-radius: 1.5rem;
    background-color: #f2f2f2;
    transition: background-color 0.3s ease;
    cursor: default;
    /* Default cursor */
}

.price-tag:hover {
    background-color: #d9d9d9;
    /* Hover background color */
}

.text-muted {
    color: #e0e0e0;
    font-size: 1.125rem;
    /* Default font size for text */
}

.crad-divider {
    width: 100%;
}

.card-features-container {
    display: flex;
    flex-direction: column;
}

.card-features-title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 1rem 0;
    font-family: "Montserrat";
}

.text-light-gray {
    color: #F2F2F2;
}

.card-features-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.feature-item {
    font-weight: bold;
    font-family: "Montserrat";
    font-size: 1.125rem;
    line-height: 1.75rem
}

.feature-value {
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 1.125rem;
    line-height: 1.75rem
}

.dashed-line {
    width: 100%;
    border-top: 1px dashed;
    margin: 1rem 0;
}

.mb-2{
    margin-bottom: 0.5rem;
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1))
}

.border-gray {
    border-color: #969696
}

.text-gary {
    color: #969696
}


@media (min-width: 640px) {
    .Service-section-subtitle {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width: 768px) {
    .translationServiceSection {
        margin-top: 10rem !important;
    }

    .Service-section-subtitle {
        max-width: 55%;
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .service-card-title {
        font-size: 28px;
    }

    .service-card-subtitle {
        font-size: 1.125rem;
        line-height: 1.75rem;
        max-width: 70%;
    }
}

@media (min-width: 1024px) {
    .service-card-container {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}

@media (min-width: 1280px) {
    .translationServiceSection {
        margin-top: 20rem !important;
    }

    .Service-section-subtitle {
        font-size: 28px;
        line-height: 2.5rem;
    }

    .service-card-title {
        font-size: 32px;
    }
}

@media (min-width: 1536px) {}
.passwordChangedForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 25px;
  border-radius: 8px;
}

@media(max-width:450px){
  .passwordChangedForm{
    width: 300px;
  }
}

.passwordChangedFormTitle{
    font-family: "Oswald", sans-serif;
    font-size: 24px;
}

.signInFormIcon {
  color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.passwordChangedFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 19px;
}

.passwordChangedFormText {
  color: #686F7B;
  align-self: center;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

@media(max-width:450px){
  .passwordChangedForm{
    text-align: center;
  }
}

.passwordChangedFormButton {
  padding: 10px 16px 10px 16px;
  background-color: #35c0cc;
  font-family: "Inter", sans-serif;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
}

.passwordChangedFormButton:hover,
.passwordChangedFormButton:focus{
  background-color: #4ec1d0;
}

.passwordChangedFormButton:disabled{
  background-color: #b3e4eb;
}

.passwordChangedFormSuccess{
  align-self: center;
  padding-bottom: 20px;
}
.titleWrapper {
  width: 100%;
  text-align: center;
}

.paymentTitle {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.1vw;
  color: #000;
  margin-top: 0;
  margin-bottom: 1vh;
  transition: opacity 0.3s ease-out;
}

.freqencyWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 1rem 0;
}

.frequencySelector {
  color: #000;
  font-size: 1vw;
  font-family: "Montserrat";
  background-color: #18b7bb25;
  border: 0;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.frequencySelector:hover,
.selectedFrequency {
  background-color: #18b7bb80;
}

.frequencyData {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 3rem;
  margin: 1rem 0;
}

.dataRangeWrapper {
  min-width: 35%;
}

.frequencyData .dataRange,
.frequencyData .orderData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;
  text-transform: capitalize;
  margin: 0.2rem 0;
  font-family: "Montserrat";
}

.frequencyData .dataRange,
.makePaymentWrapper .text {
  color: #000;
  font-size: 0.95vw;
  font-weight: 700;
  cursor: pointer;
}

.frequencyData .dataRange .date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dataRange .checkbox {
  width: 20px;
  margin-right: 10px;
}
.frequencyData .orderData,
.makePaymentWrapper .subText {
  color: #666;
  font-size: 0.9vw;
}

.frequencyData .paymentStatus,
.paymentBtn {
  min-width: 160px;
  font-size: 0.8vw;
  font-weight: 700;
  text-align: center;
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
  border-radius: 5px;
}

.paymentBtn {
  text-transform: none;
}

.paid {
  color: #000;
  cursor: default;
  background-color: rgb(23, 188, 91);
}
.no-order {
  color: #000;
  cursor: default;
  background-color: rgb(225, 80, 74);
}
.awaiting {
  color: #000;
  cursor: pointer;
  background-color: rgb(246, 201, 7);
}

.makePaymentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 1rem 0;
}

.paymentBtn {
  background-color: rgb(59, 230, 59);
}
.paymentBtn:hover {
  background-color: rgb(100, 255, 100);
  cursor: pointer;
}

.loadingWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000025;
  z-index: 11000;
}

.loadingWrapper .icon {
  width: 50px;
  height: 50px;
  animation: loading 2s linear infinite;
  color: #1e7e81;
}

@keyframes loading {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}

@media (orientation: portrait) and (max-width: 1024px) {
  .paymentTitle {
    font-size: 1.8vw;
  }
  .frequencySelector {
    font-size: 1.6vw;
  }

  .frequencyData .dataRange,
  .makePaymentWrapper .text {
    font-size: 1.6vw;
  }

  .frequencyData .orderData,
  .makePaymentWrapper .subText {
    font-size: 1.5vw;
  }

  .frequencyData .paymentStatus,
  .paymentBtn {
    min-width: 150px;
    font-size: 1.3vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .paymentTitle {
    font-size: 3.5vw;
  }
  .frequencySelector {
    font-size: 3.2vw;
  }

  .frequencyData .dataRange,
  .makePaymentWrapper .text {
    font-size: 3.2vw;
  }

  .frequencyData .orderData,
  .makePaymentWrapper .subText {
    font-size: 3vw;
  }

  .frequencyData .paymentStatus,
  .paymentBtn {
    min-width: 100px;
    font-size: 2.2vw;
  }
}

.singlePaymentInformation {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 5vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

.singlePaymentMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singlePaymentTableHeader {
  margin-top: 1vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid #e1e1e1;
}

.singlePaymentTableLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  border-bottom: 1px solid rgba(225,225,225,0.5);
}

.singlePaymentNumberColumn {
  width: 19%;
}

.singlePaymentSubmittedColumn {
  width: 19%;
}

.singlePaymentWordsColumn {
  width: 9%;
}

.singlePaymentRateColumn {
  width: 9%;
}

.singlePaymentTotalColumn {
  width: 9%;
}

.singlePaymentStatusColumn {
  width: 14%;
}

.singlePaymentIconColumn {
  width: 4%;
}

.singlePaymentDecideTitleContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
}

.singlePaymentDecideTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333;
  font-size: 1.1vw;
  margin-top: 0;
  margin-bottom: 0;
}

.singlePaymentClickIcon {
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singlePaymentClickIcon:hover {
  opacity: 0.6;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singlePaymentInformation {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
  }

  .singlePaymentNumberColumn {
    width: 38%;
  }
  
  .singlePaymentSubmittedColumn {
    display: none;
  }
  
  .singlePaymentWordsColumn {
    display: none;
  }
  
  .singlePaymentRateColumn {
    display: none;
  }
  
  .singlePaymentTotalColumn {
    width: 16%;
  }
  
  .singlePaymentStatusColumn {
    width: 27%;
  }
  
  .singlePaymentIconColumn {
    width: 4%;
  }
  
  .singlePaymentDecideTitle {
    font-size: 1.8vw;
  }
  
  .singlePaymentClickIcon:hover {
    opacity: 1;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singlePaymentInformation {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
  }

  .singlePaymentNumberColumn {
    width: 35%;
  }
  
  .singlePaymentSubmittedColumn {
    display: none;
  }
  
  .singlePaymentWordsColumn {
    display: none;
  }
  
  .singlePaymentRateColumn {
    display: none;
  }
  
  .singlePaymentTotalColumn {
    width: 16%;
  }
  
  .singlePaymentStatusColumn {
    width: 30%;
  }
  
  .singlePaymentIconColumn {
    width: 4%;
  }
  
  .singlePaymentDecideTitle {
    font-size: 3.8vw;
  }
  
  .singlePaymentClickIcon:hover {
    opacity: 1;
  }
}
.singleLanguageHeading {
  font-family: 'Montserrat';
  font-size: 2.8vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.singleLanguageSubheading {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 1.1vw;
  line-height: 2.8vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 5vh;
}

.singleLanguageMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.singleLanguageMainImage {
  width: 38%;
  height: auto;
  object-fit: cover;
}

.singleLanguageMainInfoContainer {
  width: 55%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleLanguageMainInfoExtra {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 5vh;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleLanguageHeading {
    font-family: 'Montserrat';
    font-size: 4vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1vh;
  }
  
  .singleLanguageSubheading {
    width: 95%;
    font-size: 1.8vw;
    line-height: 4vw;
    margin-bottom: 4vh;
  }

  .singleLanguageMainContainer {
    align-items: flex-start;
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .singleLanguageMainImage {
    width: 50%;
    margin-top: 5vh;
  }
  
  .singleLanguageMainInfoContainer {
    width: 50%;
  }
  
  .singleLanguageMainInfoExtra {
    font-size: 2vw;
    margin-top: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleLanguageHeading {
    font-size: 7.5vw;
    margin-bottom: 2vh;
  }
  
  .singleLanguageSubheading {
    font-size: 4.7vw;
    line-height: 10vw;
    margin-bottom: 5vh;
  }

  .singleLanguageMainContainer {
    align-items: flex-start;
    margin-top: 10vh;
    margin-bottom: 8vh;
  }
  
  .singleLanguageMainImage {
    display: none;
  }
  
  .singleLanguageMainInfoContainer {
    width: 100%;
  }
  
  .singleLanguageMainInfoExtra {
    font-size: 3.5vw;
  }
}
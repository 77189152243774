.formInputSimpleContainer {
  position: relative;
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1vh;
  max-width: 600px;
}

.formInputSimpleLabel {
  margin-bottom: 1vh;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1vw;
  color: #666;
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
}

.formInputSimple {
  color: #666;
  background-color: transparent;
  border-bottom: 1px solid rgba(102, 102, 102, 0.7);
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.95vw;
  line-height: 1vw;
  margin-top: 0;
  margin-bottom: 1vh;
  height: 2vw;
  width: inherit;
  padding-left: 0;
  padding:  10px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid rgba(102, 102, 102, 0.7);
}

.formInputSimple::placeholder {
  color: rgba(102, 102, 102, 0.8);
}

.formInputSimple:focus,.formInputSimple:valid, .validInput{
  border-color: #18b7bb !important;
}

.formInputSimple:focus ~ .formInputSimpleLabel, .formInputSimple:valid ~ .formInputSimpleLabel, .validInputLabel{
  padding: 5px 10px;
  background: white;
  color: #18b7bb !important;
  transform: translateX(10px) translateY(-21px);
  font-size: 0.9vw;
}


@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    /* font-size: 1.8vw; */
    font-size: 2vw;
    line-height: 2vw;
  }
  
  .formInputSimple {
    font-size: 2vw;
    line-height: 2vw;
    height: 4vw;
  }

  .formInputSimple:focus ~ .formInputSimpleLabel, .formInputSimple:valid ~ .formInputSimpleLabel, .validInputLabel{
    line-height: 1.8vw;
    font-size: 1.8vw;
  }
  
}

@media (orientation: portrait) and (max-width: 767px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 3.6vw;
    line-height: 3.6vw;
    /* font-size: 2.7vw;
    line-height: 2.7vw; */
  }
  
  .formInputSimple {
    font-size: 3.6vw;
    line-height: 3.6vw;
    height: 8vw;

    width: 95%;
  }

  .formInputSimple:focus ~ .formInputSimpleLabel, .formInputSimple:valid ~ .formInputSimpleLabel, .validInputLabel{
    font-size: 2.7vw;
    line-height: 2.7vw;
  }
}

.standardExtraContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.standardExtraTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
}

.standardExtraSub {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 8vh;
}

.standardExtraBlocksContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.standardExtraBlock {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.standardExtraBlockInfo {
  margin-left: 1.5vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.standardExtraBlockTitle {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  line-height: 1.3vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.standardExtraBlockText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.8vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .standardExtraContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .standardExtraTitle {
    font-size: 3.5vw;
    margin-bottom: 1vh;
  }
  
  .standardExtraSub {
    font-size: 2.3vw;
    margin-bottom: 5vh;
  }
  
  .standardExtraBlock {
    width: 46%;
  }
  
  .standardExtraBlockInfo {
    margin-left: 1.5vw;
  }
  
  .standardExtraBlockTitle {
    font-family: 'Montserrat';
    font-size: 2.5vw;
    line-height: 2vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1.5vh;
  }
  
  .standardExtraBlockText {
    font-family: 'Montserrat';
    font-size: 1.9vw;
    line-height: 3.5vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .standardExtraContainer {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  
  .standardExtraTitle {
    font-size: 7.5vw;
    margin-bottom: 1vh;
  }
  
  .standardExtraSub {
    font-size: 3.8vw;
    line-height: 7.5vw;
    margin-bottom: 5vh;
  }
  
  .standardExtraBlocksContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .standardExtraBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4vh;
  }
  
  .standardExtraBlockInfo {
    margin-left: 4vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .standardExtraBlockTitle {
    font-size: 5vw;
    line-height: 4vw;
    margin-bottom: 1vh;
  }
  
  .standardExtraBlockText {
    font-size: 3.5vw;
    line-height: 6.5vw;
    margin-bottom: 0;
  }
}
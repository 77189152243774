.accountMainContainer{
  width: 100%;
}

.teamNameWrapper{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vw;
}

.teamNameText {
  margin-bottom: 0;
  margin-top: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  line-height: 1vw;
  color: #666;
}

  .usersEditButton {
      width: 1.1vw;
      height: 1.1vw;
      color: #18b7bb;
      cursor: pointer;
      transition: filter 0.3s ease-out;
    }
  
  .usersDeleteButton:hover {
    filter: brightness(0.5);
  }

  .usersDeleteButton {
    width: 1.1vw;
    height: 1.1vw;
    color: #f44336;
    cursor: pointer;
    transition: filter 0.3s ease-out;
  }
  
  .usersDeleteButton:hover {
    filter: brightness(0.7);
  }
  
  .loadMoreUsersButton {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    color: #333;
    margin-top: 3vh;
    margin-bottom: 2vh;
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
    align-self: flex-start;
    margin-left: 2%;
  }
  
  .loadMoreUsersButton:hover {
    opacity: 0.7;
  }

  .userStatusText {
    font-size: 0.9vw;
    padding-left: 0.3vw;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5vw;
  }

  .quoteTotalHeaderColumn{
      width: 25%;
  }

  .addUserContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    margin: 10px 0;
  }

  .addNewUserBtn{
    width: 1.4vw;
    height: 1.4vw;
    margin: 10px 0;
    cursor: pointer;
  }

  .addNewUserBtn > img {
    width: inherit;
    height: inherit;
  }

  .userInputsWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    
    .accountMainContainer{
      width: 90%;
    }
    
    .usersDeleteButton {
      width: 2.1vw;
      height: 2.1vw;
      color: #f44336;
      cursor: pointer;
      transition: filter 0.3s ease-out;
    }

    .teamNameText {
      margin-bottom: 0;
      margin-top: 1vw;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 1.8vw;
      line-height: 1vw;
      color: #666;
    }

    .loadMoreUsersButton {
      font-size: 1.8vw;
    }
    
    .loadMoreUsersButton:hover {
      opacity: 1;
    }

    .addNewUserBtn{
      width: 2.3vw;
      height: 2.3vw;
      margin: 10px 0;
    }

    .userInputsWrapper{
      width: 55%;
    }

  }

  @media (orientation: portrait) and (max-width: 767px) {
    .accountMainContainer{
      width: 95%;
    }
    
    .usersDeleteButton {
      width: 15px;
      height: 15px;
    }

    .userStatusText {
      font-size: 7px;
      padding-left: 0.3vw;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 3vw;
    }
    .teamNameText {
      margin-top: 2vw;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 3.5vw;
      line-height: 1vw;
      color: #666;
    }

    .loadMoreUsersButton {
      font-size: 3.5vw;
    }

    .addNewUserBtn{
      width: 15px;
      height: 15px;
      margin: 10px 0;
    }

    .addUserContainer{
      width: 90%;
    }

    .userInputsWrapper{
      width: 100%;
      /* flex-wrap: wrap; */
      margin: 10px 0;
    }
    
  }

  @media (orientation: landscape) and (max-width: 1023px) {

  .usersDeleteButton {
    width: 12px;
    height: 12px;
  }
  
  .usersDeleteButton:hover {
    filter: brightness(1);
  }

  .loadMoreUsersButton:hover {
    opacity: 1;
  }

}
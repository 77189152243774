.editBtn {
    width: 1.1vw;
    height: 1.1vw;
    color: #18b7bb;
    cursor: pointer;
    transition: filter 0.3s ease-out;
    margin: 0 8px;
  }

  .blueColor{
    color: #18b7bb;
  }


  .notes{
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #18b8bb60;
    /* color: #FFF !important; */
    padding: 10px;
    
    overflow-wrap: break-word;
    white-space: initial;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }


  .advOption{
    text-decoration: underline;
    margin-top: 10px;
  }

  .orderName{
    width: 28%;
  }


  .searchFilterRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 3vw;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .showAnim{
    display: flex;
    transition: 0.5s;
    animation: show 0.5s ease-in-out;
    overflow: hidden;
  }

  .hideAnim{
    display: none;
    transition: 0.5s;
  }

  @keyframes show {
    from {opacity: 0};
    to {opacity: 1};
  }

  .inputWrap{
    width: 80%;
  }

  .refToolTip{
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    width: 140px;
    height: auto;
    line-height: 1.4;
    color: #fff;
    padding: 1rem;
    background-color: #45C4C7;
    overflow-wrap: break-word;
    white-space: initial;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    border-radius: 5px 0px 5px 5px;
  }

  .tooltip:hover~.refToolTip{
    display: flex;
  }

  .teamNote{
    border-bottom: 1px solid #e1e1e1;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    color: #ff7c1d;
    cursor: pointer;
  }

  .warningContainer{
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    box-shadow: 1px 1px 5px #8d8d8d;
    border-radius: 5px;
    padding: 0.75rem;
    background-color: #ffedb5;
    flex: 1;
    margin: 0 1rem;
    overflow: hidden;
  }

  .warningContainer * {
    cursor: default !important;
    color: #665e48 !important;
  }

  .warningContainer .quoteDetailsTitle:hover{
    opacity: 1;
  }

  .subText{
    font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  }

  .paymentIconWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 6vh;
  }

  .closeBtnWrap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .notficationTitle{
    margin-bottom: 2vh !important;
  }

  .emailInputWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2vh;
  }

  .emailInputWrapper label{
    margin-top: 6px;
    margin-right: 10px;
  }
  .emailInputWrapper input{
    font-size: 0.9vw;
    max-width: 300px !important;
  }

  .invoiceAlert{
    color: #666;
    font-weight: 700;
    margin-top: 5vh;
  }

  .notificationText{
    font-weight: 400 !important;
  }

  .notifyOverdueWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    .editBtn {
      width: 2.1vw;
      height: 2.1vw;
    }

    .inputWrap{
      width:73%;
    }

    .teamNote{
      font-size: 1.6vw;
    }

    .subText{
      font-size: 1.5vw;
    }

     .emailInputWrapper input{
      font-size: 0.9vw;
    }

}

@media (orientation: portrait) and (max-width: 767px) {

  .searchFilterRow{
    flex-wrap: wrap;
  }
    
    .editBtn {
      width: 15px;
      height: 15px;
    }

    .teamNote{
      font-size: 3.2vw;
    }
    .subText{
      font-size: 3.2vw;
    }

    .emailInputWrapper input{
      font-size: 3vw;
    }
}

@media (orientation: landscape) and (max-width: 1023px) {

    .editBtn {
      width: 12px;
      height: 12px;
    }
}
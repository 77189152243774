.notificationsSubjectColumn {
  width: 70%;
}

.notificationsSubjectColumn p {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notificationsDateColumn {
  width: 20%;
}

.notificationsReadColumn {
  width: 10%;
}

.announcementContainer {
  margin-top: 3vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.announcementTitleContainer {
  width: 30%;
}

.announcementMessageContainer {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.announcementSubmitButtonContainer {
  width: 15%;
}

.announcementRichTextButton {
  width: 17px;
  height: 17px;
  padding: 0.3vw;
  border-radius: 3px;
  color: #666;
  margin-right: 1vw;
  background-color: rgba(255, 124, 29, 0);
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.announcementRichTextButton:hover {
  background-color: rgba(255, 124, 29, 1);
  color: #fff;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .notificationsSubjectColumn {
    width: 90%;
  }
  
  .notificationsDateColumn {
    display: none;
  }
  
  .notificationsReadColumn {
    width: 10%;
  }

  .announcementTitleContainer {
    width: 25%;
  }
  
  .announcementMessageContainer {
    width: 42%;
  }
  
  .announcementSubmitButtonContainer {
    width: 20%;
  }

  .announcementRichTextButton {
    width: 18px;
    height: 18px;
    margin-right: 1.5vw;
  }
  
  .announcementRichTextButton:hover {
    background-color: transparent;
    color: #666;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .notificationsSubjectColumn {
    width: 100%;
  }

  .notificationsSubjectColumn p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .notificationsReadColumn {
    display: none;
  }

  .announcementContainer {
    margin-top: 3vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .announcementTitleContainer {
    width: 100%;
  }
  
  .announcementMessageContainer {
    margin-top: 5vh;
    width: 100%;
  }
  
  .announcementSubmitButtonContainer {
    width: auto;
    align-self: flex-end;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }

  .announcementRichTextButton {
    width: 18px;
    height: 18px;
    margin-right: 4vw;
  }
}
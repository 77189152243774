.languagesHeaderImg {
  width: 50%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.languagesListColumn {
  width: 24%
}

.languagesExtraInfoContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6vh;
  padding-top: 3vh;
  border-top: 1px solid #e1e1e1;
}

.languagesExtraInfoText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.hoveringTextLink {
  text-decoration: underline;
  text-decoration-color: #18b7bb;
  cursor: pointer;
  background-color: rgba(24, 183, 187,0);
  transition: background-color 0.3s ease-out;
}

.hoveringTextLink:hover {
  background-color: rgba(24, 183, 187,0.3);
}


@media (orientation: portrait) and (max-width: 1024px) {
  .languagesHeaderImg {
    width: 45%;
    align-self: flex-start;
  }

  .languagesListColumn {
    width: 32%
  }

  .languagesExtraInfoContainer {
    margin-top: 3vh;
    padding-top: 1.5vh;
  }
  
  .languagesExtraInfoText {
    font-size: 1.9vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .languagesHeaderImg {
    display: none;
  }
  
  .languagesListColumn {
    width: 100%
  }

  .languagesExtraInfoContainer {
    margin-top: 2.5vh;
    padding-top: 2vh;
  }
  
  .languagesExtraInfoText {
    font-size: 4vw;
    line-height: 8vw;
  }
}
.notificationBox {
  z-index: 10000000000009;
  position: fixed;
  top: 3vh;
  right: 3vw;
  width: 30vw;
  height: auto;
  pointer-events: none;
  border-radius: 15px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notificationIcon {
  width: 6vh;
  height: 6vh;
  color: rgba(255,255,255,0.8);
  margin-right: 2vw;
  margin-left: 2vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.notificationInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 2vw;
}

.notificationTitle {
  margin-bottom: 0.5vh;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: rgba(255,255,255,1);
  margin-top: 3vh;
}

.notificationText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.8vw;
  line-height: 1.3vw;
  margin-top: 0;
  margin-bottom: 3vh;
  color: rgba(255,255,255,1);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .notificationBox {
    top: 2vh;
    width: 40vw;
    height: auto;
    border-radius: 15px;
  }
  
  .notificationIcon {
    width: 5vh;
    height: 5vh;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  
  .notificationInfo {
    margin-right: 3vw;
  }
  
  .notificationTitle {
    margin-bottom: 0.5vh;
    font-size: 2vw;
    margin-top: 2vh;
  }
  
  .notificationText {
    font-size: 1.6vw;
    line-height: 2.1vw;
    margin-bottom: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .notificationBox {
    top: 2vh;
    width: 75vw;
    height: auto;
    border-radius: 15px;
  }
  
  .notificationIcon {
    width: 5vh;
    height: 5vh;
    margin-right: 4vw;
    margin-left: 4vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  
  .notificationInfo {
    margin-right: 6vw;
  }
  
  .notificationTitle {
    margin-bottom: 0.5vh;
    font-size: 3.5vw;
    margin-top: 2vh;
  }
  
  .notificationText {
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 2vh;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  .notificationBox {
    border-radius: 6px;
  }
}
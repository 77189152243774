@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.modal, .modal-2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 1000;
}

.modal-2{
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1002;
}

.signInForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 24px;
  border-radius: 8px;
}

.signInFormHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
}

.signInFormIcon {
  color: #2d3643;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.signInFormContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.signInFormInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  color: #030F23;
}

.signInFormInput::placeholder {
  color: #686F7B;
}

.signInFormInput:focus {
  padding-top: 17px;
  padding-bottom: 3px;
  border: 1px solid #030F23;
}

.signInFormInput:focus + .placeholder {
  transform: translateY(-12px);
  font-size: 9px;
}

.signInFormInput:not(:focus):not(:placeholder-shown) {
  padding-top: 17px;
  padding-bottom: 3px;
}

.signsignInFormInputNotEmpty {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  padding-top: 17px;
  padding-bottom: 3px;
}

.placeholderNotInFocus {
  margin-bottom: 1vh;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(-12px);
}

.placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  font-size: 13px;
}

.signshowPasswordEye {
  position: absolute;
  font-family: "Inter", sans-serif;
  transform: translate(372px, 16px);
  z-index: 10001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #686F7B;
}

.focusedPasswordEye {
  stroke: #030F23;
}

.signInFormTitle {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  color: #030F23;
}

.signInFormInputValidation {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #d70015;
}

.errorInput {
  border: 1px solid #d70015;
  margin-bottom: 0px;
}

.errorInput:focus {
  border: 1px solid #d70015;
}

.signInFormForgotPassword {
  text-decoration: none;
  align-self: flex-end;
  font-weight: 450;
  color: #12abbf;
  font-size: 12px;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

.signInFormButton {
  padding: 10px 12px;
  background-color: #12abbf;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-size: 14px;
  height: 40px;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signInFormButton:hover,
.signInFormButton:focus {
  background-color: #4ec1d0;
}
.signInFormButton:disabled {
  background-color: #b3e4eb;
}

.btnLoader{
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  animation: rotation infinite 3s linear;
}

@keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

.signInFormDontHaveAccount {
  margin-top: 20px;
  margin-bottom: 5px;
  /* width: 45%; */
  align-self: center;
  display: flex;
  justify-content: space-between;
}

.signInFormDontHaveAccountSpan {
  font-size: 12px;
  color: #686F7B;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
  margin-right: 4px;
}

.signInFormDontHaveAccountRegister {
  font-size: 12px;
  text-decoration: none;
  color: #12abbf;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
  cursor: pointer;
}

.signInFormForgotPassword:hover,
.signInFormDontHaveAccountRegister:hover,
.signInFormForgotPassword:focus,
.signInFormDontHaveAccountRegister:focus {
  color: #4ec1d0;
}

.signInFormForgotPassword:disabled,
.signInFormDontHaveAccountRegister:disabled{
  color: #D0EEF2;
}

@media (max-width: 450px) {
  .signInForm {
    width: 300px;
  }

  .showPasswordEye {
    transform: translate(267px, 15px);
  }
}

.translationsContainer {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.translationsInnerContainer {
  width: 96%;
  margin-top: 3vh;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  margin-bottom: 7vh;
}

.translationsTitleRow {
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5vh;
}

.translationsTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.6vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.newOrderButton {
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 0.7vw;
  background-color: #FF7C1D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: 0.7vw;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.newOrderButton:hover {
  opacity: 0.7;
}

.newOrderIcon {
  width: 1vw;
  height: 1vw;
  color: #fff;
}

.translationsTableHeader {
  margin-top: 3vh;
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid #e1e1e1;
}

.translationsTableLine {
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  border-bottom: 1px solid rgba(225,225,225,0.5);
}

.loadingTranslations {
  width: 96%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333;
  font-size: 1.5vw;
}

.translationsTimerColumn {
  width: 3%;
}

.translationsOrderColumn {
  width: 12%;
}

.translationsInvoiceColumn {
  width: 12%;
}

.translationsLanguageColumn {
  width: 9%;
}

.translationsDueColumn {
  width: 20%;
}

.translationsTranslatorColumn {
  width: 14%;
}

.translationsStatusColumn {
  width: 10%;
}

.translationsColumnIcon {
  width: 15px;
  height: 15px;
  color: #666;
}

.translationsLineColumnIcon {
  color: #FF7C1D;
  transition: opacity 0.3s ease-out;
}

.translationsLineColumnIcon:hover {
  opacity: 0.8 !important;
}

.translationsStatusContainer {
  width: 80%;
  height: auto;
  padding-top: 0.7vh;
  padding-bottom: 0.7vh;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.6vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.userFunctionsCheckboxContainer {
  width: auto;
  height: 2.3vw;
  margin-right: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}

.userFunctionsCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
}

.userFunctionsCheck {
  width: 15px;
  height: 15px;
  color: #666;
  transition: opacity 0.3s ease-out;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .translationsInnerContainer {
    width: 96%;
    margin-top: 2vh;
    min-height: 80vh;
  }
  
  .translationsTitleRow {
    width: 96%;
    margin-top: 1.5vh;
  }
  
  .translationsTitle {
    font-size: 2.2vw;
  }
  
  .newOrderButton {
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 1.25vw;
    margin-left: 2vw;
  }
  
  .newOrderButton:hover {
    opacity: 1;
  }
  
  .newOrderIcon {
    width: 1.5vw;
    height: 1.5vw;
    color: #fff;
  }
  
  .loadingTranslations {
    font-size: 2vw;
  }
  
  .translationsTimerColumn {
    width: 3%;
  }
  
  .translationsOrderColumn {
    width: 20%;
  }
  
  .translationsInvoiceColumn {
    width: 20%;
  }
  
  .translationsLanguageColumn {
    display: none;
  }
  
  .translationsDueColumn {
    width: 24%;
  }
  
  .translationsTranslatorColumn {
    display: none;
  }
  
  .translationsStatusColumn {
    width: 15%;
  }
  
  .translationsColumnIcon {
    width: 2vw;
    height: 2vw;
    color: #666;
  }
  
  .translationsLineColumnIcon {
    color: #FF7C1D;
    transition: opacity 0.3s ease-out;
  }
  
  .translationsLineColumnIcon:hover {
    opacity: 0.8 !important;
  }
  
  .translationsStatusContainer {
    width: 80%;
    height: auto;
    padding-top: 0.7vh;
    padding-bottom: 0.7vh;
    font-size: 1.2vw;
  }
  
  .userFunctionsCheckboxContainer {
    width: auto;
    height: 5vw;
    margin-right: 4vw;
    font-size: 1.5vw;
    line-height: 1.5vw;
  }
  
  .userFunctionsCheckbox {
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 3px;
    margin-right: 1.5vw;
  }
  
  .userFunctionsCheck {
    width: 1.5vw;
    height: 1.5vw;
    color: #666;
    transition: opacity 0.3s ease-out;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .translationsInnerContainer {
    width: 96%;
    margin-top: 2vh;
    min-height: 80vh;
  }
  
  .translationsTitleRow {
    width: 90%;
    margin-top: 2.5vh;
  }
  
  .translationsTitle {
    font-size: 5vw;
  }
  
  .newOrderButton {
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
    margin-left: 3vw;
  }
  
  .newOrderButton:hover {
    opacity: 1;
  }
  
  .newOrderIcon {
    width: 3vw;
    height: 3vw;
    color: #fff;
  }
  
  .loadingTranslations {
    font-size: 4vw;
  }

  .translationsTableHeader {
    margin-top: 2vh;
    width: 90%;
  }
  
  .translationsTableLine {
    width: 90%;
  }
  
  .translationsTimerColumn {
    display: none;
  }
  
  .translationsOrderColumn {
    width: 32%;
  }
  
  .translationsInvoiceColumn {
    display: none;
  }
  
  .translationsDueColumn {
    width: 38%;
  }
  
  .translationsStatusColumn {
    width: 20%;
  }

  .translationsColumnIcon {
    width: 3vw;
    height: 3vw;
    color: #666;
  }
  
  .translationsStatusContainer {
    width: 100%;
    height: auto;
    padding-top: 0.7vh;
    padding-bottom: 0.7vh;
    font-size: 2vw;
  }
  
  .userFunctionsCheckboxContainer {
    display: none;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .newOrderButton {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 0.7vw;
    margin-left: 1vw;
  }
  
  .newOrderButton:hover {
    opacity: 1;
  }
  
  .newOrderIcon {
    width: 1vw;
    height: 1vw;
    color: #fff;
  }
  
  .loadingTranslations {
    font-size: 1.1vw;
  }

  .translationsTableHeader {
    margin-top: 3vh;
    width: 96%;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
  
  .translationsTableLine {
    width: 96%;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
  }
  
  .translationsTimerColumn {
    width: 3%;
  }
  
  .translationsOrderColumn {
    width: 12%;
  }
  
  .translationsInvoiceColumn {
    width: 12%;
  }
  
  .translationsLanguageColumn {
    width: 9%;
  }
  
  .translationsDueColumn {
    width: 20%;
  }
  
  .translationsTranslatorColumn {
    width: 14%;
  }
  
  .translationsStatusColumn {
    width: 10%;
  }
  
  .translationsColumnIcon {
    width: 1vw;
    height: 1vw;
    color: #666;
  }
  
  .translationsLineColumnIcon {
    color: #FF7C1D;
    transition: opacity 0.3s ease-out;
  }
  
  .translationsLineColumnIcon:hover {
    opacity: 0.8 !important;
  }
  
  .translationsStatusContainer {
    width: 80%;
    height: auto;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.6vw;
    border-radius: 2.5px;
  }
  
  .userFunctionsCheckboxContainer {
    width: auto;
    height: 5vw;
    margin-right: 3vw;
  }
  
  .userFunctionsCheckbox {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 3px;
    margin-right: 1vw;
  }
  
  .userFunctionsCheck {
    width: 0.9vw;
    height: 0.9vw;
    color: #666;
    transition: opacity 0.3s ease-out;
  }
}
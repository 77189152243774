.clientsOrderColumn {
  width: 12%;
}

.clientsNameColumn {
  width: 21%;
}

.clientsRatingColumn {
  width: 7%;
}

.clientsCommentColumn {
  width: 27%;
}

.clientsCommentColumn p {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clientsIconColumn {
  width: 5%;
}

.clientsStatusColumn {
  width: 10%;
}

.clientsTotalColumn {
  width: 8%;
  overflow: visible;
  position: relative;
}

.clientsExtraOverlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #085f63;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  width: auto;
  height: auto;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 1.5vh;
  padding-bottom: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 400;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.clientsTotalColumn:hover .clientsExtraOverlay {
  opacity: 1;
  pointer-events: all;
}

.clientsExtraText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.8vw;
  color: #fff;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-right: 2vw;
}

.clientSummaryTotal {
  border-top: 1px solid rgba(225,225,225,0.3);
  width: 100%;
  height: auto;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #fff;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .clientsOrderColumn {
    width: 20%;
  }
  
  .clientsNameColumn {
    width: 25%;
  }
  
  .clientsRatingColumn {
    width: 9%;
  }
  
  .clientsCommentColumn {
    display: none;
  }

  .clientsCommentColumn p {
    display: none;
  }
  
  .clientsIconColumn {
    width: 5%;
  }
  
  .clientsStatusColumn {
    width: 15%;
  }
  
  .clientsTotalColumn {
    width: 10%;
    overflow: visible;
    position: relative;
  }
  
  .clientsExtraText {
    font-size: 1.5vw;
    margin-top: 0.3vh;
    margin-bottom: 0.3vh;
    margin-right: 2vw;
  }
  
  .clientSummaryTotal {
    font-size: 1.8vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .clientsOrderColumn {
    width: 35%;
  }
  
  .clientsNameColumn {
    width: 45%;
  }
  
  .clientsRatingColumn {
    display: none;
  }
  
  .clientsIconColumn {
    display: none;
  }
  
  .clientsStatusColumn {
    display: none;
  }
  
  .clientsTotalColumn {
    width: 15%;
    overflow: visible;
    position: relative;
  }

  .clientsExtraOverlay {
    padding-right: 4vw;
    padding-left: 4vw;
    padding-top: 1.5vh;
    padding-bottom: 0vh;
  }
  
  .clientsExtraText {
    font-size: 3vw;
    margin-top: 0.2vh;
    margin-bottom: 0.2vh;
    margin-right: 2vw;
  }
  
  .clientSummaryTotal {
    font-size: 3.5vw;
  }
}
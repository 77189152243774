.businessHeaderContainer {
  margin-bottom: 12vh !important;
}

.businessHeaderSeparator {
  height: 3vh;
}

.businessMainInfoContainer {
  width: 40% !important;
}

.businessMainContainer {
  align-items: flex-start !important;
  margin-top: 15vh !important;
}

.businessFormContainer {
  margin-top: 0 !important;
}

.businessLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3.5vh;
}

.businessLineGlyph {
  margin-top: 0;
}

.businessLineInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1.3vw;
}

.businessLineInfoTitle {
  font-family: 'Montserrat';
  font-size: 1.4vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .businessHeaderContainer {
    margin-bottom: 4vh !important;
  }
  
  .businessHeaderSeparator {
    height: 1.5vh;
  }
  
  .businessMainInfoContainer {
    width: 45% !important;
  }
  
  .businessMainContainer {
    align-items: flex-start !important;
    margin-top: 12vh !important;
  }
  
  .businessFormContainer {
    margin-top: 0 !important;
  }
  
  .businessLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2.5vh;
  }
  
  .businessLineGlyph {
    margin-top: 0;
  }
  
  .businessLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1.3vw;
  }

  .businessLineInfoTitle {
    font-family: 'Montserrat';
    font-size: 2vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.8vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .businessHeaderContainer {
    margin-bottom: 12vh !important;
  }
  
  .businessHeaderSeparator {
    height: 6vh;
  }
  
  .businessMainContainer {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 0 !important;
  }

  .businessMainInfoContainer {
    width: 100% !important;
    margin-bottom: 7vh !important;
  }
  
  .businessFormContainer {
    margin-top: 0 !important;
  }
  
  .businessLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5vh;
  }
  
  .businessLineGlyph {
    margin-top: 0;
  }
  
  .businessLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5vw;
  }

  .businessLineInfoTitle {
    font-family: 'Montserrat';
    font-size: 5vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1.2vh;
  }
}
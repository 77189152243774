@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.sendVarificationCodeForm{
    display: flex;
    flex-direction: column;
    width:406px;
    background: white;
    padding: 25px;
    border-radius: 8px;
    box-sizing: border-box;
}

.sendVarificationCodeFormHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 19px;
}

.sendVarificationCodeFormHeaderTitle{
    font-family: "Oswald", sans-serif;
    font-size: 24px;
}

.sendVarificationCodeFormHeaderClose{
    color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.sendVarificationCodeFormText{
    color: #686F7B;
    font-family: "Inter", sans-serif;
    font-size: 14px;
}

.sendVarificationCodeFormEmail{
    margin-bottom: 12px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
}

.sendVarificationCodeFormCodes{
    display: flex;
    width:100%;
    justify-content: space-between;
}

.sendVarificationCodeFormValidation{
    color:#D70015;
    margin-top: 5px;
    font-size:13px;
    line-height: 16.9px;
    font-family: "Inter", sans-serif;
    margin-bottom: 5px;
}

.sendVarificationCodeFormNumber{
    width: 14px;
    padding: 25px 33px;
    border-radius: 7px;
    text-decoration: none;
    outline: none;
    border: 1px solid rgb(210, 210, 210);
    color: #686F7B;
    font-size: 17px;
    font-family: "Inter", sans-serif;
    text-align: center;
    line-height: 1;
    caret-color:white;
    cursor: default;
}

.sendVarificationCodeFormNumber:focus{
    border:1px solid #030F23;
}

.sendVarificationCodeFormNumberError{
    border: 1px solid #D70015;
}
.sendVarificationCodeFormNumberError:focus{
    border: 1px solid #D70015;
}

.sendVarificationCodeFormTimerTime{
    font-family: "Inter", sans-serif;
}

.sendVarificationCodeFormNumber::-webkit-outer-spin-button,
.sendVarificationCodeFormNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sendVarificationCodeFormNumber[type="number"] {
    -moz-appearance: textfield;
}

.sendVarificationCodeFormTimer{
    width:38%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 15px;
}

.sendVarificationCodeFormTimerText{
    font-size: 12px;
    color:#686F7B;
    font-family: "Inter", sans-serif;
}

.sendVarificationCodeAgainAvalible{
    color:#12ABBF;
    cursor:pointer;
    font-size: 12px;
    font-family: "Inter", sans-serif;
}

.sendVarificationCodeFormTimerTime{
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 15.6px;
    color: #030F23;
}

.sendVarificationCodeFormButton{
    padding: 10px 12px;
    background-color: #12abbf;
    border-radius: 6px;
    font-family: "Inter", sans-serif;
    border: none;
    color:white;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sendVarificationCodeFormButton:hover, 
.sendVarificationCodeFormButton:focus{
    background-color: #4ec1d0;
}
.sendVarificationCodeFormButton:disabled{
    background-color: #b3e4eb;
}

.sendVarificationCodeFormSignIn{
    margin-top: 15px;
  text-decoration: none;
  color: #12abbf;
  align-self: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  cursor: pointer;
}
.sendVarificationCodeFormSignIn:hover,
.sendVarificationCodeFormSignIn:focus{
    color: #4ec1d0;
}

.sendVarificationCodeFormSignIn:disabled{
    color: #b3e4eb;
}

.btnLoader{
    width: 20px;
    height: 20px;
    color: white;
    margin-left: 5px;
    animation: rotation infinite 3s linear;
  }
  
  @keyframes rotation{
    from{
      transform:rotate(0deg);
    }
    
    to{
      transform:rotate(360deg);
    }
  }

@media (max-width: 450px) {
    .sendVarificationCodeForm {
      width: 300px;
    }

    .sendVarificationCodeFormNumber{
        padding: 22px;
    }
  }
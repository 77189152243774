.singleUseCaseHeaderImg {
  width: 30%;
  height: auto;
  margin-right: 10vw;
  object-fit: cover;
  z-index: 10;
}

.singleUseCaseHeading {
  font-family: 'Montserrat';
  font-size: 2.6vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.singleUseCaseSubheading {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 5vh;
}

.singleUseCaseMainBlockContainer {
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.singleUseCaseSeparator {
  height: 8vh;
  width: 100%;
}

.singleUseCaseLinkContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3vh;
  border-top: 1px solid #e1e1e1;
  margin-top: 8vh;
}

.singleUseCaseLinkText {
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1.3vw;
  transition: margin-right 0.3s ease-out;
  cursor: pointer;
}

.singleUseCaseLinkText:hover {
  margin-right: 2.2vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleUseCaseHeaderImg {
    width: 38%;
    margin-right: 3vw;
  }

  .singleUseCaseHeading {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .singleUseCaseSubheading {
    width: 95%;
    font-size: 1.6vw;
    line-height: 3.2vw;
    margin-bottom: 4vh;
  }

  .singleUseCaseMainBlockContainer {
    width: 98%;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  
  .singleUseCaseSeparator {
    height: 3vh;
  }
  
  .singleUseCaseLinkContainer {
    padding-top: 2.5vh;
    border-top: 1px solid #e1e1e1;
    margin-top: 3vh;
  }
  
  .singleUseCaseLinkText {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .singleUseCaseLinkText:hover {
    margin-right: 2.2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleUseCaseHeaderImg {
    display: none;
  }

  .singleUseCaseHeading {
    font-size: 7.5vw;
    margin-bottom: 3vh;
  }
  
  .singleUseCaseSubheading {
    font-size: 4.2vw;
    line-height: 10vw;
    margin-bottom: 5vh;
  }

  .singleUseCaseMainBlockContainer {
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  
  .singleUseCaseSeparator {
    height: 5vh;
  }
  
  .singleUseCaseLinkContainer {
    padding-top: 2.5vh;
    border-top: 1px solid #e1e1e1;
    margin-top: 5vh;
  }
  
  .singleUseCaseLinkText {
    font-family: 'Montserrat';
    font-size: 4.2vw;
    line-height: 8vw;
  }
}
.useCasesHeaderImg {
  width: 50%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.useCasesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 8vh;
  margin-top: 8vh;
}

.useCasesTitle {
  font-family: 'Montserrat';
  font-size: 2vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5vh;
}

.useCasesBlockContainer {
  width: 100%;
  height: auto;
}

.useCasePageBlock {
  display: inline-block !important;
  position: relative;
  width: 14%;
  margin-right: 2.6666%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* cursor: pointer; */
}

.useCasePageImgContainer {
  width: 100%;
  height: 38vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  background-color: rgba(24, 183, 187, 0.05);
  border: 1px solid #e1e1e1;
  transition: all 0.3s ease-out;
}

.useCasePageBlock:hover .useCasePageImgContainer {
  background-color: rgba(24, 183, 187, 0.4);
  border: 1px solid #c1c1c1;
}

.useCasePageImg {
  height: auto;
  width: 80%;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

.useCasePageBlock:hover .useCasePageImg {
  transform: scale(1.2);
}

.useCasePageBlockText {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 0;
  transition: filter 0.3s ease-out;
}

.useCasePageBlock:hover .useCasePageBlockText {
  filter: brightness(0.5);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .useCasesHeaderImg {
    width: 50%;
    align-self: flex-start;
  }
  
  .useCasesContainer {
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  
  .useCasesTitle {
    font-size: 3vw;
    margin-bottom: 4vh;
  }
  
  .useCasePageBlock {
    width: 22%;
    margin-right: 3%;
    margin-bottom: 3vh;
  }
  
  .useCasePageImgContainer {
    height: 22vh;
  }
  
  .useCasePageImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #c1c1c1;
  }
  
  .useCasePageBlockText {
    font-size: 1.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .useCasesHeaderImg {
    display: none;
  }
  
  .useCasesContainer {
    margin-bottom: 7vh;
    margin-top: 7vh;
  }
  
  .useCasesTitle {
    font-size: 7vw;
    margin-bottom: 4vh;
  }
  
  .useCasePageBlock {
    width: 45%;
    margin-right: 5%;
    margin-bottom: 5vh;
  }
  
  .useCasePageImgContainer {
    height: 27vh;
  }
  
  .useCasePageImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #c1c1c1;
  }
  
  .useCasePageBlockText {
    font-size: 3vw;
  }
}
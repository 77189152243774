.quotePrimaryOptions {
  width: calc(100% - 3vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vh;
}

.quotePrimaryOption {
  width: 48%;
  height: 18vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #666;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.quotePrimaryOption:hover {
  opacity: 0.8;
}

.quotePrimaryOptionTitle {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1vh;
  width: 80%;
}

.quotePrimaryOptionText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.4vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  width: 80%;
}

.quoteSmallMessage {
  margin-bottom: 6vh;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.4vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  width: 80%;
}

.quoteDetailsInputsContainer {
  width: calc(100% - 3vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vh;
}

.quoteDetailsSelectContainer {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quoteDetailsSelect {
  width: 100%;
  height: 5vh;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #666;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  font-weight: 400;
  color: #666;
}

.quoteDetailsSelect:focus {
  outline: none;
}

.hardCopyLineSeparator {
  height: 2vh;
}

.hardCopyCheckboxLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 1vh;
}

.hardCopyCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid rgba(102,102,102,0.7);
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hardCopyCheck {
  width: 15px;
  height: 15px;
  color: #18b7bb;
  transition: opacity 0.3s ease-out;
}

.hardCopyCheckboxText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  width: 80%;
}

.quoteDetailsFileUploaderContainer {
  margin-top: 3vh;
  width: calc(100% - 3vw);
}

.quoteOptionalContainer {
  width: calc(100% - 7vw);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-right: 2vw;
  padding-left: 2vw;
  border: 2px solid rgba(102,102,102,0.1);
  transition: border-color 0.3s ease-out;
  border-radius: 10px;
  margin-top: 3vh;
}

.quoteOptionalTitleRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.quoteOptionalTitleStatus {
  font-family: 'Montserrat';
  font-size: 0.8vw;
  font-weight: 400;
  color: #fff;
  padding: 0.5vh 1vw 0.5vh 1vw;
  border-radius: 5px;
  background-color: #FF7C1D;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  cursor: pointer;
}

.quoteRichTextButtons {
  margin-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
}

.quoteRichTextButton {
  width: 17px;
  height: 17px;
  padding: 0.3vw;
  border-radius: 3px;
  color: #666;
  margin-right: 1vw;
  background-color: rgba(255, 124, 29, 0);
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.quoteRichTextButton:hover {
  background-color: rgba(255, 124, 29, 1);
  color: #fff;
}

.quoteRichTextEditor {
  margin-bottom: 3vh;
  width: calc(100% - 3vw);
  min-height: 20vh;
  border: 1px solid rgba(102,102,102,0.2);
  border-radius: 10px;
}

.DraftEditor-root {
  min-height: 20vh;
  padding: 2vh 1vw 2vh 1vw;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: #666;
  line-height: 2vw;
}

.quoteSubmitButtonContainer {
  width: auto;
  height: auto;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 3vh;
  margin-bottom: 10vh;
  margin-right: 3vw;
}

.quoteGoBackButton {
  margin-right: 0.8vw;
  width: 2.8vw;
  height: 2.8vw;
  border-radius: 1.4vw;
  border: 1px solid #FF7C1D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.quoteGoBackIcon {
  width: 1.3vw;
  height: 1.3vw;
  color: #FF7C1D;
}

.quoteGoBackButton:hover {
  opacity: 0.5;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .quotePrimaryOptions {
    width: calc(100% - 5vw);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 0;
  }
  
  .quotePrimaryOption {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
  }
  
  .quotePrimaryOption:hover {
    opacity: 1;
  }
  
  .quotePrimaryOptionTitle {
    font-size: 2vw;
    width: 90%;
  }
  
  .quotePrimaryOptionText {
    font-size: 1.7vw;
    line-height: 3vw;
    width: 90%;
  }
  
  .quoteSmallMessage {
    margin-bottom: 3vh;
    font-size: 1.6vw;
    line-height: 3.2vw;
    width: calc(100% - 5vw);
  }
  
  .quoteDetailsInputsContainer {
    width: calc(100% - 5vw);
    margin-bottom: 3vh;
  }
  
  .quoteDetailsSelectContainer {
    width: 46%;
  }
  
  .quoteDetailsSelect {
    height: 4vh;
    font-size: 1.6vw;
    line-height: 3.2vw;
  }

  .hardCopyLineSeparator {
    height: 2vh;
  }
  
  .hardCopyCheckbox {
    margin-right: 2vw;
  }
  
  .hardCopyCheckboxText {
    font-size: 1.8vw;
    line-height: 3vw;
  }
  
  .quoteDetailsFileUploaderContainer {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: calc(100% - 5vw);
  }
  
  .quoteOptionalContainer {
    width: calc(100% - 9vw);
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-right: 2vw;
    padding-left: 2vw;
    border: 2px solid rgba(102,102,102,0.1);
    transition: border-color 0.3s ease-out;
    border-radius: 10px;
    margin-top: 1vh;
    margin-bottom: 2vh;
  }
  
  .quoteOptionalTitleStatus {
    font-size: 1.4vw;
  }
  
  .quoteRichTextButtons {
    margin-bottom: 1.5vh;
  }
  
  .quoteRichTextButton {
    width: 20px;
    height: 20px;
    margin-right: 2vw;
  }
  
  .quoteRichTextButton:hover {
    background-color: transparent;
    color: #666;
  }
  
  .quoteRichTextEditor {
    margin-bottom: 0;
    width: calc(100% - 5vw);
    min-height: 15vh;
  }
  
  .DraftEditor-root {
    min-height: 15vh;
    padding: 1vh 2vw 1vh 2vw;
    font-size: 1.7vw;
    line-height: 3.4vw;
  }
  
  .quoteSubmitButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 3vh;
    margin-bottom: 6vh;
    margin-right: 5vw;
  }

  .quoteGoBackButton {
    margin-right: 2vw;
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
  }
  
  .quoteGoBackIcon {
    width: 2.5vw;
    height: 2.5vw;
    color: #FF7C1D;
  }
  
  .quoteGoBackButton:hover {
    opacity: 1;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .quotePrimaryOptions {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3vh;
  }
  
  .quotePrimaryOption {
    width: 100%;
    height: 16vh;
  }
  
  .quotePrimaryOption:hover {
    opacity: 1;
  }
  
  .quotePrimaryOptionTitle {
    font-size: 4vw;
    margin-bottom: 1vh;
    width: 90%;
  }
  
  .quotePrimaryOptionText {
    font-size: 3vw;
    line-height: 6vw;
    width: 90%;
  }
  
  .quoteSmallMessage {
    margin-bottom: 3vh;
    font-size: 3vw;
    line-height: 6vw;
    margin-top: 0;
    width: 100%;
  }
  
  .quoteDetailsInputsContainer {
    width: 100%;
    margin-bottom: 2vh;
  }
  
  .quoteDetailsSelectContainer {
    width: 45%;
  }
  
  .quoteDetailsSelect {
    font-size: 3vw;
    line-height: 5vw;
  }

  .hardCopyLineSeparator {
    height: 2.5vh;
  }

  .hardCopyCheckboxLine {
    margin-top: 2vh;
  }
  
  .hardCopyCheckbox {
    margin-right: 2vw;
  }
  
  .hardCopyCheckboxText {
    font-size: 3.2vw;
    line-height: 5vw;
  }
  
  .quoteDetailsFileUploaderContainer {
    margin-top: 1vh;
    width: 100%;
  }
  
  .quoteOptionalContainer {
    width: calc(100% - 8vw);
    padding-right: 4vw;
    padding-left: 4vw;
    margin-top: 2vh;
  }
  
  .quoteOptionalTitleRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
  }
  
  .quoteOptionalTitleStatus {
    font-size: 3vw;
    padding: 0.6vh 4vw 0.6vh 4vw;
  }
  
  .quoteRichTextButtons {
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
  }
  
  .quoteRichTextButton {
    width: 20px;
    height: 20px;
    margin-right: 3vw;
  }
  
  .quoteRichTextEditor {
    margin-bottom: 3vh;
    width: 100%;
    min-height: 20vh;
  }
  
  .DraftEditor-root {
    border: 1px solid rgba(102,102,102,0.2);
    min-height: 20vh;
    padding: 1.5vh 4vw 1.5vh 4vw;
    font-size: 3.2vw;
    line-height: 6.4vw;
  }
  
  .quoteSubmitButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-right: 0;
  }

  .quoteGoBackButton {
    margin-right: 3.5vw;
    width: 12vw;
    height: 12vw;
    border-radius: 6vw;
  }
  
  .quoteGoBackIcon {
    width: 6vw;
    height: 6vw;
    color: #FF7C1D;
  }
  
  .quoteGoBackButton:hover {
    opacity: 1;
  }
} 
.fileUploaderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.fileUploaderInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
}

.dragDropBox{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 90%;
  max-width: 300px;
  min-width: 50px;
  height: 100%;
  max-height: 150px;
  min-height: 40px;
  border: 1px dashed #FF7C1D;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  margin: 1vw;
  padding: 1vw;
}

.dragDropBox:hover , .dragDropBox.dragover{
  opacity: 0.7;
}

.dragDropBox > p{
  color: #FF964A;
  font-family: 'Montserrat';
    font-size: 1vw;
    z-index: 2;
}

.dragDropBox > img{
  width: 20%;
  height: 20%;
  z-index: 2;
}

.fileUploaderLineOuter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3vh;
  padding-top: 1vh;
  margin-bottom: 4vh;
  border-bottom: 1px solid #e1e1e1;
}

.fileUploaderLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fileUploaderLineInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.fileUploaderLineName {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 0.95vw;
  margin-top: 0;
  margin-bottom: 0;
}

.fileUploaderLineSize {
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1vw;
}

.fileUploaderLineIcon {
  width: 17px;
  height: 17px;
  color: #18b7bb;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.fileUploaderLineIcon:hover {
  opacity: 0.6;
}

.fileUploaderLineProgressContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vh;
}

.fileUploaderLineProgress {
  height: 0;
  border-bottom-width: 3px;
  border-radius: 5px;
  border-style: solid;
  transition: width 0.3s ease-out, border-color 0.3s ease-out;
}

.fileUploaderLineProgressText {
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2vw;
  text-align: right;
  width: 5vw;
}

.fileUploaderFormattedTimestamp {
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85vw;
  margin-top: 1vh;
  margin-bottom: 0;
  margin-left: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  
  .fileUploaderLineOuter {
    padding-bottom: 2vh;
    padding-top: 1vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .fileUploaderLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
  }
  
  .fileUploaderLineName {
    font-family: 'Montserrat';
    font-weight: 700;
    color: #666;
    font-size: 1.7vw;
    margin-top: 0;
    margin-bottom: 0.7vh;
  }
  
  .fileUploaderLineSize {
    font-size: 1.5vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .fileUploaderFormattedTimestamp {
    font-size: 1.5vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  
  .fileUploaderLineIcon {
    width: 20px;
    height: 20px;
  }
  
  .fileUploaderLineIcon:hover {
    opacity: 1;
  }
  
  .fileUploaderLineProgressContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3vh;
  }
  
  .fileUploaderLineProgress {
    height: 0;
    border-width: 2px;
  }
  
  .fileUploaderLineProgressText {
    font-size: 1.4vw;
    margin-left: 3vw;
    width: auto;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  /* .dragDropBox{
    width: 90%;
    max-width: 300px;
    height: 150px;
  }
  
  .dragDropBox > img{
    width: 50px;
    height: 50px;
  } */

  .dragDropBox > p{
    font-size: 2.5vw;
  }
  
  .fileUploaderLineOuter {
    padding-bottom: 2vh;
    padding-top: 1vh;
    margin-bottom: 4vh;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .fileUploaderLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .fileUploaderLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
  }
  
  .fileUploaderLineName {
    font-size: 3.5vw;
    margin-bottom: 1vh;
  }
  
  .fileUploaderLineSize {
    font-size: 3vw;
    margin-left: 0;
  }

  .fileUploaderFormattedTimestamp {
    font-size: 3vw;
    margin-left: 0;
  }
  
  .fileUploaderLineIcon {
    width: 20px;
    height: 20px;
  }
  
  .fileUploaderLineIcon:hover {
    opacity: 1;
  }
  
  .fileUploaderLineProgressContainer {
    width: 100%;
    margin-top: 2vh;
  }
  
  .fileUploaderLineProgress {
    height: 0;
    border-width: 2px;
  }
  
  .fileUploaderLineProgressText {
    font-size: 3vw;
    margin-left: 5vw;
    text-align: right;
    width: auto;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {

 

  .fileUploaderLineIcon {
    width: 11px;
    height: 11px;
    color: #18b7bb;
    transition: opacity 0.3s ease-out;
    cursor: pointer;
  }
  
  .fileUploaderLineIcon:hover {
    opacity: 1;
  }
}
.documentsDisplayContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.documentsDisplayInfo {
  height: 46.87vw;
  width: 46.87vw;
  margin-left: -3vw;
  background-image: url('../assets/balloonLeft.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.documentsDisplayInfoTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #fff;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
  width: 75%;
  margin-left: 5%;
}

.documentsDisplayInfoText {
  width: 75%;
  margin-left: 5%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #f5f5f5;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.documentsDisplayInfoButtonContainer {
  width: 75%;
  margin-left: 5%;
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.documentsDisplayInfoButtonText {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  color: #fff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.3vh;
  margin-right: 0.7vw;
  cursor: pointer;
  transition: margin-right 0.3s ease-out;
}

.documentsDisplayInfoButtonText:hover {
  margin-right: 1.2vw;
}

.documentsDisplayListContainer {
  width: 40%;
  height: auto;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsDisplayListTitle {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 7vh;
  align-self: flex-start
}

.documentsDisplayListInner {
  width: auto;
  height: auto;
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsDisplayListColumn {
  width: 20vw;
  height: auto;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsDisplaySecondColumn {
  width: auto;
}

.documentsDisplayListItem {
  width: 100%;
  height: auto;
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5.5vh;
}

.documentsDisplayListItemText {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1vw;
  /* cursor: pointer; */
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.documentsDisplayListItemText:hover {
  /* opacity: 0.6; */
  opacity: 1;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .documentsDisplayContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    align-items: center;
  }
  
  .documentsDisplayInfo {
    height: 52vw;
    width: 52vw;
    margin-left: -5vw;
    margin-bottom: 5vh;
  }
  
  .documentsDisplayInfoTitle {
    margin-top: 2vh;
    margin-bottom: 1vh;
    font-size: 3vw;
    width: 80%;
    margin-left: 8%;
  }
  
  .documentsDisplayInfoText {
    width: 80%;
    margin-left: 8%;
    font-size: 2vw;
    line-height: 4vw;
  }
  
  .documentsDisplayInfoButtonContainer {
    width: 80%;
    margin-left: 8%;
    margin-top: 3vh;
  }
  
  .documentsDisplayInfoButtonText {
    font-size: 2vw;
    margin-right: 1vw;
  }
  
  .documentsDisplayInfoButtonText:hover {
    margin-right: 1.8vw;
  }
  
  .documentsDisplayListContainer {
    width: 46%;
  }
  
  .documentsDisplayListTitle {
    font-size: 3vw;
    margin-bottom: 4vh;
  }
  
  .documentsDisplayListInner {
    width: auto;
    height: auto;
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListColumn {
    width: 100%;
    height: auto;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListItem {
    margin-bottom: 3vh;
  }
  
  .documentsDisplayListItemText {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    color: #666;
    font-weight: 400;
    margin-left: 1.5vw;
  }
  
  .documentsDisplayListItemText:hover {
    opacity: 0.6;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .documentsDisplayContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .documentsDisplayInfo {
    height: 112vw;
    width: 112vw;
    margin-left: -4vw;
    margin-bottom: 5vh;
  }
  
  .documentsDisplayInfoTitle {
    font-size: 6.2vw;
    width: 80%;
    margin-left: 5%;
  }
  
  .documentsDisplayInfoText {
    width: 80%;
    margin-left: 5%;
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .documentsDisplayInfoButtonContainer {
    width: 80%;
    margin-left: 5%;
    margin-top: 3vh;
  }
  
  .documentsDisplayInfoButtonText {
    font-size: 4vw;
    margin-right: 1vw;
  }
  
  .documentsDisplayInfoButtonText:hover {
    margin-right: 1.8vw;
  }
  
  .documentsDisplayListContainer {
    width: 90%;
    margin-left: 5%;
  }
  
  .documentsDisplayListTitle {
    font-size: 5.8vw;
    margin-bottom: 5vh;
  }
  
  .documentsDisplayListInner {
    width: auto;
    height: auto;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListColumn {
    width: 100%;
    height: auto;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListItem {
    margin-bottom: 4vh;
  }
  
  .documentsDisplayListItemText {
    font-family: 'Montserrat';
    font-size: 4.5vw;
    color: #666;
    font-weight: 400;
    margin-left: 3vw;
  }
  
  .documentsDisplayListItemText:hover {
    opacity: 0.6;
  }
}
.backendOuterContainer {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.backendSidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 17vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #085f63;
  overflow: hidden;
  z-index: 2000;
  box-shadow: 2px 0px 10px rgba(0,0,0,0.2);
}

.backendLogoContainer {
  width: 90%;
  height: 10vh;
  margin-bottom: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.backendCloseSidebarIcon {
  display: none;
}

.backendLogo {
  height: 45%;
  width: auto;
  object-fit: cover;
  cursor: pointer;
}

.backendSidebarLinksContainer {
  margin-left: 2%;
  width: 87%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.backendSidebarLinkContainer {
  width: 100%;
  height: auto;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.backendSidebarLink {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  color: rgba(255,255,255,0.4);
  font-size: 0.95vw;
  margin-bottom: 1.5vh;
  cursor: pointer;
  transition: color 0.3s ease-out;
  position: relative;
}

.backendSidebarLink:hover {
  color: rgba(255,255,255,1) !important;
}

.backendSidebarLinkIcon {
  width: 15px;
  height: 15px;
  color: inherit;
  margin-right: 0.6vw;
}

.backendSidebarLink:hover .backendSidebarLinkIcon {
  color: rgba(255,255,255,1) !important;
}

.backendSidebarLinkExtraIconContainer {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.backendSidebarExtraIcon {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.8vw;
  color: #fff;
  margin-left: 0.2vw;
}

.backendSidebarLink:hover .backendSidebarExtraIcon {
  color: rgba(255,255,255,1) !important;
}

.backendInnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 83vw;
  height: 100%;
  display: block;
  overflow-y: scroll;
  background-color: #f5f5f5;
}

.backendHeader {
  width: 100%;
  height: 10vh;
  background-color: #fff;
  border-bottom: 1px solid rgba(102,102,102,0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.backendSwitchIcon {
  width: 20px;
  height: 20px;
  margin-left: 2vw;
  color: #666;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.backendSwitchIcon:hover {
  filter: brightness(0.5);
}

.backendTabContainer {
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
}

.backendHeaderOptionsContainer {
  margin-right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.backendHeaderOptionsSeparator {
  width: 0;
  height: 3vh;
  border-right: 1px solid #e1e1e1;
  margin-right: 1.5vw;
  margin-left: 1.5vw;
}

.backendHeaderOptionsMenuContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 200;
}

.backendHeaderOptionsName {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
  cursor: pointer;
}

.backendHeaderOptionsIcon {
  width: 15px;
  height: 15px;
  color: #666;
  cursor: pointer;
  margin-top: 0.3vh;
}

.backendHeaderOptionsMenuInner {
  position: absolute;
  right: 0;
  top: 100%;
  width: auto;
  height: auto;
  padding: 1vh 4vw 1vh 1vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid rgba(102,102,102,0.4);
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.backendHeaderOptionsMenuContainer:hover .backendHeaderOptionsMenuInner {
  opacity: 1;
  pointer-events: all;
}

.backendHeaderOptionsMenuLink {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-right: 0.5vw;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.backendHeaderOptionsMenuLink:hover {
  filter: brightness(0.2);
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .backendLogoContainer {
    justify-content: space-between;
    height: 8vh;
  }

  .backendCloseSidebarIcon {
    display: flex;
    width: 3vw;
    height: 3vw;
    color: #fff;
  }
  
  .backendLogo {
    height: 45%;
  }
  
  .backendSidebarLink {
    font-size: 1.8vw;
    margin-bottom: 1.5vh;
  }
  
  .backendSidebarLink:hover {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarLinkIcon {
    width: 3vw;
    height: 3vw;
    color: inherit;
    margin-right: 2vw;
  }
  
  .backendSidebarLink:hover .backendSidebarLinkIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarExtraIcon {
    width: 3vw;
    height: 3vw;
    border-radius: 1.5vw;
    font-size: 1.7vw;
    color: #fff;
    margin-left: 0.7vw;
  }
  
  .backendSidebarLink:hover .backendSidebarExtraIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSwitchIcon {
    width: 3vw;
    height: 3vw;
    margin-left: 3vw;
  }
  
  .backendSwitchIcon:hover {
    filter: brightness(1);
  }

  .backendHeader {
    height: 8vh;
  }
  
  .backendHeaderOptionsContainer {
    margin-right: 3vw;
  }
  
  .backendHeaderOptionsSeparator {
    width: 0;
    height: 3vh;
    border-right: 1px solid #e1e1e1;
    margin-right: 3vw;
    margin-left: 3vw;
  }
  
  .backendHeaderOptionsName {
    font-size: 1.7vw;
    margin-right: 1vw;
  }
  
  .backendHeaderOptionsIcon {
    width: 2vw;
    height: 2vw;
    color: #666;
    cursor: pointer;
    margin-top: 0;
  }
  
  .backendHeaderOptionsMenuContainer:hover .backendHeaderOptionsMenuInner {
    opacity: 1;
    pointer-events: all;
  }
  
  .backendHeaderOptionsMenuLink {
    font-size: 1.6vw;
  }
  
  .backendHeaderOptionsMenuLink:hover {
    filter: brightness(1);
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .backendLogoContainer {
    justify-content: space-between;
  }

  .backendCloseSidebarIcon {
    display: flex;
    width: 5vw;
    height: 5vw;
    color: #fff;
  }
  
  .backendLogo {
    height: 40%;
  }
  
  .backendSidebarLink {
    font-size: 3.5vw;
    margin-bottom: 1.8vh;
  }
  
  .backendSidebarLink:hover {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarLinkIcon {
    width: 4.5vw;
    height: 4.5vw;
    color: inherit;
    margin-right: 4vw;
  }
  
  .backendSidebarLink:hover .backendSidebarLinkIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarExtraIcon {
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
    font-size: 3vw;
    color: #fff;
    margin-left: 1.5vw;
  }
  
  .backendSidebarLink:hover .backendSidebarExtraIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSwitchIcon {
    width: 4.5vw;
    height: 4.5vw;
    margin-left: 3vw;
  }
  
  .backendSwitchIcon:hover {
    filter: brightness(1);
  }
  
  .backendHeaderOptionsContainer {
    margin-right: 3vw;
  }
  
  .backendHeaderOptionsSeparator {
    width: 0;
    height: 4vh;
    border-right: 1px solid #e1e1e1;
    margin-right: 4vw;
    margin-left: 4vw;
  }
  
  .backendHeaderOptionsName {
    font-size: 3.6vw;
    margin-right: 3vw;
  }
  
  .backendHeaderOptionsIcon {
    width: 4vw;
    height: 4vw;
    color: #666;
    cursor: pointer;
    margin-top: 0;
  }

  .backendHeaderOptionsMenuInner {
    position: absolute;
    right: 0;
    top: 100%;
    width: auto;
    height: auto;
    padding: 1vh 7vw 1vh 3vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid rgba(102,102,102,0.4);
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-out;
  }
  
  .backendHeaderOptionsMenuContainer:hover .backendHeaderOptionsMenuInner {
    opacity: 1;
    pointer-events: all;
  }
  
  .backendHeaderOptionsMenuLink {
    font-size: 3.5vw;
  }
  
  .backendHeaderOptionsMenuLink:hover {
    filter: brightness(1);
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .backendSidebarLinkContainer {
    margin-bottom: 3vh;
  }
  
  .backendSidebarLink:hover {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarLinkIcon {
    width: 13px;
    height: 13px;
    color: inherit;
    margin-right: 1.2vw;
  }
  
  .backendSidebarLink:hover .backendSidebarLinkIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarExtraIcon {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    font-size: 0.9vw;
    margin-left: 0.5vw;
  }
  
  .backendSidebarLink:hover .backendSidebarExtraIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSwitchIcon {
    width: 12px;
    height: 12px;
    margin-left: 2vw;
  }
  
  .backendSwitchIcon:hover {
    filter: brightness(1);
  }
  
  .backendHeaderOptionsIcon {
    width: 11px;
    height: 11px;
    color: #666;
    cursor: pointer;
    margin-top: 0;
  }
  
  .backendHeaderOptionsMenuLink:hover {
    filter: brightness(1);
  }
}
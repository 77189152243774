@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 25px;
  border-radius: 8px;
}

.forgotPasswordFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
}

.forgotPasswordFormHeaderTitle {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  color: #030F23;
}

.forgotPasswordFormText {
  color: #686F7B;
  font-size: 14px;
  padding-bottom: 20px;
  font-family: "Inter", sans-serif;
}

.forgotPassworFormContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.forgotPasswordFormInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  color: #030F23;
}

.forgotPassworFormPlaceholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  font-size: 13px;
}

.forgotPasswordFormInput:focus {
  padding-top: 17px;
  padding-bottom: 3px;
  border: 1px solid #030F23;
}

.forgotPasswordFormInput:focus + .forgotPassworFormPlaceholder {
  transform: translateY(-12px);
  font-size: 9px;
}

.forgotPasswordFormInput:not(:focus):not(:placeholder-shown) {
  padding-top: 17px;
  padding-bottom: 3px;
}

.forgotPasswordFormPlaceholderNotEmpty {
  margin-bottom: 1vh;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(-12px);
}

.forgotPasswordFormInputNotEmpty {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  padding-top: 17px;
  padding-bottom: 3px;
}

.signInFormIcon {
  color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.errorInput {
  border: 1px solid rgb(208, 1, 1);
}

.errorInput:focus{
  border: 1px solid rgb(208, 1, 1);
}

.forgotPasswordFormButton {
  margin-top: 8px;
  padding: 10px 16px 10px 16px;
  background-color: #12abbf;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotPasswordFormButton:hover, .forgotPasswordFormButton:focus {
  background: #4ec1d0;
}
.forgotPasswordFormButton:disabled {
  background: #b3e4eb;
}

.forgotPasswordFormBackToSignIn {
  margin-top: 15px;
  text-decoration: none;
  color: #12abbf;
  align-self: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.forgotPasswordFormBackToSignIn:hover, .forgotPasswordFormBackToSignIn:focus{
  color: #4ec1d0;
}

.forgotPasswordFormBackToSignIn:disabled{
  color: #D0EEF2;
}

.forgotPasswordFormError {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: rgb(228, 0, 0);
}

.btnLoader{
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  animation: rotation infinite 3s linear;
}

@media (max-width: 450px) {
  .forgotPasswordForm {
    width: 300px;
  }
}

.singleClientReviewsMessage {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.singleClientReviewLine {
  border: 1px solid #e1e1e1;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  width: calc(100% - 3vw);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleClientReviewText {
  font-family: 'Montserrat';
  font-weight: 300;
  color: #666;
  font-size: 0.9vw;
  line-height: 1.8vw;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleClientReviewsMessage {
    font-size: 1.8vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .singleClientReviewLine {
    border: 1px solid #e1e1e1;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: calc(100% - 5vw);
  }
  
  .singleClientReviewText {
    font-size: 1.7vw;
    line-height: 3.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleClientReviewsMessage {
    font-size: 1.8vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .singleClientReviewLine {
    border: 1px solid #e1e1e1;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
    width: calc(100% - 7vw);
  }
  
  .singleClientReviewText {
    font-size: 3.5vw;
    line-height: 7vw;
  }
}
.guidelinesDetailsMain {
  width: 80%;
}

.guidelinesHeader {
  margin-top: 2vh;
  margin-bottom: 0;
}

.guidelinesHeading {
  font-size: 1.9vw;
  margin-bottom: 1.5vh;
}

.guidelinesSubheading {
  font-size: 0.9vw;
  line-height: 2.1vw;
  width: 80%;
  text-align: left;
  margin-bottom: 4vh;
}

.guidelinesAuxHeading {
  font-size: 1.3vw;
  line-height: 3vw;
  margin-bottom: 2.5vh;
  margin-top: 0;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
}

.guidelinesText {
  font-size: 0.9vw;
  line-height: 2vw;
  margin-bottom: 4vh;
}

.guidelinesTextStrong {
  font-size: 1.1vw;
  font-weight: 900;
  color: #18b7bb;
  line-height: 3vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .guidelinesDetailsMain {
    width: 100%;
  }
  
  .guidelinesHeader {
    margin-top: 1vh;
    margin-bottom: 0;
  }
  
  .guidelinesHeading {
    font-size: 2.8vw;
    margin-bottom: 1vh;
  }
  
  .guidelinesSubheading {
    font-size: 1.7vw;
    line-height: 3.8vw;
    width: 90%;
    text-align: left;
    margin-bottom: 3vh;
  }
  
  .guidelinesAuxHeading {
    font-size: 2.3vw;
    line-height: 6vw;
    margin-bottom: 1.5vh;
    margin-top: 0;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
  }
  
  .guidelinesText {
    font-size: 1.7vw;
    line-height: 3.8vw;
    margin-bottom: 3vh;
  }
  
  .guidelinesTextStrong {
    font-size: 2vw;
    font-weight: 900;
    color: #18b7bb;
    line-height: 6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  
  .guidelinesHeading {
    font-size: 6vw;
    line-height: 8vw;
    margin-bottom: 1vh;
  }
  
  .guidelinesSubheading {
    font-size: 3.5vw;
    line-height: 7vw;
    width: 100%;
    text-align: left;
    margin-bottom: 4vh;
  }
  
  .guidelinesAuxHeading {
    font-size: 4.8vw;
    line-height: 10vw;
    margin-bottom: 2vh;
  }
  
  .guidelinesText {
    font-size: 3.3vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }
  
  .guidelinesTextStrong {
    font-size: 4vw;
    font-weight: 900;
    color: #18b7bb;
    line-height: 12vw;
  }
}
.formInputSimpleContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.formInputSimpleLabel {
  margin-bottom: 1vh;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1vw;
  color: #666;

  /* new input styles */
  position: absolute;
  left: 0;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  /*  */
}

.formTextArea {
  color: #666;
  background-color: transparent;
  /* border-bottom: 1px solid rgba(102, 102, 102, 0.7);
  border-right: none;
  border-top: none;
  border-left: none; */
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.95vw;
  line-height: 1.9vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 25vh;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;

   /* new input styles */
   padding:  10px;
   outline: transparent;
   border-radius: 5px;
   transition: 0.5s;
   border: 1px solid rgba(102, 102, 102, 0.7);
   /*  */
}

.formTextArea::placeholder {
  color: rgba(102, 102, 102, 1);
}

.formTextArea:focus, .formInputSimple:valid {
  border-color: #18b7bb !important;
}

.formTextArea:focus ~ .formInputSimpleLabel, .formTextArea:valid ~ .formInputSimpleLabel{
  padding: 0 10px;
  background: white;
  color: #18b7bb !important;
  transform: translateX(10px) translateY(-15px);
  font-size: 0.9vw;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .formTextArea {
    height: 15vh;
  }

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 2vw;
    line-height: 4vw;
    /* font-size: 1.8vw; */
    /* line-height: 1.8vw; */
  }
  
  .formTextArea {
    font-size: 2vw;
    line-height: 4vw;
  }

  .formTextArea:focus ~ .formInputSimpleLabel, .formTextArea:valid ~ .formInputSimpleLabel{
    font-size: 1.8vw;
    line-height: 1.8vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 3.6vw;
    line-height: 7.2vw;
    /* font-size: 2.7vw;
    line-height: 2.7vw; */
  }
  
  .formTextArea {
    font-size: 3.6vw;
    line-height: 7.2vw;
    width: 95%;
  }

  .formTextArea:focus ~ .formInputSimpleLabel, .formTextArea:valid ~ .formInputSimpleLabel{
    font-size: 2.7vw !important;
    line-height: 2.7vw;
  }
}
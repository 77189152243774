.termsHeader {
  width: 80%;
}

.termsHeading {
  font-family: 'Montserrat';
  font-size: 2.2vw;
  line-height: 4vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.termsSubheading {
  width: 50%;
  text-align: left;
}

.termsUpdateContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.termsUpdateContainer p {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.termsIcon {
  width: 15px;
  height: 15px;
  color: #666;
  margin-right: 1vw;
}

.termsHeaderSeparator {
  width: 7vw;
  height: 0;
  border-bottom: 1px solid #666;
  margin-top: 5vh;
  margin-bottom: 0;
}

.termsContainer {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 8vh;
}

.termsText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  line-height: 2.2vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.termsSeparator {
  width: 0;
  height: 7vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .termsHeading {
    font-size: 3vw;
    line-height: 5vw;
    margin-bottom: 3vh;
  }

  .termsHeader {
    width: 100%;
  }
  
  .termsSubheading {
    width: 70%;
    text-align: left;
  }
  
  .termsUpdateContainer {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .termsUpdateContainer p {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.7vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsIcon {
    width: 15px;
    height: 15px;
    color: #666;
    margin-right: 1vw;
  }
  
  .termsHeaderSeparator {
    width: 10vw;
    margin-top: 3vh;
    margin-bottom: 0;
  }
  
  .termsContainer {
    width: 100%;
    margin-bottom: 8vh;
  }
  
  .termsText {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.8vw;
    line-height: 4vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsSeparator {
    width: 0;
    height: 7vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .termsHeading {
    font-size: 6vw;
    line-height: 10vw;
    margin-bottom: 4vh;
  }

  .termsHeader {
    width: 100%;
  }
  
  .termsSubheading {
    width: 100%;
    text-align: left;
  }
  
  .termsUpdateContainer {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .termsUpdateContainer p {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 3.5vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsIcon {
    width: 17px;
    height: 17px;
    color: #666;
    margin-right: 3vw;
  }
  
  .termsHeaderSeparator {
    width: 20vw;
    margin-top: 4vh;
    margin-bottom: 0;
  }
  
  .termsContainer {
    width: 100%;
    margin-bottom: 8vh;
  }
  
  .termsText {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 3.6vw;
    line-height: 8.2vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsSeparator {
    width: 0;
    height: 7vh;
  }
}
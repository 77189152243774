.dataWrapper {
  width: 100%;
}

.thankUWrapper .headingText, .dataWrapper .headingText{
  font-family: "Montserrat";
  color: #000;
  font-size: 0.95vw;
  margin-bottom: 1rem;
  font-weight: 700;
}

.thankUWrapper .text, .dataWrapper .text{
  font-family: "Montserrat";
  color: #000;
  font-size: 0.9vw;
  margin-bottom: 1rem;
  font-weight: 400;
}

.dataWrapper .orderDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  text-transform: capitalize;
  margin: 0.2rem 0;
  font-family: "Montserrat";
}

.bankDetails {
  font-family: "Montserrat";
  color: #000;
  font-size: 0.95vw;
  font-weight: 700;
  margin: 2rem 0;
}

.dataWrapper .orderTotal {
  color: #000;
  font-size: 0.95vw;
  margin-bottom: 1rem;
  font-weight: 700;
}

.dataWrapper .orderDate {
  color: #000;
  font-size: 0.95vw;
  margin-bottom: 1rem;
  font-weight: 400;
}

.total {
  padding: 0 0.5rem;
  background-color: #18b7bb80;
  border-radius: 5px;
}

.dataWrapper .paymentTitle {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.1vw;
  color: #000;
  transition: opacity 0.3s ease-out;
  margin: 1rem 0;
}

.paymentSelectorWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem;
  font-size: 0.95vw;
  text-align: center;
  padding: 0.2rem 0.5rem;
}

.paymentSelector {
  flex: 1;
}

.confirmPaymentBtn {
  outline: none;
  border: 0;
  cursor: pointer;
  margin: 1rem;
  font-weight: 700;
  font-size: 1.1vw;
}

.uploadBoxWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paypalBtnWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}



@media (orientation: portrait) and (max-width: 1024px) {
    .thankUWrapper .headingText, .dataWrapper .headingText{
        font-size: 1.6vw;
      }
      
      .thankUWrapper .text, .dataWrapper .text{
        font-size: 1.5vw;
      }
      
      .bankDetails {
        font-size: 1.6vw;
      }
      
      .dataWrapper .orderTotal {
        font-size: 1.6vw;
      }
      
      .dataWrapper .orderDate {
        font-size: 1.6vw;
      }
      
      .dataWrapper .paymentTitle {
        font-size: 1.8vw;
      }
      
      .paymentSelectorWrap {
        font-size: 1.6vw;
      }
      
      .confirmPaymentBtn {
        font-size: 1.8vw;
      }
      
}


@media (orientation: portrait) and (max-width: 767px) {
    .thankUWrapper .headingText, .dataWrapper .headingText{
        font-size: 3.1vw;
      }
      
      .thankUWrapper .text, .dataWrapper .text{
        font-size: 3vw;
      }
      
      .bankDetails {
        font-size: 3.2vw;
      }
      
      .dataWrapper .orderTotal {
        font-size: 3.2vw;
      }
      
      .dataWrapper .orderDate {
        font-size: 3.2vw;
      }
      
      .dataWrapper .paymentTitle {
        font-size: 3.5vw;
      }
      
      .paymentSelectorWrap {
        font-size: 3.2vw;
      }
      
      .confirmPaymentBtn {
        font-size: 3.5vw;
      }
}
.checkoutStatusOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  padding-left: 25vw;
  padding-right: 25vw;
  z-index: 1000000000008;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #fff;
  font-size: 1.8vw;
  background-color: rgba(19, 20, 20, 0.9);
}

.orderPaymentTripleLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orderPaymentTripleField {
  width: 31%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.orderPaymentPoweredBy {
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.orderPaymentPoweredByText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
}

.orderPaymentPoweredByLogo {
  height: 4vh;
  width: auto;
  object-fit: cover;
}

.orderAddressSelector {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.orderAddressOptionalInner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.orderAddressBigIcon {
  width: 30px;
  height: 30px;
  color: #18b7bb;
  margin-right: 1.5vw;
}

.orderAddressInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.orderAddressInfoTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1vh;
}

.orderAddressInfoText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.orderAddressOptionalInnerForm {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.orderAddressOptionalNewAddressIcon {
  width: 30px;
  height: 30px;
  color: #18b7bb;
  margin-right: 1.5vw;
}

.orderAddressOptionalNewAddress {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}

.paypal-buttons {
  width: 25vw !important;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .checkoutStatusOverlay {
    width: 70vw;
    padding-left: 15vw;
    padding-right: 15vw;
    font-size: 3vw;
    line-height: 4.5vw;
  }

  .orderPaymentTripleField {
    width: 32%;
  }
  
  .orderPaymentPoweredBy {
    margin-top: 0.7vh;
  }
  
  .orderPaymentPoweredByText {
    font-size: 1.4vw;
    margin-right: 1vw;
  }
  
  .orderPaymentPoweredByLogo {
    height: 3.3vh;
  }
  
  .orderAddressBigIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 3vw;
  }
  
  .orderAddressInfo {
    width: 70%;
  }
  
  .orderAddressInfoTitle {
    font-size: 2vw;
    margin-bottom: 0.7vh;
  }
  
  .orderAddressInfoText {
    font-size: 1.6vw;
    line-height: 3.2vw;
  }
  
  .orderAddressOptionalNewAddressIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 3vw;
  }
  
  .orderAddressOptionalNewAddress {
    font-size: 2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .checkoutStatusOverlay {
    width: 80vw;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 5vw;
    line-height: 8vw;
  }

  .orderPaymentPoweredBy {
    margin-top: 0.7vh;
  }
  
  .orderPaymentPoweredByText {
    font-size: 3vw;
    margin-right: 2.5vw;
  }
  
  .orderPaymentPoweredByLogo {
    height: 5vh;
  }

  .orderAddressBigIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 5vw;
  }
  
  .orderAddressInfo {
    width: 70%;
  }
  
  .orderAddressInfoTitle {
    font-size: 4vw;
    margin-bottom: 0.7vh;
  }
  
  .orderAddressInfoText {
    font-size: 3.3vw;
    line-height: 5.8vw;
  }
  
  .orderAddressOptionalNewAddressIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 5vw;
  }
  
  .orderAddressOptionalNewAddress {
    font-size: 4vw;
  }

  .paypal-buttons {
    min-width: 70vw !important;
  }
}
.navbarContainer {
  margin-top: 3.7vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
}

.logo {
  height: 6vh;
  width: auto;
  object-fit: cover;
  cursor: pointer;
}

.newIcon{
  width: 25px;
  height:25px;
  margin-top: 1vw;
  margin-right: 5px;
}

.navbarMain {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbarButtons {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navbarLink {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2vw;
  position: relative;
  cursor: pointer;
}

.navbarLinkText {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #fff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.3vw;
  cursor: pointer;
}

.navbarOrderButtons {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4vw;
}

.navbarLinkSubMenuOuter {
  cursor: default;
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  transition: all 0.5s ease-out;
  opacity: 0;
  pointer-events: none;
}

.navbarLink:hover .navbarLinkSubMenuOuter {
  opacity: 1;
  pointer-events: all;
}

.navbarLinkSubMenu {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  padding-top: 4vh;
  padding-bottom: 4vh;
  padding-right: 2vw;
  padding-left: 2vw;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  background-color: #fff;
  border-radius: 10px;
}

.navbarSubMenuBlock {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.navbarSubMenuBlock:hover {
  filter: brightness(0.5);
}

.navbarSubMenuBlockImg {
  width: 2.8vw;
  height: auto;
  object-fit: cover;
}

.navbarSubMenuBlockInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2vw;
}

.navbarSubMenuBlockTitle {
  font-family: 'Montserrat';
  font-size: 1.2vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1vh;
  width: 25vw;
}

.navbarSubMenuBlockText {
  font-family: 'Montserrat';
  font-size: 0.85vw;
  line-height: 1.7vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.navbarSubMenuMiniBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5vh;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.navbarSubMenuMiniBlock:hover {
  filter: brightness(0.5);
}

.navbarSubMenuMiniBlockImg {
  width: 2vw;
  height: auto;
  object-fit: cover;
}

.navbarSubMenuMiniBlockInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.5vw;
}

.navbarSubMenuMiniBlockTitle {
  font-family: 'Montserrat';
  font-size: 1.2vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2vw;
}

.navbarSubMenuMiniBlockText {
  font-family: 'Montserrat';
  font-size: 0.85vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.navbarSubMenuLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5vw;
  width: 100%;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.navbarSubMenuLinkContainer:hover {
  filter: brightness(0.5);
}

.navbarSubMenuLink {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
  transition: margin-right 0.3s ease-out;
}

.navbarSubMenuLinkContainer:hover .navbarSubMenuLink {
  margin-right: 1vw;
}

.simpleSubMenu {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  padding-right: 1.5vw;
  padding-left: 1.5vw;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  background-color: #fff;
  border-radius: 10px;
}

.simpleSubMenuLink {
  margin-top: 1.3vh;
  margin-bottom: 1.3vh;
  width: 12vw;
}

.navbarLinkOrder {
  margin-right: 2vw;
}

.navbarMobile {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .navbarContainer {
    margin-top: 2.7vh;
    margin-bottom: 1vh;
  }
  
  .logo {
    height: 40px;
    width: auto;
  }
  
  .navbarLink {
    margin-left: 3vw;
  }

  .navbarLinkButton {
    display: none;
  }
  
  .navbarLinkText {
    font-size: 1.4vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.8vw;
  }
  
  .navbarOrderButtons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 4vw;
  }
  
  .navbarLinkOrder {
    margin-right: 2vw;
  }

  .navbarLinkSubMenu {
    margin-top: 1vh;
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 5vw;
    padding-left: 5vw;
    border: 1px solid #e1e1e1;
  }
  
  .navbarSubMenuBlock {
    margin-bottom: 2.5vh;
  }
  
  .navbarSubMenuBlockImg {
    width: 6vw;
  }
  
  .navbarSubMenuBlockInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3vw;
  }
  
  .navbarSubMenuBlockTitle {
    font-size: 2.4vw;
    width: 43vw;
  }
  
  .navbarSubMenuBlockText {
    font-size: 1.5vw;
    line-height: 3vw;
  }
  
  .navbarSubMenuMiniBlock {
    margin-bottom: 1.2vh;
    margin-top: 1.2vh;
  }
  
  .navbarSubMenuMiniBlockImg {
    width: 4vw;
  }
  
  .navbarSubMenuMiniBlockInfo {
    margin-left: 2.5vw;
  }
  
  .navbarSubMenuMiniBlockTitle {
    font-size: 2vw;
    margin-right: 2.5vw;
  }
  
  .navbarSubMenuMiniBlockText {
    font-size: 1.5vw;
  }
  
  .navbarSubMenuLinkContainer {
    margin-top: 3vh;
  }
  
  .navbarSubMenuLink {
    font-size: 2.1vw;
    margin-bottom: 0.4vh;
    margin-right: 2vw;
  }
  
  .simpleSubMenu {
    margin-top: 1vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-right: 3vw;
    padding-left: 3vw;
    border: 1px solid #e1e1e1;
  }
  
  .simpleSubMenuLink {
    margin-top: 1.1vh;
    margin-bottom: 1.1vh;
    width: 22vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .navbarMain {
    display: none;
  }

  .navbarContainer {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
  }

  .logo {
    height: 50px;
  }

  .navbarMobile {
    display: flex;
  }

  .mobileMenuButton {
    color: #18b7bb;
  }

  .mobileMenuOuter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    z-index: 200;
    background-color: rgba(0,0,0,0.1);
  }

  .mobileMenuMid {
    position: absolute;
    width: 90vw;
    top: 3vh;
    left: 5vw;
    height: auto;
  }

  .mobileMenu {
    position: relative;
    width: 90vw;
    top: 0;
    left: 0;
    margin-bottom: 20vh;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
    z-index: 201;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 3vh;
  }

  .mobileMenuControls {
    margin-top: 2vh;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .mobileMenuTitle {
    font-family: 'Montserrat';
    font-size: 4vw;
    color: #666;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobileMenuOrderButtons {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    margin-top: 4vh;
  }

  .navbarSubMenuBlock {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 4vh;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }

  .navbarSubMenuBlock:hover {
    filter: brightness(0.5);
  }

  .navbarSubMenuBlockImg {
    margin-top: 1vh;
    width: auto;
    height: 5vh;
    object-fit: cover;
  }

  .navbarSubMenuBlockInfo {
    margin-left: 5vw;
  }

  .navbarSubMenuBlockTitle {
    font-size: 5vw;
    width: auto;
  }

  .navbarSubMenuBlockText {
    font-size: 3.5vw;
    line-height: 7vw;
    width: 100%;
  }

  .mobileMenuMiniBlocks {
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
  }

  .navbarSubMenuMiniBlock {
    margin-bottom: 1.5vh;
    margin-top: 1.5vh;
    width: 85%;
  }

  .navbarSubMenuMiniBlockImg {
    width: auto;
    height: 4vh;
    object-fit: cover;
  }

  .navbarSubMenuMiniBlockInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5vw;
  }

  .navbarSubMenuMiniBlockTitle {
    font-family: 'Montserrat';
    font-size: 5vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .navbarSubMenuMiniBlockText {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    line-height: 7vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobileMenuLinksLine {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
  }

  .mobileMenuLink {
    width: 50%;
    font-family: 'Montserrat';
    font-size: 4.5vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .navbarLink {
    margin-left: 3vw;
  }

  .navbarLinkButton {
    display: none;
  }
  
  .navbarOrderButtons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5vw;
  }
  
  .navbarLinkOrder {
    margin-right: 2vw;
  }
}
.certifiedHeaderImg {
  width: 55%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.certifiedMainInfoContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.certifiedMainInfoTextContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.certifiedMainInfoTitle {
  font-family: 'Montserrat';
  font-size: 1.8vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 3vh;
}

.certifiedMainInfoText {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 2.2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.certifiedMainInfoBlockContainer {
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(24, 183, 187, 0.3);
  border-radius: 10px;
}

.certifiedMainInfoBlockHeader {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #18b7bb;
  border-radius: 10px;
}

.certifiedMainInfoBlockHeaderTitle {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #fff;
  font-weight: 700;
  margin-top: 4vh;
  margin-bottom: 1vh;
}

.certifiedMainInfoBlockHeaderPrice {
  font-family: 'Montserrat';
  font-size: 4vw;
  color: #fff;
  font-weight: 700;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.certifiedMainInfoBlockHeaderMini {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #f5f5f5;
  font-weight: 400;
  margin-top: 1vh;
  margin-bottom: 4vh;
}

.certifiedMainInfoBlockMain {
  margin-top: 2vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.certifiedMainInfoBlockLine {
  width: 85%;
  height: auto;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.certifiedMainInfoBlockLineText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1vw;
}

.certifiedSampleContainer {
  width: calc(100% - 6vw);
  padding-top: 7vh;
  padding-bottom: 7vh;
  padding-right: 3vw;
  padding-left: 3vw;
  background-color: rgba(24, 183, 187, 0.1);
}

.certifiedSampleInfoContainer {
  margin-left: 0vw !important;
  margin-right: 4vw !important;
}

.certifiedSampleBlock {
  width: 10vw !important;
  margin-right: 2vw;
}

.certifiedSampleBlockImgContainer {
  width: 100% !important;
  height: 25vh !important;
}

.certifiedFaqSection {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.certifiedFaqs {
  width: 55%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.certifiedFaqTitle {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5vh;
}

.certifiedFaqLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(102,102,102,0.15);
  overflow-y: hidden;
  margin-bottom: 2vh;
}

.certifiedFaqLineTitle {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1.1vw;
  line-height: 2.6vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 2vh;
  cursor: pointer;
  transition: filter 0.3s ease-in-out;
}

.certifiedFaqLineTitle:hover {
  filter: brightness(0.5);
}

.certifiedFaqLineText {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4vh;
}

.bullet{
  height: 20px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5vw;
  margin-left: 2vw;
  display: inline-block;
}

.certifiedGuides {
  width: 35%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.certifiedGuidesLineText {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 2vh;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.certifiedGuidesLineText:hover {
  filter: brightness(0.5);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .certifiedHeaderImg {
    width: 45%;
    height: auto;
    margin-right: 0;
    object-fit: cover;
    z-index: 10;
    align-self: flex-start;
  }

  .certifiedMainInfoContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .certifiedMainInfoTextContainer {
    width: 55%;
  }
  
  .certifiedMainInfoTitle {
    font-size: 3.5vw;
    margin-bottom: 2vh;
  }
  
  .certifiedMainInfoText {
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .certifiedMainInfoBlockContainer {
    width: 40%;
  }
  
  .certifiedMainInfoBlockHeaderTitle {
    font-size: 2.2vw;
    margin-top: 3vh;
  }
  
  .certifiedMainInfoBlockHeaderPrice {
    font-size: 7vw;
  }
  
  .certifiedMainInfoBlockHeaderMini {
    font-size: 2.2vw;
    margin-bottom: 3vh;
  }
  
  .certifiedMainInfoBlockMain {
    margin-top: 2vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .certifiedMainInfoBlockLine {
    width: 90%;
    height: auto;
    margin-top: 2vh;
    margin-bottom: 0;
  }
  
  .certifiedMainInfoBlockLineText {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    line-height: 3.5vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1vw;
  }

  .certifiedSampleContainer {
    width: calc(100% - 6vw);
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 3vw;
    padding-left: 3vw;
    background-color: rgba(24, 183, 187, 0.1);
  }
  
  .certifiedSampleInfoContainer {
    margin-left: 0vw !important;
    margin-right: 4vw !important;
  }
  
  .certifiedSampleBlock {
    width: 15vw !important;
    margin-right: 2vw;
  }
  
  .certifiedSampleBlockImgContainer {
    width: 100% !important;
    height: 15vh !important;
  }

  .certifiedFaqSection {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .certifiedFaqTitle {
    font-size: 3.5vw;
    margin-bottom: 3vh;
  }

  .certifiedFaqs {
    width: 65%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .certifiedFaqLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #c1c1c1;
    overflow-y: hidden;
    margin-bottom: 2vh;
  }

  .certifiedFaqLineTitle {
    font-size: 2vw;
    line-height: 3.2vw;
    margin-bottom: 2vh;
  }

  .certifiedFaqLineText {
    font-size: 1.8vw;
    line-height: 3.6vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 4vh;
  }

  .certifiedGuides {
    width: 30%;
  }

  .certifiedGuidesLineText {
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .certifiedHeaderImg {
    display: none;
  }

  .certifiedMainInfoContainer {
    margin-top: 12vh;
    margin-bottom: 5vh;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
  
  .certifiedMainInfoTextContainer {
    margin-top: 10vh;
    width: 100%;
  }
  
  .certifiedMainInfoTitle {
    font-size: 6.5vw;
    margin-bottom: 2vh;
  }
  
  .certifiedMainInfoText {
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .certifiedMainInfoBlockContainer {
    width: 100%;
  }
  
  .certifiedMainInfoBlockHeaderTitle {
    font-size: 4.5vw;
    margin-top: 3vh;
  }
  
  .certifiedMainInfoBlockHeaderPrice {
    font-size: 15vw;
  }
  
  .certifiedMainInfoBlockHeaderMini {
    font-size: 4.5vw;
    margin-bottom: 3vh;
  }
  
  .certifiedMainInfoBlockMain {
    margin-top: 2.5vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .certifiedMainInfoBlockLine {
    width: 90%;
    height: auto;
    margin-top: 0vh;
    margin-bottom: 2.5vh;
  }
  
  .certifiedMainInfoBlockLineText {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    line-height: 7vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1vw;
  }

  .certifiedSampleContainer {
    width: calc(100% - 6vw);
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 3vw;
    padding-left: 3vw;
    background-color: rgba(24, 183, 187, 0.1);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .certifiedSampleInfoContainer {
    margin-left: 0vw !important;
    margin-right: 0 !important;
    margin-bottom: 3vh !important;
  }
  
  .certifiedSampleBlock {
    width: 30vw !important;
    margin-right: 5vw !important;
    margin-left: 5vw !important;
  }
  
  .certifiedSampleBlockImgContainer {
    width: 100% !important;
    height: 18vh !important;
  }

  .certifiedFaqSection {
    margin-top: 7vh;
    margin-bottom: 7vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .certifiedFaqs {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5vh;
  }

  .certifiedFaqTitle {
    font-size: 8vw;
    margin-bottom: 4vh;
  }

  .certifiedFaqLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #c1c1c1;
    overflow-y: hidden;
    margin-bottom: 1vh;
  }

  .certifiedFaqLineTitle {
    width: 100%;
    height: 20vw;
    min-height: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 4vw;
    line-height: 6.5vw;
  }

  .certifiedFaqLineText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }

  .certifiedGuides {
    width: 100%;
  }

  .certifiedGuidesLineText {
    font-size: 4vw;
    line-height: 6.5vw;
    margin-top: 1vh;
    margin-bottom: 2.5vh;
  }
}
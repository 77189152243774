.documentsHeaderImg {
  width: 50%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.documentsHeading {
  width: 95%;
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.documentsSubheading {
  width: 95%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.6vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 2vh;
}

.documentsListOuter {
  width: 100%;
  height: auto;
  margin-top: 12vh;
  margin-bottom: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.documentsListTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 7vh;
}

.documentsList {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.documentsListColumn {
  width: 32%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsListItem {
  height: auto;
  width: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5vh;
  /* cursor: pointer; */
  transition: filter 0.3s ease-out;
}

.documentsListItem:hover {
  /* filter: brightness(0.5); */
  filter: brightness(1);
}

.documentsListItemSpecial {
  height: auto;
  width: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5vh;
}

.documentsListItemText {
  margin-left: 1.5vw;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1em;
}

.documentsListItemTextSpecial {
  margin-left: 1.5vw;
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .documentsHeaderImg {
    width: 45%;
    margin-right: 0;
    align-self: flex-start;
  }
  
  .documentsHeading {
    font-size: 3vw;
    margin-bottom: 3vh;
  }
  
  .documentsSubheading {
    width: 95%;
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .documentsListOuter {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .documentsListTitle {
    font-size: 3vw;
    margin-bottom: 4vh;
  }
  
  .documentsListItem:hover {
    filter: brightness(1);
  }
  
  .documentsListItemText {
    margin-left: 1.5vw;
    font-size: 1.8vw;
  }

  .documentsListItemTextSpecial {
    font-size: 1.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .documentsHeaderImg {
    display: none;
  }
  
  .documentsHeading {
    font-size: 7vw;
    margin-bottom: 3vh;
  }
  
  .documentsSubheading {
    width: 100%;
    font-family: 'Montserrat';
    font-size: 4vw;
    line-height: 8vw;
    margin-bottom: 0;
  }
  
  .documentsListOuter {
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .documentsListTitle {
    font-family: 'Montserrat';
    font-size: 8vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 4vh;
  }
  
  .documentsList {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .documentsListColumn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsListItem {
    margin-bottom: 4.5vh;
  }
  
  .documentsListItem:hover {
    filter: brightness(1);
  }
  
  .documentsListItemText {
    margin-left: 2vw;
    font-family: 'Montserrat';
    font-size: 5vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  .documentsListItemTextSpecial {
    font-size: 4.3vw;
  }
}
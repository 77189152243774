.createAccountContainer {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 8vh;
}

.createAccountInfoContainer {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.createAccountExtraContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vh;
}

.createAccountExtraText {
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 0.9vw;
  margin-left: 1.3vw;
}

.createAccountFormContainer {
  margin-top: 0;
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.createAccountFormSeparator {
  height: 2.5vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .createAccountContainer {
    width: 100%;
    margin-top: 8vh;
    margin-bottom: 5vh;
  }
  
  .createAccountExtraText {
    font-family: 'Montserrat';
    font-weight: 400;
    color: #666;
    font-size: 1.8vw;
    margin-left: 1.5vw;
  }
  
  .createAccountFormContainer {
    margin-top: 0;
  }
  
  .createAccountFormSeparator {
    height: 1.5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .createAccountContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
  
  .createAccountInfoContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 4vh;
  }
  
  .createAccountExtraContainer {
    margin-top: -2vh;
  }
  
  .createAccountExtraText {
    font-family: 'Montserrat';
    font-weight: 400;
    color: #666;
    font-size: 3.5vw;
    margin-left: 3vw;
  }
  
  .createAccountFormContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .createAccountFormSeparator {
    height: 2.5vh;
  }
}
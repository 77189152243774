@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
}

h1, h2, h3 {
  margin: 0;
}

.rotateOverlay, .rotateOverlayText {
  display: none;
}

.preloaderOverlay {
  z-index: 1000000000000000;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media (orientation: landscape) and (max-width: 767px) {
  .rotateOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #18b7bb;
    z-index: 100000000000000;
    pointer-events: none;
  }

  .rotateOverlayText {
    display: flex;
    width: 60%;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 2.5vw;
    line-height: 5vw;
    color: #fff;
    margin-top: 7vh;
    margin-bottom: 0;
    text-align: center;
  }
}
.faqHeader {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
  margin-bottom: 8vh;
}

.faqSubheading {
  width: 70%;
  font-family: 'Montserrat';
  font-size: 1.1vw;
  line-height: 2.8vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: center;
}

.faqGeneral {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 7vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .faqHeader {
    margin-top: 6vh;
    margin-bottom: 5vh;
  }
  
  .faqSubheading {
    width: 90%;
    font-size: 2vw;
    line-height: 3.8vw;
    margin-top: 1vh;
    margin-bottom: 2vh;
  }
  
  .faqGeneral {
    width: 100%;
    margin-bottom: 5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .faqHeader {
    margin-top: 6vh;
    margin-bottom: 5vh;
    align-items: flex-start;
  }
  
  .faqSubheading {
    width: 100%;
    font-size: 4.5vw;
    line-height: 9vw;
    text-align: left;
    margin-top: 1vh;
    margin-bottom: 2vh;
  }
  
  .faqGeneral {
    width: 100%;
    margin-bottom: 7vh;
  }
}
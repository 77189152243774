.altAnimatedButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  border: 0.5px solid rgba(255,124,29,0.8);
  background-color: #FF7C1D;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.altAnimatedButton:disabled {
  opacity: 0.3;
}

.altAnimatedButton:focus {
  outline: none;
}

.altAnimatedButton:hover div {
  top: 0;
}

.altAnimatedButton:hover p {
  color: #FF7C1D;
}

.altButtonBackground {
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: #fff;
  transition: all 0.3s ease-out;
}

.altButtonText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.1vw;
  color: #fff;
  z-index: 1;
  margin: 0;
  transition: all 0.3s ease-out;
  text-transform: capitalize;
}

@media (orientation: portrait) and (max-width: 767px) {
  .altAnimatedButton {
    margin: auto;
  }
}
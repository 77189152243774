.manualOrderDecideMainContainer {
  width: 98%;
  margin-left: 2%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.element{
  position: relative;
}

.searchBoxWrap{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: #18b7bb;
  border: 1px solid  #18b7bb;
  box-shadow: 0 0 5px 0.5px #00000052;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 5;
  padding: 0.1rem;
}

.searchTextWrap > .editBtn{
  color: #fff;
}

.searchTextWrap > div{
  width: 100%;
  padding: 0.2rem;
  color: #fff;
  /* border-bottom: 1px solid rgba(128, 128, 128, 0.37); */
  cursor: pointer;
}

/* width */
.searchBoxWrap::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.searchBoxWrap::-webkit-scrollbar-track {
  background: trasparent;
}
 
/* Handle */
.searchBoxWrap::-webkit-scrollbar-thumb {
  background: #fff; 
  border-radius: 5px;
  margin: 5px;
}

/* Handle on hover */
.searchBoxWrap::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}
 
.searchTextWrap{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.closeIcon{
  position: sticky;
  top: 2px;
  left: 95%;
  color: #fff !important;
}


.singleTranslationContainer {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-bottom: 5vh;
}

.singleTranslationHeader {
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(102,102,102,0.2);
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.singleTranslationOrderNumber {
  margin-left: 2vw;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #333;
  font-size: 1.6vw;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationTabsControl {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2vw;
}

.singleTranslationTabButton {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.5vw;
  padding-bottom: 0.5vh;
  border-bottom: 2px solid rgba(255, 124, 29, 0);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.singleTranslationTabButton:hover {
  opacity: 0.7;
}

.singleTranslationDecideContainer {
  width: 94%;
  padding: 2.5vh 2% 2.5vh 2%;
  height: auto;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  margin-top: 2vh;
}

.singleTranslationDecideTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333;
  font-size: 1.1vw;
  margin-top: 0;
  margin-bottom: 2vh;
}

.singleTranslationDecideMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationWrapper{
  width: 45%;
  min-height: 37vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.singleTranslationDecideInfoContainer {
  width: 54%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationDecideInfoLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #666;
}

.singleTranslationDecideInfoTitle {
  width: 35%;
}

.singleTranslationDecideInfoText {
  width: 65%;
}

.singleTranslationDecideInfoSeparator {
  width: 100%;
  height: 0;
  border-bottom: 1px solid rgba(102,102,102,0.15);
}

.singleTranslationDecideExtraContainer {
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.singleTranslationDecideExtraText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #FF7C1D;
  padding: 1vh 2.5vw 1vh 2.5vw;
  border-radius: 10px;
}

.singleTranslationDecideExtraSecondary {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #333;
  margin-top: 1.5vh;
  margin-bottom: 0;
}

.singleTranslationDecideExtraButton {
  width: auto;
  min-width: 40%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 3vw 1.5vh 3vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 2vh;
  border-radius: 7px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s ease-out;
}

.singleTranslationDecideExtraButton:hover {
  opacity: 0.7;
}

.singleTranslatoionWrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4vw;
}

.cancelBtn{
  margin-top: 3vh;
  background-color: rgb(225, 80, 74);
  outline: none;
  padding: 0.5vh 1.5vw;
  font-size: 0.85vw;
  color: #fff;
  font-weight: 600;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}
.cancelBtn:hover{
  transform: scale(1.05);
  transition: transform 0.1s;
}

.singleTranslationDecideTurnaround {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.85vw;
  color: #666;
  margin-top: 3vh;
}

.singleTranslationDecideTurnaroundIcon {
  width: 15px;
  height: 15px;
  margin-right: 0.5vw;
}

.singleTranslationDetailsOuter {
  width: 96%;
  padding: 2.5vh 2% 2.5vh 2%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.singleTranslationDetailsMain {
  width: 65%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationDetailsDates {
  width: calc(100% - 2vw);
  height: auto;
  min-height: 30vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2vh 1vw 2vh 1vw;
}

.singleTranslationDetailsComments {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

.singleTranslationDetailsSecondary {
  width: calc(32% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 40vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesOuter {
  width: 96%;
  padding: 2.5vh 2% 2.5vh 2%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesMain {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 35vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesSecondary {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3vh;
}

.singleTranslationFilesUpload {
  width: calc(49% - 2vw);
  padding: 2vh 1vw 0 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesCoversheet {
  width: calc(49% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationInvoiceMain {
  width: calc(65% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationInvoiceTitleLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleTranslationInvoiceEditButton {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 0.5vh 1vw 0.5vh 1vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.8vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singleTranslationInvoiceEditButton:hover {
  opacity: 0.6;
}

.singleTranslationInvoiceSecondaryOuter {
  width: 32%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationInvoiceSecondary {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 15vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

.singleTranslationDatesProgressContainer {
  width: 40%;
  margin-right: 3%;
  height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.singleTranslationDatesProgressText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #333;
  z-index: 100;
  margin-top: 0;
  margin-bottom: 0;
}

.progress-ring__circle {
  transition: stroke-dashoffset 1s ease-out;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.topCircle {
  z-index: 100;
}

.singleTranslationDetailsSecondaryText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  line-height: 1.6vw;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.singleTranslationStatusContainer {
  width: auto;
  height: auto;
  padding: 0.8vh 1vw 0.8vh 1vw;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.6vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 1vh;
}

.singleTranslationDetailsExtraButton {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 0 1.5vh 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 0;
  border-radius: 7px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s ease-out;
}

.singleTranslationDetailsExtraButton:hover {
  opacity: 0.7;
}

.singleTranslationNotesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1vh;
}

.singleTranslationNotesTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 0;
  width: calc(100% - 1vw);
  border: 1px solid rgba(102,102,102,0.15);
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 1vw;
  background-color: #1e7e81;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleTranslationNotesAuxButton {
  border: 1px solid #fff;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 0.5vh 1vw 0.5vh 1vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.8vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  margin-left: 0.5vw;
  margin-right: 1vw;
}

.singleTranslationNotesAuxButton:hover {
  opacity: 0.8;
}

.singleTranslationRichTextEditor {
  margin-bottom: 3vh;
  margin-top: 0;
  width: 100%;
  min-height: 20vh;
  border: 1px solid rgba(102,102,102,0.15);
}

.singleTranslationFilesUploadText {
  margin-top: 0;
  margin-bottom: 2vh;
}

.singleTranslationDetailsSecondarySeparator {
  margin-top: 2vh;
}

.singleTranslationFilesTableHeader {
  width: 100%;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e1e1e1;
}

.singleTranslationNoLines {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}

.singleTranslationFilesTableHeaderColumn {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationFilesTableHeaderNameColumn {
  width: 80%;
}

.singleTranslationFilesTableHeaderCertificateColumn {
  width: 20%;
  margin-left: 2vw;
}

.singleTranslationFilesTableHeaderNameColumnAlt {
  width: 70%;
}

.singleTranslationFilesTableHeaderTypeColumn {
  width: 15%;
}

.singleTranslationFilesTableHeaderButtonColumn {
  width: 5%;
}

.singleTranslationFilesTableLine {
  width: 100%;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}

.singleTranslationFilesTableLineColumn {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationFilesTableLineTextColumn {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleTranslationFilesTableLineText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  line-height: 1.8vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singleTranslationFilesTableLineText:hover {
  opacity: 0.7;
}

.coversheetInput {
  color: #333;
  background-color: transparent;
  border-bottom: 1px solid rgba(102, 102, 102, 1);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  line-height: 0.9vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 2.3vw;
  width: 100%;
  padding-left: 0;
}

.coversheetInput:focus {
  outline: none;
}

.singleTranslationInvoiceTotals {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.singleTranslationInvoiceAddLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singleTranslationInvoiceAddLineContainer:hover {
  opacity: 0.6;
}

.singleTranslationInvoiceAddLineText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #FF7C1D;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationAddLineIcon {
  width: 17px;
  height: 17px;
  margin-right: 0.6vw;
  color: #FF7C1D;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.singleTranslationAddLineIcon:hover {
  opacity: 0.6;
}

.singleTranslationInvoiceTotalsInner {
  width: 50%;
  height: 6vh;
  padding: 2vh 1vw 2vh 1vw;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: space-between;
}

.singleTranslationInvoiceTotalsTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 65%;
}

.singleTranslationInvoiceTotalsTextTitle {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationInvoiceTotalsText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 35%;
}

.singleTranslationInvoiceMemo {
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.singleTranslationInvoiceMemoTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationInvoiceMemoTextArea {
  color: #333;
  background-color: transparent;
  border: 1px solid rgba(102, 102, 102, 0.3);
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.85vw;
  line-height: 1.6vw;
  border-radius: 5px;
  margin-top: 1vh;
  margin-bottom: 0;
  height: 15vh;
  width: 100%;
  padding: 5px;
  resize: none;
}

.singleTranslationInvoiceMemoTextArea::placeholder {
  color: rgba(102, 102, 102, 1);
}

.singleTranslationInvoiceMemoTextArea:focus {
  outline: none;
}

.singleTranslationInvoiceButtons {
  width: auto;
  align-self: flex-end;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleTranslationInvoiceButtonsSeparator {
  width: 0.7vw;
}

.singleTranslationInvoiceReviewText {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.singleTranslationInvoiceReviewMemoArea {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: calc(100% - 1vw);
}

.singleTranslationDiscountInputContainer {
  width: 50%;
}

.notarizationStatusSelect {
  width: 100%;
  height: 3vh;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #666;
  font-family: 'Montserrat';
  font-size: 0.85vw;
  line-height: 2vw;
  font-weight: 400;
  color: #666;
  margin-bottom: 2vh;
}

.notarizationStatusSelect:focus {
  outline: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleTranslationHeader {
    width: 98%;
    padding-left: 1%;
    padding-right: 1%;
  }
  
  .singleTranslationOrderNumber {
    margin-left: 2vw;
    font-size: 2.2vw;
  }
  
  .singleTranslationTabButton {
    font-size: 1.6vw;
    margin-left: 2.2vw;
    padding-bottom: 0.5vh;
    border-bottom: 2px solid rgba(255, 124, 29, 0);
  }
  
  .singleTranslationTabButton:hover {
    opacity: 1;
  }

  .singleTranslationDecideContainer {
    width: 89%;
    padding: 2.5vh 3% 2.5vh 3%;
  }
  
  .singleTranslationDecideTitle {
    font-size: 2vw;
    margin-bottom: 0;
  }

  .singleTranslationDecideMainContainer {
    margin-top: 2vh;
  }

  .singleTranslationDecideInfoContainer {
    width: 60%;
  }
  
  .singleTranslationDecideInfoLine {
    font-weight: 500;
    font-size: 1.7vw;
    color: #666;
  }
  
  .singleTranslationDecideInfoTitle {
    width: 40%;
  }
  
  .singleTranslationDecideInfoText {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .singleTranslationDecideExtraContainer {
    width: 40%;
  }
  
  .singleTranslationDecideExtraText {
    font-size: 1.7vw;
    padding: 0.7vh 2.5vw 0.7vh 2.5vw;
    border-radius: 6px;
  }
  
  .singleTranslationDecideExtraSecondary {
    font-size: 1.5vw;
    margin-top: 1.2vh;
  }
  
  .singleTranslationDecideExtraButton {
    min-width: 50%;
  }
  
  .singleTranslationDecideExtraButton:hover {
    opacity: 1;
  }

  .cancelBtn{
    font-size: 1.6vw;
    margin-top: 2.5vh;
    padding: 0.7vh 2.5vw 0.7vh 2.5vw;
  }
  
  .singleTranslationDecideTurnaround {
    font-size: 1.6vw;
    margin-top: 2.5vh;
  }
  
  .singleTranslationDecideTurnaroundIcon {
    width: 2vw;
    height: 2vw;
    margin-right: 1.2vw;
  }
  
  .singleTranslationDetailsOuter {
    width: 95%;
    padding: 2.5vh 2% 2.5vh 2%;
  }
  
  .singleTranslationDetailsMain {
    width: 55%;
  }
  
  .singleTranslationDetailsDates {
    width: calc(100% - 4vw);
    min-height: 0;
    padding: 2vh 2vw 2vh 2vw;
  }
  
  .singleTranslationDetailsComments {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
  }
  
  .singleTranslationDetailsSecondary {
    width: calc(42% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesOuter {
    width: 95%;
  }
  
  .singleTranslationFilesMain {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesUpload {
    width: calc(49% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesCoversheet {
    width: calc(49% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationInvoiceMain {
    width: calc(62% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
    background-color: #fff;
  }
  
  .singleTranslationInvoiceTitleLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .singleTranslationInvoiceEditButton {
    font-size: 1.6vw;
    margin-bottom: 2vh;
  }
  
  .singleTranslationInvoiceEditButton:hover {
    opacity: 1;
  }
  
  .singleTranslationInvoiceSecondaryOuter {
    width: 35%;
  }
  
  .singleTranslationInvoiceSecondary {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDatesProgressContainer {
    display: none;
  }
  
  .singleTranslationDetailsSecondaryText {
    font-size: 1.6vw;
    line-height: 3vw;
    margin-top: 2vh;
    margin-bottom: 0.5vh;
  }
  
  .singleTranslationStatusContainer {
    padding: 0.8vh 2vw 0.8vh 2vw;
    font-size: 1.3vw;
    margin-top: 2vh;
  }
  
  .singleTranslationNotesContainer {
    margin-bottom: 0;
    margin-top: 2vh;
  }
  
  .singleTranslationNotesTitle {
    font-size: 1.5vw;
    margin-top: 1vh;
    margin-bottom: 0;
    width: calc(100% - 1vw);
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
  }
  
  .singleTranslationNotesAuxButton {
    padding: 0.5vh 1vw 0.5vh 1vw;
    font-size: 1.4vw;
  }
  
  .singleTranslationNotesAuxButton:hover {
    opacity: 1;
  }
  
  .singleTranslationFilesUploadText {
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .singleTranslationDetailsSecondarySeparator {
    margin-top: 1vh;
  }
  
  .singleTranslationFilesTableHeader {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationNoLines {
    font-size: 1.6vw;
  }
  
  .singleTranslationFilesTableHeaderColumn {
    font-size: 1.6vw;
  }
  
  .singleTranslationFilesTableHeaderNameColumn {
    width: 80%;
  }

  .singleTranslationFilesTableHeaderCertificateColumn {
    display: none;
  }
  
  .singleTranslationFilesTableHeaderNameColumnAlt {
    width: 70%;
  }
  
  .singleTranslationFilesTableHeaderTypeColumn {
    width: 15%;
  }
  
  .singleTranslationFilesTableHeaderButtonColumn {
    width: 5%;
  }
  
  .singleTranslationFilesTableLine {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationFilesTableLineColumn {
    font-size: 1.6vw;
  }
  
  .singleTranslationFilesTableLineText {
    font-size: 1.6vw;
    line-height: 3vw;
  }
  
  .singleTranslationFilesTableLineText:hover {
    opacity: 1;
  }
  
  .coversheetInput {
    font-size: 1.6vw;
    line-height: 1.6vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 4.6vw;
  }
  
  .singleTranslationInvoiceAddLineContainer:hover {
    opacity: 1;
  }
  
  .singleTranslationInvoiceAddLineText {
    font-size: 1.6vw;
  }
  
  .singleTranslationAddLineIcon {
    width: 2vw;
    height: 2vw;
    margin-right: 0.8vw;
  }
  
  .singleTranslationAddLineIcon:hover {
    opacity: 1;
  }
  
  .singleTranslationInvoiceTotalsInner {
    width: 50%;
    height: 4vh;
    padding: 1.5vh 1vw 1.5vh 1vw;
  }
  
  .singleTranslationInvoiceTotalsTextTitle {
    font-size: 1.6vw;
  }
  
  .singleTranslationInvoiceMemo {
    width: 70%;
    margin-top: 2vh;
    margin-bottom: 1vh;
  }
  
  .singleTranslationInvoiceMemoTitle {
    font-size: 1.7vw;
  }
  
  .singleTranslationInvoiceMemoTextArea {
    font-size: 1.6vw;
    line-height: 3vw;
    border-radius: 5px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    height: 12vh;
  }
  
  .singleTranslationInvoiceButtonsSeparator {
    width: 1.5vw;
  }
  
  .singleTranslationInvoiceReviewText {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .singleTranslationInvoiceReviewMemoArea {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: calc(100% - 1vw);
  }

  .singleTranslationDiscountInputContainer {
    width: 80%;
  }

  .notarizationStatusSelect {
    width: 100%;
    height: 3vh;
    font-size: 1.9vw;
    line-height: 3.2vw;
    margin-bottom: 0.5vh;
  }
  
  .notarizationStatusSelect:focus {
    outline: none;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .singleTranslationWrapper{
    min-height: 10vh;
  }

  .singleTranslationHeader {
    width: 98%;
    padding-left: 1%;
    padding-right: 1%;
  }
  
  .singleTranslationOrderNumber {
    margin-left: 2vw;
    font-size: 4.2vw;
  }
  
  .singleTranslationTabButton {
    font-size: 3.2vw;
    margin-left: 4.3vw;
    padding-bottom: 0.5vh;
  }

  .singleTranslationDecideContainer {
    width: 89%;
    padding: 2.5vh 3% 2.5vh 3%;
  }
  
  .singleTranslationDecideTitle {
    font-size: 4vw;
    margin-bottom: 1vh;
  }

  .singleTranslationDecideMainContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 1vh;
  }

  .singleTranslationDecideInfoContainer {
    width: 100%;
  }
  
  .singleTranslationDecideInfoLine {
    font-weight: 500;
    font-size: 3.5vw;
    color: #666;
  }
  
  .singleTranslationDecideInfoTitle {
    width: 40%;
  }
  
  .singleTranslationDecideInfoText {
    width: 60%;
  }
  
  .singleTranslationDecideExtraContainer {
    width: 100%;
    margin-top: 3vh;
  }
  
  .singleTranslationDecideExtraText {
    font-size: 3vw;
    padding: 0.7vh 5vw 0.7vh 5vw;
    border-radius: 6px;
  }
  
  .singleTranslationDecideExtraSecondary {
    font-size: 3.2vw;
    margin-top: 1vh;
  }
  
  .singleTranslationDecideExtraButton {
    min-width: 50%;
  }
  
  .singleTranslationDecideExtraButton:hover {
    opacity: 1;
  }

  .cancelBtn{
    font-size: 3.5vw;
    margin-top: 4vh;
    padding: 0.7vh 5vw 0.7vh 5vw;
  }
  
  .singleTranslationDecideTurnaround {
    font-size: 3.5vw;
    margin-top: 4vh;
  }
  
  .singleTranslationDecideTurnaroundIcon {
    width: 4vw;
    height: 4vw;
    margin-right: 1.8vw;
  }
  
  .singleTranslationDetailsOuter {
    width: 95%;
    padding: 2.5vh 2% 2.5vh 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .singleTranslationDetailsMain {
    width: 100%;
  }
  
  .singleTranslationDetailsDates {
    width: calc(100% - 6vw);
    min-height: 0;
    padding: 2vh 3vw 2vh 3vw;
  }
  
  .singleTranslationDetailsComments {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDetailsSecondary {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesOuter {
    width: 95%;
  }
  
  .singleTranslationFilesMain {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
  }

  .singleTranslationFilesSecondary {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3vh;
  }
  
  .singleTranslationFilesUpload {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationFilesCoversheet {
    width: calc(100% - 4vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationInvoiceMain {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
    background-color: #fff;
    margin-bottom: 3vh;
  }
  
  .singleTranslationInvoiceEditButton {
    font-size: 3.5vw;
    padding: 0.5vh 3vw 0.5vh 3vw;
    margin-bottom: 2vh;
  }

  .singleTranslationInvoiceSecondaryOuter {
    width: 100%;
  }
  
  .singleTranslationInvoiceSecondary {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDetailsSecondaryText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-top: 2vh;
    margin-bottom: 0.5vh;
  }
  
  .singleTranslationStatusContainer {
    padding: 0.8vh 4vw 0.8vh 4vw;
    font-size: 2.5vw;
    margin-top: 2vh;
  }
  
  .singleTranslationNotesContainer {
    margin-bottom: 0;
  }
  
  .singleTranslationNotesTitle {
    font-size: 3.5vw;
    margin-top: 1vh;
    margin-bottom: 0;
    width: calc(100% - 2vw);
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vw;
  }
  
  .singleTranslationNotesAuxButton {
    padding: 0.5vh 3vw 0.5vh 3vw;
    font-size: 3vw;
    margin-right: 2vw;
  }
  
  .singleTranslationFilesUploadText {
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .singleTranslationDetailsSecondarySeparator {
    margin-top: 1vh;
  }
  
  .singleTranslationFilesTableHeader {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationNoLines {
    font-size: 3.5vw;
  }
  
  .singleTranslationFilesTableHeaderColumn {
    font-size: 3.5vw;
  }
  
  .singleTranslationFilesTableHeaderNameColumn {
    width: 100%;
  }
  
  .singleTranslationFilesTableHeaderNameColumnAlt {
    width: 70%;
  }
  
  .singleTranslationFilesTableHeaderTypeColumn {
    width: 15%;
  }
  
  .singleTranslationFilesTableHeaderButtonColumn {
    width: 5%;
  }
  
  .singleTranslationFilesTableLine {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationFilesTableLineColumn {
    font-size: 3.2vw;
  }
  
  .singleTranslationFilesTableLineText {
    font-size: 3.2vw;
    line-height: 6vw;
  }
  
  .coversheetInput {
    font-size: 3.5vw;
    line-height: 3.5vw;
    margin-top: 0;
    margin-bottom: 3vh;
    height: 9.2vw;
  }
  
  .singleTranslationInvoiceAddLineText {
    font-size: 3.5vw;
  }
  
  .singleTranslationAddLineIcon {
    width: 5vw;
    height: 5vw;
    margin-right: 1.2vw;
  }
  
  .singleTranslationInvoiceTotalsInner {
    width: 60%;
    height: 6vh;
    padding: 1.5vh 1vw 1.5vh 1vw;
  }
  
  .singleTranslationInvoiceTotalsTextTitle {
    font-size: 3.5vw;
  }
  
  .singleTranslationInvoiceMemo {
    width: 95%;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }
  
  .singleTranslationInvoiceMemoTitle {
    font-size: 3.6vw;
  }
  
  .singleTranslationInvoiceMemoTextArea {
    font-size: 3.5vw;
    line-height: 7vw;
    border-radius: 5px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    height: 18vh;
  }
  
  .singleTranslationInvoiceButtonsSeparator {
    width: 1.5vw;
  }
  
  .singleTranslationInvoiceReviewText {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .singleTranslationInvoiceReviewMemoArea {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: calc(100% - 4vw);
  }

  .singleTranslationDiscountSubmitButtonContainer {
    align-self: flex-end;
    margin-bottom: 3vh;
  }

  .notarizationStatusSelect {
    width: 100%;
    height: 3vh;
    font-size: 3.5vw;
    line-height: 5vw;
  }
  
  .notarizationStatusSelect:focus {
    outline: none;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .singleTranslationOrderNumber {
    font-size: 1.2vw;
  }
  
  .singleTranslationTabButton:hover {
    opacity: 1;
  }
  
  .singleTranslationDecideTitle {
    font-family: 'Montserrat';
    font-weight: 700;
    color: #333;
    font-size: 1.1vw;
    margin-top: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDecideInfoContainer {
    width: 54%;
  }
  
  .singleTranslationDecideExtraText {
    font-size: 1.1vw;
    padding: 1.2vh 2.5vw 1.2vh 2.5vw;
    border-radius: 5px;
  }

  .singleTranslationDecideExtraSecondary {
    margin-top: 2.5vh;
  }
  
  .singleTranslationDecideExtraButton:hover {
    opacity: 1;
  }
  
  .singleTranslationDecideTurnaroundIcon {
    width: 11px;
    height: 11px;
    margin-right: 0.5vw;
  }
  
  
  .singleTranslationFilesMain {
    padding: 2vh 1vw 4vh 1vw;
  }
  
  .singleTranslationFilesUpload {
    padding: 2vh 1vw 4vh 1vw;
  }
  
  .singleTranslationFilesCoversheet {
    padding: 2vh 1vw 4vh 1vw;
  }
  
  .singleTranslationInvoiceEditButton {
    border: 1px solid #e1e1e1;
    border-radius: 3px;
  }
  
  .singleTranslationInvoiceEditButton:hover {
    opacity: 1;
  }
  
  .singleTranslationDatesProgressContainer {
    display: none;
  }
  
  .singleTranslationStatusContainer {
    border-radius: 2px;
  }
  
  .singleTranslationNotesAuxButton:hover {
    opacity: 1;
  }
  
  .singleTranslationRichTextEditor {
    margin-bottom: 3vh;
    margin-top: 0;
    width: 100%;
    min-height: 30vh;
    border: 1px solid rgba(102,102,102,0.15);
  }
  
  .singleTranslationFilesUploadText {
    margin-top: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDetailsSecondarySeparator {
    margin-top: 3vh;
  }
  
  .singleTranslationFilesTableLineText:hover {
    opacity: 1;
  }
  
  .coversheetInput {
    margin-bottom: 3vh;
  }
  
  .singleTranslationInvoiceAddLineContainer:hover {
    opacity: 1;
  }
  
  
  .singleTranslationAddLineIcon {
    width: 11px;
    height: 11px;
  }
  
  .singleTranslationAddLineIcon:hover {
    opacity: 1;
  }

  .notarizationStatusSelect {
    width: 100%;
    height: 5vh;
    font-size: 0.9vw;
    line-height: 2vw;
  }
}
/* Section container */
.section {
    margin-top: 7rem; /* Tailwind's mt-28 */
  }
  
  @media (min-width: 1280px) {
    .section {
      margin-top: 10rem; /* Tailwind's xl:mt-40 */
    }
  }
  
 /* Section Header */
 .section-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Tailwind's gap-4 */
  text-align: center;
}

.section-title {
  font-size: 2rem; /* Tailwind's text-[32px] */
  font-family: 'Montserrat', sans-serif;
  color: #1EB8BC;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 640px) {
  .section-title {
    font-size: 2.25rem; /* Tailwind's sm:text-[36px] */
  }
}

@media (min-width: 768px) {
  .section-title {
    font-size: 2.5rem; /* Tailwind's md:text-[40px] */
  }
}

@media (min-width: 1280px) {
  .section-title {
    font-size: 2.75rem; /* Tailwind's xl:text-[44px] */
  }
}

  
  /* Card grid */
  .card-grid {
    display: grid;
    gap: 2.5rem; /* Tailwind's gap-10 */
    margin-top: 2.5rem; /* Tailwind's mt-10 */
    grid-template-columns: 1fr;
  }
  
  @media (min-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr); /* Tailwind's md:grid-cols-2 */
    }
  }
  
  @media (min-width: 1280px) {
    .card-grid {
      grid-template-columns: repeat(4, 1fr) !important; /* Tailwind's xl:grid-cols-4 */
    }
  }
  
  /* Card styles */
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem; /* Tailwind's gap-4 */
    background-color: #fff; /* Tailwind's bg-white */
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15); /* Tailwind's shadow */
    border-radius: 1.5rem; /* Tailwind's rounded-3xl */
    padding: 2rem; /* Tailwind's p-8 */
    font-family: 'Montserrat', sans-serif;
  }
  

  .card-title {
    font-size: 1.5rem; /* Tailwind's text-2xl */
    font-weight: 600; /* Tailwind's font-semibold */
  }
  
  .card-description {
    font-size: 0.875rem; /* Tailwind's text-sm */
    font-weight: 500; /* Tailwind's font-medium */
  }
  
.orderSecondaryIcon {
  width: 17px;
  height: 17px;
  color: #18b7bb;
  margin-right: 0.7vw;
}

.orderSecondaryTitle {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orderCountContainer {
  width: calc(100% - 3vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
}

.orderCountInputContainer {
  width: 35%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orderCountWarning {
  width: calc(100% - 5vw);
  height: auto;
  background-color: #f5f5f5;
  padding: 2vh 0 2vh 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 1.8vw;
  font-weight: 400;
  color: #666;
  margin-top: 2vh;
  border-left: 5px solid #FF7C1D;
}

.orderCountAuxText {
  width: 60%;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.6vw;
  font-weight: 300;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.orderSummary {
  width: calc(100% - 5vw);
  height: auto;
  margin-top: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 6vh;
}

.orderSummaryTitle {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 4vh;
}

.orderSummaryInfo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 3vh;
}

.orderSummaryInfoMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.orderSummaryInfoType {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 1vh;
}

.orderSummaryInfoCount {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  font-weight: 300;
  color: rgba(102,102,102,0.5);
  margin-top: 0;
  margin-bottom: 0;
  width: 15vw;
}

.orderSummaryTotal {
  border-top: 1px solid rgba(102,102,102,0.8);
  width: 100%;
  height: auto;
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 1.3vw;
  font-weight: 900;
  color: #666;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .orderSecondaryIcon {
    margin-right: 1.2vw;
  }

  .orderSecondaryTitle {
    font-size: 1.9vw;
    margin-bottom: 1vh;
  }
  
  .orderCountContainer {
    width: calc(100% - 3vw);
    margin-top: 1vh;
  }
  
  .orderCountInputContainer {
    width: 35%;
  }
  
  .orderCountWarning {
    width: calc(100% - 7vw);
    padding: 1vh 0 1vh 2vw;
    font-size: 1.7vw;
    line-height: 3.2vw;
    margin-top: 1vh;
  }
  
  .orderCountAuxText {
    width: 60%;
    font-size: 1.4vw;
    line-height: 2.8vw;
    text-align: right;
  }
  
  .orderSummary {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .orderSummaryTitle {
    font-size: 2vw;
    margin-bottom: 3vh;
  }
  
  .orderSummaryInfo {
    padding-bottom: 2vh;
  }
  
  .orderSummaryInfoType {
    font-size: 2.2vw;
    margin-bottom: 1vh;
  }
  
  .orderSummaryInfoCount {
    font-family: 'Montserrat';
    font-size: 1.7vw;
    width: 20vw;
  }
  
  .orderSummaryTotal {
    margin-top: 1vh;
    font-size: 2.4vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .orderSecondaryIcon {
    margin-right: 2vw;
  }
  
  .orderSecondaryTitle {
    font-size: 3.3vw;
    margin-bottom: 2vh;
  }
  
  .orderCountContainer {
    width: 100%;
    margin-top: 2vh;
  }
  
  .orderCountInputContainer {
    width: 35%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .orderCountWarning {
    width: calc(100% - 2vw);
    padding: 2vh 0 2vh 2vw;
    font-size: 3.3vw;
    line-height: 6vw;
    margin-top: 2vh;
  }
  
  .orderCountAuxText {
    font-size: 3vw;
    line-height: 5vw;
  }
  
  .orderSummary {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 6vh;
  }
  
  .orderSummaryTitle {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    font-weight: 400;
    color: #18b7bb;
    margin-top: 0;
    margin-bottom: 4vh;
  }
  
  .orderSummaryInfo {
    width: 100%;
    padding-bottom: 3vh;
  }
  
  .orderSummaryInfoType {
    font-size: 4.5vw;
    margin-bottom: 1vh;
  }
  
  .orderSummaryInfoCount {
    font-size: 3.3vw;
    width: 60vw;
  }
  
  .orderSummaryTotal {
    border-top: 1px solid rgba(102,102,102,0.8);
    width: 100%;
    height: auto;
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 4.5vw;
    font-weight: 900;
    color: #666;
  }
}
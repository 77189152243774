.invoicesInvoiceColumn {
  width: 12%;
}

.invoicesOrderColumn {
  width: 12%;
}

.invoicesPaymentColumn {
  width: 12%;
}

.invoicesFromColumn {
  width: 9%;
}

.invoicesFromColumn p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.invoicesToColumn {
  width: 9%;
}

.invoicesToColumn p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.invoicesWordsColumn {
  width: 5%;
}

.invoicesRateColumn {
  width: 5%;
}

.invoicesSubmittedColumn {
  width: 9%;
}

.invoicesTranslatorColumn {
  width: 13%;
}

.invoicesStatusColumn {
  width: 8%;
}

.invoicesTotalColumn {
  width: 5%;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .invoicesInvoiceColumn {
    width: 19%;
  }
  
  .invoicesOrderColumn {
    display: none;
  }
  
  .invoicesPaymentColumn {
    width: 19%;
  }
  
  .invoicesFromColumn {
    display: none;
  }
  
  .invoicesFromColumn p {
    display: none;
  }
  
  .invoicesToColumn {
    display: none;
  }
  
  .invoicesToColumn p {
    display: none;
  }
  
  .invoicesWordsColumn {
    display: none;
  }
  
  .invoicesRateColumn {
    display: none;
  }
  
  .invoicesSubmittedColumn {
    width: 16%;
  }
  
  .invoicesTranslatorColumn {
    width: 20%;
  }
  
  .invoicesStatusColumn {
    width: 14%;
  }
  
  .invoicesTotalColumn {
    width: 7%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .invoicesInvoiceColumn {
    width: 32%;
  }
  
  .invoicesPaymentColumn {
    display: none;
  }
  
  .invoicesSubmittedColumn {
    width: 22%;
  }
  
  .invoicesTranslatorColumn {
    display: none;
  }
  
  .invoicesStatusColumn {
    width: 20%;
  }
  
  .invoicesTotalColumn {
    width: 15%;
  }
}
.footerContainer {
  margin-top: 12vh;
  height: auto;
  width: 100vw;
  background-image: url('../assets/footerBg.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20vh;
  padding-bottom: 2vh;
}

.footerReadyContainer {
  width: 82%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footerReadyText {
  font-family: 'Montserrat';
  font-weight: 900;
  font-size: 1.8vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.footerSeparator {
  width: 82%;
  height: 0;
  margin-top: 8vh;
  margin-bottom: 8vh;
  border: 0.3px solid #f5f5f5;
}

.footerMainContainer {
  width: 82%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8vh;
}

.footerMainSecondary {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.footerMainSecondaryLogo {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.footerSecondaryText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 0;
}

.footerMainListContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.footerMainListColumn {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 8vw;
}

.footerMainListColumnTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.footerMainListColumnLink {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.95vw;
  color: #f5f5f5;
  margin-top: 4vh;
  margin-bottom: 0;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}

.footerMainListColumnLink:hover {
  opacity: 0.5;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .footerContainer {
    margin-top: 4vh;
    height: auto;
    width: 100vw;
    padding-top: 12vh;
    padding-bottom: 2vh;
  }

  .footerReadyContainer {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .footerReadyText {
    font-size: 3vw;
  }
  
  .footerSeparator {
    width: 90%;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  
  .footerMainContainer {
    width: 90%;
    margin-bottom: 4vh;
  }

  .footerMainSecondaryLogo {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  
  .footerSecondaryText {
    font-size: 1.3vw;
    margin-top: 1vh;
  }
  
  .footerMainListColumn {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5vw;
  }
  
  .footerMainListColumnTitle {
    font-size: 2vw;
  }
  
  .footerMainListColumnLink {
    font-size: 1.7vw;
    margin-top: 1.5vh;
  }
  
  .footerMainListColumnLink:hover {
    opacity: 0.5;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .footerContainer {
    margin-top: 6vh;
    height: auto;
    width: 100vw;
    padding-top: 20vh;
    padding-bottom: 2vh;
  }
  
  .footerReadyContainer {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .footerReadyText {
    font-size: 6vw;
    line-height: 9vw;
    text-align: center;
    margin-bottom: 3vh;
  }
  
  .footerSeparator {
    margin-top: 5.5vh;
    margin-bottom: 5.5vh;
  }

  .nonMobileColumn {
    display: none !important;
  }
  
  .footerMainContainer {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
  }
  
  .footerMainSecondary {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5vh;
  }
  
  .footerMainSecondaryLogo {
    display: none;
  }
  
  .footerSecondaryText {
    font-size: 3.5vw;
    margin-top: 2vh;
  }
  
  .footerMainListContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .footerMainListColumn {
    margin-left: 0;
  }
  
  .footerMainListColumnTitle {
    font-size: 4.5vw;
  }
  
  .footerMainListColumnLink {
    font-size: 3.6vw;
    color: #f5f5f5;
    margin-top: 3vh;
    opacity: 1;
  }
  
  .footerMainListColumnLink:hover {
    opacity: 0.8;
  }
}
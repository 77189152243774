.footer-Container {
    background-color: #1EB8BC;
    padding-top: 2.5rem;
    padding-bottom: 3rem;
}

.footerReadyContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: 0 1rem; */
}

.relative-group {
    position: relative;
}

.group-text {
    color: #F2F2F2;
    font-size: 1.125rem;
    /* equivalent to text-lg */
    line-height: 1.75rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.group-text:hover {
    color: #FFF;
}

.group-underline {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #FFF;
    transition: width 0.3s ease-in-out;
    transform: translateX(-50%);
}

.relative-group:hover .group-underline {
    width: 80%;
}


.topButton {
    background-color: #F2F2F2;
    padding: 0.75rem;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
}

.text-blue {
    color: #1EB8BC;
}

.navigation-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1.5rem;
}

.copyRight {
    text-align: center;
    color: #F2F2F2;
    padding-top: 2rem;
    font-size: 0.875rem;
    line-height: 1.25rem
}

.top-text {
    color: #F2F2F2;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
}

.topButton-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

@media (min-width: 640px) {}

@media (min-width: 800px) {
    .footerReadyContainer {
        flex-direction: row;
    }

    .navigation-container {
        flex-direction: row;
        margin-bottom: 0;
        gap: 2rem;
    }

    .top-text {
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: left;
    }

    .topButton-container {
        flex-direction: row;
    }

}

@media (min-width: 768px) {

    .topButton {
        padding: 1rem;
    }

    .copyRight {
        font-size: 1rem;
        line-height: 1.5rem
    }



}

@media (min-width: 1024px) {}

@media (min-width: 1280px) {}

@media (min-width: 1536px) {}
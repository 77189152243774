.aboutHeaderImg {
  width: 35%;
  margin-right: 5%;
  align-self: flex-start;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .aboutHeaderImg {
    width: 45%;
    margin-right: 0;
    align-self: flex-start;
  }
  
  .aboutMainContainer {
    align-items: center !important;
  }

  .aboutSecondContainer {
    align-items: center !important;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .aboutMainContainer {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .aboutSecondContainer {
    flex-direction: column-reverse !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 0 !important;
  }

  .aboutHeaderImg {
    display: flex !important;
    width: 90% !important;
    height: auto !important;
    margin-bottom: 5vh !important;
  }
}
.box{
    box-sizing: border-box;
    padding: 5rem 3rem;
}

.titleWrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    margin: 2rem 0;
}


.title{
    font-size: 1.75rem;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    font-family: 'Montserrat';
}

.section{
    flex:1;
    margin-bottom: 1rem;
}

.heading{
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    color: #000;
    font-family: 'Montserrat';
}

.text{
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    color: #000;
    overflow-wrap: break-word;
    white-space: initial;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: 'Montserrat';
}

table{
    width: 100%;
    text-align: left;
    border-spacing: 0;
}



table thead{
    background-color: #808080;
}
table tbody{
    background-color: #fff;
}

th{
    color: #000;
    padding: 5px 0;
    font-size: 1rem;
    font-weight: 700;
    padding: 5px 10px;
    margin: 0;
    font-family: 'Montserrat';
}

tr td{
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    color: #000;
    padding: 5px 10px;
    font-family: 'Montserrat';
}

tbody tr td:nth-child(1){
    font-weight: 700;
}

.bg{
    border: 1px solid black;
}

.color{
    background-color: #808080;
    font-weight: 700;
}
.preferencesDecideInfoContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2vh;
}

.preferencesInfoLine {
  width: 100%;
  height: auto;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.preferencesModalText {
  width: 100%;
  height: auto;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  line-height: 1.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: justify;
}

.preferencesSubtitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #444;
  margin-top: 0;
  margin-bottom: 0;
}

.preferencesScheduleSeparator {
  margin-bottom: 2vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .preferencesModalText {
    font-size: 1.5vw;
    line-height: 3vw;
  }
  
  .preferencesSubtitle {
    font-size: 2vw;
  }
  
  .preferencesScheduleSeparator {
    margin-bottom: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .preferencesModalText {
    font-size: 3vw;
    line-height: 6.5vw;
  }
  
  .preferencesSubtitle {
    font-size: 3vw;
  }
  
  .preferencesScheduleSeparator {
    margin-bottom: 2vh;
  }
}
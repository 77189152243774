.accountShippingLoading {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 1.1vw;
  line-height: 2.2vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingListContainer {
  width: 100%;
  height: auto;
  border-top: 2px solid #18b7bb;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: -2vh;
}

.accountShippingLine {
  width: 100%;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid rgba(102,102,102,0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.accountShippingLineInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Montserrat';
  font-weight: 300;
  color: #666;
  font-size: 0.9vw;
  line-height: 1.5vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingLineRecipient {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 1vw;
  line-height: 1.7vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingExtra {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.accountShippingDefaultContainer {
  margin-right: 2vw;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102,102,102,1);
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingDefaultIcon {
  width: 15px;
  height: 15px;
  color: #FF7C1D;
  margin-right: 0.5vw;
}

.accountShippingMoreContainer {
  width: auto;
  height: auto;
  position: relative;
}

.accountShippingMoreIcon {
  width: 20px;
  height: 20px;
  color: #666;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.accountShippingMoreIcon:hover {
  filter: brightness(0);
}

.accountShippingMoreMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  min-width: 13vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.1);
  border-radius: 6px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.accountShippingMoreContainer:hover .accountShippingMoreMenu {
  opacity: 1;
  pointer-events: all;
}

.accountShippingMoreMenuLink {
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  cursor: pointer;
  margin-left: 1.5vw;
  transition: filter 0.3s ease-out;
}

.accountShippingMoreMenuLink:hover {
  filter: brightness(0);
}

.newAddressModalOuter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0,0,0,0.3);
}

.newAddressModal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 35vw;
  height: auto;
  min-height: 70vh;
  border-radius: 10px;
  padding-top: 6vh;
  padding-bottom: 6vh;
  padding-left: 3vw;
  padding-right: 0;
  background-color: #fff;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #e1e1e1;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  z-index: 250;
}

.newAddressModalCloseIcon {
  position: absolute;
  top: 2vh;
  right: 1.5vw;
  width: 25px;
  height: 25px;
  color: #333;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.newAddressModalCloseIcon:hover {
  opacity: 0.7;
}

.newAddressTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 1.1vw;
  margin-top: 0;
  margin-bottom: 2vh;
}

.newAddressButtonContainer {
  width: auto;
  height: auto;
  align-self: flex-end;
  margin-top: 2vh;
  margin-right: 3vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .accountShippingLoading {
    font-size: 1.6vw;
    line-height: 2.8vw;
  }
  
  .accountShippingLine {
    width: 100%;
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-bottom: 1px solid rgba(102,102,102,0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .accountShippingLineInfo {
    font-size: 1.5vw;
    line-height: 2.5vw;
  }
  
  .accountShippingLineRecipient {
    font-size: 1.8vw;
    line-height: 2.8vw;
  }
  
  .accountShippingDefaultContainer {
    margin-right: 3vw;
    font-size: 1.8vw;
    line-height: 2.9vw;
  }
  
  .accountShippingDefaultIcon {
    margin-right: 1vw;
  }
  
  .accountShippingMoreIcon:hover {
    filter: brightness(1);
  }
  
  .accountShippingMoreMenu {
    min-width: 20vw;
  }
  
  .accountShippingMoreMenuLink {
    font-size: 1.8vw;
    line-height: 2.9vw;
    margin-left: 1.5vw;
  }
  
  .accountShippingMoreMenuLink:hover {
    filter: brightness(1);
  }
  
  .newAddressModalOuter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: rgba(0,0,0,0.3);
  }
  
  .newAddressModal {
    width: 80vw;
    min-height: 60vh;
  }
  
  .newAddressModalCloseIcon {
    right: 2.5vw;
  }
  
  .newAddressModalCloseIcon:hover {
    opacity: 1;
  }
  
  .newAddressTitle {
    font-size: 2vw;
    margin-bottom: 2.5vh;
  }
  
  .newAddressButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
    margin-right: 3vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .accountShippingLoading {
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .accountShippingLineInfo {
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .accountShippingLineRecipient {
    font-size: 3.5vw;
    line-height: 6.5vw;
  }
  
  .accountShippingDefaultContainer {
    margin-right: 3vw;
    font-size: 3.5vw;
    line-height: 6.5vw;
  }
  
  .accountShippingDefaultIcon {
    margin-right: 2vw;
  }
  
  .accountShippingMoreMenu {
    min-width: 40vw;
  }
  
  .accountShippingMoreMenuLink {
    font-size: 3.5vw;
    line-height: 6.5vw;
    margin-left: 4vw;
  }
  
  .newAddressModal {
    width: 87vw;
    min-height: 60vh;
    padding-right: 3vw;
  }
  
  .newAddressModalCloseIcon {
    right: 3.5vw;
  }
  
  .newAddressModalCloseIcon:hover {
    opacity: 1;
  }
  
  .newAddressTitle {
    font-size: 4vw;
    margin-bottom: 2.5vh;
  }
  
  .newAddressButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
    margin-right: 3vw;
  }
}
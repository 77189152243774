.orderHeaderConnector {
  width: 2vw;
  height: 0;
  border-bottom: 0.5px solid #fff;
  opacity: 0.3;
  margin-left: 1vw;
  margin-right: 1vw;
}

@media (orientation: portrait) and (max-width: 767px) {
  .orderHeaderConnector {
    width: 2vw;
    height: 0;
    border-bottom: 0.5px solid #fff;
    opacity: 0.3;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }
}
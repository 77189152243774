@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.accountCreatedForm {
  width: 406px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
}

@media (max-width: 300px) {
  .accountCreatedForm {
    width: 300px;
  }
}

.accountCreatedFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.accountCreatedFormTitle {
  font-size: 24px;
  font-family: "Oswald", sans-serif;
  padding: 0px;
  margin: 0px;
}

.accountCreatedFormSpan {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #686f7b;
  width: 50%;
  align-self: center;
  padding-bottom: 20px;
}

.accountCreatedFormGotoAccount {
  padding: 10px 12px;
  background-color: #12abbf;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-size: 14px;
  height: 40px;
  font-family: "Inter", sans-serif;
  margin-bottom: 0px;
}

.accountCreatedSuceessIcon{
  align-self: center;
  margin-bottom: 20px;
}
.contactQRContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2vh;
}

.contactQRText {
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 0.9vw;
  line-height: 1.8vw;
}

.contactQRImg {
  height: auto;
  width: auto;
  object-fit: cover;
  margin-top: 2vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .contactQRContainer {
    margin-top: 1vh;
  }
  
  .contactQRText {
    font-size: 1.8vw;
    line-height: 3.6vw;
  }

  .contactQRImg {
    margin-top: 1vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .contactQRContainer {
    margin-top: 1vh;
  }
  
  .contactQRText {
    font-size: 4vw;
    line-height: 8vw;
  }

  .contactQRImg {
    margin-top: 1vh;
    margin-bottom: 5vh;
  }
}
.verifySeparator {
  height: 3vh;
}

.verifyAuthenticContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5vh;
}

.verifyBigCheckContainer {
  width: 3vw;
  height: 3vw;
  border-radius: 1.5vw;
  background-color: #18b7bb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2vw;
  margin-left: 3vw;
}

.verifyBigCheck {
  width: 1.5vw;
  height: 1.5vw;
  color: #fff;
}

.verifyCheckTitle {
  width: calc(100% - 8vw);
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  line-height: 1.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.verifyLinkContainer {
  width: calc(100% - 3vw);
  margin-left: 3vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.verifyLinkText {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  line-height: 1.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 4vh;
}

.verifyLinkMain {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  color: #555;
  margin-top: 0;
  margin-bottom: 4vh;
  transition: opacity 0.3s ease-out;
}

.verifyLinkMain:hover {
  opacity: 0.7;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .verifySeparator {
    height: 2vh;
  }

  .verifyAuthenticContainer {
    margin-bottom: 3vh;
  }
  
  .verifyBigCheckContainer {
    width: 4vw;
    height: 4vw;
    border-radius: 2vw;
    margin-right: 2vw;
    margin-left: 3vw;
  }
  
  .verifyBigCheck {
    width: 2vw;
    height: 2vw;
    color: #fff;
  }
  
  .verifyCheckTitle {
    width: calc(100% - 10vw);
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .verifyLinkText {
    font-size: 1.6vw;
    line-height: 3.2vw;
    margin-bottom: 3vh;
  }
  
  .verifyLinkMain {
    font-size: 1.9vw;
    line-height: 4vw;
    margin-bottom: 3vh;
  }
  
  .verifyLinkMain:hover {
    opacity: 1;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .verifySeparator {
    height: 1vh;
  }

  .verifyAuthenticContainer {
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  
  .verifyBigCheckContainer {
    width: 13vw;
    height: 13vw;
    border-radius: 6.5vw;
    margin-right: 5vw;
    margin-left: 3vw;
  }
  
  .verifyBigCheck {
    width: 6.5vw;
    height: 6.5vw;
    color: #fff;
  }
  
  .verifyCheckTitle {
    width: calc(100% - 19vw);
    font-size: 4.5vw;
    line-height: 8.5vw;
    text-align: right;
  }
  
  .verifyLinkText {
    font-size: 3.5vw;
    line-height: 8vw;
    margin-bottom: 4vh;
  }
  
  .verifyLinkMain {
    font-size: 4vw;
    line-height: 8vw;
    margin-bottom: 4vh;
  }
  
  .verifyLinkMain:hover {
    opacity: 1;
  }
}
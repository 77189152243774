@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animatedButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  border-width: 0.5px;
  border-style: solid;
  border-color: #FF7C1D;
  background-color: #FF7C1D;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: border-color 0.3s ease-out, border-width 1s ease-out;
}

.altAnimatedButton:disabled {
  opacity: 0.3;
}

.animatedButton:focus {
  outline: none;
}

.animatedButton:hover {
  border-color: #fff;
}

.animatedButton:hover div {
  top: 0;
}

.animatedButton:hover p {
  color: #FF7C1D;
}

.buttonBackground {
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: #fff;
  transition: all 0.3s ease-out;
}

.buttonText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.1vw;
  color: #fff;
  z-index: 1;
  margin: 0;
  transition: all 0.3s ease-out;
  text-transform: capitalize;
}

@media (orientation: portrait) and (max-width: 767px) {
  .animatedButton {
    margin: auto;
  }
}
.altAnimatedButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  border: 0.5px solid rgba(255,124,29,0.8);
  background-color: #FF7C1D;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.altAnimatedButton:disabled {
  opacity: 0.3;
}

.altAnimatedButton:focus {
  outline: none;
}

.altAnimatedButton:hover div {
  top: 0;
}

.altAnimatedButton:hover p {
  color: #FF7C1D;
}

.altButtonBackground {
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: #fff;
  transition: all 0.3s ease-out;
}

.altButtonText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.1vw;
  color: #fff;
  z-index: 1;
  margin: 0;
  transition: all 0.3s ease-out;
  text-transform: capitalize;
}

@media (orientation: portrait) and (max-width: 767px) {
  .altAnimatedButton {
    margin: auto;
  }
}
.navbarContainer {
  margin-top: 3.7vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
}

.logo {
  height: 6vh;
  width: auto;
  object-fit: cover;
  cursor: pointer;
}

.newIcon{
  width: 25px;
  height:25px;
  margin-top: 1vw;
  margin-right: 5px;
}

.navbarMain {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbarButtons {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navbarLink {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2vw;
  position: relative;
  cursor: pointer;
}

.navbarLinkText {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #fff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.3vw;
  cursor: pointer;
}

.navbarOrderButtons {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4vw;
}

.navbarLinkSubMenuOuter {
  cursor: default;
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  transition: all 0.5s ease-out;
  opacity: 0;
  pointer-events: none;
}

.navbarLink:hover .navbarLinkSubMenuOuter {
  opacity: 1;
  pointer-events: all;
}

.navbarLinkSubMenu {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  padding-top: 4vh;
  padding-bottom: 4vh;
  padding-right: 2vw;
  padding-left: 2vw;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  background-color: #fff;
  border-radius: 10px;
}

.navbarSubMenuBlock {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.navbarSubMenuBlock:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.navbarSubMenuBlockImg {
  width: 2.8vw;
  height: auto;
  object-fit: cover;
}

.navbarSubMenuBlockInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2vw;
}

.navbarSubMenuBlockTitle {
  font-family: 'Montserrat';
  font-size: 1.2vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1vh;
  width: 25vw;
}

.navbarSubMenuBlockText {
  font-family: 'Montserrat';
  font-size: 0.85vw;
  line-height: 1.7vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.navbarSubMenuMiniBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5vh;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.navbarSubMenuMiniBlock:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.navbarSubMenuMiniBlockImg {
  width: 2vw;
  height: auto;
  object-fit: cover;
}

.navbarSubMenuMiniBlockInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.5vw;
}

.navbarSubMenuMiniBlockTitle {
  font-family: 'Montserrat';
  font-size: 1.2vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2vw;
}

.navbarSubMenuMiniBlockText {
  font-family: 'Montserrat';
  font-size: 0.85vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.navbarSubMenuLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5vw;
  width: 100%;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.navbarSubMenuLinkContainer:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.navbarSubMenuLink {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
  transition: margin-right 0.3s ease-out;
}

.navbarSubMenuLinkContainer:hover .navbarSubMenuLink {
  margin-right: 1vw;
}

.simpleSubMenu {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  padding-right: 1.5vw;
  padding-left: 1.5vw;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  background-color: #fff;
  border-radius: 10px;
}

.simpleSubMenuLink {
  margin-top: 1.3vh;
  margin-bottom: 1.3vh;
  width: 12vw;
}

.navbarLinkOrder {
  margin-right: 2vw;
}

.navbarMobile {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .navbarContainer {
    margin-top: 2.7vh;
    margin-bottom: 1vh;
  }
  
  .logo {
    height: 40px;
    width: auto;
  }
  
  .navbarLink {
    margin-left: 3vw;
  }

  .navbarLinkButton {
    display: none;
  }
  
  .navbarLinkText {
    font-size: 1.4vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.8vw;
  }
  
  .navbarOrderButtons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 4vw;
  }
  
  .navbarLinkOrder {
    margin-right: 2vw;
  }

  .navbarLinkSubMenu {
    margin-top: 1vh;
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 5vw;
    padding-left: 5vw;
    border: 1px solid #e1e1e1;
  }
  
  .navbarSubMenuBlock {
    margin-bottom: 2.5vh;
  }
  
  .navbarSubMenuBlockImg {
    width: 6vw;
  }
  
  .navbarSubMenuBlockInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3vw;
  }
  
  .navbarSubMenuBlockTitle {
    font-size: 2.4vw;
    width: 43vw;
  }
  
  .navbarSubMenuBlockText {
    font-size: 1.5vw;
    line-height: 3vw;
  }
  
  .navbarSubMenuMiniBlock {
    margin-bottom: 1.2vh;
    margin-top: 1.2vh;
  }
  
  .navbarSubMenuMiniBlockImg {
    width: 4vw;
  }
  
  .navbarSubMenuMiniBlockInfo {
    margin-left: 2.5vw;
  }
  
  .navbarSubMenuMiniBlockTitle {
    font-size: 2vw;
    margin-right: 2.5vw;
  }
  
  .navbarSubMenuMiniBlockText {
    font-size: 1.5vw;
  }
  
  .navbarSubMenuLinkContainer {
    margin-top: 3vh;
  }
  
  .navbarSubMenuLink {
    font-size: 2.1vw;
    margin-bottom: 0.4vh;
    margin-right: 2vw;
  }
  
  .simpleSubMenu {
    margin-top: 1vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-right: 3vw;
    padding-left: 3vw;
    border: 1px solid #e1e1e1;
  }
  
  .simpleSubMenuLink {
    margin-top: 1.1vh;
    margin-bottom: 1.1vh;
    width: 22vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .navbarMain {
    display: none;
  }

  .navbarContainer {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
  }

  .logo {
    height: 50px;
  }

  .navbarMobile {
    display: flex;
  }

  .mobileMenuButton {
    color: #18b7bb;
  }

  .mobileMenuOuter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    z-index: 200;
    background-color: rgba(0,0,0,0.1);
  }

  .mobileMenuMid {
    position: absolute;
    width: 90vw;
    top: 3vh;
    left: 5vw;
    height: auto;
  }

  .mobileMenu {
    position: relative;
    width: 90vw;
    top: 0;
    left: 0;
    margin-bottom: 20vh;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
    z-index: 201;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 3vh;
  }

  .mobileMenuControls {
    margin-top: 2vh;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .mobileMenuTitle {
    font-family: 'Montserrat';
    font-size: 4vw;
    color: #666;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobileMenuOrderButtons {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    margin-top: 4vh;
  }

  .navbarSubMenuBlock {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 4vh;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }

  .navbarSubMenuBlock:hover {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
  }

  .navbarSubMenuBlockImg {
    margin-top: 1vh;
    width: auto;
    height: 5vh;
    object-fit: cover;
  }

  .navbarSubMenuBlockInfo {
    margin-left: 5vw;
  }

  .navbarSubMenuBlockTitle {
    font-size: 5vw;
    width: auto;
  }

  .navbarSubMenuBlockText {
    font-size: 3.5vw;
    line-height: 7vw;
    width: 100%;
  }

  .mobileMenuMiniBlocks {
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
  }

  .navbarSubMenuMiniBlock {
    margin-bottom: 1.5vh;
    margin-top: 1.5vh;
    width: 85%;
  }

  .navbarSubMenuMiniBlockImg {
    width: auto;
    height: 4vh;
    object-fit: cover;
  }

  .navbarSubMenuMiniBlockInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5vw;
  }

  .navbarSubMenuMiniBlockTitle {
    font-family: 'Montserrat';
    font-size: 5vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .navbarSubMenuMiniBlockText {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    line-height: 7vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobileMenuLinksLine {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
  }

  .mobileMenuLink {
    width: 50%;
    font-family: 'Montserrat';
    font-size: 4.5vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .navbarLink {
    margin-left: 3vw;
  }

  .navbarLinkButton {
    display: none;
  }
  
  .navbarOrderButtons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5vw;
  }
  
  .navbarLinkOrder {
    margin-right: 2vw;
  }
}
.servicesDisplayContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.servicesDisplayHeadingContainer {
  width: 45%;
  height: 33vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.servicesDisplayHeading {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
}

.servicesDisplaySubheading {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  width: 85%;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.servicesDisplayTitlesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 7vh;
}

.servicesDisplayTitle {
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 1.4vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1vh;
}

.servicesDisplayTitleLine {
  height: 7vh;
  width: 100%;
  border-bottom: 0.5px solid #c1c1c1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.servicesDisplayTitleInfoContainer {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.servicesDisplayHelpIcon {
  position: relative;
  overflow: visible;
}

.servicesDisplayHelpTooltip {
  position: absolute;
  top: 50%;
  left: 100%;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  height: auto;
  width: auto;
  min-width: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 1.5vw 1.5vh 1.5vw;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #444;
  font-size: 0.85vw;
  line-height: 1.8vw;
  opacity: 0;
  z-index: 100 !important;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.servicesDisplayHelpIcon:hover  .servicesDisplayHelpTooltip{
  opacity: 1;
  pointer-events: all;
}

.servicesDisplayTitleInfoText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
}

.servicesDisplayTitlesOffset {
  width: 100%;
  height: 25vh;
}

.servicesDisplayColumnsContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.servicesDisplayColumn {
  width: 20vw;
  height: 100%;
  border-radius: 20px;
  margin-left: 3vw;
  background-color: rgba(24, 183, 187, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.servicesDisplayColumnTitleContainer {
  width: 100%;
  height: 33vh;
  border-radius: 20px;
  background-color: #18b7bb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.servicesDisplayColumnTitle {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  color: #fff;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.servicesDisplayColumnSub {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #fff;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4vh;
  text-align: center;
}

.servicesDisplayColumnPrice {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  color: #fff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.servicesDisplayValue {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.servicesDisplayValueText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.servicesDisplayButtonsContainer {
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.servicesDisplayButtonsMore {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 700;
  margin-top: 3vh;
  margin-bottom: 0;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-out; 
}

.servicesDisplayButtonsMore:hover {
  opacity: 0.7;
}

.mobileServicesShortDescription {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .servicesDisplayContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
  
  .servicesDisplayHeadingContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .servicesDisplayHeading {
    font-size: 3.7vw;
  }
  
  .servicesDisplaySubheading {
    font-size: 2vw;
    line-height: 4.3vw;
    margin-bottom: 3vh;
  }
  
  .servicesDisplayTitlesContainer {
    display: none;
  }
  
  .servicesDisplayTitlesOffset {
    display: none;
  }
  
  .servicesDisplayColumnsContainer {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .servicesDisplayColumn {
    width: 48%;
    margin-left: 0;
  }
  
  .servicesDisplayColumnTitleContainer {
    width: 100%;
    height: 25vh;
    border-radius: 20px;
    background-color: #18b7bb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .servicesDisplayColumnTitle {
    font-family: 'Montserrat';
    font-size: 2.8vw;
    color: #fff;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1.5vh;
  }
  
  .servicesDisplayColumnSub {
    font-size: 1.8vw;
    line-height: 3.6vw;
    margin-bottom: 4vh;
  }
  
  .servicesDisplayColumnPrice {
    font-size: 2.3vw;
  }
  
  .servicesDisplayButtonsContainer {
    width: 100%;
    height: 15vh;
  }
  
  .servicesDisplayButtonsMore {
    font-size: 2vw;
    margin-top: 3vh;
  }
  
  .servicesDisplayButtonsMore:hover {
    opacity: 1;
  }

  .mobileServicesShortDescription {
    display: flex;
    width: 85%;
    height: 20vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 2vw;
    line-height: 4vw;
    text-align: center;
    color: #666;
    font-weight: 400;
    margin-top: 4vh;
    margin-bottom: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .servicesDisplayContainer {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
  
  .servicesDisplayHeading {
    font-size: 6.5vw;
  }
  
  .servicesDisplaySubheading {
    font-size: 4vw;
    line-height: 9.3vw;
    margin-bottom: 3vh;
  }
  
  .servicesDisplayTitlesContainer {
    display: none;
  }
  
  .servicesDisplayTitlesOffset {
    display: none;
  }
  
  .servicesDisplayColumnsContainer {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .servicesDisplayColumn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 5vh;
  }
  
  .servicesDisplayColumnTitleContainer {
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #18b7bb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .servicesDisplayColumnTitle {
    font-size: 5vw;
    margin-top: 3vh;
    margin-bottom: 1.5vh;
  }
  
  .servicesDisplayColumnSub {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }
  
  .servicesDisplayColumnPrice {
    font-size: 4.5vw;
    margin-bottom: 3vh;
  }
  
  .servicesDisplayButtonsContainer {
    width: 100%;
    height: auto;
  }
  
  .servicesDisplayButtonsMore {
    font-size: 4vw;
    margin-top: 3vh;
    margin-bottom: 4vh;
  }
  
  .servicesDisplayButtonsMore:hover {
    opacity: 1;
  }

  .mobileServicesShortDescription {
    display: flex;
    width: 85%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 3.8vw;
    line-height: 7vw;
    text-align: center;
    color: #666;
    font-weight: 400;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
}
.useContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.useCaseBlock {
  width: 15vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 2vw;
}

.useCaseImgContainer {
  width: 100%;
  height: 43vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  background-color: rgba(24, 183, 187, 0.05);
  border: 1px solid #e1e1e1;
  transition: all 0.3s ease-out;
}

.useCaseImgContainer:hover {
  background-color: rgba(24, 183, 187, 0.4);
  border: 1px solid #c1c1c1;
}

.useCaseImg {
  height: auto;
  width: 80%;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

.useCaseImgContainer:hover .useCaseImg {
  transform: scale(1.2);
}

.useCaseBlockText {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 0;
}

.useCaseInfoContainer {
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2vw;
}

.useCaseInfoTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
}

.useCaseInfoSub {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.useCaseInfoButtonContainer {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.useCaseInfoButtonText {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.3vh;
  margin-right: 0.7vw;
  cursor: pointer;
  transition: margin-right 0.3s ease-out;
}

.useCaseInfoButtonText:hover {
  margin-right: 1.2vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .nonMobileBlock {
    display: none;
  }

  .useContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .useCaseBlock {
    width: 25%;
    margin-right: 2vw;
  }
  
  .useCaseImgContainer {
    width: 100%;
    height: 25vh;
  }
  
  .useCaseImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #e1e1e1;
  }
  
  .useCaseBlockText {
    font-size: 1.8vw;
  }
  
  .useCaseInfoContainer {
    height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2vw;
  }
  
  .useCaseInfoTitle {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1vh;
  }
  
  .useCaseInfoSub {
    width: 100%;
    font-family: 'Montserrat';
    font-size: 2vw;
    line-height: 4vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .useCaseInfoButtonContainer {
    margin-top: 3vh;
  }
  
  .useCaseInfoButtonText {
    font-size: 2.5vw;
    margin-bottom: 0.7vh;
  }
  
  .useCaseInfoButtonText:hover {
    margin-right: 1.8vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .nonMobileBlock {
    display: none !important;
  }
  
  .useContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    display: inline-block;
  }
  
  .useCaseBlock {
    display: inline-block;
    width: 45%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-bottom: 4vh;
  }
  
  .useCaseImgContainer {
    width: 100%;
    height: 30vh;
  }
  
  .useCaseImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #e1e1e1;
  }
  
  .useCaseBlockText {
    font-size: 3vw;
  }
  
  .useCaseInfoContainer {
    height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2vw;
  }
  
  .useCaseInfoTitle {
    font-family: 'Montserrat';
    font-size: 6.5vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .useCaseInfoSub {
    font-size: 4vw;
    line-height: 9.3vw;
  }
  
  .useCaseInfoButtonContainer {
    margin-top: 4vh;
  }
  
  .useCaseInfoButtonText {
    font-size: 4.5vw;
    margin-bottom: 0.4vh;
    margin-right: 1.5vw;
  }
  
  .useCaseInfoButtonText:hover {
    margin-right: 4vw;
  }
}
.documentsDisplayContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.documentsDisplayInfo {
  height: 46.87vw;
  width: 46.87vw;
  margin-left: -3vw;
  background-image: url(/static/media/balloonLeft.e0144f71.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.documentsDisplayInfoTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #fff;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
  width: 75%;
  margin-left: 5%;
}

.documentsDisplayInfoText {
  width: 75%;
  margin-left: 5%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #f5f5f5;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.documentsDisplayInfoButtonContainer {
  width: 75%;
  margin-left: 5%;
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.documentsDisplayInfoButtonText {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  color: #fff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.3vh;
  margin-right: 0.7vw;
  cursor: pointer;
  transition: margin-right 0.3s ease-out;
}

.documentsDisplayInfoButtonText:hover {
  margin-right: 1.2vw;
}

.documentsDisplayListContainer {
  width: 40%;
  height: auto;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsDisplayListTitle {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 7vh;
  align-self: flex-start
}

.documentsDisplayListInner {
  width: auto;
  height: auto;
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsDisplayListColumn {
  width: 20vw;
  height: auto;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsDisplaySecondColumn {
  width: auto;
}

.documentsDisplayListItem {
  width: 100%;
  height: auto;
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5.5vh;
}

.documentsDisplayListItemText {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1vw;
  /* cursor: pointer; */
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.documentsDisplayListItemText:hover {
  /* opacity: 0.6; */
  opacity: 1;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .documentsDisplayContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    align-items: center;
  }
  
  .documentsDisplayInfo {
    height: 52vw;
    width: 52vw;
    margin-left: -5vw;
    margin-bottom: 5vh;
  }
  
  .documentsDisplayInfoTitle {
    margin-top: 2vh;
    margin-bottom: 1vh;
    font-size: 3vw;
    width: 80%;
    margin-left: 8%;
  }
  
  .documentsDisplayInfoText {
    width: 80%;
    margin-left: 8%;
    font-size: 2vw;
    line-height: 4vw;
  }
  
  .documentsDisplayInfoButtonContainer {
    width: 80%;
    margin-left: 8%;
    margin-top: 3vh;
  }
  
  .documentsDisplayInfoButtonText {
    font-size: 2vw;
    margin-right: 1vw;
  }
  
  .documentsDisplayInfoButtonText:hover {
    margin-right: 1.8vw;
  }
  
  .documentsDisplayListContainer {
    width: 46%;
  }
  
  .documentsDisplayListTitle {
    font-size: 3vw;
    margin-bottom: 4vh;
  }
  
  .documentsDisplayListInner {
    width: auto;
    height: auto;
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListColumn {
    width: 100%;
    height: auto;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListItem {
    margin-bottom: 3vh;
  }
  
  .documentsDisplayListItemText {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    color: #666;
    font-weight: 400;
    margin-left: 1.5vw;
  }
  
  .documentsDisplayListItemText:hover {
    opacity: 0.6;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .documentsDisplayContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .documentsDisplayInfo {
    height: 112vw;
    width: 112vw;
    margin-left: -4vw;
    margin-bottom: 5vh;
  }
  
  .documentsDisplayInfoTitle {
    font-size: 6.2vw;
    width: 80%;
    margin-left: 5%;
  }
  
  .documentsDisplayInfoText {
    width: 80%;
    margin-left: 5%;
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .documentsDisplayInfoButtonContainer {
    width: 80%;
    margin-left: 5%;
    margin-top: 3vh;
  }
  
  .documentsDisplayInfoButtonText {
    font-size: 4vw;
    margin-right: 1vw;
  }
  
  .documentsDisplayInfoButtonText:hover {
    margin-right: 1.8vw;
  }
  
  .documentsDisplayListContainer {
    width: 90%;
    margin-left: 5%;
  }
  
  .documentsDisplayListTitle {
    font-size: 5.8vw;
    margin-bottom: 5vh;
  }
  
  .documentsDisplayListInner {
    width: auto;
    height: auto;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListColumn {
    width: 100%;
    height: auto;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsDisplayListItem {
    margin-bottom: 4vh;
  }
  
  .documentsDisplayListItemText {
    font-family: 'Montserrat';
    font-size: 4.5vw;
    color: #666;
    font-weight: 400;
    margin-left: 3vw;
  }
  
  .documentsDisplayListItemText:hover {
    opacity: 0.6;
  }
}
.languagesDisplayContainer {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.languagesDisplayInfo {
  height: 46.87vw;
  width: 46.87vw;
  margin-right: -3vw;
  background-image: url(/static/media/balloonRight.e3122efb.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.languagesDisplayListContainer {
  margin-left: 4.3vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .languagesDisplayContainer {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  
  .languagesDisplayInfo {
    height: 52vw;
    width: 52vw;
    margin-right: -5vw;
    margin-bottom: 5vh;
    padding: 15px;
  }
  
  .languagesDisplayListContainer {
    margin-left: 0;
  }

  .mobileTableColumnDocumentsDisplay {
    margin-right: 5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .languagesDisplayContainer {
    margin-top: 4vh;
    margin-bottom: 4vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .languagesDisplayInfo {
    height: 112vw;
    width: 112vw;
    margin-right: -4vw;
    margin-bottom: 5vh;
  }
  
  .languagesDisplayListContainer {
    margin-left: 4.3vw;
  }
}
.footerContainer {
  margin-top: 12vh;
  height: auto;
  width: 100vw;
  background-image: url(/static/media/footerBg.6f310471.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20vh;
  padding-bottom: 2vh;
}

.footerReadyContainer {
  width: 82%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footerReadyText {
  font-family: 'Montserrat';
  font-weight: 900;
  font-size: 1.8vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.footerSeparator {
  width: 82%;
  height: 0;
  margin-top: 8vh;
  margin-bottom: 8vh;
  border: 0.3px solid #f5f5f5;
}

.footerMainContainer {
  width: 82%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8vh;
}

.footerMainSecondary {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.footerMainSecondaryLogo {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.footerSecondaryText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 0;
}

.footerMainListContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.footerMainListColumn {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 8vw;
}

.footerMainListColumnTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.footerMainListColumnLink {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.95vw;
  color: #f5f5f5;
  margin-top: 4vh;
  margin-bottom: 0;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}

.footerMainListColumnLink:hover {
  opacity: 0.5;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .footerContainer {
    margin-top: 4vh;
    height: auto;
    width: 100vw;
    padding-top: 12vh;
    padding-bottom: 2vh;
  }

  .footerReadyContainer {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .footerReadyText {
    font-size: 3vw;
  }
  
  .footerSeparator {
    width: 90%;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  
  .footerMainContainer {
    width: 90%;
    margin-bottom: 4vh;
  }

  .footerMainSecondaryLogo {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  
  .footerSecondaryText {
    font-size: 1.3vw;
    margin-top: 1vh;
  }
  
  .footerMainListColumn {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5vw;
  }
  
  .footerMainListColumnTitle {
    font-size: 2vw;
  }
  
  .footerMainListColumnLink {
    font-size: 1.7vw;
    margin-top: 1.5vh;
  }
  
  .footerMainListColumnLink:hover {
    opacity: 0.5;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .footerContainer {
    margin-top: 6vh;
    height: auto;
    width: 100vw;
    padding-top: 20vh;
    padding-bottom: 2vh;
  }
  
  .footerReadyContainer {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .footerReadyText {
    font-size: 6vw;
    line-height: 9vw;
    text-align: center;
    margin-bottom: 3vh;
  }
  
  .footerSeparator {
    margin-top: 5.5vh;
    margin-bottom: 5.5vh;
  }

  .nonMobileColumn {
    display: none !important;
  }
  
  .footerMainContainer {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
  }
  
  .footerMainSecondary {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5vh;
  }
  
  .footerMainSecondaryLogo {
    display: none;
  }
  
  .footerSecondaryText {
    font-size: 3.5vw;
    margin-top: 2vh;
  }
  
  .footerMainListContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .footerMainListColumn {
    margin-left: 0;
  }
  
  .footerMainListColumnTitle {
    font-size: 4.5vw;
  }
  
  .footerMainListColumnLink {
    font-size: 3.6vw;
    color: #f5f5f5;
    margin-top: 3vh;
    opacity: 1;
  }
  
  .footerMainListColumnLink:hover {
    opacity: 0.8;
  }
}
.homeContainer {
  width: 79.5vw;
  height: auto;
  padding-left: 9vw;
  padding-right: 9vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.home-Container {
  /* width: 79.5vw; */
  height: auto;
  padding-left: 9vw;
  padding-right: 9vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.homeHeaderBg {
  position: absolute;
  top: 0;
  right: 0;
  height: 93.5vh;
  width: 80vw;
  z-index: 0;
  pointer-events: none;
}

.headerContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 13vh;
}

.headerInfoContainer {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.heading {
  font-family: 'Montserrat';
  font-size: 2.6vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.subheading {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 1.2vw;
  line-height: 2.4vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 2vh;
}

.subheadingFootnote {
  align-self: flex-end;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 5vh;
}

.headerImg {
  width: 45%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.extraInfoContainer {
  margin-top: 10vh;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.extraInfoTextContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2vh;
  cursor: pointer;
}

.extraInfoText {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1vw;
  transition: margin-right 0.3s ease-out;
}

.extraInfoTextContainer:hover .extraInfoText {
  margin-right: 1.5vw;
}

.extraInfoButton {
  width: 20px;
  height: 20px;
  color: #666;
}

.extraInfoUnderline {
  width: 10vw;
  height: 0;
  border-bottom: 3px solid #18b7bb;
  border-radius: 100px;
}

.certificationContainer {
  margin-top: 15vh;
  margin-bottom: 10vh;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  align-self: flex-start;
}

.certificationItemContainer {
  width: auto;
  max-width: 14vw;
  height: auto;
  margin-right: 7vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.certificationLogo {
  width: auto;
  height: 4.5vh;
  object-fit: cover;
  margin-bottom: 2vh;
}

.certificationText {
  width: 100%;
  height: auto;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .homeContainer {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .homeHeaderBg {
    height: 40.5vh;
    width: 88vw;
    display: none;
  }

  .headerInfoContainer {
    width: 52%;
  }

  .headerImg {
    width: 45%;
    height: auto;
    margin-right: 0;
    object-fit: cover;
    z-index: 10;
    align-self: flex-start;
  }

  .headerContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 7vh;
  }

  .heading {
    font-family: 'Montserrat';
    font-size: 4vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1vh;
  }

  .subheading {
    width: 95%;
    font-size: 1.8vw;
    line-height: 4vw;
    margin-bottom: 1.5vh;
  }

  .subheadingFootnote {
    font-size: 1.4vw;
    margin-bottom: 4vh;
    margin-right: 5%;
  }

  .extraInfoContainer {
    margin-top: 4vh;
  }

  .extraInfoTextContainer {
    margin-bottom: 0.8vh;
  }

  .extraInfoText {
    font-size: 1.4vw;
  }

  .extraInfoUnderline {
    width: 8vw;
    border: 1px solid #18b7bb;
  }

  .certificationContainer {
    margin-top: 7vh;
    margin-bottom: 7vh;
  }

  .certificationItemContainer {
    max-width: 18vw;
    margin-right: 5vw;
  }

  .certificationLogo {
    height: 2.8vh;
    margin-bottom: 1.5vh;
  }

  .certificationText {
    font-size: 1.3vw;
    line-height: 2.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .homeHeaderBg {
    display: none;
  }

  .headerInfoContainer {
    width: 100%;
  }

  .headerImg {
    display: none;
  }

  .headerContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 7vh;
  }

  .heading {
    font-size: 7.5vw;
    margin-bottom: 2vh;
  }

  .subheading {
    font-size: 4.7vw;
    line-height: 10vw;
    margin-bottom: 4vh;
  }

  .subheadingFootnote {
    font-size: 3.5vw;
    margin-bottom: 7vh;
  }

  .extraInfoContainer {
    margin-top: 7vh;
  }

  .extraInfoTextContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1vh;
  }

  .extraInfoText {
    font-size: 4vw;
    line-height: 6vw;
    margin-right: 3vw;
  }

  .extraInfoButton {
    width: 30px;
    height: 30px;
  }

  .extraInfoUnderline {
    width: 20vw;
    border: 1px solid #18b7bb;
  }

  .certificationContainer {
    display: none;
  }
}
.topBar{
    position: relative;
    width: 100%;
    background-color: #D83D1E;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    color: #fff;
  }
  
  .topBar img{
    width: 25px;
    height: 25px;
    margin-left: 20px;
  }

  .topBar p{
    font-family: 'Montserrat';
    font-size: 0.85rem;
    font-weight: 700;
    padding: 0 10px 0 20px;
  }

  .topBar .closeBtn{
    cursor: pointer;
    margin-right: 20px;
  }
.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 25px;
  border-radius: 8px;
}

.forgotPasswordFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
}

.forgotPasswordFormHeaderTitle {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  color: #030F23;
}

.forgotPasswordFormText {
  color: #686F7B;
  font-size: 14px;
  padding-bottom: 20px;
  font-family: "Inter", sans-serif;
}

.forgotPassworFormContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.forgotPasswordFormInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  color: #030F23;
}

.forgotPassworFormPlaceholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  font-size: 13px;
}

.forgotPasswordFormInput:focus {
  padding-top: 17px;
  padding-bottom: 3px;
  border: 1px solid #030F23;
}

.forgotPasswordFormInput:focus + .forgotPassworFormPlaceholder {
  transform: translateY(-12px);
  font-size: 9px;
}

.forgotPasswordFormInput:not(:focus):not(:placeholder-shown) {
  padding-top: 17px;
  padding-bottom: 3px;
}

.forgotPasswordFormPlaceholderNotEmpty {
  margin-bottom: 1vh;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(-12px);
}

.forgotPasswordFormInputNotEmpty {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  padding-top: 17px;
  padding-bottom: 3px;
}

.signInFormIcon {
  color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.errorInput {
  border: 1px solid rgb(208, 1, 1);
}

.errorInput:focus{
  border: 1px solid rgb(208, 1, 1);
}

.forgotPasswordFormButton {
  margin-top: 8px;
  padding: 10px 16px 10px 16px;
  background-color: #12abbf;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotPasswordFormButton:hover, .forgotPasswordFormButton:focus {
  background: #4ec1d0;
}
.forgotPasswordFormButton:disabled {
  background: #b3e4eb;
}

.forgotPasswordFormBackToSignIn {
  margin-top: 15px;
  text-decoration: none;
  color: #12abbf;
  align-self: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.forgotPasswordFormBackToSignIn:hover, .forgotPasswordFormBackToSignIn:focus{
  color: #4ec1d0;
}

.forgotPasswordFormBackToSignIn:disabled{
  color: #D0EEF2;
}

.forgotPasswordFormError {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: rgb(228, 0, 0);
}

.btnLoader{
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  -webkit-animation: rotation infinite 3s linear;
          animation: rotation infinite 3s linear;
}

@media (max-width: 450px) {
  .forgotPasswordForm {
    width: 300px;
  }
}

.sendVarificationCodeForm{
    display: flex;
    flex-direction: column;
    width:406px;
    background: white;
    padding: 25px;
    border-radius: 8px;
    box-sizing: border-box;
}

.sendVarificationCodeFormHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 19px;
}

.sendVarificationCodeFormHeaderTitle{
    font-family: "Oswald", sans-serif;
    font-size: 24px;
}

.sendVarificationCodeFormHeaderClose{
    color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.sendVarificationCodeFormText{
    color: #686F7B;
    font-family: "Inter", sans-serif;
    font-size: 14px;
}

.sendVarificationCodeFormEmail{
    margin-bottom: 12px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
}

.sendVarificationCodeFormCodes{
    display: flex;
    width:100%;
    justify-content: space-between;
}

.sendVarificationCodeFormValidation{
    color:#D70015;
    margin-top: 5px;
    font-size:13px;
    line-height: 16.9px;
    font-family: "Inter", sans-serif;
    margin-bottom: 5px;
}

.sendVarificationCodeFormNumber{
    width: 14px;
    padding: 25px 33px;
    border-radius: 7px;
    text-decoration: none;
    outline: none;
    border: 1px solid rgb(210, 210, 210);
    color: #686F7B;
    font-size: 17px;
    font-family: "Inter", sans-serif;
    text-align: center;
    line-height: 1;
    caret-color:white;
    cursor: default;
}

.sendVarificationCodeFormNumber:focus{
    border:1px solid #030F23;
}

.sendVarificationCodeFormNumberError{
    border: 1px solid #D70015;
}
.sendVarificationCodeFormNumberError:focus{
    border: 1px solid #D70015;
}

.sendVarificationCodeFormTimerTime{
    font-family: "Inter", sans-serif;
}

.sendVarificationCodeFormNumber::-webkit-outer-spin-button,
.sendVarificationCodeFormNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sendVarificationCodeFormNumber[type="number"] {
    -moz-appearance: textfield;
}

.sendVarificationCodeFormTimer{
    width:38%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 15px;
}

.sendVarificationCodeFormTimerText{
    font-size: 12px;
    color:#686F7B;
    font-family: "Inter", sans-serif;
}

.sendVarificationCodeAgainAvalible{
    color:#12ABBF;
    cursor:pointer;
    font-size: 12px;
    font-family: "Inter", sans-serif;
}

.sendVarificationCodeFormTimerTime{
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 15.6px;
    color: #030F23;
}

.sendVarificationCodeFormButton{
    padding: 10px 12px;
    background-color: #12abbf;
    border-radius: 6px;
    font-family: "Inter", sans-serif;
    border: none;
    color:white;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sendVarificationCodeFormButton:hover, 
.sendVarificationCodeFormButton:focus{
    background-color: #4ec1d0;
}
.sendVarificationCodeFormButton:disabled{
    background-color: #b3e4eb;
}

.sendVarificationCodeFormSignIn{
    margin-top: 15px;
  text-decoration: none;
  color: #12abbf;
  align-self: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  cursor: pointer;
}
.sendVarificationCodeFormSignIn:hover,
.sendVarificationCodeFormSignIn:focus{
    color: #4ec1d0;
}

.sendVarificationCodeFormSignIn:disabled{
    color: #b3e4eb;
}

.btnLoader{
    width: 20px;
    height: 20px;
    color: white;
    margin-left: 5px;
    -webkit-animation: rotation infinite 3s linear;
            animation: rotation infinite 3s linear;
  }
  
  @-webkit-keyframes rotation{
    from{
      transform:rotate(0deg);
    }
    
    to{
      transform:rotate(360deg);
    }
  }
  
  @keyframes rotation{
    from{
      transform:rotate(0deg);
    }
    
    to{
      transform:rotate(360deg);
    }
  }

@media (max-width: 450px) {
    .sendVarificationCodeForm {
      width: 300px;
    }

    .sendVarificationCodeFormNumber{
        padding: 22px;
    }
  }
.resetPasswordForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 25px;
  border-radius: 8px;
}

.resetPasswordFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
}

.resetPasswordFormTitle{
  font-family: "Oswald", sans-serif;
  font-size: 24px;
}

.resetPasswordFormIcon{
  color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.resetPasswordInputContainer{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.resetPasswordFormInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  color: #030F23;
}

.resetPasswordInputPlaceholder{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  font-size: 13px;
}

.resetPasswordFormInput:focus {
  padding-top: 17px;
  padding-bottom: 3px;
  border:1px solid #030F23;
}

.resetPasswordFormInput:focus + .resetPasswordInputPlaceholder{
  transform: translateY(-12px);
  font-size: 9px;
}

.resetPasswordFormInputNotEmpty {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  padding-top: 17px;
  padding-bottom: 3px;
}

.resetPlaceholderNotInFocus {
  margin-bottom: 1vh;
  font-family: "Inter", sans-serif;
  font-weight: 406;
  font-size: 9px;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(-12px);
}

.errorInput {
  border: 1px solid #D70015;
}

.errorInput:focus{
  border:1px solid #D70015;
}

.resetshowPasswordEye {
  position: absolute;
  font-family: "Inter", sans-serif;
  transform: translate(372px, 16px);
  z-index: 10001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #686F7B;
}

.focusedPasswordEye {
  stroke: #030F23;
}

.resetPasswordFormValidation {
  font-size: 14px;
  margin-left: 10px;
  color: #D70015;
  margin-bottom: 5px;
}

.successValidation {
  color: #0FAF62;
}

.resetPasswordFormButton {
  padding: 10px 16px 10px 16px;
  font-family: "Inter", sans-serif;
  background-color: #12ABBF;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetPasswordFormButton:hover,
.resetPasswordFormButton:focus {
  background-color: #4ec1d0;
}

.resetPasswordFormButton:disabled{
  background: #b3e4eb;
  cursor:text;
}

.resetPasswordFormLogIn {
  margin-top: 10px;
  text-decoration: none;
  color: #12ABBF;
  align-self: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.resetPasswordFormLogIn:hover,
.resetPasswordFormLogIn:focus {
  color: #4ec1d0;
}

.resetPasswordFormLogIn:disabled{
  color: #b3e4eb;
}

.btnLoader{
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  -webkit-animation: rotation infinite 3s linear;
          animation: rotation infinite 3s linear;
}

@-webkit-keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

@keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

@media (max-width: 450px) {
  .resetPasswordForm{
    width: 300px;
  }

  .showPasswordEye {
    transform: translate(267px, 15px);
  }
}

.passwordChangedForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 25px;
  border-radius: 8px;
}

@media(max-width:450px){
  .passwordChangedForm{
    width: 300px;
  }
}

.passwordChangedFormTitle{
    font-family: "Oswald", sans-serif;
    font-size: 24px;
}

.signInFormIcon {
  color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.passwordChangedFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 19px;
}

.passwordChangedFormText {
  color: #686F7B;
  align-self: center;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

@media(max-width:450px){
  .passwordChangedForm{
    text-align: center;
  }
}

.passwordChangedFormButton {
  padding: 10px 16px 10px 16px;
  background-color: #35c0cc;
  font-family: "Inter", sans-serif;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
}

.passwordChangedFormButton:hover,
.passwordChangedFormButton:focus{
  background-color: #4ec1d0;
}

.passwordChangedFormButton:disabled{
  background-color: #b3e4eb;
}

.passwordChangedFormSuccess{
  align-self: center;
  padding-bottom: 20px;
}
.modal, .modal-2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 1000;
}

.modal-2{
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1002;
}

.signInForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 24px;
  border-radius: 8px;
}

.signInFormHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
}

.signInFormIcon {
  color: #2d3643;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.signInFormContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.signInFormInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  color: #030F23;
}

.signInFormInput::-webkit-input-placeholder {
  color: #686F7B;
}

.signInFormInput:-ms-input-placeholder {
  color: #686F7B;
}

.signInFormInput::-ms-input-placeholder {
  color: #686F7B;
}

.signInFormInput::placeholder {
  color: #686F7B;
}

.signInFormInput:focus {
  padding-top: 17px;
  padding-bottom: 3px;
  border: 1px solid #030F23;
}

.signInFormInput:focus + .placeholder {
  transform: translateY(-12px);
  font-size: 9px;
}

.signInFormInput:not(:focus):not(:placeholder-shown) {
  padding-top: 17px;
  padding-bottom: 3px;
}

.signsignInFormInputNotEmpty {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  padding-top: 17px;
  padding-bottom: 3px;
}

.placeholderNotInFocus {
  margin-bottom: 1vh;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(-12px);
}

.placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  font-size: 13px;
}

.signshowPasswordEye {
  position: absolute;
  font-family: "Inter", sans-serif;
  transform: translate(372px, 16px);
  z-index: 10001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #686F7B;
}

.focusedPasswordEye {
  stroke: #030F23;
}

.signInFormTitle {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  color: #030F23;
}

.signInFormInputValidation {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #d70015;
}

.errorInput {
  border: 1px solid #d70015;
  margin-bottom: 0px;
}

.errorInput:focus {
  border: 1px solid #d70015;
}

.signInFormForgotPassword {
  text-decoration: none;
  align-self: flex-end;
  font-weight: 450;
  color: #12abbf;
  font-size: 12px;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

.signInFormButton {
  padding: 10px 12px;
  background-color: #12abbf;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-size: 14px;
  height: 40px;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signInFormButton:hover,
.signInFormButton:focus {
  background-color: #4ec1d0;
}
.signInFormButton:disabled {
  background-color: #b3e4eb;
}

.btnLoader{
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  -webkit-animation: rotation infinite 3s linear;
          animation: rotation infinite 3s linear;
}

@-webkit-keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

@keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

.signInFormDontHaveAccount {
  margin-top: 20px;
  margin-bottom: 5px;
  /* width: 45%; */
  align-self: center;
  display: flex;
  justify-content: space-between;
}

.signInFormDontHaveAccountSpan {
  font-size: 12px;
  color: #686F7B;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
  margin-right: 4px;
}

.signInFormDontHaveAccountRegister {
  font-size: 12px;
  text-decoration: none;
  color: #12abbf;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
  cursor: pointer;
}

.signInFormForgotPassword:hover,
.signInFormDontHaveAccountRegister:hover,
.signInFormForgotPassword:focus,
.signInFormDontHaveAccountRegister:focus {
  color: #4ec1d0;
}

.signInFormForgotPassword:disabled,
.signInFormDontHaveAccountRegister:disabled{
  color: #D0EEF2;
}

@media (max-width: 450px) {
  .signInForm {
    width: 300px;
  }

  .showPasswordEye {
    transform: translate(267px, 15px);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.createAccountForm {
  width: 792px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
}

.createAccountFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createAccountFormTitle {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
}

.createAccountCloseIcon {
  color: #2d3643;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.createAccountFormSpan {
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.createAccountFormContainerAdd {
  display: grid;
  grid-template-columns: 50% 50%;
}

@media(max-width:450px){
  .createAccountFormContainerAdd{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.createAccountFormAccountType {
  margin: 0px;
  padding-right: 10px;
}

.createAccountFormTypeOption {
  padding: 21px 16px 21px 16px;
  border: 1px solid #f2f3f4;
  border-radius: 8px;
  cursor:pointer;
}

.createAccountFormTypeOption:nth-child(1) {
  margin-bottom: 1vh;
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.createAccountFormTypeOption:nth-child(2) {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.createAccountFormTypeOptionHead {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;
}

.personalIcon {
  margin-right: 10px;
  padding-left: 0px;
}

.createAccountFormTypeOptionHeadTitle {
  font-size: 14px;
  font-family: "Oswald", sans-serif;
  padding: 0px;
  margin: 0px;
}

.createAccountFormTypeOptionSpan {
  font-size: 12px;
  color: #686f7b;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
  margin-top: 8px;
  margin-left: 25px;
  font-weight: 500;
}

.createAccountFormTypeOptionListItemIcon {
  height: 10.33px;
  width: 13.47px;
  margin-left: 4.27px;
  color: #686f7b;
}

.createAccountFormTypeOptionListItemText {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
  color: #686f7b;
  margin-left: 8px;
}

.createAccountFormInputContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.1vh;
}

.createAccountFormInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 40px;
  color: #030F23;
}

.createAccountFormPlaceholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 18.5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  font-size: 13px;
  height: 17px;
}

.createAccountEmptyInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 40px;
  padding-top: 17px;
  padding-bottom: 3px;
}

.createAccountEmptyPlaceholder {
  margin-bottom: 1vh;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 18.5px;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(-15px);
}

.createAccountFormInput:focus {
  padding-top: 17px;
  padding-bottom: 3px;
  border:1px solid #030F23;
}

.createAccountFormInput:focus + .createAccountFormPlaceholder {
  transform: translateY(-15px);
  font-size: 9px;
}

.createAccountFormInput:not(:focus):not(:placeholder-shown) {
  padding-top: 17px;
  padding-bottom: 3px;
}

.showPasswordEye {
  position: absolute;
  font-family: "Inter", sans-serif;
  transform: translate(362px, 20px);
  z-index: 10001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #686F7B;
}

.focusedPasswordEye {
  stroke: #030F23;
}

.createFormDontHaveAccount {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 43.5%;
  align-self: center;
  display: flex;
  justify-content: space-between;
}

.btnLoader{
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  -webkit-animation: rotation infinite 3s linear;
          animation: rotation infinite 3s linear;
}

@-webkit-keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

@keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

@media(max-width:450px){
  .createFormDontHaveAccount{
    width:100%;
  }
}

.createFormDontHaveAccountSpan {
  font-size: 12px;
  color: #686F7B;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
}

.createFormDontHaveAccountRegister {
  font-size: 12px;
  text-decoration: none;
  color: #12abbf;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 15.6px;
  cursor: pointer;
}

.createFormDontHaveAccountRegister:hover,
.createFormDontHaveAccountRegister:hover {
  color: #4ec1d0;
}

.createFormDontHaveAccountRegister:disabled{
  color: #D0EEF2;
}

.createAccountFormButton {
  margin-top: 20px;
  padding: 10px 12px;
  background-color: #12abbf;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-size: 14px;
  height: 40px;
  font-family: "Inter", sans-serif;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createAccountFormButton:hover,
.createAccountFormButton:focus {
  background-color: #4ec1d0;
}

.createAccountFormButton:disabled {
  background-color: #b3e4eb;
}

.createAccountWrongIcon{
  color:#D70015;
  position: absolute;
  font-family: "Inter", sans-serif;
  transform: translate(362px, 20px);
  z-index: 10001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  font-size: 17px;
}

.createAccountErrorInput{
  border:1px solid #D70015;
}

.createAccountErrorInput:focus{
  border:1px solid #D70015;
}

.createAccountFormValidation{
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 13px;
  color: #d70015;
}

.createAccountFormValidationPassword{
  font-size: 14px;
  margin-left: 10px;
  color: #d70015;
  margin-top: 1vh;
}

.passwordFits{
  color:#0FAF62;
}

.chosenAccountBorder{
  border:1px solid #12ABBF;
}
.accountCreatedForm {
  width: 406px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
}

@media (max-width: 300px) {
  .accountCreatedForm {
    width: 300px;
  }
}

.accountCreatedFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.accountCreatedFormTitle {
  font-size: 24px;
  font-family: "Oswald", sans-serif;
  padding: 0px;
  margin: 0px;
}

.accountCreatedFormSpan {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #686f7b;
  width: 50%;
  align-self: center;
  padding-bottom: 20px;
}

.accountCreatedFormGotoAccount {
  padding: 10px 12px;
  background-color: #12abbf;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-size: 14px;
  height: 40px;
  font-family: "Inter", sans-serif;
  margin-bottom: 0px;
}

.accountCreatedSuceessIcon{
  align-self: center;
  margin-bottom: 20px;
}
.simpleSubMenu {
    display: none;
    position: absolute;
    top: 100%;
    /* Position below the button */
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 1rem;
    z-index: 10;
}

.group:hover .simpleSubMenu {
    display: block;
}


.custom-dashed-line {
    border-top: 1px dashed;
    border-color: #FFFFFF;
    border-style: dashed;
    border-width: 1px;
    border-image: repeating-linear-gradient(to right,
            #FFFFFF 0,
            #FFFFFF 2%,
            transparent 2%,
            transparent 4%) 100% 1;
}

.custom-dashed-line-gray {
    border-top: 1px dashed;
    border-color: #969696;
    border-style: dashed;
    border-width: 1px;
    border-image: repeating-linear-gradient(to right,
            #969696 0,
            #969696 2%,
            transparent 2%,
            transparent 4%) 100% 1;
}

@-webkit-keyframes up-down-right {

    0%,
    100% {
        transform: translate(0px, 0px) scale(1);
    }

    50% {
        transform: translate(20px, 60px) scale(1);
    }

}

@keyframes up-down-right {

    0%,
    100% {
        transform: translate(0px, 0px) scale(1);
    }

    50% {
        transform: translate(20px, 60px) scale(1);
    }

}

@-webkit-keyframes up-down-left {

    0%,
    100% {
        transform: translate(-100px, -20px) scale(1);
    }

    50% {
        transform: translate(-80px, 80px) scale(1);
    }

}

@keyframes up-down-left {

    0%,
    100% {
        transform: translate(-100px, -20px) scale(1);
    }

    50% {
        transform: translate(-80px, 80px) scale(1);
    }

}

@-webkit-keyframes scale-in-out {

    0%,
    100% {
        transform: translate(0px, 0px) scale(1);
    }

    50% {
        transform: translate(0px, 20px) scale(1.2);
    }

}

@keyframes scale-in-out {

    0%,
    100% {
        transform: translate(0px, 0px) scale(1);
    }

    50% {
        transform: translate(0px, 20px) scale(1.2);
    }

}

.animated-left-down-scale {
    -webkit-animation: up-down-left-scale 2s ease-in-out infinite;
            animation: up-down-left-scale 2s ease-in-out infinite;
}


@-webkit-keyframes fade-in-out {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}


@keyframes fade-in-out {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@-webkit-keyframes up-down-left-fade {
    0% {
        transform: translate(-100px, -20px) scale(1);
        opacity: 1;
    }

    50% {
        transform: translate(-80px, 80px) scale(1);
        opacity: 0;
    }

    100% {
        transform: translate(-100px, -20px) scale(1);
        opacity: 1;
    }
}

@keyframes up-down-left-fade {
    0% {
        transform: translate(-100px, -20px) scale(1);
        opacity: 1;
    }

    50% {
        transform: translate(-80px, 80px) scale(1);
        opacity: 0;
    }

    100% {
        transform: translate(-100px, -20px) scale(1);
        opacity: 1;
    }
}

.animated-sync {
    -webkit-animation: up-down-left-fade 2s ease-in-out infinite;
            animation: up-down-left-fade 2s ease-in-out infinite;
}

.animated-circle {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}


.orange-button {
    background-color: #ff7c1d;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 500;
    /* Medium font weight */
    padding: 0.5rem 2.5rem;
    /* Vertical and horizontal padding */
    border-radius: 9999px;
    /* Full rounded corners */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.orange-button:hover {
    background-color: #d96814;
    /* Hover background color */
}
.container {
    width: 100%;
}

.nweNavbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.logo {
    height: 6vh;
    width: auto;
    cursor: pointer;
}

.menuItems,
.navButton {
    display: none;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.menuText {
    font-family: "Montserrat";
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
}

.menuItems :hover {
    color: #12ABBF;
    cursor: pointer;
}

.underline {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #12ABBF;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(-50%);
}

.group:hover .underline {
    width: 80%;
}

.menu-container {
    position: relative;
}

.menu-button {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.user-icon {
    height: 1.5rem;
    width: 1.5rem;
}


.login-button {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
}


.blue-button {
    background-color: #1EB8BC;
    color: white;
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 9999px;
    font-family: 'Montserrat', sans-serif;
    transition: background-color 300ms;
    border: none;
    cursor: pointer;
}

.blue-button:hover {
    background-color: #358590;
}

.silder {
    height: 2rem;
    border-right-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(202 196 208 / 1);
    border-color: rgb(202 196 208 / var(--tw-border-opacity, 1))
        /* #cac4d0 */
}

/* General Styles */
.mobile-menu-container {
    display: block;
}

.icon {
    color: #12ABBF;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.mobile-menu {
    position: fixed;
    inset: 0;
    background: white;
    z-index: 50;
    padding: 1.5rem;
    width: 50%;
    height: 100vh;
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.menu-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.divider {
    border-color: #CAC4D0;
    margin: 1rem 0;
}

.menu-items {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.menu-item {
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.menu-item:hover {
    color: #12ABBF;
}

/* Action Buttons */
.menu-actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.user-button {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    background: none;
    border: none;
    cursor: pointer;
}

.order-button {
    background: #1EB8BC;
    color: white;
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 9999px;
    font-family: 'Montserrat', sans-serif;
    transition: background-color 300ms;
    border: none;
    cursor: pointer;
}

.order-button:hover {
    background: #358590;
}

.menu-container {
    position: relative;
}

.menu-button {
    position: relative;
}

.submenu-container {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: -100px;
    background: white;
    padding:1rem 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 6px 6px 6px #00000026;
    z-index: 100;
    height: auto;
    width: 250px;
    border: 1px solid #00000026;
    text-align: center;
}

.menu-container:hover .submenu-container {
    display: block;
}

.submenu-content {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.submenu-link-container {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.submenu-link {
    margin: 0;
    color: #1EB8BC;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}


/* .login-button {
    background: none;
    color: inherit;
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-radius: 9999px;
    cursor: pointer;
} */



@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }


}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }

    .menuItems {
        display: flex;
        align-items: center;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .navButton {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .mobile-menu-container {
        display: none;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
/* Container styles */
.IconCircle {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    padding: 1.2rem; /* Tailwind's `p-8` equals 2rem padding */
    border-radius: 50%; /* Tailwind's `rounded-full` */
    background-color: #F9F9F9; /* Tailwind's `bg-[#F9F9F9]` */
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15); /* Tailwind's shadow */
  }
  
  /* Section container */
.section {
    margin-top: 5rem; /* Tailwind's mt-20 */
  }
  
  /* Icon grid for larger screens */
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .icon-grid-2 {
    grid-template-columns: repeat(2, 1fr);
    margin-top: -5rem; /* Tailwind's -mt-20 */
  }
  
  .icon-item {
    justify-self: center;
  }
  
  .icon-item.start {
    justify-self: start;
  }
  
  .icon-item.end {
    justify-self: end;
  }
  
  .icon-item.margin-bottom {
    margin-bottom: 5rem;
  }
  
  /* Hidden for medium and above */
  .hidden-md {
    display: none;
  }
  
  /* Text section */
  .text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem;
  }
  
  @media (min-width: 640px) {
    .text-section {
      margin-top: 3rem;
      grid-gap: 1.5rem;
      gap: 1.5rem;
    }
  }
  
  .main-heading {
    font-size: 2rem; /* Tailwind's text-[32px] */
    font-family: 'Montserrat', sans-serif;
    color: #1EB8BC;
    font-weight: bold;
    text-align: center;
  }
  
  @media (min-width: 640px) {
    .main-heading {
      font-size: 2.5rem; /* Tailwind's sm:text-[40px] */
    }
  }
  
  @media (min-width: 1024px) {
    .main-heading {
      font-size: 3.5rem; /* Tailwind's lg:text-[56px] */
    }
  }
  
  .sub-heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 1rem; /* Tailwind's text-base */
    text-align: center;
    max-width: 90%;
  }
  
  @media (min-width: 640px) {
    .sub-heading {
      font-size: 1.25rem; /* Tailwind's sm:text-xl */
      max-width: 75%;
    }
  }
  
  @media (min-width: 768px) {
    .sub-heading {
      font-size: 1.5rem; /* Tailwind's md:text-2xl */
      max-width: 60%;
    }
    .hidden-md{
        display: grid;
    }
  }
  
  @media (min-width: 1024px) {
    .sub-heading {
      max-width: 50%;
    }
  }
  
  /* Button styles */
  .order-button {
    background-color: #FF7C1D;
    color: #fff;
    padding: 0.5rem 1.5rem; /* Tailwind's py-2 px-6 */
    font-size: 1.125rem; /* Tailwind's text-lg */
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    border-radius: 9999px; /* Tailwind's rounded-full */
    transition: background-color 0.3s;
  }
  
  @media (min-width: 640px) {
    .order-button {
      padding: 0.75rem 2rem; /* Tailwind's sm:py-3 sm:px-8 */
      font-size: 1.25rem; /* Tailwind's sm:text-xl */
    }
  }
  
  @media (min-width: 768px) {
    .order-button {
      padding: 1rem 2.5rem; /* Tailwind's md:py-4 md:px-10 */
    }
  }
  
  .order-button:hover {
    background-color: #D96814; /* Tailwind's hover:bg-[#D96814] */
  }
  
/* Section container */
.section {
    margin-top: 7rem; /* Tailwind's mt-28 */
  }
  
  @media (min-width: 1280px) {
    .section {
      margin-top: 10rem; /* Tailwind's xl:mt-40 */
    }
  }
  
 /* Section Header */
 .section-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; /* Tailwind's gap-4 */
  text-align: center;
}

.section-title {
  font-size: 2rem; /* Tailwind's text-[32px] */
  font-family: 'Montserrat', sans-serif;
  color: #1EB8BC;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 640px) {
  .section-title {
    font-size: 2.25rem; /* Tailwind's sm:text-[36px] */
  }
}

@media (min-width: 768px) {
  .section-title {
    font-size: 2.5rem; /* Tailwind's md:text-[40px] */
  }
}

@media (min-width: 1280px) {
  .section-title {
    font-size: 2.75rem; /* Tailwind's xl:text-[44px] */
  }
}

  
  /* Card grid */
  .card-grid {
    display: grid;
    grid-gap: 2.5rem;
    gap: 2.5rem; /* Tailwind's gap-10 */
    margin-top: 2.5rem; /* Tailwind's mt-10 */
    grid-template-columns: 1fr;
  }
  
  @media (min-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr); /* Tailwind's md:grid-cols-2 */
    }
  }
  
  @media (min-width: 1280px) {
    .card-grid {
      grid-template-columns: repeat(4, 1fr) !important; /* Tailwind's xl:grid-cols-4 */
    }
  }
  
  /* Card styles */
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem; /* Tailwind's gap-4 */
    background-color: #fff; /* Tailwind's bg-white */
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15); /* Tailwind's shadow */
    border-radius: 1.5rem; /* Tailwind's rounded-3xl */
    padding: 2rem; /* Tailwind's p-8 */
    font-family: 'Montserrat', sans-serif;
  }
  

  .card-title {
    font-size: 1.5rem; /* Tailwind's text-2xl */
    font-weight: 600; /* Tailwind's font-semibold */
  }
  
  .card-description {
    font-size: 0.875rem; /* Tailwind's text-sm */
    font-weight: 500; /* Tailwind's font-medium */
  }
  
.translationServiceSection {
    margin-top: 7rem;
}

.ServiceHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    text-align: center;
}

.Service-section-subtitle {
    text-align: center;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
}

.service-card-container {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.bg-blue {
    background-color: #1EB8BC;
}

.bg-white {
    background-color: #fff;
}

.text-white {
    color: #fff;
}

.service-card-content {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.service-card {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    border-radius: 32px;
    --tw-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    --tw-shadow-colored: 0 12px 25px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 2rem 2.5rem;
}

.service-card-title {
    font-weight: bold;
    font-family: "Montserrat";
    font-size: 24px;
    text-align: center;
}

.service-card-subtitle {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Montserrat";
    line-height: 1.5rem;
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    /* Adjust gap between items */
    margin-top: 0.5rem;
}

.price-tag {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    padding: 0.25rem 1rem;
    border-radius: 1.5rem;
    background-color: #f2f2f2;
    transition: background-color 0.3s ease;
    cursor: default;
    /* Default cursor */
}

.price-tag:hover {
    background-color: #d9d9d9;
    /* Hover background color */
}

.text-muted {
    color: #e0e0e0;
    font-size: 1.125rem;
    /* Default font size for text */
}

.crad-divider {
    width: 100%;
}

.card-features-container {
    display: flex;
    flex-direction: column;
}

.card-features-title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 1rem 0;
    font-family: "Montserrat";
}

.text-light-gray {
    color: #F2F2F2;
}

.card-features-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.feature-item {
    font-weight: bold;
    font-family: "Montserrat";
    font-size: 1.125rem;
    line-height: 1.75rem
}

.feature-value {
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 1.125rem;
    line-height: 1.75rem
}

.dashed-line {
    width: 100%;
    border-top: 1px dashed;
    margin: 1rem 0;
}

.mb-2{
    margin-bottom: 0.5rem;
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / 1);
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1))
}

.border-gray {
    border-color: #969696
}

.text-gary {
    color: #969696
}


@media (min-width: 640px) {
    .Service-section-subtitle {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width: 768px) {
    .translationServiceSection {
        margin-top: 10rem !important;
    }

    .Service-section-subtitle {
        max-width: 55%;
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .service-card-title {
        font-size: 28px;
    }

    .service-card-subtitle {
        font-size: 1.125rem;
        line-height: 1.75rem;
        max-width: 70%;
    }
}

@media (min-width: 1024px) {
    .service-card-container {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}

@media (min-width: 1280px) {
    .translationServiceSection {
        margin-top: 20rem !important;
    }

    .Service-section-subtitle {
        font-size: 28px;
        line-height: 2.5rem;
    }

    .service-card-title {
        font-size: 32px;
    }
}

@media (min-width: 1536px) {}
/* Section container */
.section {
    margin-top: 7rem;
    /* Tailwind's mt-28 */
}

@media (min-width: 1280px) {
    .section {
        margin-top: 10rem;
        /* Tailwind's xl:mt-40 */
    }
}

/* Section Header */
.section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    text-align: center;
}

.section-title {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    color: #1EB8BC;
    font-weight: bold;
    text-align: center;
}

.section-subtitle {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: 100;
}

@media (min-width: 640px) {
    .section-title {
        font-size: 2.25rem;
    }

    .section-subtitle {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width: 768px) {
    .section-title {
        font-size: 2.5rem;
    }

    .section-subtitle {
        max-width: 65%;
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

@media (min-width: 1280px) {
    .section-title {
        font-size: 2.75rem;
    }

    .section-subtitle {
        font-size: 28px;
        line-height: 2.5rem;
    }
}


/* Card grid */
.card-grid-con {
    display: grid;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    /* Tailwind's gap-10 */
    margin-top: 2.5rem;
    /* Tailwind's mt-10 */
    grid-template-columns: 1fr;
}

@media (min-width: 768px) {
    .card-grid-con {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1280px) {
    .card-grid-con {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

/* Card styles */
.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
    /* Tailwind's gap-4 */
    background-color: #fff;
    /* Tailwind's bg-white */
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    /* Tailwind's shadow */
    border-radius: 1.5rem;
    /* Tailwind's rounded-3xl */
    padding: 2rem;
    /* Tailwind's p-8 */
    font-family: 'Montserrat', sans-serif;
}


.card-title {
    font-size: 1.5rem;
    /* Tailwind's text-2xl */
    font-weight: 600;
    /* Tailwind's font-semibold */
}

.card-description {
    font-size: 0.875rem;
    /* Tailwind's text-sm */
    font-weight: 500;
    /* Tailwind's font-medium */
}
.SectionMargin {
    margin-top: 7rem;
}

.languages-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: center;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    margin-top: 2rem;
}

.language-item {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    background-color: #D2F2F3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-family: 'Montserrat';
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
    --tw-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    --tw-shadow-colored: 0 12px 25px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.popular-languages {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: center;
    grid-gap: 7rem;
    gap: 7rem;
    margin-top: 7rem;
}

.responsive-paragraph {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 1rem;
    max-width: 70%;
    margin: 0 auto;
}

.desktop-screen-languages {
    display: none;
}

.popular-languages-container {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
}

.grid-container-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-container-4 {
    grid-template-columns: repeat(4, 1fr);
}

.popular-languages-item {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-self: center;
    background-color: #d2f2f3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 9999px;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.75rem;
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

.language-span {
    padding: 0.5rem 1.5rem 0.5rem 0;
}

.space-y-8>*:not(:last-child) {
    margin-bottom: 2rem;
    /* 2rem = 32px, equivalent to Tailwind's spacing scale of 8 */
}

.mobile-screen-languages {
    display: block;
}

.mobile-screen-languages-container {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}


@media (min-width: 640px) {
    .responsive-paragraph {
        font-size: 1.25rem;
        /* 20px for sm screens */
    }

}


@media (min-width: 768px) {
    .SectionMargin {
        margin-top: 10rem
    }

    .languages-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .popular-languages {
        margin-top: 10rem
    }

    .mobile-screen-languages {
        display: none;
    }

    .desktop-screen-languages {
        display: block;
        --tw-space-y-reverse: 0;
        margin-top: calc(2rem
                /* 32px */
                * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2rem
                /* 32px */
                * var(--tw-space-y-reverse));
    }

    .grid-container-md-3 {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid-container-md-4 {
        grid-template-columns: repeat(4, 1fr);

    }
}

@media (min-width: 1024px) {
    .languages-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .popular-languages {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 20rem;
        grid-gap: 1rem;
        gap: 1rem
    }

}

@media (min-width: 1280px) {
    .SectionMargin {
        margin-top: 20rem
    }

    .languages-container {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (min-width: 1536px) {
    .languages-container {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}
.footer-Container {
    background-color: #1EB8BC;
    padding-top: 2.5rem;
    padding-bottom: 3rem;
}

.footerReadyContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: 0 1rem; */
}

.relative-group {
    position: relative;
}

.group-text {
    color: #F2F2F2;
    font-size: 1.125rem;
    /* equivalent to text-lg */
    line-height: 1.75rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.group-text:hover {
    color: #FFF;
}

.group-underline {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #FFF;
    transition: width 0.3s ease-in-out;
    transform: translateX(-50%);
}

.relative-group:hover .group-underline {
    width: 80%;
}


.topButton {
    background-color: #F2F2F2;
    padding: 0.75rem;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
}

.text-blue {
    color: #1EB8BC;
}

.navigation-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1.5rem;
}

.copyRight {
    text-align: center;
    color: #F2F2F2;
    padding-top: 2rem;
    font-size: 0.875rem;
    line-height: 1.25rem
}

.top-text {
    color: #F2F2F2;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
}

.topButton-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

@media (min-width: 640px) {}

@media (min-width: 800px) {
    .footerReadyContainer {
        flex-direction: row;
    }

    .navigation-container {
        flex-direction: row;
        margin-bottom: 0;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .top-text {
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: left;
    }

    .topButton-container {
        flex-direction: row;
    }

}

@media (min-width: 768px) {

    .topButton {
        padding: 1rem;
    }

    .copyRight {
        font-size: 1rem;
        line-height: 1.5rem
    }



}

@media (min-width: 1024px) {}

@media (min-width: 1280px) {}

@media (min-width: 1536px) {}
.certifiedHeaderImg {
  width: 55%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.certifiedMainInfoContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.certifiedMainInfoTextContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.certifiedMainInfoTitle {
  font-family: 'Montserrat';
  font-size: 1.8vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 3vh;
}

.certifiedMainInfoText {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 2.2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.certifiedMainInfoBlockContainer {
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(24, 183, 187, 0.3);
  border-radius: 10px;
}

.certifiedMainInfoBlockHeader {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #18b7bb;
  border-radius: 10px;
}

.certifiedMainInfoBlockHeaderTitle {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #fff;
  font-weight: 700;
  margin-top: 4vh;
  margin-bottom: 1vh;
}

.certifiedMainInfoBlockHeaderPrice {
  font-family: 'Montserrat';
  font-size: 4vw;
  color: #fff;
  font-weight: 700;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.certifiedMainInfoBlockHeaderMini {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #f5f5f5;
  font-weight: 400;
  margin-top: 1vh;
  margin-bottom: 4vh;
}

.certifiedMainInfoBlockMain {
  margin-top: 2vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.certifiedMainInfoBlockLine {
  width: 85%;
  height: auto;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.certifiedMainInfoBlockLineText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1vw;
}

.certifiedSampleContainer {
  width: calc(100% - 6vw);
  padding-top: 7vh;
  padding-bottom: 7vh;
  padding-right: 3vw;
  padding-left: 3vw;
  background-color: rgba(24, 183, 187, 0.1);
}

.certifiedSampleInfoContainer {
  margin-left: 0vw !important;
  margin-right: 4vw !important;
}

.certifiedSampleBlock {
  width: 10vw !important;
  margin-right: 2vw;
}

.certifiedSampleBlockImgContainer {
  width: 100% !important;
  height: 25vh !important;
}

.certifiedFaqSection {
  margin-top: 12vh;
  margin-bottom: 12vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.certifiedFaqs {
  width: 55%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.certifiedFaqTitle {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5vh;
}

.certifiedFaqLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(102,102,102,0.15);
  overflow-y: hidden;
  margin-bottom: 2vh;
}

.certifiedFaqLineTitle {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1.1vw;
  line-height: 2.6vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 2vh;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
}

.certifiedFaqLineTitle:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.certifiedFaqLineText {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4vh;
}

.bullet{
  height: 20px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5vw;
  margin-left: 2vw;
  display: inline-block;
}

.certifiedGuides {
  width: 35%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.certifiedGuidesLineText {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.2vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 2vh;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.certifiedGuidesLineText:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .certifiedHeaderImg {
    width: 45%;
    height: auto;
    margin-right: 0;
    object-fit: cover;
    z-index: 10;
    align-self: flex-start;
  }

  .certifiedMainInfoContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .certifiedMainInfoTextContainer {
    width: 55%;
  }
  
  .certifiedMainInfoTitle {
    font-size: 3.5vw;
    margin-bottom: 2vh;
  }
  
  .certifiedMainInfoText {
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .certifiedMainInfoBlockContainer {
    width: 40%;
  }
  
  .certifiedMainInfoBlockHeaderTitle {
    font-size: 2.2vw;
    margin-top: 3vh;
  }
  
  .certifiedMainInfoBlockHeaderPrice {
    font-size: 7vw;
  }
  
  .certifiedMainInfoBlockHeaderMini {
    font-size: 2.2vw;
    margin-bottom: 3vh;
  }
  
  .certifiedMainInfoBlockMain {
    margin-top: 2vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .certifiedMainInfoBlockLine {
    width: 90%;
    height: auto;
    margin-top: 2vh;
    margin-bottom: 0;
  }
  
  .certifiedMainInfoBlockLineText {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    line-height: 3.5vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1vw;
  }

  .certifiedSampleContainer {
    width: calc(100% - 6vw);
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 3vw;
    padding-left: 3vw;
    background-color: rgba(24, 183, 187, 0.1);
  }
  
  .certifiedSampleInfoContainer {
    margin-left: 0vw !important;
    margin-right: 4vw !important;
  }
  
  .certifiedSampleBlock {
    width: 15vw !important;
    margin-right: 2vw;
  }
  
  .certifiedSampleBlockImgContainer {
    width: 100% !important;
    height: 15vh !important;
  }

  .certifiedFaqSection {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .certifiedFaqTitle {
    font-size: 3.5vw;
    margin-bottom: 3vh;
  }

  .certifiedFaqs {
    width: 65%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .certifiedFaqLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #c1c1c1;
    overflow-y: hidden;
    margin-bottom: 2vh;
  }

  .certifiedFaqLineTitle {
    font-size: 2vw;
    line-height: 3.2vw;
    margin-bottom: 2vh;
  }

  .certifiedFaqLineText {
    font-size: 1.8vw;
    line-height: 3.6vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 4vh;
  }

  .certifiedGuides {
    width: 30%;
  }

  .certifiedGuidesLineText {
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .certifiedHeaderImg {
    display: none;
  }

  .certifiedMainInfoContainer {
    margin-top: 12vh;
    margin-bottom: 5vh;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
  
  .certifiedMainInfoTextContainer {
    margin-top: 10vh;
    width: 100%;
  }
  
  .certifiedMainInfoTitle {
    font-size: 6.5vw;
    margin-bottom: 2vh;
  }
  
  .certifiedMainInfoText {
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .certifiedMainInfoBlockContainer {
    width: 100%;
  }
  
  .certifiedMainInfoBlockHeaderTitle {
    font-size: 4.5vw;
    margin-top: 3vh;
  }
  
  .certifiedMainInfoBlockHeaderPrice {
    font-size: 15vw;
  }
  
  .certifiedMainInfoBlockHeaderMini {
    font-size: 4.5vw;
    margin-bottom: 3vh;
  }
  
  .certifiedMainInfoBlockMain {
    margin-top: 2.5vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .certifiedMainInfoBlockLine {
    width: 90%;
    height: auto;
    margin-top: 0vh;
    margin-bottom: 2.5vh;
  }
  
  .certifiedMainInfoBlockLineText {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    line-height: 7vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1vw;
  }

  .certifiedSampleContainer {
    width: calc(100% - 6vw);
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 3vw;
    padding-left: 3vw;
    background-color: rgba(24, 183, 187, 0.1);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .certifiedSampleInfoContainer {
    margin-left: 0vw !important;
    margin-right: 0 !important;
    margin-bottom: 3vh !important;
  }
  
  .certifiedSampleBlock {
    width: 30vw !important;
    margin-right: 5vw !important;
    margin-left: 5vw !important;
  }
  
  .certifiedSampleBlockImgContainer {
    width: 100% !important;
    height: 18vh !important;
  }

  .certifiedFaqSection {
    margin-top: 7vh;
    margin-bottom: 7vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .certifiedFaqs {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5vh;
  }

  .certifiedFaqTitle {
    font-size: 8vw;
    margin-bottom: 4vh;
  }

  .certifiedFaqLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #c1c1c1;
    overflow-y: hidden;
    margin-bottom: 1vh;
  }

  .certifiedFaqLineTitle {
    width: 100%;
    height: 20vw;
    min-height: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 4vw;
    line-height: 6.5vw;
  }

  .certifiedFaqLineText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }

  .certifiedGuides {
    width: 100%;
  }

  .certifiedGuidesLineText {
    font-size: 4vw;
    line-height: 6.5vw;
    margin-top: 1vh;
    margin-bottom: 2.5vh;
  }
}
.standardExtraContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.standardExtraTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 2vh;
}

.standardExtraSub {
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 8vh;
}

.standardExtraBlocksContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.standardExtraBlock {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.standardExtraBlockInfo {
  margin-left: 1.5vw;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.standardExtraBlockTitle {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  line-height: 1.3vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.standardExtraBlockText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.8vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .standardExtraContainer {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .standardExtraTitle {
    font-size: 3.5vw;
    margin-bottom: 1vh;
  }
  
  .standardExtraSub {
    font-size: 2.3vw;
    margin-bottom: 5vh;
  }
  
  .standardExtraBlock {
    width: 46%;
  }
  
  .standardExtraBlockInfo {
    margin-left: 1.5vw;
  }
  
  .standardExtraBlockTitle {
    font-family: 'Montserrat';
    font-size: 2.5vw;
    line-height: 2vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1.5vh;
  }
  
  .standardExtraBlockText {
    font-family: 'Montserrat';
    font-size: 1.9vw;
    line-height: 3.5vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .standardExtraContainer {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  
  .standardExtraTitle {
    font-size: 7.5vw;
    margin-bottom: 1vh;
  }
  
  .standardExtraSub {
    font-size: 3.8vw;
    line-height: 7.5vw;
    margin-bottom: 5vh;
  }
  
  .standardExtraBlocksContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .standardExtraBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4vh;
  }
  
  .standardExtraBlockInfo {
    margin-left: 4vw;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .standardExtraBlockTitle {
    font-size: 5vw;
    line-height: 4vw;
    margin-bottom: 1vh;
  }
  
  .standardExtraBlockText {
    font-size: 3.5vw;
    line-height: 6.5vw;
    margin-bottom: 0;
  }
}
.languagesHeaderImg {
  width: 50%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.languagesListColumn {
  width: 24%
}

.languagesExtraInfoContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6vh;
  padding-top: 3vh;
  border-top: 1px solid #e1e1e1;
}

.languagesExtraInfoText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.hoveringTextLink {
  text-decoration: underline;
  -webkit-text-decoration-color: #18b7bb;
          text-decoration-color: #18b7bb;
  cursor: pointer;
  background-color: rgba(24, 183, 187,0);
  transition: background-color 0.3s ease-out;
}

.hoveringTextLink:hover {
  background-color: rgba(24, 183, 187,0.3);
}


@media (orientation: portrait) and (max-width: 1024px) {
  .languagesHeaderImg {
    width: 45%;
    align-self: flex-start;
  }

  .languagesListColumn {
    width: 32%
  }

  .languagesExtraInfoContainer {
    margin-top: 3vh;
    padding-top: 1.5vh;
  }
  
  .languagesExtraInfoText {
    font-size: 1.9vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .languagesHeaderImg {
    display: none;
  }
  
  .languagesListColumn {
    width: 100%
  }

  .languagesExtraInfoContainer {
    margin-top: 2.5vh;
    padding-top: 2vh;
  }
  
  .languagesExtraInfoText {
    font-size: 4vw;
    line-height: 8vw;
  }
}
.documentsHeaderImg {
  width: 50%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.documentsHeading {
  width: 95%;
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.documentsSubheading {
  width: 95%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.6vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 2vh;
}

.documentsListOuter {
  width: 100%;
  height: auto;
  margin-top: 12vh;
  margin-bottom: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.documentsListTitle {
  font-family: 'Montserrat';
  font-size: 2.1vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 7vh;
}

.documentsList {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.documentsListColumn {
  width: 32%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.documentsListItem {
  height: auto;
  width: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5vh;
  /* cursor: pointer; */
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.documentsListItem:hover {
  /* filter: brightness(0.5); */
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

.documentsListItemSpecial {
  height: auto;
  width: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5vh;
}

.documentsListItemText {
  margin-left: 1.5vw;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1em;
}

.documentsListItemTextSpecial {
  margin-left: 1.5vw;
  font-family: 'Montserrat';
  font-size: 1vw;
  color: #666;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .documentsHeaderImg {
    width: 45%;
    margin-right: 0;
    align-self: flex-start;
  }
  
  .documentsHeading {
    font-size: 3vw;
    margin-bottom: 3vh;
  }
  
  .documentsSubheading {
    width: 95%;
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .documentsListOuter {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .documentsListTitle {
    font-size: 3vw;
    margin-bottom: 4vh;
  }
  
  .documentsListItem:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .documentsListItemText {
    margin-left: 1.5vw;
    font-size: 1.8vw;
  }

  .documentsListItemTextSpecial {
    font-size: 1.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .documentsHeaderImg {
    display: none;
  }
  
  .documentsHeading {
    font-size: 7vw;
    margin-bottom: 3vh;
  }
  
  .documentsSubheading {
    width: 100%;
    font-family: 'Montserrat';
    font-size: 4vw;
    line-height: 8vw;
    margin-bottom: 0;
  }
  
  .documentsListOuter {
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .documentsListTitle {
    font-family: 'Montserrat';
    font-size: 8vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 4vh;
  }
  
  .documentsList {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .documentsListColumn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .documentsListItem {
    margin-bottom: 4.5vh;
  }
  
  .documentsListItem:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .documentsListItemText {
    margin-left: 2vw;
    font-family: 'Montserrat';
    font-size: 5vw;
    color: #666;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  .documentsListItemTextSpecial {
    font-size: 4.3vw;
  }
}
.useCasesHeaderImg {
  width: 50%;
  height: auto;
  margin-right: 0;
  object-fit: cover;
  z-index: 10;
}

.useCasesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 8vh;
  margin-top: 8vh;
}

.useCasesTitle {
  font-family: 'Montserrat';
  font-size: 2vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5vh;
}

.useCasesBlockContainer {
  width: 100%;
  height: auto;
}

.useCasePageBlock {
  display: inline-block !important;
  position: relative;
  width: 14%;
  margin-right: 2.6666%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* cursor: pointer; */
}

.useCasePageImgContainer {
  width: 100%;
  height: 38vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  background-color: rgba(24, 183, 187, 0.05);
  border: 1px solid #e1e1e1;
  transition: all 0.3s ease-out;
}

.useCasePageBlock:hover .useCasePageImgContainer {
  background-color: rgba(24, 183, 187, 0.4);
  border: 1px solid #c1c1c1;
}

.useCasePageImg {
  height: auto;
  width: 80%;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

.useCasePageBlock:hover .useCasePageImg {
  transform: scale(1.2);
}

.useCasePageBlockText {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 0;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.useCasePageBlock:hover .useCasePageBlockText {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .useCasesHeaderImg {
    width: 50%;
    align-self: flex-start;
  }
  
  .useCasesContainer {
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  
  .useCasesTitle {
    font-size: 3vw;
    margin-bottom: 4vh;
  }
  
  .useCasePageBlock {
    width: 22%;
    margin-right: 3%;
    margin-bottom: 3vh;
  }
  
  .useCasePageImgContainer {
    height: 22vh;
  }
  
  .useCasePageImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #c1c1c1;
  }
  
  .useCasePageBlockText {
    font-size: 1.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .useCasesHeaderImg {
    display: none;
  }
  
  .useCasesContainer {
    margin-bottom: 7vh;
    margin-top: 7vh;
  }
  
  .useCasesTitle {
    font-size: 7vw;
    margin-bottom: 4vh;
  }
  
  .useCasePageBlock {
    width: 45%;
    margin-right: 5%;
    margin-bottom: 5vh;
  }
  
  .useCasePageImgContainer {
    height: 27vh;
  }
  
  .useCasePageImgContainer:hover {
    background-color: rgba(24, 183, 187, 0.05);
    border: 1px solid #c1c1c1;
  }
  
  .useCasePageBlockText {
    font-size: 3vw;
  }
}
.faqHeader {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
  margin-bottom: 8vh;
}

.faqSubheading {
  width: 70%;
  font-family: 'Montserrat';
  font-size: 1.1vw;
  line-height: 2.8vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: center;
}

.faqGeneral {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 7vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .faqHeader {
    margin-top: 6vh;
    margin-bottom: 5vh;
  }
  
  .faqSubheading {
    width: 90%;
    font-size: 2vw;
    line-height: 3.8vw;
    margin-top: 1vh;
    margin-bottom: 2vh;
  }
  
  .faqGeneral {
    width: 100%;
    margin-bottom: 5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .faqHeader {
    margin-top: 6vh;
    margin-bottom: 5vh;
    align-items: flex-start;
  }
  
  .faqSubheading {
    width: 100%;
    font-size: 4.5vw;
    line-height: 9vw;
    text-align: left;
    margin-top: 1vh;
    margin-bottom: 2vh;
  }
  
  .faqGeneral {
    width: 100%;
    margin-bottom: 7vh;
  }
}
.singleLanguageHeading {
  font-family: 'Montserrat';
  font-size: 2.8vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.singleLanguageSubheading {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 1.1vw;
  line-height: 2.8vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 5vh;
}

.singleLanguageMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.singleLanguageMainImage {
  width: 38%;
  height: auto;
  object-fit: cover;
}

.singleLanguageMainInfoContainer {
  width: 55%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleLanguageMainInfoExtra {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  color: #666;
  font-weight: 400;
  margin-top: 5vh;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleLanguageHeading {
    font-family: 'Montserrat';
    font-size: 4vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 1vh;
  }
  
  .singleLanguageSubheading {
    width: 95%;
    font-size: 1.8vw;
    line-height: 4vw;
    margin-bottom: 4vh;
  }

  .singleLanguageMainContainer {
    align-items: flex-start;
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .singleLanguageMainImage {
    width: 50%;
    margin-top: 5vh;
  }
  
  .singleLanguageMainInfoContainer {
    width: 50%;
  }
  
  .singleLanguageMainInfoExtra {
    font-size: 2vw;
    margin-top: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleLanguageHeading {
    font-size: 7.5vw;
    margin-bottom: 2vh;
  }
  
  .singleLanguageSubheading {
    font-size: 4.7vw;
    line-height: 10vw;
    margin-bottom: 5vh;
  }

  .singleLanguageMainContainer {
    align-items: flex-start;
    margin-top: 10vh;
    margin-bottom: 8vh;
  }
  
  .singleLanguageMainImage {
    display: none;
  }
  
  .singleLanguageMainInfoContainer {
    width: 100%;
  }
  
  .singleLanguageMainInfoExtra {
    font-size: 3.5vw;
  }
}
.aboutHeaderImg {
  width: 35%;
  margin-right: 5%;
  align-self: flex-start;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .aboutHeaderImg {
    width: 45%;
    margin-right: 0;
    align-self: flex-start;
  }
  
  .aboutMainContainer {
    align-items: center !important;
  }

  .aboutSecondContainer {
    align-items: center !important;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .aboutMainContainer {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .aboutSecondContainer {
    flex-direction: column-reverse !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 0 !important;
  }

  .aboutHeaderImg {
    display: flex !important;
    width: 90% !important;
    height: auto !important;
    margin-bottom: 5vh !important;
  }
}
.formInputSimpleContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.formInputSimpleLabel {
  margin-bottom: 1vh;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1vw;
  color: #666;

  /* new input styles */
  position: absolute;
  left: 0;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  /*  */
}

.formTextArea {
  color: #666;
  background-color: transparent;
  /* border-bottom: 1px solid rgba(102, 102, 102, 0.7);
  border-right: none;
  border-top: none;
  border-left: none; */
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.95vw;
  line-height: 1.9vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 25vh;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;

   /* new input styles */
   padding:  10px;
   outline: transparent;
   border-radius: 5px;
   transition: 0.5s;
   border: 1px solid rgba(102, 102, 102, 0.7);
   /*  */
}

.formTextArea::-webkit-input-placeholder {
  color: rgba(102, 102, 102, 1);
}

.formTextArea:-ms-input-placeholder {
  color: rgba(102, 102, 102, 1);
}

.formTextArea::-ms-input-placeholder {
  color: rgba(102, 102, 102, 1);
}

.formTextArea::placeholder {
  color: rgba(102, 102, 102, 1);
}

.formTextArea:focus, .formInputSimple:valid {
  border-color: #18b7bb !important;
}

.formTextArea:focus ~ .formInputSimpleLabel, .formTextArea:valid ~ .formInputSimpleLabel{
  padding: 0 10px;
  background: white;
  color: #18b7bb !important;
  transform: translateX(10px) translateY(-15px);
  font-size: 0.9vw;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .formTextArea {
    height: 15vh;
  }

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 2vw;
    line-height: 4vw;
    /* font-size: 1.8vw; */
    /* line-height: 1.8vw; */
  }
  
  .formTextArea {
    font-size: 2vw;
    line-height: 4vw;
  }

  .formTextArea:focus ~ .formInputSimpleLabel, .formTextArea:valid ~ .formInputSimpleLabel{
    font-size: 1.8vw;
    line-height: 1.8vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 3.6vw;
    line-height: 7.2vw;
    /* font-size: 2.7vw;
    line-height: 2.7vw; */
  }
  
  .formTextArea {
    font-size: 3.6vw;
    line-height: 7.2vw;
    width: 95%;
  }

  .formTextArea:focus ~ .formInputSimpleLabel, .formTextArea:valid ~ .formInputSimpleLabel{
    font-size: 2.7vw !important;
    line-height: 2.7vw;
  }
}
.formInputSimpleContainer {
  position: relative;
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1vh;
  max-width: 600px;
}

.formInputSimpleLabel {
  margin-bottom: 1vh;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1vw;
  color: #666;
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
}

.formInputSimple {
  color: #666;
  background-color: transparent;
  border-bottom: 1px solid rgba(102, 102, 102, 0.7);
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.95vw;
  line-height: 1vw;
  margin-top: 0;
  margin-bottom: 1vh;
  height: 2vw;
  width: inherit;
  padding-left: 0;
  padding:  10px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid rgba(102, 102, 102, 0.7);
}

.formInputSimple::-webkit-input-placeholder {
  color: rgba(102, 102, 102, 0.8);
}

.formInputSimple:-ms-input-placeholder {
  color: rgba(102, 102, 102, 0.8);
}

.formInputSimple::-ms-input-placeholder {
  color: rgba(102, 102, 102, 0.8);
}

.formInputSimple::placeholder {
  color: rgba(102, 102, 102, 0.8);
}

.formInputSimple:focus,.formInputSimple:valid, .validInput{
  border-color: #18b7bb !important;
}

.formInputSimple:focus ~ .formInputSimpleLabel, .formInputSimple:valid ~ .formInputSimpleLabel, .validInputLabel{
  padding: 5px 10px;
  background: white;
  color: #18b7bb !important;
  transform: translateX(10px) translateY(-21px);
  font-size: 0.9vw;
}


@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    /* font-size: 1.8vw; */
    font-size: 2vw;
    line-height: 2vw;
  }
  
  .formInputSimple {
    font-size: 2vw;
    line-height: 2vw;
    height: 4vw;
  }

  .formInputSimple:focus ~ .formInputSimpleLabel, .formInputSimple:valid ~ .formInputSimpleLabel, .validInputLabel{
    line-height: 1.8vw;
    font-size: 1.8vw;
  }
  
}

@media (orientation: portrait) and (max-width: 767px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 3.6vw;
    line-height: 3.6vw;
    /* font-size: 2.7vw;
    line-height: 2.7vw; */
  }
  
  .formInputSimple {
    font-size: 3.6vw;
    line-height: 3.6vw;
    height: 8vw;

    width: 95%;
  }

  .formInputSimple:focus ~ .formInputSimpleLabel, .formInputSimple:valid ~ .formInputSimpleLabel, .validInputLabel{
    font-size: 2.7vw;
    line-height: 2.7vw;
  }
}

.businessHeaderContainer {
  margin-bottom: 12vh !important;
}

.businessHeaderSeparator {
  height: 3vh;
}

.businessMainInfoContainer {
  width: 40% !important;
}

.businessMainContainer {
  align-items: flex-start !important;
  margin-top: 15vh !important;
}

.businessFormContainer {
  margin-top: 0 !important;
}

.businessLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3.5vh;
}

.businessLineGlyph {
  margin-top: 0;
}

.businessLineInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1.3vw;
}

.businessLineInfoTitle {
  font-family: 'Montserrat';
  font-size: 1.4vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .businessHeaderContainer {
    margin-bottom: 4vh !important;
  }
  
  .businessHeaderSeparator {
    height: 1.5vh;
  }
  
  .businessMainInfoContainer {
    width: 45% !important;
  }
  
  .businessMainContainer {
    align-items: flex-start !important;
    margin-top: 12vh !important;
  }
  
  .businessFormContainer {
    margin-top: 0 !important;
  }
  
  .businessLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2.5vh;
  }
  
  .businessLineGlyph {
    margin-top: 0;
  }
  
  .businessLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1.3vw;
  }

  .businessLineInfoTitle {
    font-family: 'Montserrat';
    font-size: 2vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.8vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .businessHeaderContainer {
    margin-bottom: 12vh !important;
  }
  
  .businessHeaderSeparator {
    height: 6vh;
  }
  
  .businessMainContainer {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 0 !important;
  }

  .businessMainInfoContainer {
    width: 100% !important;
    margin-bottom: 7vh !important;
  }
  
  .businessFormContainer {
    margin-top: 0 !important;
  }
  
  .businessLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5vh;
  }
  
  .businessLineGlyph {
    margin-top: 0;
  }
  
  .businessLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5vw;
  }

  .businessLineInfoTitle {
    font-family: 'Montserrat';
    font-size: 5vw;
    color: #18b7bb;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1.2vh;
  }
}
.verifySeparator {
  height: 3vh;
}

.verifyAuthenticContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5vh;
}

.verifyBigCheckContainer {
  width: 3vw;
  height: 3vw;
  border-radius: 1.5vw;
  background-color: #18b7bb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2vw;
  margin-left: 3vw;
}

.verifyBigCheck {
  width: 1.5vw;
  height: 1.5vw;
  color: #fff;
}

.verifyCheckTitle {
  width: calc(100% - 8vw);
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  line-height: 1.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.verifyLinkContainer {
  width: calc(100% - 3vw);
  margin-left: 3vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.verifyLinkText {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  line-height: 1.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 4vh;
}

.verifyLinkMain {
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  color: #555;
  margin-top: 0;
  margin-bottom: 4vh;
  transition: opacity 0.3s ease-out;
}

.verifyLinkMain:hover {
  opacity: 0.7;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .verifySeparator {
    height: 2vh;
  }

  .verifyAuthenticContainer {
    margin-bottom: 3vh;
  }
  
  .verifyBigCheckContainer {
    width: 4vw;
    height: 4vw;
    border-radius: 2vw;
    margin-right: 2vw;
    margin-left: 3vw;
  }
  
  .verifyBigCheck {
    width: 2vw;
    height: 2vw;
    color: #fff;
  }
  
  .verifyCheckTitle {
    width: calc(100% - 10vw);
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .verifyLinkText {
    font-size: 1.6vw;
    line-height: 3.2vw;
    margin-bottom: 3vh;
  }
  
  .verifyLinkMain {
    font-size: 1.9vw;
    line-height: 4vw;
    margin-bottom: 3vh;
  }
  
  .verifyLinkMain:hover {
    opacity: 1;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .verifySeparator {
    height: 1vh;
  }

  .verifyAuthenticContainer {
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  
  .verifyBigCheckContainer {
    width: 13vw;
    height: 13vw;
    border-radius: 6.5vw;
    margin-right: 5vw;
    margin-left: 3vw;
  }
  
  .verifyBigCheck {
    width: 6.5vw;
    height: 6.5vw;
    color: #fff;
  }
  
  .verifyCheckTitle {
    width: calc(100% - 19vw);
    font-size: 4.5vw;
    line-height: 8.5vw;
    text-align: right;
  }
  
  .verifyLinkText {
    font-size: 3.5vw;
    line-height: 8vw;
    margin-bottom: 4vh;
  }
  
  .verifyLinkMain {
    font-size: 4vw;
    line-height: 8vw;
    margin-bottom: 4vh;
  }
  
  .verifyLinkMain:hover {
    opacity: 1;
  }
}
.contactQRContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2vh;
}

.contactQRText {
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 0.9vw;
  line-height: 1.8vw;
}

.contactQRImg {
  height: auto;
  width: auto;
  object-fit: cover;
  margin-top: 2vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .contactQRContainer {
    margin-top: 1vh;
  }
  
  .contactQRText {
    font-size: 1.8vw;
    line-height: 3.6vw;
  }

  .contactQRImg {
    margin-top: 1vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .contactQRContainer {
    margin-top: 1vh;
  }
  
  .contactQRText {
    font-size: 4vw;
    line-height: 8vw;
  }

  .contactQRImg {
    margin-top: 1vh;
    margin-bottom: 5vh;
  }
}
.termsHeader {
  width: 80%;
}

.termsHeading {
  font-family: 'Montserrat';
  font-size: 2.2vw;
  line-height: 4vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.termsSubheading {
  width: 50%;
  text-align: left;
}

.termsUpdateContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.termsUpdateContainer p {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.termsIcon {
  width: 15px;
  height: 15px;
  color: #666;
  margin-right: 1vw;
}

.termsHeaderSeparator {
  width: 7vw;
  height: 0;
  border-bottom: 1px solid #666;
  margin-top: 5vh;
  margin-bottom: 0;
}

.termsContainer {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 8vh;
}

.termsText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  line-height: 2.2vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.termsSeparator {
  width: 0;
  height: 7vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .termsHeading {
    font-size: 3vw;
    line-height: 5vw;
    margin-bottom: 3vh;
  }

  .termsHeader {
    width: 100%;
  }
  
  .termsSubheading {
    width: 70%;
    text-align: left;
  }
  
  .termsUpdateContainer {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .termsUpdateContainer p {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.7vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsIcon {
    width: 15px;
    height: 15px;
    color: #666;
    margin-right: 1vw;
  }
  
  .termsHeaderSeparator {
    width: 10vw;
    margin-top: 3vh;
    margin-bottom: 0;
  }
  
  .termsContainer {
    width: 100%;
    margin-bottom: 8vh;
  }
  
  .termsText {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.8vw;
    line-height: 4vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsSeparator {
    width: 0;
    height: 7vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .termsHeading {
    font-size: 6vw;
    line-height: 10vw;
    margin-bottom: 4vh;
  }

  .termsHeader {
    width: 100%;
  }
  
  .termsSubheading {
    width: 100%;
    text-align: left;
  }
  
  .termsUpdateContainer {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .termsUpdateContainer p {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 3.5vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsIcon {
    width: 17px;
    height: 17px;
    color: #666;
    margin-right: 3vw;
  }
  
  .termsHeaderSeparator {
    width: 20vw;
    margin-top: 4vh;
    margin-bottom: 0;
  }
  
  .termsContainer {
    width: 100%;
    margin-bottom: 8vh;
  }
  
  .termsText {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 3.6vw;
    line-height: 8.2vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .termsSeparator {
    width: 0;
    height: 7vh;
  }
}
.headingReviews {
  font-size: 2.3vw;
}

.subheadingReviews {
  font-size: 1vw;
  font-weight: 500;
}

.infoButtonReviews {
  color: #18b7bb;
  margin-right: 1vw;
}

.reviewsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 5vh;
}

.reviewsTitle {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #18b7bb;
  font-size: 1vw;
  margin-top: 10vh;
  margin-bottom: 0;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  text-align: left;
}

.loadingReviews {
  width: 100%;
  height: 30vh;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 1vw;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loadMoreReviewsButton {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 1vw;
  margin-top: 7vh;
  margin-bottom: 3vh;
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 0.3s ease-out;
}

.loadMoreReviewsButton:hover {
  opacity: 0.7;
}

.reviewLine {
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.reviewScoreContainer {
  width: 7vw;
  height: 7vw;
  border-radius: 3.5vw;
  background-color: rgba(24, 183, 187, 0.15);
  font-family: 'Montserrat';
  font-weight: 700;
  color: #18b7bb;
  font-size: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reviewInnerContainer {
  width: calc(100% - 10vw);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.reviewName {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #18b7bb;
  font-size: 1.3vw;
  margin-top: 0;
  margin-bottom: 1vh;
}

.reviewLanguages {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 0.9vw;
  margin-top: 0;
  margin-bottom: 4vh;
}

.reviewText {
  font-family: 'Montserrat';
  font-weight: 300;
  color: #666;
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 0;
  margin-bottom: 4vh;
  white-space: pre-line;
  font-style: italic;
}

.reviewDate {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 0.85vw;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .headingReviews {
    font-size: 2.8vw;
    line-height: 4.5vw;
  }
  
  .subheadingReviews {
    font-size: 1.6vw;
    font-weight: 500;
  }
  
  .infoButtonReviews {
    color: #18b7bb;
    margin-right: 2vw;
  }
  
  .reviewsTitle {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #18b7bb;
    font-size: 1.5vw;
    margin-top: 6vh;
    margin-bottom: 0;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }
  
  .loadingReviews {
    width: 100%;
    height: 20vh;
    font-size: 1.8vw;
  }
  
  .loadMoreReviewsButton {
    font-size: 1.8vw;
    margin-top: 4vh;
  }
  
  .loadMoreReviewsButton:hover {
    opacity: 1;
  }
  
  .reviewLine {
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }
  
  .reviewScoreContainer {
    width: 10vw;
    height: 10vw;
    border-radius: 5vw;
    font-size: 3vw;
  }
  
  .reviewInnerContainer {
    width: calc(100% - 15vw);
  }
  
  .reviewName {
    font-size: 2.3vw;
    margin-bottom: 1vh;
  }
  
  .reviewLanguages {
    font-size: 1.6vw;
    margin-bottom: 3vh;
  }
  
  .reviewText {
    font-size: 1.6vw;
    line-height: 3.2vw;
    margin-bottom: 3vh;
  }
  
  .reviewDate {
    font-size: 1.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .headingReviews {
    font-size: 5.5vw;
    line-height: 9vw;
  }
  
  .subheadingReviews {
    font-size: 4vw;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .infoButtonReviews {
    color: #18b7bb;
    margin-right: 3vw;
    width: 20px;
    height: 20px;
  }
  
  .reviewsTitle {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #18b7bb;
    font-size: 3.5vw;
    margin-top: 6vh;
    margin-bottom: 0;
    padding-top: 1.8vh;
    padding-bottom: 1.8vh;
  }
  
  .loadingReviews {
    width: 100%;
    height: 30vh;
    font-size: 3.7vw;
  }
  
  .loadMoreReviewsButton {
    font-size: 3.7vw;
    margin-top: 4vh;
  }
  
  .reviewLine {
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }
  
  .reviewScoreContainer {
    width: 15vw;
    height: 15vw;
    border-radius: 7.5vw;
    font-size: 4vw;
  }
  
  .reviewInnerContainer {
    width: calc(100% - 23vw);
  }
  
  .reviewName {
    font-size: 4vw;
    margin-bottom: 1vh;
  }
  
  .reviewLanguages {
    font-size: 3.3vw;
    margin-bottom: 3vh;
    color: #666;
  }
  
  .reviewText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 3vh;
  }
  
  .reviewDate {
    font-size: 3.3vw;
  }
}
.singleUseCaseHeaderImg {
  width: 30%;
  height: auto;
  margin-right: 10vw;
  object-fit: cover;
  z-index: 10;
}

.singleUseCaseHeading {
  font-family: 'Montserrat';
  font-size: 2.6vw;
  color: #18b7bb;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 3vh;
}

.singleUseCaseSubheading {
  width: 85%;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #666;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 5vh;
}

.singleUseCaseMainBlockContainer {
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.singleUseCaseSeparator {
  height: 8vh;
  width: 100%;
}

.singleUseCaseLinkContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3vh;
  border-top: 1px solid #e1e1e1;
  margin-top: 8vh;
}

.singleUseCaseLinkText {
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 2.4vw;
  color: #18b7bb;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1.3vw;
  transition: margin-right 0.3s ease-out;
  cursor: pointer;
}

.singleUseCaseLinkText:hover {
  margin-right: 2.2vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleUseCaseHeaderImg {
    width: 38%;
    margin-right: 3vw;
  }

  .singleUseCaseHeading {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    color: #18b7bb;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .singleUseCaseSubheading {
    width: 95%;
    font-size: 1.6vw;
    line-height: 3.2vw;
    margin-bottom: 4vh;
  }

  .singleUseCaseMainBlockContainer {
    width: 98%;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  
  .singleUseCaseSeparator {
    height: 3vh;
  }
  
  .singleUseCaseLinkContainer {
    padding-top: 2.5vh;
    border-top: 1px solid #e1e1e1;
    margin-top: 3vh;
  }
  
  .singleUseCaseLinkText {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    line-height: 3.6vw;
  }
  
  .singleUseCaseLinkText:hover {
    margin-right: 2.2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleUseCaseHeaderImg {
    display: none;
  }

  .singleUseCaseHeading {
    font-size: 7.5vw;
    margin-bottom: 3vh;
  }
  
  .singleUseCaseSubheading {
    font-size: 4.2vw;
    line-height: 10vw;
    margin-bottom: 5vh;
  }

  .singleUseCaseMainBlockContainer {
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  
  .singleUseCaseSeparator {
    height: 5vh;
  }
  
  .singleUseCaseLinkContainer {
    padding-top: 2.5vh;
    border-top: 1px solid #e1e1e1;
    margin-top: 5vh;
  }
  
  .singleUseCaseLinkText {
    font-family: 'Montserrat';
    font-size: 4.2vw;
    line-height: 8vw;
  }
}
.accountFooterContainer {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8vh;
  padding-bottom: 2vh;
  border-top: 1px solid rgba(102,102,102,0.2);
}
.accountContainer {
  width: 82vw;
  padding-left: 9vw;
  padding-right: 9vw;
  padding-top: 7vh;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid rgba(102,102,102,0.2);
  margin-top: 3vh;
  margin-bottom: 10vh;
}

.accountSidebar {
  width: 23%;
  padding-right: 2%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.accountSidebarTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.7vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 5vh;
}

.accountSidebarLink {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  color: rgba(102,102,102,0.5);
  margin-top: 0;
  margin-bottom: 2.5vh;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.accountSidebarLink:hover {
  -webkit-filter: brightness(0.6);
          filter: brightness(0.6);
}

.accountMainContainer {
  width: 45%;
  padding-left: 2%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.accountMainTitleRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}

.accountMainTitle {
  font-family: 'Montserrat';
  font-weight: 900;
  font-size: 2.5vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 0;
}

.accountProfileFormLine {
  width: 100%;
  height: auto;
  margin-bottom: 2vh;
}

.accountProfileFormButton {
  width:400px;
  align-self: flex-end;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .accountContainer {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 5vh;
    margin-top: 2vh;
    margin-bottom: 10vh;
  }
  
  .accountSidebar {
    width: 23%;
    padding-right: 2%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .accountSidebarTitle {
    font-size: 2.2vw;
  }
  
  .accountSidebarLink {
    font-size: 1.8vw;
    margin-bottom: 2vh;
  }
  
  .accountSidebarLink:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .accountMainContainer {
    width: 55%;
    padding-left: 2%;
  }
  
  .accountMainTitleRow {
    margin-bottom: 3.5vh;
  }
  
  .accountMainTitle {
    font-size: 2.5vw;
  }
  
  .accountProfileFormLine {
    width: 100%;
    height: auto;
    margin-bottom: 2vh;
  }
  
  .accountProfileFormButton {
    align-self: flex-end;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .accountContainer {
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .accountSidebar {
    width: 100%;
    padding-right: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3vh;
    background-color: #f5f5f5;
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-bottom: 1px solid rgba(102,102,102,0.2);
    overflow: hidden;
  }
  
  .accountSidebarTitle {
    font-size: 4.5vw;
  }
  
  .accountSidebarLink {
    font-size: 3.8vw;
    margin-bottom: 2.5vh;
  }
  
  .accountMainContainer {
    width: 86%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .accountMainTitleRow {
    width: 98%;
    margin-bottom: 3.5vh;
  }
  
  .accountMainTitle {
    font-size: 5vw;
  }
  
  .accountProfileFormLine {
    width: 100%;
    height: auto;
    margin-bottom: 2vh;
  }
  
  .accountProfileFormButton {
    align-self: flex-end;
  }
}

.toggle--checked .react-toggle-track {
  background-color: #ff4546 !important;
  width:44px;
  height:18px;
} 
.toggle--checked.react-toggle--checked .react-toggle-track {
  background-color: #11d111 !important;
} 
.toggle--checked.react-toggle--checked .react-toggle-thumb{
  border-color: #11d111 !important;
}
.toggle--checked .react-toggle-thumb{
  border-color: #ff4546 !important;
  width: 16px;
  height:16px;
}


.quoteHeaderLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  border-bottom: 1.5px solid rgba(102,102,102,0.6);
}

.quoteDetailsHeaderColumn {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 0;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quoteLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid #e1e1e1;
}

.quoteDetailsLineColumn {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 1vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quoteStatusHeaderColumn {
  width: 25%;
}

.quoteTotalHeaderColumn {
  width: 15%;
  font-weight: 400;
}

.loadingQuotes {
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.quoteStatusContainer {
  width: auto;
  height: auto;
  padding: 1vh 2vw 1vh 2vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.75vw;
  color: #fff;
  border-radius: 5px;
  min-width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loadMoreQuotesButton {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  text-decoration: underline;
  color: rgba(102,102,102,0.3);
  margin-top: 2vh;
  margin-bottom: 0;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.loadMoreQuotesButton:hover {
  -webkit-filter: brightness(0.1);
          filter: brightness(0.1);
}

.quoteDetailsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.quoteDetailsTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.95vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1vh;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.optionTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.95vw;
  color: #000;
  margin-top: 0;
  margin-bottom: 1vh;
  transition: opacity 0.3s ease-out;
}

.quoteDetailsTitle:hover {
  opacity: 0.6;
}

.quoteDetailsSub {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .toggle--checked .react-toggle-track {
    width: 40px;
    height:14px;
  } 
  .toggle--checked .react-toggle-thumb{
    width: 12px;
    height: 12px;
  }

  .quoteDetailsHeaderColumn {
    font-size: 1.8vw;
  }
  
  .quoteDetailsLineColumn {
    font-size: 1.8vw;
  }
  
  .quoteStatusHeaderColumn {
    width: 25%;
  }
  
  .quoteTotalHeaderColumn {
    width: 15%;
    font-weight: 400;
  }
  
  .loadingQuotes {
    font-size: 2.2vw;
  }
  
  .quoteStatusContainer {
    padding: 0.5vh 2vw 0.5vh 2vw;
    font-size: 1.2vw;
  }
  
  .loadMoreQuotesButton {
    font-size: 2vw;
    color: rgba(102,102,102,0.8);
  }
  
  .quoteDetailsTitle {
    font-size: 1.6vw;
  }
  .optionTitle {
    font-size: 1.6vw;
  }
  
  .quoteDetailsTitle:hover {
    opacity: 1;
  }
  
  .quoteDetailsSub {
    font-size: 1.5vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .toggle--checked .react-toggle-track {
    width: 40px;
    height:14px;
  } 
  .toggle--checked .react-toggle-thumb{
    width: 12px;
    height: 12px;
  }
  
  .quoteDetailsHeaderColumn {
    font-size: 3.5vw;
    width: 50%;
  }
  
  .quoteDetailsLineColumn {
    width: 50%;
    font-size: 3.5vw;
  }
  
  .quoteStatusHeaderColumn {
    width: 28%;
  }
  
  .quoteTotalHeaderColumn {
    width: 17%;
    font-weight: 400;
  }
  
  .loadingQuotes {
    font-size: 4vw;
  }
  
  .quoteStatusContainer {
    padding: 0.8vh 3vw 0.8vh 3vw;
    font-size: 2.2vw;
  }
  
  .loadMoreQuotesButton {
    font-size: 3.8vw;
    color: rgba(102,102,102,0.8);
  }
  
  .quoteDetailsTitle {
    font-size: 3.2vw;
  }
  .optionTitle {
    font-size: 3.2vw;
  }
  
  .quoteDetailsTitle:hover {
    opacity: 1;
  }
  
  .quoteDetailsSub {
    font-size: 3.2vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
  }
}
.payLaterWrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.orText{
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 1vw;
  color: #666;
  margin: 0;
}

.singleQuoteMainPrimaryContainer {
  width: 60%;
  height: auto;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleQuoteMainSecondaryContainer {
  width: calc(35% - 1.5vw);
  height: auto;
  min-height: 80vh;
  background-color: #f8f8f8;
  padding-left: 2.5%;
  padding-right: calc(2.5% + 1.5vw);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleQuoteOuter {
  margin-top: 3vh;
  border-top: 1px solid rgba(102,102,102,0.2);
  transition: opacity 0.5s ease-out;
}

.singleQuoteMainPrimaryTitleRow {
  margin-left: 9vw;
  margin-right: 3vw;
  width: calc(100% - 12vw);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.singleQuoteMainPrimaryTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.3vw;
  color: #18b7bb;
  margin-top: 1vh;
  margin-bottom: 0;
}

.singleQuoteCheckoutContainer {
  margin-left: 9vw;
  margin-right: 3vw;
  width: calc(100% - 18vw);
  height: auto;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 3vw;
  padding-right: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.singleQuoteCheckoutImg {
  height: auto;
  width: 8vw;
  object-fit: cover;
  margin-right: 2vw;
}

.singleQuoteCheckoutInfo {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.singleQuoteCheckoutInfoText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  color: #666;
  line-height: 2vw;
  margin-bottom: 3vh;
}

.singleQuoteMainPrimaryInfo {
  margin-left: 9vw;
  margin-right: 3vw;
  width: calc(100% - 12vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.singleQuoteMainPrimaryInfoTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102,102,102,1);
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 1vh;
}

.singleQuoteMainPrimaryInfoSub {
  font-family: 'Montserrat';
  font-weight: 300;
  color: rgba(102,102,102,1);
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 1vh;
}

.singleQuoteCardContainer {
  width: 42%;
  min-height: 18vh;
  height: auto;
  padding-top: 2vh;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  /* border: 1px solid rgba(102,102,102,0.6); */
  box-shadow: 1px 1px 5px rgba(102,102,102,0.6);
}

.singleQuoteCardTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #18b7bb;
  margin-bottom: 2vh;
  margin-top: 0;
}

.singleQuoteCardName {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #666;
  margin-bottom: 0.5vh;
  margin-top: 0;
}

.singleQuoteCardText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  line-height: 1.6vw;
  color: #666;
  margin-bottom: 2vh;
  margin-top: 0;
}

.singleQuoteMainPrimaryFilesContainer {
  margin-left: 9vw;
  margin-right: 3vw;
  width: calc(100% - 12vw);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

.singleQuoteMainPrimaryFilesTitle {
  width: 100%;
  margin-top: 4vh;
  height: auto;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.2vw;
  color: #18b7bb;
  border-bottom: 1px solid rgba(102,102,102,0.6);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleQuoteFileRow {
  width: 100%;
  height: auto;
  padding-top: 3vh;
  padding-bottom: 3vh;
  border-bottom: 1px solid rgba(102,102,102,0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.singleQuoteFileTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1.5vh;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.singleQuoteFileTitle:hover {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}

.singleQuoteFileSub {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: rgba(102,102,102,0.7);
  margin-top: 0;
  margin-bottom: 0;
}

.singleQuoteRichTextEditor {
  margin-bottom: 3vh;
  margin-top: 3vh;
  width: 100%;
  min-height: 20vh;
}

.singleQuoteOrderSummary {
  width: calc(100% - 5vw);
  height: auto;
  margin-top: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 6vh;
}

.singleQuoteOrderSummaryTitle {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 4vh;
}

.singleQuoteOrderSummaryInfo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 3vh;
}

.singleQuoteOrderSummaryInfoMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.singleQuoteOrderSummaryInfoType {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 1vh;
}

.singleQuoteOrderSummaryInfoCount {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  font-weight: 300;
  color: rgba(102,102,102,0.5);
  margin-top: 0;
  margin-bottom: 0;
  width: 15vw;
}

.singleQuoteOrderSummaryTotal {
  border-top: 1px solid rgba(102,102,102,0.8);
  width: 100%;
  height: auto;
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 1.3vw;
  font-weight: 900;
  color: #666;
}

.singleQuoteShippingFormSeparator {
  height: 3.5vh;
}

.singleQuoteInputsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleQuoteMainPrimaryContainer {
    width: 60%;
    height: auto;
    min-height: 80vh;
  }
  
  .singleQuoteMainSecondaryContainer {
    width: 35%;
    height: auto;
    min-height: 80vh;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  
  .singleQuoteOuter {
    margin-top: 1vh;
  }
  
  .singleQuoteMainPrimaryTitleRow {
    margin-left: 6vw;
    margin-right: 3vw;
    width: calc(100% - 9vw);
    margin-top: 3vh;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .singleQuoteMainPrimaryTitle {
    font-size: 2.2vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .singleQuoteCheckoutContainer {
    margin-left: 6vw;
    margin-right: 3vw;
    width: calc(100% - 15vw);
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    margin-top: 3vh;
  }
  
  .singleQuoteCheckoutImg {
    height: auto;
    width: 10vw;
    object-fit: cover;
    margin-right: 4vw;
  }
  
  .singleQuoteCheckoutInfoText {
    font-size: 1.3vw;
    line-height: 2.6vw;
    margin-bottom: 1.7vh;
  }
  
  .singleQuoteMainPrimaryInfo {
    margin-left: 6vw;
    margin-right: 3vw;
    width: calc(100% - 9vw);
  }
  
  .singleQuoteMainPrimaryInfoTitle {
    font-size: 1.6vw;
    margin-bottom: 0.7vh;
  }
  
  .singleQuoteMainPrimaryInfoSub {
    font-size: 1.5vw;
  }
  
  .singleQuoteCardContainer {
    width: 44%;
    min-height: 12vh;
    height: auto;
    padding-top: 1vh;
    padding-left: 2%;
    padding-right: 2%;
  }
  
  .singleQuoteCardTitle {
    font-size: 1.5vw;
    margin-bottom: 1.5vh;
  }
  
  .singleQuoteCardName {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 0.5vh;
  }
  
  .singleQuoteCardText {
    font-size: 1.5vw;
    line-height: 2.5vw;
    margin-bottom: 2vh;
  }
  
  .singleQuoteMainPrimaryFilesContainer {
    margin-left: 6vw;
    margin-right: 3vw;
    width: calc(100% - 9vw);
    margin-bottom: 2vh;
  }
  
  .singleQuoteMainPrimaryFilesTitle {
    margin-top: 1vh;
    height: auto;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1.9vw;
  }
  
  .singleQuoteFileRow {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleQuoteFileTitle {
    font-size: 1.5vw;
    margin-bottom: 0.7vh;
  }
  
  .singleQuoteFileTitle:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .singleQuoteFileSub {
    font-size: 1.4vw;
  }
  
  .singleQuoteRichTextEditor {
    margin-bottom: 2vh;
    margin-top: 2vh;
    width: 100%;
    min-height: 20vh;
  }

  .singleQuoteOrderSummary {
    width: calc(100% - 3vw);
    height: auto;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3vh;
  }
  
  .singleQuoteOrderSummaryTitle {
    font-size: 1.8vw;
    margin-bottom: 3vh;
  }
  
  .singleQuoteOrderSummaryInfo {
    padding-bottom: 2vh;
  }

  .singleQuoteOrderSummaryInfoMain {
    width: 80%;
  }
  
  .singleQuoteOrderSummaryInfoType {
    font-size: 1.7vw;
    margin-bottom: 0.5vh;
  }
  
  .singleQuoteOrderSummaryInfoCount {
    font-family: 'Montserrat';
    font-size: 1.5vw;
    width: 20vw;
  }
  
  .singleQuoteOrderSummaryTotal {
    margin-top: 1vh;
    font-size: 2vw;
  }

  .singleQuoteShippingFormSeparator {
    height: 2.5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleQuoteMainPrimaryContainer {
    width: 98%;
    height: auto;
    min-height: 0;
    margin-bottom: 3vh;
  }
  
  .singleQuoteMainSecondaryContainer {
    width: 98%;
    height: auto;
    min-height: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
    align-items: center;
    background-color: transparent;
  }
  
  .singleQuoteOuter {
    margin-top: 2vh;
  }
  
  .singleQuoteMainPrimaryTitleRow {
    margin-left: 6vw;
    margin-right: 3vw;
    width: calc(100% - 9vw);
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .singleQuoteMainPrimaryTitle {
    font-size: 4.5vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .singleQuoteCheckoutContainer {
    margin-left: 6vw;
    margin-right: 3vw;
    width: calc(100% - 20vw);
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-top: 3vh;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  
  .singleQuoteCheckoutImg {
    display: none;
  }
  
  .singleQuoteCheckoutInfoText {
    font-size: 3vw;
    line-height: 6vw;
    margin-bottom: 2vh;
  }
  
  .singleQuoteMainPrimaryInfoTitle {
    font-size: 3vw;
    margin-bottom: 1vh;
  }
  
  .singleQuoteMainPrimaryInfoSub {
    font-size: 2.8vw;
  }
  
  .singleQuoteCardContainer {
    width: 96%;
    min-height: 10vh;
    height: auto;
    padding-top: 2vh;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 2vh;
  }
  
  .singleQuoteCardTitle {
    font-size: 3.3vw;
    margin-bottom: 2vh;
  }
  
  .singleQuoteCardName {
    font-size: 3.2vw;
    line-height: 6vw;
    margin-bottom: 0;
  }
  
  .singleQuoteCardText {
    font-size: 3vw;
    line-height: 6vw;
    margin-bottom: 2vh;
  }
  
  .singleQuoteMainPrimaryFilesTitle {
    margin-top: 1vh;
    height: auto;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    font-size: 4.4vw;
  }
  
  .singleQuoteFileRow {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleQuoteFileTitle {
    font-size: 3.2vw;
    margin-bottom: 1vh;
  }
  
  .singleQuoteFileTitle:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .singleQuoteFileSub {
    font-size: 3vw;
  }

  .singleQuoteOrderSummary {
    width: 88%;
    height: auto;
    margin-top: 0;
    margin-bottom: 3vh;
  }
  
  .singleQuoteOrderSummaryTitle {
    font-size: 4.5vw;
    margin-bottom: 4vh;
  }
  
  .singleQuoteOrderSummaryInfo {
    width: 100%;
    padding-bottom: 3vh;
  }
  
  .singleQuoteOrderSummaryInfoType {
    font-size: 4vw;
    margin-bottom: 1vh;
  }
  
  .singleQuoteOrderSummaryInfoCount {
    font-size: 3.2vw;
    width: 60vw;
  }
  
  .singleQuoteOrderSummaryTotal {
    font-size: 4.2vw;
  }

  .singleQuoteShippingFormSeparator {
    height: 4.5vh;
  }

  .quoteDetailsInputsContainer {
    margin-bottom: 2vh;
  }
}
.editBtn {
    width: 1.1vw;
    height: 1.1vw;
    color: #18b7bb;
    cursor: pointer;
    transition: -webkit-filter 0.3s ease-out;
    transition: filter 0.3s ease-out;
    transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
    margin-left: 5px;
  }

  .advOption{
    text-decoration: underline;
    margin-top: 10px;
  }

  .searchFilterRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 3vw;
    gap: 3vw;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .showAnim{
    display: flex;
    transition: 0.5s;
    -webkit-animation: show 0.5s ease-in-out;
            animation: show 0.5s ease-in-out;
    overflow: hidden;
  }

  .hideAnim{
    display: none;
    transition: 0.5s;
  }

  @-webkit-keyframes show {
    from {opacity: 0};
    to {opacity: 1};
  }

  @keyframes show {
    from {opacity: 0};
    to {opacity: 1};
  }


  @media (orientation: portrait) and (max-width: 1024px) {
    .editBtn {
      width: 2.1vw;
      height: 2.1vw;
    }

}

@media (orientation: portrait) and (max-width: 767px) {

  .searchFilterRow{
    flex-wrap: wrap;
  }
    
    .editBtn {
      width: 15px;
      height: 15px;
    }
}

@media (orientation: landscape) and (max-width: 1023px) {

    .editBtn {
      width: 12px;
      height: 12px;
    }
}
.fileUploaderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.fileUploaderInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
}

.dragDropBox{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 90%;
  max-width: 300px;
  min-width: 50px;
  height: 100%;
  max-height: 150px;
  min-height: 40px;
  border: 1px dashed #FF7C1D;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  margin: 1vw;
  padding: 1vw;
}

.dragDropBox:hover , .dragDropBox.dragover{
  opacity: 0.7;
}

.dragDropBox > p{
  color: #FF964A;
  font-family: 'Montserrat';
    font-size: 1vw;
    z-index: 2;
}

.dragDropBox > img{
  width: 20%;
  height: 20%;
  z-index: 2;
}

.fileUploaderLineOuter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3vh;
  padding-top: 1vh;
  margin-bottom: 4vh;
  border-bottom: 1px solid #e1e1e1;
}

.fileUploaderLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fileUploaderLineInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.fileUploaderLineName {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 0.95vw;
  margin-top: 0;
  margin-bottom: 0;
}

.fileUploaderLineSize {
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1vw;
}

.fileUploaderLineIcon {
  width: 17px;
  height: 17px;
  color: #18b7bb;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.fileUploaderLineIcon:hover {
  opacity: 0.6;
}

.fileUploaderLineProgressContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vh;
}

.fileUploaderLineProgress {
  height: 0;
  border-bottom-width: 3px;
  border-radius: 5px;
  border-style: solid;
  transition: width 0.3s ease-out, border-color 0.3s ease-out;
}

.fileUploaderLineProgressText {
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2vw;
  text-align: right;
  width: 5vw;
}

.fileUploaderFormattedTimestamp {
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85vw;
  margin-top: 1vh;
  margin-bottom: 0;
  margin-left: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  
  .fileUploaderLineOuter {
    padding-bottom: 2vh;
    padding-top: 1vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .fileUploaderLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
  }
  
  .fileUploaderLineName {
    font-family: 'Montserrat';
    font-weight: 700;
    color: #666;
    font-size: 1.7vw;
    margin-top: 0;
    margin-bottom: 0.7vh;
  }
  
  .fileUploaderLineSize {
    font-size: 1.5vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .fileUploaderFormattedTimestamp {
    font-size: 1.5vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  
  .fileUploaderLineIcon {
    width: 20px;
    height: 20px;
  }
  
  .fileUploaderLineIcon:hover {
    opacity: 1;
  }
  
  .fileUploaderLineProgressContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3vh;
  }
  
  .fileUploaderLineProgress {
    height: 0;
    border-width: 2px;
  }
  
  .fileUploaderLineProgressText {
    font-size: 1.4vw;
    margin-left: 3vw;
    width: auto;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  /* .dragDropBox{
    width: 90%;
    max-width: 300px;
    height: 150px;
  }
  
  .dragDropBox > img{
    width: 50px;
    height: 50px;
  } */

  .dragDropBox > p{
    font-size: 2.5vw;
  }
  
  .fileUploaderLineOuter {
    padding-bottom: 2vh;
    padding-top: 1vh;
    margin-bottom: 4vh;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .fileUploaderLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .fileUploaderLineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
  }
  
  .fileUploaderLineName {
    font-size: 3.5vw;
    margin-bottom: 1vh;
  }
  
  .fileUploaderLineSize {
    font-size: 3vw;
    margin-left: 0;
  }

  .fileUploaderFormattedTimestamp {
    font-size: 3vw;
    margin-left: 0;
  }
  
  .fileUploaderLineIcon {
    width: 20px;
    height: 20px;
  }
  
  .fileUploaderLineIcon:hover {
    opacity: 1;
  }
  
  .fileUploaderLineProgressContainer {
    width: 100%;
    margin-top: 2vh;
  }
  
  .fileUploaderLineProgress {
    height: 0;
    border-width: 2px;
  }
  
  .fileUploaderLineProgressText {
    font-size: 3vw;
    margin-left: 5vw;
    text-align: right;
    width: auto;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {

 

  .fileUploaderLineIcon {
    width: 11px;
    height: 11px;
    color: #18b7bb;
    transition: opacity 0.3s ease-out;
    cursor: pointer;
  }
  
  .fileUploaderLineIcon:hover {
    opacity: 1;
  }
}
.singleOrderActionButtonsContainer {
  width: calc(100% - 5vw);
  height: 5vh;
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10vh;
}

.revisionModalBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 200;
}

.revisionModalOuter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  max-height: 90%;
  overflow: auto;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.3);
  z-index: 2000;
  display: block;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.revisionModalOuter::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.revisionModalOuter {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.singleOrderTextButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleOrderRichTextButton {
  width: 15px;
  height: 15px;
  margin-right: 0.5vw;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
  background-color: rgba(255,255,255,0);
  padding: 3px;
  border-radius: 3px;
}

.singleOrderRichTextButton:hover {
  background-color: rgba(255,255,255,0.8);
  color: #666;
}

.singleOrderRevisionButtons {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleOrderRevisionButtonsSeparator {
  width: 0.5vw;
}

.confirmationModalOuter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: 35%;
  height: auto;
  max-height: 90%;
  overflow: auto;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.3);
  z-index: 2000;
  display: block;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.confirmationModalOuter::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.confirmationModalOuter {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.confirmationModalCheckContainer {
  width: 12vw;
  height: 12vw;
  border-radius: 6vw;
  background-color: #18b7bb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 6vh;
}

.confirmationModalCheck {
  width: 7.5vw;
  height: 7.5vw;
  color: #fff;
}

.confirmationModalTitle {
  align-self: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 2vw;
  color: #666;
  margin-top: 7vh;
  margin-bottom: 7vh;
}

.confirmationModalText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  line-height: 2vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 2vh;
}

.confirmationModalSubtitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  color: #18b7bb;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.confirmationModalCloseContainer {
  margin-top: 3vh;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleOrderReviewSeparator {
  height: 2vh;
}

.singleOrderReviewSelectContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vh;
}

.singleOrderReviewSliderContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
}

.singleOrderReviewSliderResult {
  margin-left: 2vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.singleOrderReviewSlider::-webkit-slider-thumb {
  background: #18b7bb;
}

.singleOrderReviewSlider::-moz-range-thumb {
  background: #18b7bb; /* Green background */
}

.singleOrderReviewMessage {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 5vh;
}

.singleOrderSubText {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 0.9vw;
  line-height: 2vw;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

.singleOrderHardCopySeparator {
  height: 3vh;
}

.checkboxContainer{
  padding: 0.5rem 0;
}

.checkboxWrap{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  cursor: default;
}



.nameText{
  margin-bottom: 1vh !important;
  line-height: 1.4 !important;
}

.contentWidth{
  width: 100% !important;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleOrderActionButtonsContainer {
    width: calc(100% - 3vw);
    height: 10vh;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5vh;
  }
  
  .revisionModalOuter {
    width: 80%;
    height: auto;
    max-height: 60%;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  
  .singleOrderRichTextButton:hover {
    background-color: rgba(255,255,255,0);
  }
  
  .singleOrderRevisionButtonsSeparator {
    width: 1vw;
  }

  .confirmationModalOuter {
    width: 60%;
    height: auto;
    max-height: 60%;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  
  .confirmationModalCheckContainer {
    width: 15vw;
    height: 15vw;
    border-radius: 7.5vw;
    margin-top: 2vh;
  }
  
  .confirmationModalCheck {
    width: 10vw;
    height: 10vw;
    color: #fff;
  }
  
  .confirmationModalTitle {
    font-size: 3vw;
    color: #666;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .confirmationModalText {
    font-size: 1.6vw;
    line-height: 3.6vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 1.5vh;
  }
  
  .confirmationModalSubtitle {
    font-size: 1.8vw;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }
  
  .confirmationModalCloseContainer {
    margin-top: 3vh;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .singleOrderReviewSelectContainer {
    width: 100%;
  }
  
  .singleOrderReviewSliderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5vh;
    width: 100%;
  }
  
  .singleOrderReviewSliderResult {
    margin-left: 2vw;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.8vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .singleOrderReviewSlider::-webkit-slider-thumb {
    background: #18b7bb;
  }
  
  .singleOrderReviewSlider::-moz-range-thumb {
    background: #18b7bb; /* Green background */
  }

  .singleOrderReviewMessage {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 1.6vw;
    line-height: 3vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 2vh;
  }

  .singleOrderSubText {
    font-size: 1.8vw;
    line-height: 3.7vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleOrderActionButtonsContainer {
    width: calc(100% - 3vw);
    height: 16vh;
    margin-top: 0;
    margin-bottom: 10vh;
  }
  
  .revisionModalOuter {
    width: 86%;
    height: auto;
    max-height: 85%;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 2vh;
    padding-top: 2vh;
  }

  .singleOrderRichTextButton:hover {
    background-color: rgba(255,255,255,0);
  }
  
  .singleOrderRevisionButtonsSeparator {
    width: 1vw;
  }

  .confirmationModalOuter {
    width: 85%;
    height: auto;
    max-height: 70vh;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 2vh;
    padding-top: 2vh;
  }
  
  .confirmationModalCheckContainer {
    width: 18vw;
    height: 18vw;
    border-radius: 9vw;
    margin-top: 2vh;
  }
  
  .confirmationModalCheck {
    width: 12vw;
    height: 12vw;
    color: #fff;
  }
  
  .confirmationModalTitle {
    font-size: 4.8vw;
    color: #666;
    margin-top: 3vh;
    margin-bottom: 4vh;
  }
  
  .confirmationModalText {
    font-size: 3.2vw;
    line-height: 7.5vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 2.5vh;
  }
  
  .confirmationModalSubtitle {
    font-size: 3.5vw;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }
  
  .confirmationModalCloseContainer {
    margin-top: 3vh;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .singleOrderReviewSlider {
    width: 100%;
  }

  .singleOrderReviewSliderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5vh;
    width: 100%;
  }
  
  .singleOrderReviewSliderResult {
    margin-left: 3vw;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 3.5vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .singleOrderReviewSlider::-webkit-slider-thumb {
    background: #18b7bb;
  }
  
  .singleOrderReviewSlider::-moz-range-thumb {
    background: #18b7bb; /* Green background */
  }

  .singleOrderReviewMessage {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 3.5vw;
    line-height: 7vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 2vh;
  }

  .singleOrderSubText {
    font-size: 3.5vw;
    line-height: 7.5vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  .singleOrderActionButtonsContainer {
    width: calc(100% - 3vw);
    height: 17vh;
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10vh;
  }
  
  .singleOrderRichTextButton {
    width: 11px;
    height: 11px;
  }

  .singleOrderRichTextButton:hover {
    background-color: rgba(255,255,255,0);
  }
}
.box{
    box-sizing: border-box;
    padding: 5rem 3rem;
}

.titleWrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 2rem 0;
}


.title{
    font-size: 1.75rem;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    font-family: 'Montserrat';
}

.section{
    flex:1 1;
    margin-bottom: 1rem;
}

.heading{
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    color: #000;
    font-family: 'Montserrat';
}

.text{
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    color: #000;
    overflow-wrap: break-word;
    white-space: normal;
    white-space: initial;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: 'Montserrat';
}

table{
    width: 100%;
    text-align: left;
    border-spacing: 0;
}



table thead{
    background-color: #808080;
}
table tbody{
    background-color: #fff;
}

th{
    color: #000;
    padding: 5px 0;
    font-size: 1rem;
    font-weight: 700;
    padding: 5px 10px;
    margin: 0;
    font-family: 'Montserrat';
}

tr td{
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    color: #000;
    padding: 5px 10px;
    font-family: 'Montserrat';
}

tbody tr td:nth-child(1){
    font-weight: 700;
}

.bg{
    border: 1px solid black;
}

.color{
    background-color: #808080;
    font-weight: 700;
}
.accountShippingLoading {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 1.1vw;
  line-height: 2.2vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingListContainer {
  width: 100%;
  height: auto;
  border-top: 2px solid #18b7bb;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: -2vh;
}

.accountShippingLine {
  width: 100%;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid rgba(102,102,102,0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.accountShippingLineInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Montserrat';
  font-weight: 300;
  color: #666;
  font-size: 0.9vw;
  line-height: 1.5vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingLineRecipient {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 1vw;
  line-height: 1.7vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingExtra {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.accountShippingDefaultContainer {
  margin-right: 2vw;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  color: rgba(102,102,102,1);
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 0;
  margin-bottom: 0;
}

.accountShippingDefaultIcon {
  width: 15px;
  height: 15px;
  color: #FF7C1D;
  margin-right: 0.5vw;
}

.accountShippingMoreContainer {
  width: auto;
  height: auto;
  position: relative;
}

.accountShippingMoreIcon {
  width: 20px;
  height: 20px;
  color: #666;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.accountShippingMoreIcon:hover {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

.accountShippingMoreMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  min-width: 13vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.1);
  border-radius: 6px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.accountShippingMoreContainer:hover .accountShippingMoreMenu {
  opacity: 1;
  pointer-events: all;
}

.accountShippingMoreMenuLink {
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  cursor: pointer;
  margin-left: 1.5vw;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.accountShippingMoreMenuLink:hover {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

.newAddressModalOuter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0,0,0,0.3);
}

.newAddressModal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 35vw;
  height: auto;
  min-height: 70vh;
  border-radius: 10px;
  padding-top: 6vh;
  padding-bottom: 6vh;
  padding-left: 3vw;
  padding-right: 0;
  background-color: #fff;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #e1e1e1;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  z-index: 250;
}

.newAddressModalCloseIcon {
  position: absolute;
  top: 2vh;
  right: 1.5vw;
  width: 25px;
  height: 25px;
  color: #333;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.newAddressModalCloseIcon:hover {
  opacity: 0.7;
}

.newAddressTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #666;
  font-size: 1.1vw;
  margin-top: 0;
  margin-bottom: 2vh;
}

.newAddressButtonContainer {
  width: auto;
  height: auto;
  align-self: flex-end;
  margin-top: 2vh;
  margin-right: 3vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .accountShippingLoading {
    font-size: 1.6vw;
    line-height: 2.8vw;
  }
  
  .accountShippingLine {
    width: 100%;
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-bottom: 1px solid rgba(102,102,102,0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .accountShippingLineInfo {
    font-size: 1.5vw;
    line-height: 2.5vw;
  }
  
  .accountShippingLineRecipient {
    font-size: 1.8vw;
    line-height: 2.8vw;
  }
  
  .accountShippingDefaultContainer {
    margin-right: 3vw;
    font-size: 1.8vw;
    line-height: 2.9vw;
  }
  
  .accountShippingDefaultIcon {
    margin-right: 1vw;
  }
  
  .accountShippingMoreIcon:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .accountShippingMoreMenu {
    min-width: 20vw;
  }
  
  .accountShippingMoreMenuLink {
    font-size: 1.8vw;
    line-height: 2.9vw;
    margin-left: 1.5vw;
  }
  
  .accountShippingMoreMenuLink:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .newAddressModalOuter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: rgba(0,0,0,0.3);
  }
  
  .newAddressModal {
    width: 80vw;
    min-height: 60vh;
  }
  
  .newAddressModalCloseIcon {
    right: 2.5vw;
  }
  
  .newAddressModalCloseIcon:hover {
    opacity: 1;
  }
  
  .newAddressTitle {
    font-size: 2vw;
    margin-bottom: 2.5vh;
  }
  
  .newAddressButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
    margin-right: 3vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .accountShippingLoading {
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .accountShippingLineInfo {
    font-size: 3.5vw;
    line-height: 7vw;
  }
  
  .accountShippingLineRecipient {
    font-size: 3.5vw;
    line-height: 6.5vw;
  }
  
  .accountShippingDefaultContainer {
    margin-right: 3vw;
    font-size: 3.5vw;
    line-height: 6.5vw;
  }
  
  .accountShippingDefaultIcon {
    margin-right: 2vw;
  }
  
  .accountShippingMoreMenu {
    min-width: 40vw;
  }
  
  .accountShippingMoreMenuLink {
    font-size: 3.5vw;
    line-height: 6.5vw;
    margin-left: 4vw;
  }
  
  .newAddressModal {
    width: 87vw;
    min-height: 60vh;
    padding-right: 3vw;
  }
  
  .newAddressModalCloseIcon {
    right: 3.5vw;
  }
  
  .newAddressModalCloseIcon:hover {
    opacity: 1;
  }
  
  .newAddressTitle {
    font-size: 4vw;
    margin-bottom: 2.5vh;
  }
  
  .newAddressButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
    margin-right: 3vw;
  }
}
.quoteContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.quoteHeader {
  width: 85vw;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #18b7bb;
}

.quoteLogo {
  height: 60%;
  width: auto;
  object-fit: cover;
  cursor: pointer;
}

.quoteHeaderExtra {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.quoteHeaderExtraBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  cursor: pointer;
}

.quoteHeaderIcon {
  width: 20px;
  height: 20px;
  color: #fff;
}

.quoteHeaderExtraBlockText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  font-weight: 400;
  color: #fff;
  margin-top: 1.2vh;
  margin-bottom: 0;
}

.quoteHeaderConnector {
  width: 5vw;
  height: 0;
  border-bottom: 0.5px solid #fff;
  opacity: 0.3;
  margin-left: 1.3vw;
  margin-right: 1.3vw;
}

.quoteMainContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.quoteMainPrimaryContainer {
  width: 60%;
  height: auto;
  min-height: 100vh;
}

.quotePrimaryInner {
  width: calc(100% - 7.5vw);
  margin-left: 7.5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.quotePrimaryTitle {
  font-family: 'Montserrat';
  font-size: 1.15vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 6vh;
  margin-bottom: 1.5vh;
}

.quotePrimarySub {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 1.4vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 5vh;
}

.quoteSignInButton {
  border-bottom: 1px solid #18b7bb;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.quoteSignInButton:hover {
  background-color: rgba(24, 183, 187, 0.2);
}

.quoteForgotPassword {
  align-self: flex-end;
  margin-right: 3vw;
  font-family: 'Montserrat';
  font-size: 0.8vw;
  font-weight: 400;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 5vh;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.quoteForgotPassword:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.quotePrimaryForm {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2vh;
}

.quotePrimaryFormElement {
  width: 45%;
  height: auto;
  margin-right: 4%;
}

.quotePrimaryCheckboxContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 2vh;
  margin-bottom: 4vh;
}

.quoteCheckbox {
  width: 1.2vw;
  height: 1.2vw;
  border-radius: 3px;
  margin-right: 1vw;
  border: 1px solid #666;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.quoteCheckboxText {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.quoteExtraMessage {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 1.6vw;
  font-weight: 400;
  color: #333;
  margin-top: 1vh;
  margin-bottom: 3vh;
  width: 85%;
}

.quoteFormButton {
  margin-right: 3vw;
  align-self: flex-end;
}

.quoteMainSecondaryContainer {
  width: 35%;
  height: auto;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.quoteSecondaryTitle {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 6vh;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quoteSecondarySub {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  line-height: 1.6vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 5vh;
  width: 90%;
}

.quoteSecondarySub a {
  color: #666;
  transition: color 0.3s ease-out;
}

.quoteSecondarySub a:hover {
  color: #333;
}

.quoteSecondaryLine {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3vh;
}

.quoteSecondaryLineText {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.2vw;
}

.quoteSecondaryExtraInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 3vh;
  width: calc(100% - 5vw);
}

.quoteSecondaryEncryptionImg {
  margin-top: 0vh;
  width: 30%;
  height: auto;
  object-fit: cover;
  margin-bottom: 8vh;
}

.quoteFooter {
  width: 100vw;
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  border-top: 1px solid rgba(102,102,102,0.3);
}

.quoteFooterLinksContainer {
  margin-right: 7.5vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2vh;
}

.quoteFooterLink {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5vw;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.quoteFooterLink:hover {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}

.quoteFooterLinkSeparator {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  margin-top: 0.5vh;
  background-color: rgba(102,102,102,0.2);
  margin-left: 0.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .quoteHeader {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
    height: 8vh;
  }
  
  .quoteLogo {
    height: 50%;
  }
  
  .quoteHeaderExtraBlockText {
    font-size: 1.4vw;
    margin-top: 0.8vh;
  }
  
  .quoteMainPrimaryContainer {
    width: 52%;
    height: auto;
    min-height: 60vh;
  }
  
  .quotePrimaryInner {
    width: calc(100% - 5vw);
    margin-left: 5vw;
    margin-top: 3vh;
  }
  
  .quotePrimaryTitle {
    font-size: 2.4vw;
    line-height: 3.5vw;
    margin-top: 2vh;
    margin-bottom: 1.3vh;
  }
  
  .quotePrimarySub {
    font-size: 1.7vw;
    line-height: 2.6vw;
    margin-bottom: 3vh;
    width: calc(100% - 5vw);
  }
  
  .quoteSignInButton:hover {
    background-color: transparent;
  }
  
  .quoteForgotPassword {
    margin-right: 5vw;
    font-size: 1.7vw;
    margin-top: 0vh;
    margin-bottom: 4vh;
  }
  
  .quoteForgotPassword:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .quotePrimaryForm {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2vh;
  }
  
  .quotePrimaryFormElement {
    width: calc(100% - 5vw);
    height: auto;
    margin-right: 5vw;
  }
  
  .quotePrimaryCheckboxContainer {
    margin-top: 1vh;
    margin-bottom: 4vh;
  }
  
  .quoteCheckbox {
    width: 2.4vw;
    height: 2.4vw;
    border-radius: 3px;
    margin-right: 2vw;
  }
  
  .quoteCheckboxText {
    font-size: 1.8vw;
  }
  
  .quoteExtraMessage {
    font-size: 1.6vw;
    line-height: 3.2vw;
    width: calc(100% - 5vw);
  }
  
  .quoteFormButton {
    margin-right: 5vw;
    align-self: flex-end;
  }
  
  .quoteMainSecondaryContainer {
    width: 47%;
    min-height: 70vh;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  
  .quoteSecondaryTitle {
    font-family: 'Montserrat';
    font-size: 1.8vw;
    font-weight: 700;
    color: #18b7bb;
    margin-top: 5vh;
    margin-bottom: 1.3vh;
  }
  
  .quoteSecondarySub {
    font-size: 1.7vw;
    line-height: 3.4vw;
    margin-bottom: 5vh;
    width: 100%;
  }
  
  .quoteSecondaryLine {
    margin-bottom: 2vh;
    width: calc(100% - 5vw);
  }
  
  .quoteSecondaryLineText {
    font-size: 1.8vw;
    margin-left: 1.2vw;
  }
  
  .quoteSecondaryExtraInfo {
    margin-top: 0vh;
  }
  
  .quoteSecondaryEncryptionImg {
    width: 40%;
  }
  
  .quoteFooterLinksContainer {
    margin-right: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2vh;
  }
  
  .quoteFooterLink {
    font-size: 1.8vw;
    line-height: 1.8vw;
    margin-left: 1vw;
  }
  
  .quoteFooterLink:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .quoteFooterLinkSeparator {
    margin-top: 0vh;
    margin-left: 1vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .quoteHeader {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
    height: 10vh;
  }
  
  .quoteLogo {
    height: 40%;
  }
  
  .quoteHeaderExtraBlockText {
    font-size: 3vw;
    margin-top: 0.7vh;
  }
  
  .quoteHeaderConnector {
    width: 7vw;
  }
  
  .quoteMainContainer {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .quoteMainPrimaryContainer {
    width: 100%;
    height: auto;
    min-height: auto;
    margin-bottom: 5vh;
  }
  
  .quotePrimaryInner {
    width: 90%;
    margin-left: 5vw;
  }
  
  .quotePrimaryTitle {
    font-family: 'Montserrat';
    font-size: 4.5vw;
    line-height: 8vw;
    font-weight: 700;
    color: #18b7bb;
    margin-top: 4vh;
    margin-bottom: 1.5vh;
  }
  
  .quotePrimarySub {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    line-height: 6vw;
    margin-bottom: 3vh;
  }
  
  .quoteSignInButton:hover {
    background-color: transparent;
  }
  
  .quoteForgotPassword {
    margin-right: 5vw;
    font-size: 3vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
  
  .quoteForgotPassword:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .quotePrimaryForm {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }
  
  .quotePrimaryFormElement {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 2vh;
  }
  
  .quotePrimaryCheckboxContainer {
    margin-top: 2vh;
    margin-bottom: 4vh;
  }
  
  .quoteCheckbox {
    width: 6vw;
    height: 6vw;
    border-radius: 3px;
    margin-right: 3vw;
    border: 1px solid rgba(102,102,102,0.7);
  }
  
  .quoteCheckboxText {
    font-size: 3.5vw;
  }
  
  .quoteExtraMessage {
    font-size: 3.2vw;
    line-height: 6.4vw;
    margin-top: 1vh;
    margin-bottom: 3vh;
    width: 100%;
  }
  
  .quoteFormButton {
    margin-right: 0;
    align-self: flex-end;
  }
  
  .quoteMainSecondaryContainer {
    width: 90%;
    height: auto;
    min-height: auto;
    background-color: transparent;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .quoteSecondaryTitle {
    font-size: 4vw;
    margin-top: 3vh;
    margin-bottom: 1.5vh;
  }
  
  .quoteSecondarySub {
    font-size: 3.5vw;
    line-height: 6vw;
    margin-bottom: 5vh;
    width: 100%;
  }
  
  .quoteSecondaryLine {
    margin-bottom: 3vh;
  }
  
  .quoteSecondaryLineText {
    font-size: 3.5vw;
    margin-left: 4vw;
  }
  
  .quoteSecondaryExtraInfo {
    margin-top: 3vh;
  }
  
  .quoteSecondaryEncryptionImg {
    margin-top: 0vh;
    margin-bottom: 4vh;
    width: 45%;
    height: auto;
    object-fit: cover;
  }
  
  .quoteFooter {
    width: 100vw;
    height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-top: 1px solid rgba(102,102,102,0.3);
  }
  
  .quoteFooterLinksContainer {
    margin-right: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3vh;
  }
  
  .quoteFooterLink {
    font-size: 3.5vw;
    line-height: 3.5vw;
    margin-left: 2vw;
  }
  
  .quoteFooterLink:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .quoteFooterLinkSeparator {
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    margin-top: 0vh;
    background-color: rgba(102,102,102,0.2);
    margin-left: 2vw;
  }
}

@media (orientation: landscape) and (max-width: 1024px) {
  .quoteHeaderIcon {
    width: 12px;
    height: 12px;
    color: #fff;
  }
}
.quotePrimaryOptions {
  width: calc(100% - 3vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vh;
}

.quotePrimaryOption {
  width: 48%;
  height: 18vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #666;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.quotePrimaryOption:hover {
  opacity: 0.8;
}

.quotePrimaryOptionTitle {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1vh;
  width: 80%;
}

.quotePrimaryOptionText {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.4vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  width: 80%;
}

.quoteSmallMessage {
  margin-bottom: 6vh;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.4vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  width: 80%;
}

.quoteDetailsInputsContainer {
  width: calc(100% - 3vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vh;
}

.quoteDetailsSelectContainer {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quoteDetailsSelect {
  width: 100%;
  height: 5vh;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #666;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 2vw;
  font-weight: 400;
  color: #666;
}

.quoteDetailsSelect:focus {
  outline: none;
}

.hardCopyLineSeparator {
  height: 2vh;
}

.hardCopyCheckboxLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 1vh;
}

.hardCopyCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid rgba(102,102,102,0.7);
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hardCopyCheck {
  width: 15px;
  height: 15px;
  color: #18b7bb;
  transition: opacity 0.3s ease-out;
}

.hardCopyCheckboxText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  width: 80%;
}

.quoteDetailsFileUploaderContainer {
  margin-top: 3vh;
  width: calc(100% - 3vw);
}

.quoteOptionalContainer {
  width: calc(100% - 7vw);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-right: 2vw;
  padding-left: 2vw;
  border: 2px solid rgba(102,102,102,0.1);
  transition: border-color 0.3s ease-out;
  border-radius: 10px;
  margin-top: 3vh;
}

.quoteOptionalTitleRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.quoteOptionalTitleStatus {
  font-family: 'Montserrat';
  font-size: 0.8vw;
  font-weight: 400;
  color: #fff;
  padding: 0.5vh 1vw 0.5vh 1vw;
  border-radius: 5px;
  background-color: #FF7C1D;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  cursor: pointer;
}

.quoteRichTextButtons {
  margin-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
}

.quoteRichTextButton {
  width: 17px;
  height: 17px;
  padding: 0.3vw;
  border-radius: 3px;
  color: #666;
  margin-right: 1vw;
  background-color: rgba(255, 124, 29, 0);
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.quoteRichTextButton:hover {
  background-color: rgba(255, 124, 29, 1);
  color: #fff;
}

.quoteRichTextEditor {
  margin-bottom: 3vh;
  width: calc(100% - 3vw);
  min-height: 20vh;
  border: 1px solid rgba(102,102,102,0.2);
  border-radius: 10px;
}

.DraftEditor-root {
  min-height: 20vh;
  padding: 2vh 1vw 2vh 1vw;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: #666;
  line-height: 2vw;
}

.quoteSubmitButtonContainer {
  width: auto;
  height: auto;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 3vh;
  margin-bottom: 10vh;
  margin-right: 3vw;
}

.quoteGoBackButton {
  margin-right: 0.8vw;
  width: 2.8vw;
  height: 2.8vw;
  border-radius: 1.4vw;
  border: 1px solid #FF7C1D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.quoteGoBackIcon {
  width: 1.3vw;
  height: 1.3vw;
  color: #FF7C1D;
}

.quoteGoBackButton:hover {
  opacity: 0.5;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .quotePrimaryOptions {
    width: calc(100% - 5vw);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 0;
  }
  
  .quotePrimaryOption {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
  }
  
  .quotePrimaryOption:hover {
    opacity: 1;
  }
  
  .quotePrimaryOptionTitle {
    font-size: 2vw;
    width: 90%;
  }
  
  .quotePrimaryOptionText {
    font-size: 1.7vw;
    line-height: 3vw;
    width: 90%;
  }
  
  .quoteSmallMessage {
    margin-bottom: 3vh;
    font-size: 1.6vw;
    line-height: 3.2vw;
    width: calc(100% - 5vw);
  }
  
  .quoteDetailsInputsContainer {
    width: calc(100% - 5vw);
    margin-bottom: 3vh;
  }
  
  .quoteDetailsSelectContainer {
    width: 46%;
  }
  
  .quoteDetailsSelect {
    height: 4vh;
    font-size: 1.6vw;
    line-height: 3.2vw;
  }

  .hardCopyLineSeparator {
    height: 2vh;
  }
  
  .hardCopyCheckbox {
    margin-right: 2vw;
  }
  
  .hardCopyCheckboxText {
    font-size: 1.8vw;
    line-height: 3vw;
  }
  
  .quoteDetailsFileUploaderContainer {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: calc(100% - 5vw);
  }
  
  .quoteOptionalContainer {
    width: calc(100% - 9vw);
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-right: 2vw;
    padding-left: 2vw;
    border: 2px solid rgba(102,102,102,0.1);
    transition: border-color 0.3s ease-out;
    border-radius: 10px;
    margin-top: 1vh;
    margin-bottom: 2vh;
  }
  
  .quoteOptionalTitleStatus {
    font-size: 1.4vw;
  }
  
  .quoteRichTextButtons {
    margin-bottom: 1.5vh;
  }
  
  .quoteRichTextButton {
    width: 20px;
    height: 20px;
    margin-right: 2vw;
  }
  
  .quoteRichTextButton:hover {
    background-color: transparent;
    color: #666;
  }
  
  .quoteRichTextEditor {
    margin-bottom: 0;
    width: calc(100% - 5vw);
    min-height: 15vh;
  }
  
  .DraftEditor-root {
    min-height: 15vh;
    padding: 1vh 2vw 1vh 2vw;
    font-size: 1.7vw;
    line-height: 3.4vw;
  }
  
  .quoteSubmitButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 3vh;
    margin-bottom: 6vh;
    margin-right: 5vw;
  }

  .quoteGoBackButton {
    margin-right: 2vw;
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
  }
  
  .quoteGoBackIcon {
    width: 2.5vw;
    height: 2.5vw;
    color: #FF7C1D;
  }
  
  .quoteGoBackButton:hover {
    opacity: 1;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .quotePrimaryOptions {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3vh;
  }
  
  .quotePrimaryOption {
    width: 100%;
    height: 16vh;
  }
  
  .quotePrimaryOption:hover {
    opacity: 1;
  }
  
  .quotePrimaryOptionTitle {
    font-size: 4vw;
    margin-bottom: 1vh;
    width: 90%;
  }
  
  .quotePrimaryOptionText {
    font-size: 3vw;
    line-height: 6vw;
    width: 90%;
  }
  
  .quoteSmallMessage {
    margin-bottom: 3vh;
    font-size: 3vw;
    line-height: 6vw;
    margin-top: 0;
    width: 100%;
  }
  
  .quoteDetailsInputsContainer {
    width: 100%;
    margin-bottom: 2vh;
  }
  
  .quoteDetailsSelectContainer {
    width: 45%;
  }
  
  .quoteDetailsSelect {
    font-size: 3vw;
    line-height: 5vw;
  }

  .hardCopyLineSeparator {
    height: 2.5vh;
  }

  .hardCopyCheckboxLine {
    margin-top: 2vh;
  }
  
  .hardCopyCheckbox {
    margin-right: 2vw;
  }
  
  .hardCopyCheckboxText {
    font-size: 3.2vw;
    line-height: 5vw;
  }
  
  .quoteDetailsFileUploaderContainer {
    margin-top: 1vh;
    width: 100%;
  }
  
  .quoteOptionalContainer {
    width: calc(100% - 8vw);
    padding-right: 4vw;
    padding-left: 4vw;
    margin-top: 2vh;
  }
  
  .quoteOptionalTitleRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
  }
  
  .quoteOptionalTitleStatus {
    font-size: 3vw;
    padding: 0.6vh 4vw 0.6vh 4vw;
  }
  
  .quoteRichTextButtons {
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
  }
  
  .quoteRichTextButton {
    width: 20px;
    height: 20px;
    margin-right: 3vw;
  }
  
  .quoteRichTextEditor {
    margin-bottom: 3vh;
    width: 100%;
    min-height: 20vh;
  }
  
  .DraftEditor-root {
    border: 1px solid rgba(102,102,102,0.2);
    min-height: 20vh;
    padding: 1.5vh 4vw 1.5vh 4vw;
    font-size: 3.2vw;
    line-height: 6.4vw;
  }
  
  .quoteSubmitButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-right: 0;
  }

  .quoteGoBackButton {
    margin-right: 3.5vw;
    width: 12vw;
    height: 12vw;
    border-radius: 6vw;
  }
  
  .quoteGoBackIcon {
    width: 6vw;
    height: 6vw;
    color: #FF7C1D;
  }
  
  .quoteGoBackButton:hover {
    opacity: 1;
  }
} 
.orderHeaderConnector {
  width: 2vw;
  height: 0;
  border-bottom: 0.5px solid #fff;
  opacity: 0.3;
  margin-left: 1vw;
  margin-right: 1vw;
}

@media (orientation: portrait) and (max-width: 767px) {
  .orderHeaderConnector {
    width: 2vw;
    height: 0;
    border-bottom: 0.5px solid #fff;
    opacity: 0.3;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }
}
.orderSecondaryIcon {
  width: 17px;
  height: 17px;
  color: #18b7bb;
  margin-right: 0.7vw;
}

.orderSecondaryTitle {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  font-weight: 700;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orderCountContainer {
  width: calc(100% - 3vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
}

.orderCountInputContainer {
  width: 35%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orderCountWarning {
  width: calc(100% - 5vw);
  height: auto;
  background-color: #f5f5f5;
  padding: 2vh 0 2vh 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 1vw;
  line-height: 1.8vw;
  font-weight: 400;
  color: #666;
  margin-top: 2vh;
  border-left: 5px solid #FF7C1D;
}

.orderCountAuxText {
  width: 60%;
  font-family: 'Montserrat';
  font-size: 0.9vw;
  line-height: 1.6vw;
  font-weight: 300;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.orderSummary {
  width: calc(100% - 5vw);
  height: auto;
  margin-top: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 6vh;
}

.orderSummaryTitle {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 4vh;
}

.orderSummaryInfo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 3vh;
}

.orderSummaryInfoMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.orderSummaryInfoType {
  font-family: 'Montserrat';
  font-size: 1.1vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 1vh;
}

.orderSummaryInfoCount {
  font-family: 'Montserrat';
  font-size: 0.9vw;
  font-weight: 300;
  color: rgba(102,102,102,0.5);
  margin-top: 0;
  margin-bottom: 0;
  width: 15vw;
}

.orderSummaryTotal {
  border-top: 1px solid rgba(102,102,102,0.8);
  width: 100%;
  height: auto;
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 1.3vw;
  font-weight: 900;
  color: #666;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .orderSecondaryIcon {
    margin-right: 1.2vw;
  }

  .orderSecondaryTitle {
    font-size: 1.9vw;
    margin-bottom: 1vh;
  }
  
  .orderCountContainer {
    width: calc(100% - 3vw);
    margin-top: 1vh;
  }
  
  .orderCountInputContainer {
    width: 35%;
  }
  
  .orderCountWarning {
    width: calc(100% - 7vw);
    padding: 1vh 0 1vh 2vw;
    font-size: 1.7vw;
    line-height: 3.2vw;
    margin-top: 1vh;
  }
  
  .orderCountAuxText {
    width: 60%;
    font-size: 1.4vw;
    line-height: 2.8vw;
    text-align: right;
  }
  
  .orderSummary {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  
  .orderSummaryTitle {
    font-size: 2vw;
    margin-bottom: 3vh;
  }
  
  .orderSummaryInfo {
    padding-bottom: 2vh;
  }
  
  .orderSummaryInfoType {
    font-size: 2.2vw;
    margin-bottom: 1vh;
  }
  
  .orderSummaryInfoCount {
    font-family: 'Montserrat';
    font-size: 1.7vw;
    width: 20vw;
  }
  
  .orderSummaryTotal {
    margin-top: 1vh;
    font-size: 2.4vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .orderSecondaryIcon {
    margin-right: 2vw;
  }
  
  .orderSecondaryTitle {
    font-size: 3.3vw;
    margin-bottom: 2vh;
  }
  
  .orderCountContainer {
    width: 100%;
    margin-top: 2vh;
  }
  
  .orderCountInputContainer {
    width: 35%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .orderCountWarning {
    width: calc(100% - 2vw);
    padding: 2vh 0 2vh 2vw;
    font-size: 3.3vw;
    line-height: 6vw;
    margin-top: 2vh;
  }
  
  .orderCountAuxText {
    font-size: 3vw;
    line-height: 5vw;
  }
  
  .orderSummary {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 6vh;
  }
  
  .orderSummaryTitle {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    font-weight: 400;
    color: #18b7bb;
    margin-top: 0;
    margin-bottom: 4vh;
  }
  
  .orderSummaryInfo {
    width: 100%;
    padding-bottom: 3vh;
  }
  
  .orderSummaryInfoType {
    font-size: 4.5vw;
    margin-bottom: 1vh;
  }
  
  .orderSummaryInfoCount {
    font-size: 3.3vw;
    width: 60vw;
  }
  
  .orderSummaryTotal {
    border-top: 1px solid rgba(102,102,102,0.8);
    width: 100%;
    height: auto;
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 4.5vw;
    font-weight: 900;
    color: #666;
  }
}
.orderOptionalExtraContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.orderOptionalPrice {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .orderOptionalExtraContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    margin-top: 1.5vh;
  }
  
  .orderOptionalPrice {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.8vw;
    color: #18b7bb;
    margin-top: 1vh;
    margin-bottom: 0;
    margin-right: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .orderOptionalExtraContainer {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    margin-top: 1.5vh;
  }
  
  .orderOptionalPrice {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 3.5vw;
    color: #18b7bb;
    margin-top: 1.5vh;
    margin-bottom: 0;
    margin-right: 0;
  }
}
.checkoutStatusOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  padding-left: 25vw;
  padding-right: 25vw;
  z-index: 1000000000008;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #fff;
  font-size: 1.8vw;
  background-color: rgba(19, 20, 20, 0.9);
}

.orderPaymentTripleLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orderPaymentTripleField {
  width: 31%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.orderPaymentPoweredBy {
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.orderPaymentPoweredByText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
}

.orderPaymentPoweredByLogo {
  height: 4vh;
  width: auto;
  object-fit: cover;
}

.orderAddressSelector {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.orderAddressOptionalInner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.orderAddressBigIcon {
  width: 30px;
  height: 30px;
  color: #18b7bb;
  margin-right: 1.5vw;
}

.orderAddressInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.orderAddressInfoTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.1vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 1vh;
}

.orderAddressInfoText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.orderAddressOptionalInnerForm {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.orderAddressOptionalNewAddressIcon {
  width: 30px;
  height: 30px;
  color: #18b7bb;
  margin-right: 1.5vw;
}

.orderAddressOptionalNewAddress {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #18b7bb;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}

.paypal-buttons {
  width: 25vw !important;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .checkoutStatusOverlay {
    width: 70vw;
    padding-left: 15vw;
    padding-right: 15vw;
    font-size: 3vw;
    line-height: 4.5vw;
  }

  .orderPaymentTripleField {
    width: 32%;
  }
  
  .orderPaymentPoweredBy {
    margin-top: 0.7vh;
  }
  
  .orderPaymentPoweredByText {
    font-size: 1.4vw;
    margin-right: 1vw;
  }
  
  .orderPaymentPoweredByLogo {
    height: 3.3vh;
  }
  
  .orderAddressBigIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 3vw;
  }
  
  .orderAddressInfo {
    width: 70%;
  }
  
  .orderAddressInfoTitle {
    font-size: 2vw;
    margin-bottom: 0.7vh;
  }
  
  .orderAddressInfoText {
    font-size: 1.6vw;
    line-height: 3.2vw;
  }
  
  .orderAddressOptionalNewAddressIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 3vw;
  }
  
  .orderAddressOptionalNewAddress {
    font-size: 2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .checkoutStatusOverlay {
    width: 80vw;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 5vw;
    line-height: 8vw;
  }

  .orderPaymentPoweredBy {
    margin-top: 0.7vh;
  }
  
  .orderPaymentPoweredByText {
    font-size: 3vw;
    margin-right: 2.5vw;
  }
  
  .orderPaymentPoweredByLogo {
    height: 5vh;
  }

  .orderAddressBigIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 5vw;
  }
  
  .orderAddressInfo {
    width: 70%;
  }
  
  .orderAddressInfoTitle {
    font-size: 4vw;
    margin-bottom: 0.7vh;
  }
  
  .orderAddressInfoText {
    font-size: 3.3vw;
    line-height: 5.8vw;
  }
  
  .orderAddressOptionalNewAddressIcon {
    width: 30px;
    height: 30px;
    color: #18b7bb;
    margin-right: 5vw;
  }
  
  .orderAddressOptionalNewAddress {
    font-size: 4vw;
  }

  .paypal-buttons {
    min-width: 70vw !important;
  }
}
.usersFunctionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.userLanguageFilterContainer {
  width: 15vw;
  margin-right: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.userNameFilter {
  color: #333;
  background-color: transparent;
  border-bottom: 1px solid rgba(102, 102, 102, 1);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 0.9vw;
  margin-top: 0;
  margin-bottom: 0;
  height: 2.3vw;
  width: 100%;
  padding-left: 0;
}

.userNameFilter:focus {
  outline: none;
}

.usersHeaderColumn {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.usersLineColumn {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.usersIDColumn {
  width: 20%;
}

.usersNameColumn {
  width: 20%;
}

.usersRoleColumn {
  width: 10%;
}

.usersLanguageColumn {
  width: 15%;
}

.usersRateColumn {
  width: 8%;
}

.usersStatusColumn {
  width: 10%;
}

.usersDeleteColumn {
  width: 5%;
  justify-content: center;
}

.usersExtraColumn {
  width: 4%;
  justify-content: center;
  overflow: visible;
  position: relative;
}

.usersExtraOverlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #085f63;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  width: auto;
  height: auto;
  padding-right: 4vw;
  padding-left: 1vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 400;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.usersExtraColumn:hover .usersExtraOverlay {
  opacity: 1;
  pointer-events: all;
}

.usersExtraText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  color: #fff;
  margin-top: 0.7vh;
  margin-bottom: 0.7vh;
}

.userModalUnderlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.3);
}

.userModal {
  z-index: 1000;
  position: fixed;
  top: 45%;
  left: 50%;
  width: 40vw;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-right: 1vw;
  padding-left: 1vw;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 2px 2px 20px rgba(0,0,0,0.1);
}

.userModalTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.4vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 3vh;
}

.userModalLine {
  width: 100%;
  margin-bottom: 1vh;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.userModalHalf {
  width: 46%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.userModalHalfText {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.userModalLanguageLine {
  margin-top: 2vh;
}

.userModalButtonContainer {
  width: auto;
  height: auto;
  align-self: flex-end;
  margin-top: 3vh;
}

.userModalRoleSelect {
  margin-bottom: 1vh;
}

.usersDeleteButton {
  width: 1.1vw;
  height: 1.1vw;
  color: #f44336;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.usersDeleteButton:hover {
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
}

.loadMoreUsersButton {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  color: #333;
  margin-top: 3vh;
  margin-bottom: 2vh;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  align-self: flex-start;
  margin-left: 2%;
}

.loadMoreUsersButton:hover {
  opacity: 0.7;
}

.userStatusSelect {
  width: 80%;
  height: 3vh;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #666;
  font-family: 'Montserrat';
  font-size: 0.8vw;
  line-height: 2vw;
  font-weight: 400;
  color: #666;
}

.userStatusSelect:focus {
  outline: none;
}

.userStatusText {
  padding-left: 0.3vw;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .userLanguageFilterContainer {
    width: auto;
    margin-right: 4vw;
  }
  
  .userNameFilter {
    font-size: 1.6vw;
    line-height: 1.6vw;
    height: 5vw;
  }
  
  .usersHeaderColumn {
    font-size: 1.7vw;
  }
  
  .usersLineColumn {
    font-size: 1.8vw;
  }
  
  .usersIDColumn {
    width: 35%;
  }
  
  .usersNameColumn {
    width: 27%;
  }
  
  .usersRoleColumn {
    width: 13%;
  }
  
  .usersLanguageColumn {
    display: none;
  }
  
  .usersRateColumn {
    display: none;
  }
  
  .usersStatusColumn {
    width: 15%;
  }
  
  .usersDeleteColumn {
    width: 5%;
    justify-content: center;
  }
  
  .usersExtraColumn {
    width: 4%;
    justify-content: center;
    overflow: visible;
    position: relative;
  }
  
  .usersExtraOverlay {
    border-radius: 3px;
    padding-right: 4vw;
    padding-left: 1vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }
  
  .usersExtraText {
    font-size: 1.5vw;
  }
  
  .userModal {
    z-index: 1000;
    position: absolute;
    top: 37%;
    left: 50%;
    width: 60vw;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-right: 4vw;
    padding-left: 4vw;
  }

  .userModalLine {
    margin-bottom: 0.7vh;
  }
  
  .userModalLanguageLine {
    margin-top: 1vh;
  }
  
  .userModalButtonContainer {
    margin-top: 2vh;
  }
  
  .usersDeleteButton {
    width: 2.1vw;
    height: 2.1vw;
    color: #f44336;
    cursor: pointer;
    transition: -webkit-filter 0.3s ease-out;
    transition: filter 0.3s ease-out;
    transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
  }
  
  .usersDeleteButton:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .loadMoreUsersButton {
    font-size: 1.8vw;
  }
  
  .loadMoreUsersButton:hover {
    opacity: 1;
  }
  
  .userStatusSelect {
    width: 80%;
    height: 3vh;
    font-size: 1.7vw;
    line-height: 3.2vw;
  }
  
  .userStatusSelect:focus {
    outline: none;
  }
  
  .userStatusText {
    padding-left: 0.3vw;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .userLanguageFilterContainer {
    display: none;
  }
  
  .usersHeaderColumn {
    font-size: 3vw;
  }
  
  .usersLineColumn {
    font-size: 3vw;
  }
  
  .usersIDColumn {
    width: 65%;
  }
  
  .usersNameColumn {
    display: none;
  }
  
  .usersRoleColumn {
    display: none;
  }
  
  .usersLanguageColumn {
    display: none;
  }
  
  .usersRateColumn {
    display: none;
  }
  
  .usersStatusColumn {
    width: 30%;
  }
  
  .usersDeleteColumn {
    width: 25%;
    display: none;
  }

  .userNameFilter {
    font-size: 8px;
    line-height: 1.6vw;
    height: 5vw;
  }
  
  .usersExtraColumn {
    display: none;
  }
  
  .usersExtraOverlay {
    display: none;
  }
  
  .userModal {
    z-index: 1000;
    position: absolute;
    top: 37%;
    left: 50%;
    width: 85vw;
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  
  .userModalLine {
    width: 100%;
    margin-bottom: 0.5vh;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .userModalLanguageLine {
    margin-top: 1vh;
  }
  
  .userModalButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 2vh;
  }
  
  .userModalRoleSelect {
    margin-bottom: 1vh;
  }

  .loadMoreUsersButton {
    font-size: 3.5vw;
  }
  
  .userStatusSelect {
    width: 100%;
    height: 3vh;
    font-size: 3vw;
    line-height: 5vw;
  }
  
  .userStatusSelect:focus {
    outline: none;
  }
  
  .userStatusText {
    padding-left: 0.3vw;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 3vw;
  }

  .usersDeleteButton {
    width: 15px;
    height: 15px;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .userLanguageFilterContainer {
    width: auto;
  }
  
  .usersIDColumn {
    width: 22%;
  }
  
  .usersNameColumn {
    width: 15%;
  }
  
  .usersRoleColumn {
    width: 10%;
  }
  
  .usersLanguageColumn {
    width: 10%;
  }
  
  .usersRateColumn {
    width: 8%;
  }
  
  .usersStatusColumn {
    width: 10%;
  }
  
  .usersDeleteColumn {
    width: 5%;
    justify-content: center;
  }
  
  .usersExtraColumn {
    width: 4%;
    justify-content: center;
    overflow: visible;
    position: relative;
  }
  
  .userModalLine {
    width: 100%;
    margin-bottom: 1.5vh;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .userModalLanguageLine {
    margin-top: 3vh;
  }
  
  .userModalButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 3vh;
  }
  
  .userModalRoleSelect {
    margin-bottom: 2vh;
  }
  
  .usersDeleteButton {
    width: 12px;
    height: 12px;
  }
  
  .usersDeleteButton:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .loadMoreUsersButton:hover {
    opacity: 1;
  }

  .userStatusSelect {
    width: 80%;
    height: 5vh;
    font-size: 0.8vw;
    line-height: 2vw;
  }
}
.translationsContainer {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.translationsInnerContainer {
  width: 96%;
  margin-top: 3vh;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  margin-bottom: 7vh;
}

.translationsTitleRow {
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5vh;
}

.translationsTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.6vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.newOrderButton {
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 0.7vw;
  background-color: #FF7C1D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: 0.7vw;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.newOrderButton:hover {
  opacity: 0.7;
}

.newOrderIcon {
  width: 1vw;
  height: 1vw;
  color: #fff;
}

.translationsTableHeader {
  margin-top: 3vh;
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid #e1e1e1;
}

.translationsTableLine {
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  border-bottom: 1px solid rgba(225,225,225,0.5);
}

.loadingTranslations {
  width: 96%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333;
  font-size: 1.5vw;
}

.translationsTimerColumn {
  width: 3%;
}

.translationsOrderColumn {
  width: 12%;
}

.translationsInvoiceColumn {
  width: 12%;
}

.translationsLanguageColumn {
  width: 9%;
}

.translationsDueColumn {
  width: 20%;
}

.translationsTranslatorColumn {
  width: 14%;
}

.translationsStatusColumn {
  width: 10%;
}

.translationsColumnIcon {
  width: 15px;
  height: 15px;
  color: #666;
}

.translationsLineColumnIcon {
  color: #FF7C1D;
  transition: opacity 0.3s ease-out;
}

.translationsLineColumnIcon:hover {
  opacity: 0.8 !important;
}

.translationsStatusContainer {
  width: 80%;
  height: auto;
  padding-top: 0.7vh;
  padding-bottom: 0.7vh;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.6vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.userFunctionsCheckboxContainer {
  width: auto;
  height: 2.3vw;
  margin-right: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}

.userFunctionsCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
}

.userFunctionsCheck {
  width: 15px;
  height: 15px;
  color: #666;
  transition: opacity 0.3s ease-out;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .translationsInnerContainer {
    width: 96%;
    margin-top: 2vh;
    min-height: 80vh;
  }
  
  .translationsTitleRow {
    width: 96%;
    margin-top: 1.5vh;
  }
  
  .translationsTitle {
    font-size: 2.2vw;
  }
  
  .newOrderButton {
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 1.25vw;
    margin-left: 2vw;
  }
  
  .newOrderButton:hover {
    opacity: 1;
  }
  
  .newOrderIcon {
    width: 1.5vw;
    height: 1.5vw;
    color: #fff;
  }
  
  .loadingTranslations {
    font-size: 2vw;
  }
  
  .translationsTimerColumn {
    width: 3%;
  }
  
  .translationsOrderColumn {
    width: 20%;
  }
  
  .translationsInvoiceColumn {
    width: 20%;
  }
  
  .translationsLanguageColumn {
    display: none;
  }
  
  .translationsDueColumn {
    width: 24%;
  }
  
  .translationsTranslatorColumn {
    display: none;
  }
  
  .translationsStatusColumn {
    width: 15%;
  }
  
  .translationsColumnIcon {
    width: 2vw;
    height: 2vw;
    color: #666;
  }
  
  .translationsLineColumnIcon {
    color: #FF7C1D;
    transition: opacity 0.3s ease-out;
  }
  
  .translationsLineColumnIcon:hover {
    opacity: 0.8 !important;
  }
  
  .translationsStatusContainer {
    width: 80%;
    height: auto;
    padding-top: 0.7vh;
    padding-bottom: 0.7vh;
    font-size: 1.2vw;
  }
  
  .userFunctionsCheckboxContainer {
    width: auto;
    height: 5vw;
    margin-right: 4vw;
    font-size: 1.5vw;
    line-height: 1.5vw;
  }
  
  .userFunctionsCheckbox {
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 3px;
    margin-right: 1.5vw;
  }
  
  .userFunctionsCheck {
    width: 1.5vw;
    height: 1.5vw;
    color: #666;
    transition: opacity 0.3s ease-out;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .translationsInnerContainer {
    width: 96%;
    margin-top: 2vh;
    min-height: 80vh;
  }
  
  .translationsTitleRow {
    width: 90%;
    margin-top: 2.5vh;
  }
  
  .translationsTitle {
    font-size: 5vw;
  }
  
  .newOrderButton {
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
    margin-left: 3vw;
  }
  
  .newOrderButton:hover {
    opacity: 1;
  }
  
  .newOrderIcon {
    width: 3vw;
    height: 3vw;
    color: #fff;
  }
  
  .loadingTranslations {
    font-size: 4vw;
  }

  .translationsTableHeader {
    margin-top: 2vh;
    width: 90%;
  }
  
  .translationsTableLine {
    width: 90%;
  }
  
  .translationsTimerColumn {
    display: none;
  }
  
  .translationsOrderColumn {
    width: 32%;
  }
  
  .translationsInvoiceColumn {
    display: none;
  }
  
  .translationsDueColumn {
    width: 38%;
  }
  
  .translationsStatusColumn {
    width: 20%;
  }

  .translationsColumnIcon {
    width: 3vw;
    height: 3vw;
    color: #666;
  }
  
  .translationsStatusContainer {
    width: 100%;
    height: auto;
    padding-top: 0.7vh;
    padding-bottom: 0.7vh;
    font-size: 2vw;
  }
  
  .userFunctionsCheckboxContainer {
    display: none;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .newOrderButton {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 0.7vw;
    margin-left: 1vw;
  }
  
  .newOrderButton:hover {
    opacity: 1;
  }
  
  .newOrderIcon {
    width: 1vw;
    height: 1vw;
    color: #fff;
  }
  
  .loadingTranslations {
    font-size: 1.1vw;
  }

  .translationsTableHeader {
    margin-top: 3vh;
    width: 96%;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
  
  .translationsTableLine {
    width: 96%;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
  }
  
  .translationsTimerColumn {
    width: 3%;
  }
  
  .translationsOrderColumn {
    width: 12%;
  }
  
  .translationsInvoiceColumn {
    width: 12%;
  }
  
  .translationsLanguageColumn {
    width: 9%;
  }
  
  .translationsDueColumn {
    width: 20%;
  }
  
  .translationsTranslatorColumn {
    width: 14%;
  }
  
  .translationsStatusColumn {
    width: 10%;
  }
  
  .translationsColumnIcon {
    width: 1vw;
    height: 1vw;
    color: #666;
  }
  
  .translationsLineColumnIcon {
    color: #FF7C1D;
    transition: opacity 0.3s ease-out;
  }
  
  .translationsLineColumnIcon:hover {
    opacity: 0.8 !important;
  }
  
  .translationsStatusContainer {
    width: 80%;
    height: auto;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.6vw;
    border-radius: 2.5px;
  }
  
  .userFunctionsCheckboxContainer {
    width: auto;
    height: 5vw;
    margin-right: 3vw;
  }
  
  .userFunctionsCheckbox {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 3px;
    margin-right: 1vw;
  }
  
  .userFunctionsCheck {
    width: 0.9vw;
    height: 0.9vw;
    color: #666;
    transition: opacity 0.3s ease-out;
  }
}
.formInputContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .formInputLabel {
    margin-bottom: 1vh;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    line-height: 1vw;
    color: #666;
  }
  
  .formInput {
    color: #666;
    background-color: transparent;
    border-bottom: 1px solid rgba(102, 102, 102, 0.7);
    border-right: none;
    border-top: none;
    border-left: none;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    line-height: 1vw;
    margin-top: 0;
    margin-bottom: 1vh;
    height: 2vw;
    width: 100%;
    padding-left: 0;
  }
  
  .formInput::-webkit-input-placeholder {
    color: rgba(102, 102, 102, 0.8);
  }
  
  .formInput:-ms-input-placeholder {
    color: rgba(102, 102, 102, 0.8);
  }
  
  .formInput::-ms-input-placeholder {
    color: rgba(102, 102, 102, 0.8);
  }
  
  .formInput::placeholder {
    color: rgba(102, 102, 102, 0.8);
  }
  
  .formInput:focus {
    outline: none;
  }
  
  @media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {
  
    .formInputLabel {
      margin-bottom: 1vh;
      font-size: 1.8vw;
      line-height: 1.8vw;
    }
    
    .formInput {
      font-size: 2vw;
      line-height: 2vw;
      height: 4vw;
    }
  }
  
  @media (orientation: portrait) and (max-width: 767px) {
  
    .formInputLabel {
      margin-bottom: 1vh;
      font-size: 2.7vw;
      line-height: 2.7vw;
    }
    
    .formInput {
      font-size: 3.6vw;
      line-height: 3.6vw;
      height: 8vw;
    }
  }



.quotesNumberColumn {
  width: 20%;
}

.quotesLanguageColumn {
  width: 15%;
}

.quotesSubmittedColumn {
  width: 20%;
}

.quotesTypeColumn {
  width: 15%;
}

.quotesStatusColumn {
  width: 15%;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .quotesNumberColumn {
    width: 25%;
  }
  
  .quotesLanguageColumn {
    display: none;
  }
  
  .quotesSubmittedColumn {
    width: 25%;
  }
  
  .quotesTypeColumn {
    width: 15%;
  }
  
  .quotesStatusColumn {
    width: 15%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .quotesNumberColumn {
    width: 32%;
  }
  
  .quotesLanguageColumn {
    display: none;
  }
  
  .quotesSubmittedColumn {
    width: 37%;
  }
  
  .quotesTypeColumn {
    display: none;
  }
  
  .quotesStatusColumn {
    width: 20%;
  }
}
.invoicesInvoiceColumn {
  width: 12%;
}

.invoicesOrderColumn {
  width: 12%;
}

.invoicesPaymentColumn {
  width: 12%;
}

.invoicesFromColumn {
  width: 9%;
}

.invoicesFromColumn p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.invoicesToColumn {
  width: 9%;
}

.invoicesToColumn p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.invoicesWordsColumn {
  width: 5%;
}

.invoicesRateColumn {
  width: 5%;
}

.invoicesSubmittedColumn {
  width: 9%;
}

.invoicesTranslatorColumn {
  width: 13%;
}

.invoicesStatusColumn {
  width: 8%;
}

.invoicesTotalColumn {
  width: 5%;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .invoicesInvoiceColumn {
    width: 19%;
  }
  
  .invoicesOrderColumn {
    display: none;
  }
  
  .invoicesPaymentColumn {
    width: 19%;
  }
  
  .invoicesFromColumn {
    display: none;
  }
  
  .invoicesFromColumn p {
    display: none;
  }
  
  .invoicesToColumn {
    display: none;
  }
  
  .invoicesToColumn p {
    display: none;
  }
  
  .invoicesWordsColumn {
    display: none;
  }
  
  .invoicesRateColumn {
    display: none;
  }
  
  .invoicesSubmittedColumn {
    width: 16%;
  }
  
  .invoicesTranslatorColumn {
    width: 20%;
  }
  
  .invoicesStatusColumn {
    width: 14%;
  }
  
  .invoicesTotalColumn {
    width: 7%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .invoicesInvoiceColumn {
    width: 32%;
  }
  
  .invoicesPaymentColumn {
    display: none;
  }
  
  .invoicesSubmittedColumn {
    width: 22%;
  }
  
  .invoicesTranslatorColumn {
    display: none;
  }
  
  .invoicesStatusColumn {
    width: 20%;
  }
  
  .invoicesTotalColumn {
    width: 15%;
  }
}
.paymentsNumberColumn {
  width: 20%;
}

.paymentsInvoicesColumn {
  width: 8%;
}

.paymentsWordsColumn {
  width: 8%;
}

.paymentsMethodColumn {
  width: 10%;
}

.paymentsPaidColumn {
  width: 13%;
}

.paymentsTranslatorColumn {
  width: 15%;
}

.paymentsStatusColumn {
  width: 10%;
}

.paymentsTotalColumn {
  width: 6%;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .paymentsNumberColumn {
    width: 22%;
  }
  
  .paymentsInvoicesColumn {
    display: none;
  }
  
  .paymentsWordsColumn {
    display: none;
  }
  
  .paymentsMethodColumn {
    display: none;
  }
  
  .paymentsPaidColumn {
    width: 13%;
  }
  
  .paymentsTranslatorColumn {
    width: 22%;
  }
  
  .paymentsStatusColumn {
    width: 15%;
  }
  
  .paymentsTotalColumn {
    width: 8%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .paymentsNumberColumn {
    width: 35%;
  }
  
  .paymentsPaidColumn {
    display: none;
  }
  
  .paymentsTranslatorColumn {
    display: none;
  }
  
  .paymentsStatusColumn {
    width: 30%;
  }
  
  .paymentsTotalColumn {
    width: 15%;
  }
}
.clientsOrderColumn {
  width: 12%;
}

.clientsNameColumn {
  width: 21%;
}

.clientsRatingColumn {
  width: 7%;
}

.clientsCommentColumn {
  width: 27%;
}

.clientsCommentColumn p {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clientsIconColumn {
  width: 5%;
}

.clientsStatusColumn {
  width: 10%;
}

.clientsTotalColumn {
  width: 8%;
  overflow: visible;
  position: relative;
}

.clientsExtraOverlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #085f63;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  width: auto;
  height: auto;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 1.5vh;
  padding-bottom: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 400;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.clientsTotalColumn:hover .clientsExtraOverlay {
  opacity: 1;
  pointer-events: all;
}

.clientsExtraText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.8vw;
  color: #fff;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-right: 2vw;
}

.clientSummaryTotal {
  border-top: 1px solid rgba(225,225,225,0.3);
  width: 100%;
  height: auto;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #fff;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .clientsOrderColumn {
    width: 20%;
  }
  
  .clientsNameColumn {
    width: 25%;
  }
  
  .clientsRatingColumn {
    width: 9%;
  }
  
  .clientsCommentColumn {
    display: none;
  }

  .clientsCommentColumn p {
    display: none;
  }
  
  .clientsIconColumn {
    width: 5%;
  }
  
  .clientsStatusColumn {
    width: 15%;
  }
  
  .clientsTotalColumn {
    width: 10%;
    overflow: visible;
    position: relative;
  }
  
  .clientsExtraText {
    font-size: 1.5vw;
    margin-top: 0.3vh;
    margin-bottom: 0.3vh;
    margin-right: 2vw;
  }
  
  .clientSummaryTotal {
    font-size: 1.8vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .clientsOrderColumn {
    width: 35%;
  }
  
  .clientsNameColumn {
    width: 45%;
  }
  
  .clientsRatingColumn {
    display: none;
  }
  
  .clientsIconColumn {
    display: none;
  }
  
  .clientsStatusColumn {
    display: none;
  }
  
  .clientsTotalColumn {
    width: 15%;
    overflow: visible;
    position: relative;
  }

  .clientsExtraOverlay {
    padding-right: 4vw;
    padding-left: 4vw;
    padding-top: 1.5vh;
    padding-bottom: 0vh;
  }
  
  .clientsExtraText {
    font-size: 3vw;
    margin-top: 0.2vh;
    margin-bottom: 0.2vh;
    margin-right: 2vw;
  }
  
  .clientSummaryTotal {
    font-size: 3.5vw;
  }
}
.singleTranslationContainer {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-bottom: 5vh;
}

.singleTranslationHeader {
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(102,102,102,0.2);
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.singleTranslationOrderNumber {
  margin-left: 2vw;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #333;
  font-size: 1.6vw;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationTabsControl {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2vw;
}

.singleTranslationTabButton {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.5vw;
  padding-bottom: 0.5vh;
  border-bottom: 2px solid rgba(255, 124, 29, 0);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.singleTranslationTabButton:hover {
  opacity: 0.7;
}

.singleTranslationDecideContainer {
  width: 94%;
  padding: 2.5vh 2% 2.5vh 2%;
  height: auto;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  margin-top: 2vh;
}

.singleTranslationDecideTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333;
  font-size: 1.1vw;
  margin-top: 0;
  margin-bottom: 2vh;
}

.singleTranslationDecideMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationWrapper{
  width: 45%;
  min-height: 37vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.singleTranslationDecideInfoContainer {
  width: 54%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationDecideInfoLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #666;
}

.singleTranslationDecideInfoTitle {
  width: 35%;
}

.singleTranslationDecideInfoText {
  width: 65%;
}

.singleTranslationDecideInfoSeparator {
  width: 100%;
  height: 0;
  border-bottom: 1px solid rgba(102,102,102,0.15);
}

.singleTranslationDecideExtraContainer {
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.singleTranslationDecideExtraText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #FF7C1D;
  padding: 1vh 2.5vw 1vh 2.5vw;
  border-radius: 10px;
}

.singleTranslationDecideExtraSecondary {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #333;
  margin-top: 1.5vh;
  margin-bottom: 0;
}

.singleTranslationDecideExtraButton {
  width: auto;
  min-width: 40%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 3vw 1.5vh 3vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 2vh;
  border-radius: 7px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s ease-out;
}

.singleTranslationDecideExtraButton:hover {
  opacity: 0.7;
}

.singleTranslatoionWrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 4vw;
  gap: 4vw;
}

.cancelBtn{
  margin-top: 3vh;
  background-color: rgb(225, 80, 74);
  outline: none;
  padding: 0.5vh 1.5vw;
  font-size: 0.85vw;
  color: #fff;
  font-weight: 600;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}
.cancelBtn:hover{
  transform: scale(1.05);
  transition: transform 0.1s;
}

.singleTranslationDecideTurnaround {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.85vw;
  color: #666;
  margin-top: 3vh;
}

.singleTranslationDecideTurnaroundIcon {
  width: 15px;
  height: 15px;
  margin-right: 0.5vw;
}

.singleTranslationDetailsOuter {
  width: 96%;
  padding: 2.5vh 2% 2.5vh 2%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.singleTranslationDetailsMain {
  width: 65%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationDetailsDates {
  width: calc(100% - 2vw);
  height: auto;
  min-height: 30vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2vh 1vw 2vh 1vw;
}

.singleTranslationDetailsComments {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

.singleTranslationDetailsSecondary {
  width: calc(32% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 40vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesOuter {
  width: 96%;
  padding: 2.5vh 2% 2.5vh 2%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesMain {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 35vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesSecondary {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3vh;
}

.singleTranslationFilesUpload {
  width: calc(49% - 2vw);
  padding: 2vh 1vw 0 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationFilesCoversheet {
  width: calc(49% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationInvoiceMain {
  width: calc(65% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationInvoiceTitleLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleTranslationInvoiceEditButton {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 0.5vh 1vw 0.5vh 1vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.8vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singleTranslationInvoiceEditButton:hover {
  opacity: 0.6;
}

.singleTranslationInvoiceSecondaryOuter {
  width: 32%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleTranslationInvoiceSecondary {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 15vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

.singleTranslationDatesProgressContainer {
  width: 40%;
  margin-right: 3%;
  height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.singleTranslationDatesProgressText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #333;
  z-index: 100;
  margin-top: 0;
  margin-bottom: 0;
}

.progress-ring__circle {
  transition: stroke-dashoffset 1s ease-out;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.topCircle {
  z-index: 100;
}

.singleTranslationDetailsSecondaryText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  line-height: 1.6vw;
  color: #666;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.singleTranslationStatusContainer {
  width: auto;
  height: auto;
  padding: 0.8vh 1vw 0.8vh 1vw;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.6vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 1vh;
}

.singleTranslationDetailsExtraButton {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 0 1.5vh 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1vw;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 0;
  border-radius: 7px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s ease-out;
}

.singleTranslationDetailsExtraButton:hover {
  opacity: 0.7;
}

.singleTranslationNotesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1vh;
}

.singleTranslationNotesTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9vw;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 0;
  width: calc(100% - 1vw);
  border: 1px solid rgba(102,102,102,0.15);
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 1vw;
  background-color: #1e7e81;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleTranslationNotesAuxButton {
  border: 1px solid #fff;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 0.5vh 1vw 0.5vh 1vw;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.8vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  margin-left: 0.5vw;
  margin-right: 1vw;
}

.singleTranslationNotesAuxButton:hover {
  opacity: 0.8;
}

.singleTranslationRichTextEditor {
  margin-bottom: 3vh;
  margin-top: 0;
  width: 100%;
  min-height: 20vh;
  border: 1px solid rgba(102,102,102,0.15);
}

.singleTranslationFilesUploadText {
  margin-top: 0;
  margin-bottom: 2vh;
}

.singleTranslationDetailsSecondarySeparator {
  margin-top: 2vh;
}

.singleTranslationFilesTableHeader {
  width: 100%;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e1e1e1;
}

.singleTranslationNoLines {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}

.singleTranslationFilesTableHeaderColumn {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationFilesTableHeaderNameColumn {
  width: 80%;
}

.singleTranslationFilesTableHeaderCertificateColumn {
  width: 20%;
  margin-left: 2vw;
}

.singleTranslationFilesTableHeaderNameColumnAlt {
  width: 70%;
}

.singleTranslationFilesTableHeaderTypeColumn {
  width: 15%;
}

.singleTranslationFilesTableHeaderButtonColumn {
  width: 5%;
}

.singleTranslationFilesTableLine {
  width: 100%;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}

.singleTranslationFilesTableLineColumn {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationFilesTableLineTextColumn {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleTranslationFilesTableLineText {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  line-height: 1.8vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singleTranslationFilesTableLineText:hover {
  opacity: 0.7;
}

.coversheetInput {
  color: #333;
  background-color: transparent;
  border-bottom: 1px solid rgba(102, 102, 102, 1);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  line-height: 0.9vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 2.3vw;
  width: 100%;
  padding-left: 0;
}

.coversheetInput:focus {
  outline: none;
}

.singleTranslationInvoiceTotals {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.singleTranslationInvoiceAddLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singleTranslationInvoiceAddLineContainer:hover {
  opacity: 0.6;
}

.singleTranslationInvoiceAddLineText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #FF7C1D;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationAddLineIcon {
  width: 17px;
  height: 17px;
  margin-right: 0.6vw;
  color: #FF7C1D;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.singleTranslationAddLineIcon:hover {
  opacity: 0.6;
}

.singleTranslationInvoiceTotalsInner {
  width: 50%;
  height: 6vh;
  padding: 2vh 1vw 2vh 1vw;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: space-between;
}

.singleTranslationInvoiceTotalsTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 65%;
}

.singleTranslationInvoiceTotalsTextTitle {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationInvoiceTotalsText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 35%;
}

.singleTranslationInvoiceMemo {
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.singleTranslationInvoiceMemoTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.singleTranslationInvoiceMemoTextArea {
  color: #333;
  background-color: transparent;
  border: 1px solid rgba(102, 102, 102, 0.3);
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.85vw;
  line-height: 1.6vw;
  border-radius: 5px;
  margin-top: 1vh;
  margin-bottom: 0;
  height: 15vh;
  width: 100%;
  padding: 5px;
  resize: none;
}

.singleTranslationInvoiceMemoTextArea::-webkit-input-placeholder {
  color: rgba(102, 102, 102, 1);
}

.singleTranslationInvoiceMemoTextArea:-ms-input-placeholder {
  color: rgba(102, 102, 102, 1);
}

.singleTranslationInvoiceMemoTextArea::-ms-input-placeholder {
  color: rgba(102, 102, 102, 1);
}

.singleTranslationInvoiceMemoTextArea::placeholder {
  color: rgba(102, 102, 102, 1);
}

.singleTranslationInvoiceMemoTextArea:focus {
  outline: none;
}

.singleTranslationInvoiceButtons {
  width: auto;
  align-self: flex-end;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleTranslationInvoiceButtonsSeparator {
  width: 0.7vw;
}

.singleTranslationInvoiceReviewText {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.singleTranslationInvoiceReviewMemoArea {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: calc(100% - 1vw);
}

.singleTranslationDiscountInputContainer {
  width: 50%;
}

.notarizationStatusSelect {
  width: 100%;
  height: 3vh;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #666;
  font-family: 'Montserrat';
  font-size: 0.85vw;
  line-height: 2vw;
  font-weight: 400;
  color: #666;
  margin-bottom: 2vh;
}

.notarizationStatusSelect:focus {
  outline: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleTranslationHeader {
    width: 98%;
    padding-left: 1%;
    padding-right: 1%;
  }
  
  .singleTranslationOrderNumber {
    margin-left: 2vw;
    font-size: 2.2vw;
  }
  
  .singleTranslationTabButton {
    font-size: 1.6vw;
    margin-left: 2.2vw;
    padding-bottom: 0.5vh;
    border-bottom: 2px solid rgba(255, 124, 29, 0);
  }
  
  .singleTranslationTabButton:hover {
    opacity: 1;
  }

  .singleTranslationDecideContainer {
    width: 89%;
    padding: 2.5vh 3% 2.5vh 3%;
  }
  
  .singleTranslationDecideTitle {
    font-size: 2vw;
    margin-bottom: 0;
  }

  .singleTranslationDecideMainContainer {
    margin-top: 2vh;
  }

  .singleTranslationDecideInfoContainer {
    width: 60%;
  }
  
  .singleTranslationDecideInfoLine {
    font-weight: 500;
    font-size: 1.7vw;
    color: #666;
  }
  
  .singleTranslationDecideInfoTitle {
    width: 40%;
  }
  
  .singleTranslationDecideInfoText {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .singleTranslationDecideExtraContainer {
    width: 40%;
  }
  
  .singleTranslationDecideExtraText {
    font-size: 1.7vw;
    padding: 0.7vh 2.5vw 0.7vh 2.5vw;
    border-radius: 6px;
  }
  
  .singleTranslationDecideExtraSecondary {
    font-size: 1.5vw;
    margin-top: 1.2vh;
  }
  
  .singleTranslationDecideExtraButton {
    min-width: 50%;
  }
  
  .singleTranslationDecideExtraButton:hover {
    opacity: 1;
  }

  .cancelBtn{
    font-size: 1.6vw;
    margin-top: 2.5vh;
    padding: 0.7vh 2.5vw 0.7vh 2.5vw;
  }
  
  .singleTranslationDecideTurnaround {
    font-size: 1.6vw;
    margin-top: 2.5vh;
  }
  
  .singleTranslationDecideTurnaroundIcon {
    width: 2vw;
    height: 2vw;
    margin-right: 1.2vw;
  }
  
  .singleTranslationDetailsOuter {
    width: 95%;
    padding: 2.5vh 2% 2.5vh 2%;
  }
  
  .singleTranslationDetailsMain {
    width: 55%;
  }
  
  .singleTranslationDetailsDates {
    width: calc(100% - 4vw);
    min-height: 0;
    padding: 2vh 2vw 2vh 2vw;
  }
  
  .singleTranslationDetailsComments {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
  }
  
  .singleTranslationDetailsSecondary {
    width: calc(42% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesOuter {
    width: 95%;
  }
  
  .singleTranslationFilesMain {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesUpload {
    width: calc(49% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesCoversheet {
    width: calc(49% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationInvoiceMain {
    width: calc(62% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
    background-color: #fff;
  }
  
  .singleTranslationInvoiceTitleLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .singleTranslationInvoiceEditButton {
    font-size: 1.6vw;
    margin-bottom: 2vh;
  }
  
  .singleTranslationInvoiceEditButton:hover {
    opacity: 1;
  }
  
  .singleTranslationInvoiceSecondaryOuter {
    width: 35%;
  }
  
  .singleTranslationInvoiceSecondary {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDatesProgressContainer {
    display: none;
  }
  
  .singleTranslationDetailsSecondaryText {
    font-size: 1.6vw;
    line-height: 3vw;
    margin-top: 2vh;
    margin-bottom: 0.5vh;
  }
  
  .singleTranslationStatusContainer {
    padding: 0.8vh 2vw 0.8vh 2vw;
    font-size: 1.3vw;
    margin-top: 2vh;
  }
  
  .singleTranslationNotesContainer {
    margin-bottom: 0;
    margin-top: 2vh;
  }
  
  .singleTranslationNotesTitle {
    font-size: 1.5vw;
    margin-top: 1vh;
    margin-bottom: 0;
    width: calc(100% - 1vw);
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
  }
  
  .singleTranslationNotesAuxButton {
    padding: 0.5vh 1vw 0.5vh 1vw;
    font-size: 1.4vw;
  }
  
  .singleTranslationNotesAuxButton:hover {
    opacity: 1;
  }
  
  .singleTranslationFilesUploadText {
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .singleTranslationDetailsSecondarySeparator {
    margin-top: 1vh;
  }
  
  .singleTranslationFilesTableHeader {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationNoLines {
    font-size: 1.6vw;
  }
  
  .singleTranslationFilesTableHeaderColumn {
    font-size: 1.6vw;
  }
  
  .singleTranslationFilesTableHeaderNameColumn {
    width: 80%;
  }

  .singleTranslationFilesTableHeaderCertificateColumn {
    display: none;
  }
  
  .singleTranslationFilesTableHeaderNameColumnAlt {
    width: 70%;
  }
  
  .singleTranslationFilesTableHeaderTypeColumn {
    width: 15%;
  }
  
  .singleTranslationFilesTableHeaderButtonColumn {
    width: 5%;
  }
  
  .singleTranslationFilesTableLine {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationFilesTableLineColumn {
    font-size: 1.6vw;
  }
  
  .singleTranslationFilesTableLineText {
    font-size: 1.6vw;
    line-height: 3vw;
  }
  
  .singleTranslationFilesTableLineText:hover {
    opacity: 1;
  }
  
  .coversheetInput {
    font-size: 1.6vw;
    line-height: 1.6vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 4.6vw;
  }
  
  .singleTranslationInvoiceAddLineContainer:hover {
    opacity: 1;
  }
  
  .singleTranslationInvoiceAddLineText {
    font-size: 1.6vw;
  }
  
  .singleTranslationAddLineIcon {
    width: 2vw;
    height: 2vw;
    margin-right: 0.8vw;
  }
  
  .singleTranslationAddLineIcon:hover {
    opacity: 1;
  }
  
  .singleTranslationInvoiceTotalsInner {
    width: 50%;
    height: 4vh;
    padding: 1.5vh 1vw 1.5vh 1vw;
  }
  
  .singleTranslationInvoiceTotalsTextTitle {
    font-size: 1.6vw;
  }
  
  .singleTranslationInvoiceMemo {
    width: 70%;
    margin-top: 2vh;
    margin-bottom: 1vh;
  }
  
  .singleTranslationInvoiceMemoTitle {
    font-size: 1.7vw;
  }
  
  .singleTranslationInvoiceMemoTextArea {
    font-size: 1.6vw;
    line-height: 3vw;
    border-radius: 5px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    height: 12vh;
  }
  
  .singleTranslationInvoiceButtonsSeparator {
    width: 1.5vw;
  }
  
  .singleTranslationInvoiceReviewText {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .singleTranslationInvoiceReviewMemoArea {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: calc(100% - 1vw);
  }

  .singleTranslationDiscountInputContainer {
    width: 80%;
  }

  .notarizationStatusSelect {
    width: 100%;
    height: 3vh;
    font-size: 1.9vw;
    line-height: 3.2vw;
    margin-bottom: 0.5vh;
  }
  
  .notarizationStatusSelect:focus {
    outline: none;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .singleTranslationWrapper{
    min-height: 10vh;
  }

  .singleTranslationHeader {
    width: 98%;
    padding-left: 1%;
    padding-right: 1%;
  }
  
  .singleTranslationOrderNumber {
    margin-left: 2vw;
    font-size: 4.2vw;
  }
  
  .singleTranslationTabButton {
    font-size: 3.2vw;
    margin-left: 4.3vw;
    padding-bottom: 0.5vh;
  }

  .singleTranslationDecideContainer {
    width: 89%;
    padding: 2.5vh 3% 2.5vh 3%;
  }
  
  .singleTranslationDecideTitle {
    font-size: 4vw;
    margin-bottom: 1vh;
  }

  .singleTranslationDecideMainContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 1vh;
  }

  .singleTranslationDecideInfoContainer {
    width: 100%;
  }
  
  .singleTranslationDecideInfoLine {
    font-weight: 500;
    font-size: 3.5vw;
    color: #666;
  }
  
  .singleTranslationDecideInfoTitle {
    width: 40%;
  }
  
  .singleTranslationDecideInfoText {
    width: 60%;
  }
  
  .singleTranslationDecideExtraContainer {
    width: 100%;
    margin-top: 3vh;
  }
  
  .singleTranslationDecideExtraText {
    font-size: 3vw;
    padding: 0.7vh 5vw 0.7vh 5vw;
    border-radius: 6px;
  }
  
  .singleTranslationDecideExtraSecondary {
    font-size: 3.2vw;
    margin-top: 1vh;
  }
  
  .singleTranslationDecideExtraButton {
    min-width: 50%;
  }
  
  .singleTranslationDecideExtraButton:hover {
    opacity: 1;
  }

  .cancelBtn{
    font-size: 3.5vw;
    margin-top: 4vh;
    padding: 0.7vh 5vw 0.7vh 5vw;
  }
  
  .singleTranslationDecideTurnaround {
    font-size: 3.5vw;
    margin-top: 4vh;
  }
  
  .singleTranslationDecideTurnaroundIcon {
    width: 4vw;
    height: 4vw;
    margin-right: 1.8vw;
  }
  
  .singleTranslationDetailsOuter {
    width: 95%;
    padding: 2.5vh 2% 2.5vh 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .singleTranslationDetailsMain {
    width: 100%;
  }
  
  .singleTranslationDetailsDates {
    width: calc(100% - 6vw);
    min-height: 0;
    padding: 2vh 3vw 2vh 3vw;
  }
  
  .singleTranslationDetailsComments {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDetailsSecondary {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationFilesOuter {
    width: 95%;
  }
  
  .singleTranslationFilesMain {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
  }

  .singleTranslationFilesSecondary {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3vh;
  }
  
  .singleTranslationFilesUpload {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationFilesCoversheet {
    width: calc(100% - 4vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
  }
  
  .singleTranslationInvoiceMain {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
    background-color: #fff;
    margin-bottom: 3vh;
  }
  
  .singleTranslationInvoiceEditButton {
    font-size: 3.5vw;
    padding: 0.5vh 3vw 0.5vh 3vw;
    margin-bottom: 2vh;
  }

  .singleTranslationInvoiceSecondaryOuter {
    width: 100%;
  }
  
  .singleTranslationInvoiceSecondary {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
    height: auto;
    min-height: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDetailsSecondaryText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-top: 2vh;
    margin-bottom: 0.5vh;
  }
  
  .singleTranslationStatusContainer {
    padding: 0.8vh 4vw 0.8vh 4vw;
    font-size: 2.5vw;
    margin-top: 2vh;
  }
  
  .singleTranslationNotesContainer {
    margin-bottom: 0;
  }
  
  .singleTranslationNotesTitle {
    font-size: 3.5vw;
    margin-top: 1vh;
    margin-bottom: 0;
    width: calc(100% - 2vw);
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vw;
  }
  
  .singleTranslationNotesAuxButton {
    padding: 0.5vh 3vw 0.5vh 3vw;
    font-size: 3vw;
    margin-right: 2vw;
  }
  
  .singleTranslationFilesUploadText {
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .singleTranslationDetailsSecondarySeparator {
    margin-top: 1vh;
  }
  
  .singleTranslationFilesTableHeader {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationNoLines {
    font-size: 3.5vw;
  }
  
  .singleTranslationFilesTableHeaderColumn {
    font-size: 3.5vw;
  }
  
  .singleTranslationFilesTableHeaderNameColumn {
    width: 100%;
  }
  
  .singleTranslationFilesTableHeaderNameColumnAlt {
    width: 70%;
  }
  
  .singleTranslationFilesTableHeaderTypeColumn {
    width: 15%;
  }
  
  .singleTranslationFilesTableHeaderButtonColumn {
    width: 5%;
  }
  
  .singleTranslationFilesTableLine {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  
  .singleTranslationFilesTableLineColumn {
    font-size: 3.2vw;
  }
  
  .singleTranslationFilesTableLineText {
    font-size: 3.2vw;
    line-height: 6vw;
  }
  
  .coversheetInput {
    font-size: 3.5vw;
    line-height: 3.5vw;
    margin-top: 0;
    margin-bottom: 3vh;
    height: 9.2vw;
  }
  
  .singleTranslationInvoiceAddLineText {
    font-size: 3.5vw;
  }
  
  .singleTranslationAddLineIcon {
    width: 5vw;
    height: 5vw;
    margin-right: 1.2vw;
  }
  
  .singleTranslationInvoiceTotalsInner {
    width: 60%;
    height: 6vh;
    padding: 1.5vh 1vw 1.5vh 1vw;
  }
  
  .singleTranslationInvoiceTotalsTextTitle {
    font-size: 3.5vw;
  }
  
  .singleTranslationInvoiceMemo {
    width: 95%;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }
  
  .singleTranslationInvoiceMemoTitle {
    font-size: 3.6vw;
  }
  
  .singleTranslationInvoiceMemoTextArea {
    font-size: 3.5vw;
    line-height: 7vw;
    border-radius: 5px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    height: 18vh;
  }
  
  .singleTranslationInvoiceButtonsSeparator {
    width: 1.5vw;
  }
  
  .singleTranslationInvoiceReviewText {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .singleTranslationInvoiceReviewMemoArea {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: calc(100% - 4vw);
  }

  .singleTranslationDiscountSubmitButtonContainer {
    align-self: flex-end;
    margin-bottom: 3vh;
  }

  .notarizationStatusSelect {
    width: 100%;
    height: 3vh;
    font-size: 3.5vw;
    line-height: 5vw;
  }
  
  .notarizationStatusSelect:focus {
    outline: none;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .singleTranslationOrderNumber {
    font-size: 1.2vw;
  }
  
  .singleTranslationTabButton:hover {
    opacity: 1;
  }
  
  .singleTranslationDecideTitle {
    font-family: 'Montserrat';
    font-weight: 700;
    color: #333;
    font-size: 1.1vw;
    margin-top: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDecideInfoContainer {
    width: 54%;
  }
  
  .singleTranslationDecideExtraText {
    font-size: 1.1vw;
    padding: 1.2vh 2.5vw 1.2vh 2.5vw;
    border-radius: 5px;
  }

  .singleTranslationDecideExtraSecondary {
    margin-top: 2.5vh;
  }
  
  .singleTranslationDecideExtraButton:hover {
    opacity: 1;
  }
  
  .singleTranslationDecideTurnaroundIcon {
    width: 11px;
    height: 11px;
    margin-right: 0.5vw;
  }
  
  
  .singleTranslationFilesMain {
    padding: 2vh 1vw 4vh 1vw;
  }
  
  .singleTranslationFilesUpload {
    padding: 2vh 1vw 4vh 1vw;
  }
  
  .singleTranslationFilesCoversheet {
    padding: 2vh 1vw 4vh 1vw;
  }
  
  .singleTranslationInvoiceEditButton {
    border: 1px solid #e1e1e1;
    border-radius: 3px;
  }
  
  .singleTranslationInvoiceEditButton:hover {
    opacity: 1;
  }
  
  .singleTranslationDatesProgressContainer {
    display: none;
  }
  
  .singleTranslationStatusContainer {
    border-radius: 2px;
  }
  
  .singleTranslationNotesAuxButton:hover {
    opacity: 1;
  }
  
  .singleTranslationRichTextEditor {
    margin-bottom: 3vh;
    margin-top: 0;
    width: 100%;
    min-height: 30vh;
    border: 1px solid rgba(102,102,102,0.15);
  }
  
  .singleTranslationFilesUploadText {
    margin-top: 0;
    margin-bottom: 3vh;
  }
  
  .singleTranslationDetailsSecondarySeparator {
    margin-top: 3vh;
  }
  
  .singleTranslationFilesTableLineText:hover {
    opacity: 1;
  }
  
  .coversheetInput {
    margin-bottom: 3vh;
  }
  
  .singleTranslationInvoiceAddLineContainer:hover {
    opacity: 1;
  }
  
  
  .singleTranslationAddLineIcon {
    width: 11px;
    height: 11px;
  }
  
  .singleTranslationAddLineIcon:hover {
    opacity: 1;
  }

  .notarizationStatusSelect {
    width: 100%;
    height: 5vh;
    font-size: 0.9vw;
    line-height: 2vw;
  }
}
.singleRevisionDetailsSecondary {
  width: 32%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleRevisionInformation {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleRevisionDetailsSecondary {
    width: 42%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .singleRevisionInformation {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 20vh;
    margin-bottom: 3vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleRevisionDetailsSecondary {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .singleRevisionInformation {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 20vh;
    margin-bottom: 3vh;
  }
}
.singlePaymentInformation {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 5vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

.singlePaymentMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singlePaymentTableHeader {
  margin-top: 1vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid #e1e1e1;
}

.singlePaymentTableLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  border-bottom: 1px solid rgba(225,225,225,0.5);
}

.singlePaymentNumberColumn {
  width: 19%;
}

.singlePaymentSubmittedColumn {
  width: 19%;
}

.singlePaymentWordsColumn {
  width: 9%;
}

.singlePaymentRateColumn {
  width: 9%;
}

.singlePaymentTotalColumn {
  width: 9%;
}

.singlePaymentStatusColumn {
  width: 14%;
}

.singlePaymentIconColumn {
  width: 4%;
}

.singlePaymentDecideTitleContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
}

.singlePaymentDecideTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333;
  font-size: 1.1vw;
  margin-top: 0;
  margin-bottom: 0;
}

.singlePaymentClickIcon {
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.singlePaymentClickIcon:hover {
  opacity: 0.6;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singlePaymentInformation {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
  }

  .singlePaymentNumberColumn {
    width: 38%;
  }
  
  .singlePaymentSubmittedColumn {
    display: none;
  }
  
  .singlePaymentWordsColumn {
    display: none;
  }
  
  .singlePaymentRateColumn {
    display: none;
  }
  
  .singlePaymentTotalColumn {
    width: 16%;
  }
  
  .singlePaymentStatusColumn {
    width: 27%;
  }
  
  .singlePaymentIconColumn {
    width: 4%;
  }
  
  .singlePaymentDecideTitle {
    font-size: 1.8vw;
  }
  
  .singlePaymentClickIcon:hover {
    opacity: 1;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singlePaymentInformation {
    width: calc(100% - 6vw);
    padding: 2vh 3vw 2vh 3vw;
  }

  .singlePaymentNumberColumn {
    width: 35%;
  }
  
  .singlePaymentSubmittedColumn {
    display: none;
  }
  
  .singlePaymentWordsColumn {
    display: none;
  }
  
  .singlePaymentRateColumn {
    display: none;
  }
  
  .singlePaymentTotalColumn {
    width: 16%;
  }
  
  .singlePaymentStatusColumn {
    width: 30%;
  }
  
  .singlePaymentIconColumn {
    width: 4%;
  }
  
  .singlePaymentDecideTitle {
    font-size: 3.8vw;
  }
  
  .singlePaymentClickIcon:hover {
    opacity: 1;
  }
}
.singleQuoteSummary {
  width: 100%;
  height: auto;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
}

.singleQuoteSummaryInfoType {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 1vh;
}

.singleQuoteSummaryInfoCount {
  font-family: 'Montserrat';
  font-size: 0.85vw;
  font-weight: 300;
  color: rgba(102,102,102,0.5);
  margin-top: 0;
  margin-bottom: 0;
  width: 15vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .singleQuoteSummaryInfoType {
    font-family: 'Montserrat';
    font-size: 1.6vw;
    font-weight: 400;
    margin-bottom: 1vh;
  }
  
  .singleQuoteSummaryInfoCount {
    font-size: 1.6vw;
    width: 80%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .singleQuoteSummaryInfoType {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    font-weight: 400;
    margin-bottom: 1vh;
  }
  
  .singleQuoteSummaryInfoCount {
    font-size: 3.5vw;
    width: 80%;
  }
}
.singleClientReviewsMessage {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.singleClientReviewLine {
  border: 1px solid #e1e1e1;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  width: calc(100% - 3vw);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleClientReviewText {
  font-family: 'Montserrat';
  font-weight: 300;
  color: #666;
  font-size: 0.9vw;
  line-height: 1.8vw;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleClientReviewsMessage {
    font-size: 1.8vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .singleClientReviewLine {
    border: 1px solid #e1e1e1;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: calc(100% - 5vw);
  }
  
  .singleClientReviewText {
    font-size: 1.7vw;
    line-height: 3.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleClientReviewsMessage {
    font-size: 1.8vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .singleClientReviewLine {
    border: 1px solid #e1e1e1;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
    width: calc(100% - 7vw);
  }
  
  .singleClientReviewText {
    font-size: 3.5vw;
    line-height: 7vw;
  }
}

.preferencesDecideInfoContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2vh;
}

.preferencesInfoLine {
  width: 100%;
  height: auto;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.preferencesModalText {
  width: 100%;
  height: auto;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.85vw;
  line-height: 1.8vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: justify;
}

.preferencesSubtitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: #444;
  margin-top: 0;
  margin-bottom: 0;
}

.preferencesScheduleSeparator {
  margin-bottom: 2vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .preferencesModalText {
    font-size: 1.5vw;
    line-height: 3vw;
  }
  
  .preferencesSubtitle {
    font-size: 2vw;
  }
  
  .preferencesScheduleSeparator {
    margin-bottom: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .preferencesModalText {
    font-size: 3vw;
    line-height: 6.5vw;
  }
  
  .preferencesSubtitle {
    font-size: 3vw;
  }
  
  .preferencesScheduleSeparator {
    margin-bottom: 2vh;
  }
}
.manualOrderDecideMainContainer {
  width: 98%;
  margin-left: 2%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.element{
  position: relative;
}

.searchBoxWrap{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: #18b7bb;
  border: 1px solid  #18b7bb;
  box-shadow: 0 0 5px 0.5px #00000052;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 5;
  padding: 0.1rem;
}

.searchTextWrap > .editBtn{
  color: #fff;
}

.searchTextWrap > div{
  width: 100%;
  padding: 0.2rem;
  color: #fff;
  /* border-bottom: 1px solid rgba(128, 128, 128, 0.37); */
  cursor: pointer;
}

/* width */
.searchBoxWrap::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.searchBoxWrap::-webkit-scrollbar-track {
  background: trasparent;
}
 
/* Handle */
.searchBoxWrap::-webkit-scrollbar-thumb {
  background: #fff; 
  border-radius: 5px;
  margin: 5px;
}

/* Handle on hover */
.searchBoxWrap::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}
 
.searchTextWrap{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.closeIcon{
  position: -webkit-sticky;
  position: sticky;
  top: 2px;
  left: 95%;
  color: #fff !important;
}


.notificationsSubjectColumn {
  width: 70%;
}

.notificationsSubjectColumn p {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notificationsDateColumn {
  width: 20%;
}

.notificationsReadColumn {
  width: 10%;
}

.announcementContainer {
  margin-top: 3vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.announcementTitleContainer {
  width: 30%;
}

.announcementMessageContainer {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.announcementSubmitButtonContainer {
  width: 15%;
}

.announcementRichTextButton {
  width: 17px;
  height: 17px;
  padding: 0.3vw;
  border-radius: 3px;
  color: #666;
  margin-right: 1vw;
  background-color: rgba(255, 124, 29, 0);
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.announcementRichTextButton:hover {
  background-color: rgba(255, 124, 29, 1);
  color: #fff;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .notificationsSubjectColumn {
    width: 90%;
  }
  
  .notificationsDateColumn {
    display: none;
  }
  
  .notificationsReadColumn {
    width: 10%;
  }

  .announcementTitleContainer {
    width: 25%;
  }
  
  .announcementMessageContainer {
    width: 42%;
  }
  
  .announcementSubmitButtonContainer {
    width: 20%;
  }

  .announcementRichTextButton {
    width: 18px;
    height: 18px;
    margin-right: 1.5vw;
  }
  
  .announcementRichTextButton:hover {
    background-color: transparent;
    color: #666;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .notificationsSubjectColumn {
    width: 100%;
  }

  .notificationsSubjectColumn p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .notificationsReadColumn {
    display: none;
  }

  .announcementContainer {
    margin-top: 3vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .announcementTitleContainer {
    width: 100%;
  }
  
  .announcementMessageContainer {
    margin-top: 5vh;
    width: 100%;
  }
  
  .announcementSubmitButtonContainer {
    width: auto;
    align-self: flex-end;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }

  .announcementRichTextButton {
    width: 18px;
    height: 18px;
    margin-right: 4vw;
  }
}
.announcementDateText {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #666;
  font-size: 0.85vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .announcementDateText {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #666;
    font-size: 1.6vw;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .announcementDateText {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #666;
    font-size: 3.2vw;
    margin-top: 1.5vh;
    margin-bottom: 3vh;
  }
}
.guidelinesDetailsMain {
  width: 80%;
}

.guidelinesHeader {
  margin-top: 2vh;
  margin-bottom: 0;
}

.guidelinesHeading {
  font-size: 1.9vw;
  margin-bottom: 1.5vh;
}

.guidelinesSubheading {
  font-size: 0.9vw;
  line-height: 2.1vw;
  width: 80%;
  text-align: left;
  margin-bottom: 4vh;
}

.guidelinesAuxHeading {
  font-size: 1.3vw;
  line-height: 3vw;
  margin-bottom: 2.5vh;
  margin-top: 0;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
}

.guidelinesText {
  font-size: 0.9vw;
  line-height: 2vw;
  margin-bottom: 4vh;
}

.guidelinesTextStrong {
  font-size: 1.1vw;
  font-weight: 900;
  color: #18b7bb;
  line-height: 3vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .guidelinesDetailsMain {
    width: 100%;
  }
  
  .guidelinesHeader {
    margin-top: 1vh;
    margin-bottom: 0;
  }
  
  .guidelinesHeading {
    font-size: 2.8vw;
    margin-bottom: 1vh;
  }
  
  .guidelinesSubheading {
    font-size: 1.7vw;
    line-height: 3.8vw;
    width: 90%;
    text-align: left;
    margin-bottom: 3vh;
  }
  
  .guidelinesAuxHeading {
    font-size: 2.3vw;
    line-height: 6vw;
    margin-bottom: 1.5vh;
    margin-top: 0;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
  }
  
  .guidelinesText {
    font-size: 1.7vw;
    line-height: 3.8vw;
    margin-bottom: 3vh;
  }
  
  .guidelinesTextStrong {
    font-size: 2vw;
    font-weight: 900;
    color: #18b7bb;
    line-height: 6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  
  .guidelinesHeading {
    font-size: 6vw;
    line-height: 8vw;
    margin-bottom: 1vh;
  }
  
  .guidelinesSubheading {
    font-size: 3.5vw;
    line-height: 7vw;
    width: 100%;
    text-align: left;
    margin-bottom: 4vh;
  }
  
  .guidelinesAuxHeading {
    font-size: 4.8vw;
    line-height: 10vw;
    margin-bottom: 2vh;
  }
  
  .guidelinesText {
    font-size: 3.3vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }
  
  .guidelinesTextStrong {
    font-size: 4vw;
    font-weight: 900;
    color: #18b7bb;
    line-height: 12vw;
  }
}
.backendOuterContainer {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.backendSidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 17vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #085f63;
  overflow: hidden;
  z-index: 2000;
  box-shadow: 2px 0px 10px rgba(0,0,0,0.2);
}

.backendLogoContainer {
  width: 90%;
  height: 10vh;
  margin-bottom: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.backendCloseSidebarIcon {
  display: none;
}

.backendLogo {
  height: 45%;
  width: auto;
  object-fit: cover;
  cursor: pointer;
}

.backendSidebarLinksContainer {
  margin-left: 2%;
  width: 87%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.backendSidebarLinkContainer {
  width: 100%;
  height: auto;
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.backendSidebarLink {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  color: rgba(255,255,255,0.4);
  font-size: 0.95vw;
  margin-bottom: 1.5vh;
  cursor: pointer;
  transition: color 0.3s ease-out;
  position: relative;
}

.backendSidebarLink:hover {
  color: rgba(255,255,255,1) !important;
}

.backendSidebarLinkIcon {
  width: 15px;
  height: 15px;
  color: inherit;
  margin-right: 0.6vw;
}

.backendSidebarLink:hover .backendSidebarLinkIcon {
  color: rgba(255,255,255,1) !important;
}

.backendSidebarLinkExtraIconContainer {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.backendSidebarExtraIcon {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.8vw;
  color: #fff;
  margin-left: 0.2vw;
}

.backendSidebarLink:hover .backendSidebarExtraIcon {
  color: rgba(255,255,255,1) !important;
}

.backendInnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 83vw;
  height: 100%;
  display: block;
  overflow-y: scroll;
  background-color: #f5f5f5;
}

.backendHeader {
  width: 100%;
  height: 10vh;
  background-color: #fff;
  border-bottom: 1px solid rgba(102,102,102,0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.backendSwitchIcon {
  width: 20px;
  height: 20px;
  margin-left: 2vw;
  color: #666;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.backendSwitchIcon:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.backendTabContainer {
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
}

.backendHeaderOptionsContainer {
  margin-right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.backendHeaderOptionsSeparator {
  width: 0;
  height: 3vh;
  border-right: 1px solid #e1e1e1;
  margin-right: 1.5vw;
  margin-left: 1.5vw;
}

.backendHeaderOptionsMenuContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 200;
}

.backendHeaderOptionsName {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
  cursor: pointer;
}

.backendHeaderOptionsIcon {
  width: 15px;
  height: 15px;
  color: #666;
  cursor: pointer;
  margin-top: 0.3vh;
}

.backendHeaderOptionsMenuInner {
  position: absolute;
  right: 0;
  top: 100%;
  width: auto;
  height: auto;
  padding: 1vh 4vw 1vh 1vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid rgba(102,102,102,0.4);
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.backendHeaderOptionsMenuContainer:hover .backendHeaderOptionsMenuInner {
  opacity: 1;
  pointer-events: all;
}

.backendHeaderOptionsMenuLink {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 0.9vw;
  color: #666;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-right: 0.5vw;
  cursor: pointer;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.backendHeaderOptionsMenuLink:hover {
  -webkit-filter: brightness(0.2);
          filter: brightness(0.2);
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .backendLogoContainer {
    justify-content: space-between;
    height: 8vh;
  }

  .backendCloseSidebarIcon {
    display: flex;
    width: 3vw;
    height: 3vw;
    color: #fff;
  }
  
  .backendLogo {
    height: 45%;
  }
  
  .backendSidebarLink {
    font-size: 1.8vw;
    margin-bottom: 1.5vh;
  }
  
  .backendSidebarLink:hover {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarLinkIcon {
    width: 3vw;
    height: 3vw;
    color: inherit;
    margin-right: 2vw;
  }
  
  .backendSidebarLink:hover .backendSidebarLinkIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarExtraIcon {
    width: 3vw;
    height: 3vw;
    border-radius: 1.5vw;
    font-size: 1.7vw;
    color: #fff;
    margin-left: 0.7vw;
  }
  
  .backendSidebarLink:hover .backendSidebarExtraIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSwitchIcon {
    width: 3vw;
    height: 3vw;
    margin-left: 3vw;
  }
  
  .backendSwitchIcon:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  .backendHeader {
    height: 8vh;
  }
  
  .backendHeaderOptionsContainer {
    margin-right: 3vw;
  }
  
  .backendHeaderOptionsSeparator {
    width: 0;
    height: 3vh;
    border-right: 1px solid #e1e1e1;
    margin-right: 3vw;
    margin-left: 3vw;
  }
  
  .backendHeaderOptionsName {
    font-size: 1.7vw;
    margin-right: 1vw;
  }
  
  .backendHeaderOptionsIcon {
    width: 2vw;
    height: 2vw;
    color: #666;
    cursor: pointer;
    margin-top: 0;
  }
  
  .backendHeaderOptionsMenuContainer:hover .backendHeaderOptionsMenuInner {
    opacity: 1;
    pointer-events: all;
  }
  
  .backendHeaderOptionsMenuLink {
    font-size: 1.6vw;
  }
  
  .backendHeaderOptionsMenuLink:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .backendLogoContainer {
    justify-content: space-between;
  }

  .backendCloseSidebarIcon {
    display: flex;
    width: 5vw;
    height: 5vw;
    color: #fff;
  }
  
  .backendLogo {
    height: 40%;
  }
  
  .backendSidebarLink {
    font-size: 3.5vw;
    margin-bottom: 1.8vh;
  }
  
  .backendSidebarLink:hover {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarLinkIcon {
    width: 4.5vw;
    height: 4.5vw;
    color: inherit;
    margin-right: 4vw;
  }
  
  .backendSidebarLink:hover .backendSidebarLinkIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarExtraIcon {
    width: 5vw;
    height: 5vw;
    border-radius: 2.5vw;
    font-size: 3vw;
    color: #fff;
    margin-left: 1.5vw;
  }
  
  .backendSidebarLink:hover .backendSidebarExtraIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSwitchIcon {
    width: 4.5vw;
    height: 4.5vw;
    margin-left: 3vw;
  }
  
  .backendSwitchIcon:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .backendHeaderOptionsContainer {
    margin-right: 3vw;
  }
  
  .backendHeaderOptionsSeparator {
    width: 0;
    height: 4vh;
    border-right: 1px solid #e1e1e1;
    margin-right: 4vw;
    margin-left: 4vw;
  }
  
  .backendHeaderOptionsName {
    font-size: 3.6vw;
    margin-right: 3vw;
  }
  
  .backendHeaderOptionsIcon {
    width: 4vw;
    height: 4vw;
    color: #666;
    cursor: pointer;
    margin-top: 0;
  }

  .backendHeaderOptionsMenuInner {
    position: absolute;
    right: 0;
    top: 100%;
    width: auto;
    height: auto;
    padding: 1vh 7vw 1vh 3vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid rgba(102,102,102,0.4);
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-out;
  }
  
  .backendHeaderOptionsMenuContainer:hover .backendHeaderOptionsMenuInner {
    opacity: 1;
    pointer-events: all;
  }
  
  .backendHeaderOptionsMenuLink {
    font-size: 3.5vw;
  }
  
  .backendHeaderOptionsMenuLink:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  
  .backendSidebarLinkContainer {
    margin-bottom: 3vh;
  }
  
  .backendSidebarLink:hover {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarLinkIcon {
    width: 13px;
    height: 13px;
    color: inherit;
    margin-right: 1.2vw;
  }
  
  .backendSidebarLink:hover .backendSidebarLinkIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSidebarExtraIcon {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    font-size: 0.9vw;
    margin-left: 0.5vw;
  }
  
  .backendSidebarLink:hover .backendSidebarExtraIcon {
    color: rgba(255,255,255,0.4) !important;
  }
  
  .backendSwitchIcon {
    width: 12px;
    height: 12px;
    margin-left: 2vw;
  }
  
  .backendSwitchIcon:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
  
  .backendHeaderOptionsIcon {
    width: 11px;
    height: 11px;
    color: #666;
    cursor: pointer;
    margin-top: 0;
  }
  
  .backendHeaderOptionsMenuLink:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }
}
.usersFunctionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .userLanguageFilterContainer {
    width: 15vw;
    margin-right: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  
  .userNameFilter {
    color: #333;
    background-color: transparent;
    border-bottom: 1px solid rgba(102, 102, 102, 1);
    border-right: none;
    border-top: none;
    border-left: none;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    line-height: 0.9vw;
    margin-top: 0;
    margin-bottom: 0;
    height: 2.3vw;
    width: 100%;
    padding-left: 0;
  }
  
  .userNameFilter:focus {
    outline: none;
  }
  
  .usersHeaderColumn {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.9vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .usersLineColumn {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.8vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .usersIDColumn {
    width: 20%;
  }

  .usersNameColumn {
    width: 20%;
  }
  
  .usersRoleColumn {
    width: 10%;
  }
  
  .usersLanguageColumn {
    width: 15%;
  }
  
  .usersRateColumn {
    width: 8%;
  }
  
  .usersStatusColumn {
    width: 10%;
  }
  
  .usersDeleteColumn {
    width: 5%;
    justify-content: center;
  }
  
  .usersExtraColumn {
    width: 4%;
    justify-content: center;
    overflow: visible;
    position: relative;
  }
  
  .usersExtraOverlay {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #085f63;
    border-radius: 3px;
    border: 1px solid #e1e1e1;
    width: auto;
    height: auto;
    padding-right: 4vw;
    padding-left: 1vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 400;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  
  .usersExtraColumn:hover .usersExtraOverlay {
    opacity: 1;
    pointer-events: all;
  }
  
  .usersExtraText {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 0.85vw;
    color: #fff;
    margin-top: 0.7vh;
    margin-bottom: 0.7vh;
  }
  
  .userModalUnderlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.3);
  }
  
  .userModal {
    z-index: 1000;
    position: fixed;
    top: 45%;
    left: 50%;
    width: 40vw;
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-right: 1vw;
    padding-left: 1vw;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 2px 2px 20px rgba(0,0,0,0.1);
  }
  
  .userModalTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.4vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 3vh;
  }
  
  .userModalLine {
    width: 100%;
    margin-bottom: 1vh;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .userModalHalf {
    width: 46%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .userModalHalfText {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.2vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .userModalLanguageLine {
    margin-top: 2vh;
  }
  
  .userModalButtonContainer {
    width: auto;
    height: auto;
    align-self: flex-end;
    margin-top: 3vh;
  }
  
  .userModalRoleSelect {
    margin-bottom: 1vh;
  }
  
  .usersDeleteButton {
    width: 1.1vw;
    height: 1.1vw;
    color: #f44336;
    cursor: pointer;
    transition: -webkit-filter 0.3s ease-out;
    transition: filter 0.3s ease-out;
    transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
  }
  
  .usersDeleteButton:hover {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
  }
  
  .loadMoreUsersButton {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    color: #333;
    margin-top: 3vh;
    margin-bottom: 2vh;
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
    align-self: flex-start;
    margin-left: 2%;
  }
  
  .loadMoreUsersButton:hover {
    opacity: 0.7;
  }
  
  .userStatusSelect {
    width: 80%;
    height: 3vh;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #666;
    font-family: 'Montserrat';
    font-size: 0.8vw;
    line-height: 2vw;
    font-weight: 400;
    color: #666;
  }
  
  .userStatusSelect:focus {
    outline: none;
  }
  
  .userStatusText {
    padding-left: 0.3vw;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5vw;
    font-size: 0.9vw;
  }
  
  @media (orientation: portrait) and (max-width: 1024px) {
    
    .userLanguageFilterContainer {
      width: auto;
      margin-right: 4vw;
    }
    
    .userNameFilter {
      font-size: 1.6vw;
      line-height: 1.6vw;
      height: 5vw;
    }
    
    .usersHeaderColumn {
      font-size: 1.7vw;
    }
    
    .usersLineColumn {
      font-size: 1.8vw;
    }
    
    .usersIDColumn {
      width: 35%;
    }
    
    .usersNameColumn {
      width: 27%;
    }
    
    .usersRoleColumn {
      width: 13%;
    }
    
    .usersLanguageColumn {
      display: none;
    }
    
    .usersRateColumn {
      display: none;
    }
    
    .usersStatusColumn {
      width: 15%;
    }
    
    .usersDeleteColumn {
      width: 5%;
      justify-content: center;
    }
    
    .usersExtraColumn {
      width: 4%;
      justify-content: center;
      overflow: visible;
      position: relative;
    }
    
    .usersExtraOverlay {
      border-radius: 3px;
      padding-right: 4vw;
      padding-left: 1vw;
      padding-top: 1.5vh;
      padding-bottom: 1.5vh;
    }
    
    .usersExtraText {
      font-size: 1.5vw;
    }
    
    .userModal {
      z-index: 1000;
      position: absolute;
      top: 37%;
      left: 50%;
      width: 60vw;
      padding-top: 2.5vh;
      padding-bottom: 2.5vh;
      padding-right: 4vw;
      padding-left: 4vw;
    }
  
    .userModalLine {
      margin-bottom: 0.7vh;
    }
    
    .userModalLanguageLine {
      margin-top: 1vh;
    }
    
    .userModalButtonContainer {
      margin-top: 2vh;
    }
    
    .usersDeleteButton {
      width: 2.1vw;
      height: 2.1vw;
      color: #f44336;
      cursor: pointer;
      transition: -webkit-filter 0.3s ease-out;
      transition: filter 0.3s ease-out;
      transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
    }
    
    .usersDeleteButton:hover {
      -webkit-filter: brightness(1);
              filter: brightness(1);
    }
    
    .loadMoreUsersButton {
      font-size: 1.8vw;
    }
    
    .loadMoreUsersButton:hover {
      opacity: 1;
    }
    
    .userStatusSelect {
      width: 80%;
      height: 3vh;
      font-size: 1.7vw;
      line-height: 3.2vw;
    }
    
    .userStatusSelect:focus {
      outline: none;
    }
    
    .userStatusText {
      padding-left: 0.3vw;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.5vw;
    }
  }
  
  @media (orientation: portrait) and (max-width: 767px) {
    .userLanguageFilterContainer {
      display: none;
    }
    .userNameFilter {
      font-size: 8px;
      line-height: 1.6vw;
      height: 5vw;
    }
    .usersHeaderColumn {
      font-size: 3vw;
    }
    
    .usersLineColumn {
      font-size: 3vw;
    }
    
    .usersIDColumn {
      width: 65%;
    }
    
    .usersNameColumn {
      display: none;
    }
    
    .usersRoleColumn {
      display: none;
    }
    
    .usersLanguageColumn {
      display: none;
    }
    
    .usersRateColumn {
      display: none;
    }
    
    .usersStatusColumn {
      width: 30%;
    }
    
    .usersDeleteColumn {
      width: 25%;
      display: none;
    }
    
    .usersExtraColumn {
      display: none;
    }
    
    .usersExtraOverlay {
      display: none;
    }
    
    .userModal {
      z-index: 1000;
      position: absolute;
      top: 37%;
      left: 50%;
      width: 85vw;
      height: auto;
      padding-top: 2vh;
      padding-bottom: 2vh;
      padding-right: 4vw;
      padding-left: 4vw;
      overflow: hidden;
    }
    
    .userModalLine {
      width: 100%;
      margin-bottom: 0.5vh;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    
    .userModalLanguageLine {
      margin-top: 1vh;
    }
    
    .userModalButtonContainer {
      width: auto;
      height: auto;
      align-self: flex-end;
      margin-top: 2vh;
    }
    
    .userModalRoleSelect {
      margin-bottom: 1vh;
    }
  
    .loadMoreUsersButton {
      font-size: 3.5vw;
    }
    
    .userStatusSelect {
      width: 100%;
      height: 3vh;
      font-size: 3vw;
      line-height: 5vw;
    }
    
    .userStatusSelect:focus {
      outline: none;
    }
    
    .userStatusText {
      padding-left: 0.3vw;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 3vw;
      font-size: 7px;
    }
  
    .usersDeleteButton {
      width: 15px;
      height: 15px;
    }
  }
  
  @media (orientation: landscape) and (max-width: 1023px) {
    
    .userLanguageFilterContainer {
      width: auto;
    }
    
    .usersIDColumn {
      width: 22%;
    }
    
    .usersNameColumn {
      width: 15%;
    }
    
    .usersRoleColumn {
      width: 10%;
    }
    
    .usersLanguageColumn {
      width: 10%;
    }
    
    .usersRateColumn {
      width: 8%;
    }
    
    .usersStatusColumn {
      width: 10%;
    }
    
    .usersDeleteColumn {
      width: 5%;
      justify-content: center;
    }
    
    .usersExtraColumn {
      width: 4%;
      justify-content: center;
      overflow: visible;
      position: relative;
    }
    
    .userModalLine {
      width: 100%;
      margin-bottom: 1.5vh;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    
    .userModalLanguageLine {
      margin-top: 3vh;
    }
    
    .userModalButtonContainer {
      width: auto;
      height: auto;
      align-self: flex-end;
      margin-top: 3vh;
    }
    
    .userModalRoleSelect {
      margin-bottom: 2vh;
    }
    
    .usersDeleteButton {
      width: 12px;
      height: 12px;
    }
    
    .usersDeleteButton:hover {
      -webkit-filter: brightness(1);
              filter: brightness(1);
    }
    
    .loadMoreUsersButton:hover {
      opacity: 1;
    }
  
    .userStatusSelect {
      width: 80%;
      height: 5vh;
      font-size: 0.8vw;
      line-height: 2vw;
    }
  }
.transitionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000000000;
  background-color: rgb(255, 255, 255);
  pointer-events: none;
}
.notificationBox {
  z-index: 10000000000009;
  position: fixed;
  top: 3vh;
  right: 3vw;
  width: 30vw;
  height: auto;
  pointer-events: none;
  border-radius: 15px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notificationIcon {
  width: 6vh;
  height: 6vh;
  color: rgba(255,255,255,0.8);
  margin-right: 2vw;
  margin-left: 2vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.notificationInfo {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 2vw;
}

.notificationTitle {
  margin-bottom: 0.5vh;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1vw;
  color: rgba(255,255,255,1);
  margin-top: 3vh;
}

.notificationText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.8vw;
  line-height: 1.3vw;
  margin-top: 0;
  margin-bottom: 3vh;
  color: rgba(255,255,255,1);
}

@media (orientation: portrait) and (max-width: 1024px) {
  .notificationBox {
    top: 2vh;
    width: 40vw;
    height: auto;
    border-radius: 15px;
  }
  
  .notificationIcon {
    width: 5vh;
    height: 5vh;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  
  .notificationInfo {
    margin-right: 3vw;
  }
  
  .notificationTitle {
    margin-bottom: 0.5vh;
    font-size: 2vw;
    margin-top: 2vh;
  }
  
  .notificationText {
    font-size: 1.6vw;
    line-height: 2.1vw;
    margin-bottom: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .notificationBox {
    top: 2vh;
    width: 75vw;
    height: auto;
    border-radius: 15px;
  }
  
  .notificationIcon {
    width: 5vh;
    height: 5vh;
    margin-right: 4vw;
    margin-left: 4vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  
  .notificationInfo {
    margin-right: 6vw;
  }
  
  .notificationTitle {
    margin-bottom: 0.5vh;
    font-size: 3.5vw;
    margin-top: 2vh;
  }
  
  .notificationText {
    font-size: 3vw;
    line-height: 4vw;
    margin-bottom: 2vh;
  }
}

@media (orientation: landscape) and (max-width: 1023px) {
  .notificationBox {
    border-radius: 6px;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url(/static/media/Montserrat-Regular.ee653992.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url(/static/media/Montserrat-Medium.c8b6e083.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url(/static/media/Montserrat-SemiBold.c641dbee.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url(/static/media/Montserrat-Black.27e3649b.ttf) format('truetype');
}

h1, h2, h3 {
  margin: 0;
}

.rotateOverlay, .rotateOverlayText {
  display: none;
}

.preloaderOverlay {
  z-index: 1000000000000000;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media (orientation: landscape) and (max-width: 767px) {
  .rotateOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #18b7bb;
    z-index: 100000000000000;
    pointer-events: none;
  }

  .rotateOverlayText {
    display: flex;
    width: 60%;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 2.5vw;
    line-height: 5vw;
    color: #fff;
    margin-top: 7vh;
    margin-bottom: 0;
    text-align: center;
  }
}
.accountMainContainer{
  width: 100%;
}

.teamNameWrapper{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 1vw;
  gap: 1vw;
}

.teamNameText {
  margin-bottom: 0;
  margin-top: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1vw;
  line-height: 1vw;
  color: #666;
}

  .usersEditButton {
      width: 1.1vw;
      height: 1.1vw;
      color: #18b7bb;
      cursor: pointer;
      transition: -webkit-filter 0.3s ease-out;
      transition: filter 0.3s ease-out;
      transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
    }
  
  .usersDeleteButton:hover {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
  }

  .usersDeleteButton {
    width: 1.1vw;
    height: 1.1vw;
    color: #f44336;
    cursor: pointer;
    transition: -webkit-filter 0.3s ease-out;
    transition: filter 0.3s ease-out;
    transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
  }
  
  .usersDeleteButton:hover {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
  }
  
  .loadMoreUsersButton {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    color: #333;
    margin-top: 3vh;
    margin-bottom: 2vh;
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
    align-self: flex-start;
    margin-left: 2%;
  }
  
  .loadMoreUsersButton:hover {
    opacity: 0.7;
  }

  .userStatusText {
    font-size: 0.9vw;
    padding-left: 0.3vw;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5vw;
  }

  .quoteTotalHeaderColumn{
      width: 25%;
  }

  .addUserContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    margin: 10px 0;
  }

  .addNewUserBtn{
    width: 1.4vw;
    height: 1.4vw;
    margin: 10px 0;
    cursor: pointer;
  }

  .addNewUserBtn > img {
    width: inherit;
    height: inherit;
  }

  .userInputsWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    
    .accountMainContainer{
      width: 90%;
    }
    
    .usersDeleteButton {
      width: 2.1vw;
      height: 2.1vw;
      color: #f44336;
      cursor: pointer;
      transition: -webkit-filter 0.3s ease-out;
      transition: filter 0.3s ease-out;
      transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
    }

    .teamNameText {
      margin-bottom: 0;
      margin-top: 1vw;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 1.8vw;
      line-height: 1vw;
      color: #666;
    }

    .loadMoreUsersButton {
      font-size: 1.8vw;
    }
    
    .loadMoreUsersButton:hover {
      opacity: 1;
    }

    .addNewUserBtn{
      width: 2.3vw;
      height: 2.3vw;
      margin: 10px 0;
    }

    .userInputsWrapper{
      width: 55%;
    }

  }

  @media (orientation: portrait) and (max-width: 767px) {
    .accountMainContainer{
      width: 95%;
    }
    
    .usersDeleteButton {
      width: 15px;
      height: 15px;
    }

    .userStatusText {
      font-size: 7px;
      padding-left: 0.3vw;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 3vw;
    }
    .teamNameText {
      margin-top: 2vw;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 3.5vw;
      line-height: 1vw;
      color: #666;
    }

    .loadMoreUsersButton {
      font-size: 3.5vw;
    }

    .addNewUserBtn{
      width: 15px;
      height: 15px;
      margin: 10px 0;
    }

    .addUserContainer{
      width: 90%;
    }

    .userInputsWrapper{
      width: 100%;
      /* flex-wrap: wrap; */
      margin: 10px 0;
    }
    
  }

  @media (orientation: landscape) and (max-width: 1023px) {

  .usersDeleteButton {
    width: 12px;
    height: 12px;
  }
  
  .usersDeleteButton:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  .loadMoreUsersButton:hover {
    opacity: 1;
  }

}
.createAccountContainer {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12vh;
  margin-bottom: 8vh;
}

.createAccountInfoContainer {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.createAccountExtraContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vh;
}

.createAccountExtraText {
  font-family: 'Montserrat';
  font-weight: 400;
  color: #666;
  font-size: 0.9vw;
  margin-left: 1.3vw;
}

.createAccountFormContainer {
  margin-top: 0;
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.createAccountFormSeparator {
  height: 2.5vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .createAccountContainer {
    width: 100%;
    margin-top: 8vh;
    margin-bottom: 5vh;
  }
  
  .createAccountExtraText {
    font-family: 'Montserrat';
    font-weight: 400;
    color: #666;
    font-size: 1.8vw;
    margin-left: 1.5vw;
  }
  
  .createAccountFormContainer {
    margin-top: 0;
  }
  
  .createAccountFormSeparator {
    height: 1.5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .createAccountContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
  
  .createAccountInfoContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 4vh;
  }
  
  .createAccountExtraContainer {
    margin-top: -2vh;
  }
  
  .createAccountExtraText {
    font-family: 'Montserrat';
    font-weight: 400;
    color: #666;
    font-size: 3.5vw;
    margin-left: 3vw;
  }
  
  .createAccountFormContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .createAccountFormSeparator {
    height: 2.5vh;
  }
}
.editBtn {
    width: 1.1vw;
    height: 1.1vw;
    color: #18b7bb;
    cursor: pointer;
    transition: -webkit-filter 0.3s ease-out;
    transition: filter 0.3s ease-out;
    transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
    margin: 0 8px;
  }

  .blueColor{
    color: #18b7bb;
  }


  .notes{
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #18b8bb60;
    /* color: #FFF !important; */
    padding: 10px;
    
    overflow-wrap: break-word;
    white-space: normal;
    white-space: initial;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }


  .advOption{
    text-decoration: underline;
    margin-top: 10px;
  }

  .orderName{
    width: 28%;
  }


  .searchFilterRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 3vw;
    gap: 3vw;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .showAnim{
    display: flex;
    transition: 0.5s;
    -webkit-animation: show 0.5s ease-in-out;
            animation: show 0.5s ease-in-out;
    overflow: hidden;
  }

  .hideAnim{
    display: none;
    transition: 0.5s;
  }

  @-webkit-keyframes show {
    from {opacity: 0};
    to {opacity: 1};
  }

  @keyframes show {
    from {opacity: 0};
    to {opacity: 1};
  }

  .inputWrap{
    width: 80%;
  }

  .refToolTip{
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    width: 140px;
    height: auto;
    line-height: 1.4;
    color: #fff;
    padding: 1rem;
    background-color: #45C4C7;
    overflow-wrap: break-word;
    white-space: normal;
    white-space: initial;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    border-radius: 5px 0px 5px 5px;
  }

  .tooltip:hover~.refToolTip{
    display: flex;
  }

  .teamNote{
    border-bottom: 1px solid #e1e1e1;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    color: #ff7c1d;
    cursor: pointer;
  }

  .warningContainer{
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap:5px;
    gap:5px;
    box-shadow: 1px 1px 5px #8d8d8d;
    border-radius: 5px;
    padding: 0.75rem;
    background-color: #ffedb5;
    flex: 1 1;
    margin: 0 1rem;
    overflow: hidden;
  }

  .warningContainer * {
    cursor: default !important;
    color: #665e48 !important;
  }

  .warningContainer .quoteDetailsTitle:hover{
    opacity: 1;
  }

  .subText{
    font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.9vw;
  }

  .paymentIconWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 6vh;
  }

  .closeBtnWrap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .notficationTitle{
    margin-bottom: 2vh !important;
  }

  .emailInputWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2vh;
  }

  .emailInputWrapper label{
    margin-top: 6px;
    margin-right: 10px;
  }
  .emailInputWrapper input{
    font-size: 0.9vw;
    max-width: 300px !important;
  }

  .invoiceAlert{
    color: #666;
    font-weight: 700;
    margin-top: 5vh;
  }

  .notificationText{
    font-weight: 400 !important;
  }

  .notifyOverdueWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    .editBtn {
      width: 2.1vw;
      height: 2.1vw;
    }

    .inputWrap{
      width:73%;
    }

    .teamNote{
      font-size: 1.6vw;
    }

    .subText{
      font-size: 1.5vw;
    }

     .emailInputWrapper input{
      font-size: 0.9vw;
    }

}

@media (orientation: portrait) and (max-width: 767px) {

  .searchFilterRow{
    flex-wrap: wrap;
  }
    
    .editBtn {
      width: 15px;
      height: 15px;
    }

    .teamNote{
      font-size: 3.2vw;
    }
    .subText{
      font-size: 3.2vw;
    }

    .emailInputWrapper input{
      font-size: 3vw;
    }
}

@media (orientation: landscape) and (max-width: 1023px) {

    .editBtn {
      width: 12px;
      height: 12px;
    }
}
.sideBar{
    width: 10% !important;
}

.toggle--checked .react-toggle-track {
    background-color: #ff4546 !important;
    width:44px;
    height:18px;
  } 
  .toggle--checked.react-toggle--checked .react-toggle-track {
    background-color: #11d111 !important;
  } 
  .toggle--checked.react-toggle--checked .react-toggle-thumb{
    border-color: #11d111 !important;
  }
  .toggle--checked .react-toggle-thumb{
    border-color: #ff4546 !important;
    width: 16px;
    height:16px;
  }
  
  
  .quoteHeaderLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    border-bottom: 1.5px solid rgba(102,102,102,0.6);
  }
  
  .quoteDetailsHeaderColumn {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.1vw;
    color: #18b7bb;
    margin-top: 0;
    margin-bottom: 0;
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .quoteLine {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1vw;
    gap: 1vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .quoteDetailsLineColumn {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 1vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    overflow-wrap: break-word;
    white-space: initial;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  
  .quoteStatusHeaderColumn {
    width: 25%;
  }
  
  .quoteTotalHeaderColumn {
    width: 15%;
    font-weight: 700;
  }
  
  .loadingQuotes {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .quoteStatusContainer {
    width: auto;
    height: auto;
    padding: 1vh 2vw 1vh 2vw;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.75vw;
    color: #fff;
    border-radius: 5px;
    min-width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .loadMoreQuotesButton {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    text-decoration: underline;
    color: rgba(102,102,102,0.3);
    margin-top: 2vh;
    margin-bottom: 0;
    cursor: pointer;
    transition: -webkit-filter 0.3s ease-out;
    transition: filter 0.3s ease-out;
    transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
  }
  
  .loadMoreQuotesButton:hover {
    -webkit-filter: brightness(0.1);
            filter: brightness(0.1);
  }
  
  .quoteDetailsContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .quoteDetailsTitle {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    color: #18b7bb;
    margin-top: 0;
    margin-bottom: 1vh;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
  }
  
  .optionTitle {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.95vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 1vh;
    transition: opacity 0.3s ease-out;
  }
  
  .quoteDetailsTitle:hover {
    opacity: 0.6;
  }
  
  .quoteDetailsSub {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }

  .referenceText{
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.9vw;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
  @media (orientation: portrait) and (max-width: 1024px) {
    .sideBar{
        width: 7% !important;
    }
    .toggle--checked .react-toggle-track {
      width: 40px;
      height:14px;
    } 
    .toggle--checked .react-toggle-thumb{
      width: 12px;
      height: 12px;
    }
  
    .quoteDetailsHeaderColumn {
      font-size: 1.8vw;
    }
    
    .quoteDetailsLineColumn {
      font-size: 1.8vw;
    }
    
    .quoteStatusHeaderColumn {
      width: 25%;
    }
    
    .quoteTotalHeaderColumn {
      width: 15%;
      font-weight: 700;
    }
    
    .loadingQuotes {
      font-size: 2.2vw;
    }
    
    .quoteStatusContainer {
      padding: 0.5vh 2vw 0.5vh 2vw;
      font-size: 1.2vw;
    }
    
    .loadMoreQuotesButton {
      font-size: 2vw;
      color: rgba(102,102,102,0.8);
    }
    
    .quoteDetailsTitle {
      font-size: 1.6vw;
    }
    .optionTitle {
      font-size: 1.6vw;
    }
    
    .quoteDetailsTitle:hover {
      opacity: 1;
    }
    
    .quoteDetailsSub {
      font-size: 1.5vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }

    .referenceText{
       font-size: 1.5vw;
    }
  }
  
  @media (orientation: portrait) and (max-width: 767px) {
    .sideBar{
        width: 100% !important;
    }
    .toggle--checked .react-toggle-track {
      width: 40px;
      height:14px;
    } 
    .toggle--checked .react-toggle-thumb{
      width: 12px;
      height: 12px;
    }
    
    .quoteDetailsHeaderColumn {
      font-size: 3.5vw;
      width: 50%;
    }
    
    .quoteDetailsLineColumn {
      width: 50%;
      font-size: 3.5vw;
    }
    .nameText {
      width: 28%;
    }
    
    .quoteStatusHeaderColumn {
      width: 28%;
    }
    
    .quoteTotalHeaderColumn {
      width: 17%;
      font-weight: 700;
    }
    
    .loadingQuotes {
      font-size: 4vw;
    }
    
    .quoteStatusContainer {
      padding: 0.8vh 3vw 0.8vh 3vw;
      font-size: 2.2vw;
    }
    
    .loadMoreQuotesButton {
      font-size: 3.8vw;
      color: rgba(102,102,102,0.8);
    }
    
    .quoteDetailsTitle {
      font-size: 3.2vw;
    }
    .optionTitle {
      font-size: 3.2vw;
    }
    
    .quoteDetailsTitle:hover {
      opacity: 1;
    }
    
    .quoteDetailsSub {
      font-size: 3.2vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }

    .referenceText{
        font-size: 3.2vw;
     }
  }
.titleWrapper {
  width: 100%;
  text-align: center;
}

.paymentTitle {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.1vw;
  color: #000;
  margin-top: 0;
  margin-bottom: 1vh;
  transition: opacity 0.3s ease-out;
}

.freqencyWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin: 1rem 0;
}

.frequencySelector {
  color: #000;
  font-size: 1vw;
  font-family: "Montserrat";
  background-color: #18b7bb25;
  border: 0;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.frequencySelector:hover,
.selectedFrequency {
  background-color: #18b7bb80;
}

.frequencyData {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  grid-gap: 3rem;
  gap: 3rem;
  margin: 1rem 0;
}

.dataRangeWrapper {
  min-width: 35%;
}

.frequencyData .dataRange,
.frequencyData .orderData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 3rem;
  gap: 3rem;
  text-transform: capitalize;
  margin: 0.2rem 0;
  font-family: "Montserrat";
}

.frequencyData .dataRange,
.makePaymentWrapper .text {
  color: #000;
  font-size: 0.95vw;
  font-weight: 700;
  cursor: pointer;
}

.frequencyData .dataRange .date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dataRange .checkbox {
  width: 20px;
  margin-right: 10px;
}
.frequencyData .orderData,
.makePaymentWrapper .subText {
  color: #666;
  font-size: 0.9vw;
}

.frequencyData .paymentStatus,
.paymentBtn {
  min-width: 160px;
  font-size: 0.8vw;
  font-weight: 700;
  text-align: center;
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
  border-radius: 5px;
}

.paymentBtn {
  text-transform: none;
}

.paid {
  color: #000;
  cursor: default;
  background-color: rgb(23, 188, 91);
}
.no-order {
  color: #000;
  cursor: default;
  background-color: rgb(225, 80, 74);
}
.awaiting {
  color: #000;
  cursor: pointer;
  background-color: rgb(246, 201, 7);
}

.makePaymentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 1rem 0;
}

.paymentBtn {
  background-color: rgb(59, 230, 59);
}
.paymentBtn:hover {
  background-color: rgb(100, 255, 100);
  cursor: pointer;
}

.loadingWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000025;
  z-index: 11000;
}

.loadingWrapper .icon {
  width: 50px;
  height: 50px;
  -webkit-animation: loading 2s linear infinite;
          animation: loading 2s linear infinite;
  color: #1e7e81;
}

@-webkit-keyframes loading {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}

@keyframes loading {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}

@media (orientation: portrait) and (max-width: 1024px) {
  .paymentTitle {
    font-size: 1.8vw;
  }
  .frequencySelector {
    font-size: 1.6vw;
  }

  .frequencyData .dataRange,
  .makePaymentWrapper .text {
    font-size: 1.6vw;
  }

  .frequencyData .orderData,
  .makePaymentWrapper .subText {
    font-size: 1.5vw;
  }

  .frequencyData .paymentStatus,
  .paymentBtn {
    min-width: 150px;
    font-size: 1.3vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .paymentTitle {
    font-size: 3.5vw;
  }
  .frequencySelector {
    font-size: 3.2vw;
  }

  .frequencyData .dataRange,
  .makePaymentWrapper .text {
    font-size: 3.2vw;
  }

  .frequencyData .orderData,
  .makePaymentWrapper .subText {
    font-size: 3vw;
  }

  .frequencyData .paymentStatus,
  .paymentBtn {
    min-width: 100px;
    font-size: 2.2vw;
  }
}

.dataWrapper {
  width: 100%;
}

.thankUWrapper .headingText, .dataWrapper .headingText{
  font-family: "Montserrat";
  color: #000;
  font-size: 0.95vw;
  margin-bottom: 1rem;
  font-weight: 700;
}

.thankUWrapper .text, .dataWrapper .text{
  font-family: "Montserrat";
  color: #000;
  font-size: 0.9vw;
  margin-bottom: 1rem;
  font-weight: 400;
}

.dataWrapper .orderDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  text-transform: capitalize;
  margin: 0.2rem 0;
  font-family: "Montserrat";
}

.bankDetails {
  font-family: "Montserrat";
  color: #000;
  font-size: 0.95vw;
  font-weight: 700;
  margin: 2rem 0;
}

.dataWrapper .orderTotal {
  color: #000;
  font-size: 0.95vw;
  margin-bottom: 1rem;
  font-weight: 700;
}

.dataWrapper .orderDate {
  color: #000;
  font-size: 0.95vw;
  margin-bottom: 1rem;
  font-weight: 400;
}

.total {
  padding: 0 0.5rem;
  background-color: #18b7bb80;
  border-radius: 5px;
}

.dataWrapper .paymentTitle {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.1vw;
  color: #000;
  transition: opacity 0.3s ease-out;
  margin: 1rem 0;
}

.paymentSelectorWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 0.5rem;
  font-size: 0.95vw;
  text-align: center;
  padding: 0.2rem 0.5rem;
}

.paymentSelector {
  flex: 1 1;
}

.confirmPaymentBtn {
  outline: none;
  border: 0;
  cursor: pointer;
  margin: 1rem;
  font-weight: 700;
  font-size: 1.1vw;
}

.uploadBoxWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paypalBtnWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}



@media (orientation: portrait) and (max-width: 1024px) {
    .thankUWrapper .headingText, .dataWrapper .headingText{
        font-size: 1.6vw;
      }
      
      .thankUWrapper .text, .dataWrapper .text{
        font-size: 1.5vw;
      }
      
      .bankDetails {
        font-size: 1.6vw;
      }
      
      .dataWrapper .orderTotal {
        font-size: 1.6vw;
      }
      
      .dataWrapper .orderDate {
        font-size: 1.6vw;
      }
      
      .dataWrapper .paymentTitle {
        font-size: 1.8vw;
      }
      
      .paymentSelectorWrap {
        font-size: 1.6vw;
      }
      
      .confirmPaymentBtn {
        font-size: 1.8vw;
      }
      
}


@media (orientation: portrait) and (max-width: 767px) {
    .thankUWrapper .headingText, .dataWrapper .headingText{
        font-size: 3.1vw;
      }
      
      .thankUWrapper .text, .dataWrapper .text{
        font-size: 3vw;
      }
      
      .bankDetails {
        font-size: 3.2vw;
      }
      
      .dataWrapper .orderTotal {
        font-size: 3.2vw;
      }
      
      .dataWrapper .orderDate {
        font-size: 3.2vw;
      }
      
      .dataWrapper .paymentTitle {
        font-size: 3.5vw;
      }
      
      .paymentSelectorWrap {
        font-size: 3.2vw;
      }
      
      .confirmPaymentBtn {
        font-size: 3.5vw;
      }
}

.container {
    width: 100%;
}

.nweNavbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.logo {
    height: 6vh;
    width: auto;
    cursor: pointer;
}

.menuItems,
.navButton {
    display: none;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.menuText {
    font-family: "Montserrat";
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
}

.menuItems :hover {
    color: #12ABBF;
    cursor: pointer;
}

.underline {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #12ABBF;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(-50%);
}

.group:hover .underline {
    width: 80%;
}

.menu-container {
    position: relative;
}

.menu-button {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.user-icon {
    height: 1.5rem;
    width: 1.5rem;
}


.login-button {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
}


.blue-button {
    background-color: #1EB8BC;
    color: white;
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 9999px;
    font-family: 'Montserrat', sans-serif;
    transition: background-color 300ms;
    border: none;
    cursor: pointer;
}

.blue-button:hover {
    background-color: #358590;
}

.silder {
    height: 2rem;
    border-right-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(202 196 208 / var(--tw-border-opacity, 1))
        /* #cac4d0 */
}

/* General Styles */
.mobile-menu-container {
    display: block;
}

.icon {
    color: #12ABBF;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.mobile-menu {
    position: fixed;
    inset: 0;
    background: white;
    z-index: 50;
    padding: 1.5rem;
    width: 50%;
    height: 100vh;
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.menu-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.divider {
    border-color: #CAC4D0;
    margin: 1rem 0;
}

.menu-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.menu-item {
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.menu-item:hover {
    color: #12ABBF;
}

/* Action Buttons */
.menu-actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.user-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    background: none;
    border: none;
    cursor: pointer;
}

.order-button {
    background: #1EB8BC;
    color: white;
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 9999px;
    font-family: 'Montserrat', sans-serif;
    transition: background-color 300ms;
    border: none;
    cursor: pointer;
}

.order-button:hover {
    background: #358590;
}

.menu-container {
    position: relative;
}

.menu-button {
    position: relative;
}

.submenu-container {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: -100px;
    background: white;
    padding:1rem 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 6px 6px 6px #00000026;
    z-index: 100;
    height: auto;
    width: 250px;
    border: 1px solid #00000026;
    text-align: center;
}

.menu-container:hover .submenu-container {
    display: block;
}

.submenu-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.submenu-link-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.submenu-link {
    margin: 0;
    color: #1EB8BC;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}


/* .login-button {
    background: none;
    color: inherit;
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-radius: 9999px;
    cursor: pointer;
} */



@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }


}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }

    .menuItems {
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    .navButton {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .mobile-menu-container {
        display: none;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
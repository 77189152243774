.singleQuoteSummary {
  width: 100%;
  height: auto;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
}

.singleQuoteSummaryInfoType {
  font-family: 'Montserrat';
  font-size: 0.95vw;
  font-weight: 400;
  color: #666;
  margin-top: 0;
  margin-bottom: 1vh;
}

.singleQuoteSummaryInfoCount {
  font-family: 'Montserrat';
  font-size: 0.85vw;
  font-weight: 300;
  color: rgba(102,102,102,0.5);
  margin-top: 0;
  margin-bottom: 0;
  width: 15vw;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .singleQuoteSummaryInfoType {
    font-family: 'Montserrat';
    font-size: 1.6vw;
    font-weight: 400;
    margin-bottom: 1vh;
  }
  
  .singleQuoteSummaryInfoCount {
    font-size: 1.6vw;
    width: 80%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .singleQuoteSummaryInfoType {
    font-family: 'Montserrat';
    font-size: 3.5vw;
    font-weight: 400;
    margin-bottom: 1vh;
  }
  
  .singleQuoteSummaryInfoCount {
    font-size: 3.5vw;
    width: 80%;
  }
}
.singleRevisionDetailsSecondary {
  width: 32%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleRevisionInformation {
  width: calc(100% - 2vw);
  padding: 2vh 1vw 2vh 1vw;
  height: auto;
  min-height: 20vh;
  background-color: #fff;
  border: 1px solid rgba(102,102,102,0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleRevisionDetailsSecondary {
    width: 42%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .singleRevisionInformation {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 20vh;
    margin-bottom: 3vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleRevisionDetailsSecondary {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .singleRevisionInformation {
    width: calc(100% - 4vw);
    padding: 2vh 2vw 2vh 2vw;
    height: auto;
    min-height: 20vh;
    margin-bottom: 3vh;
  }
}
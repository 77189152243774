.resetPasswordForm {
  display: flex;
  flex-direction: column;
  width: 406px;
  background: white;
  padding: 25px;
  border-radius: 8px;
}

.resetPasswordFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
}

.resetPasswordFormTitle{
  font-family: "Oswald", sans-serif;
  font-size: 24px;
}

.resetPasswordFormIcon{
  color: #686F7B;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.resetPasswordInputContainer{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.resetPasswordFormInput {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  color: #030F23;
}

.resetPasswordInputPlaceholder{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.95vw;
  font-size: 13px;
}

.resetPasswordFormInput:focus {
  padding-top: 17px;
  padding-bottom: 3px;
  border:1px solid #030F23;
}

.resetPasswordFormInput:focus + .resetPasswordInputPlaceholder{
  transform: translateY(-12px);
  font-size: 9px;
}

.resetPasswordFormInputNotEmpty {
  color: #686F7B;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  margin-bottom: 0px;
  padding: 10px 12px 10px 12px;
  outline: transparent;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #eff0f2;
  height: 30px;
  padding-top: 17px;
  padding-bottom: 3px;
}

.resetPlaceholderNotInFocus {
  margin-bottom: 1vh;
  font-family: "Inter", sans-serif;
  font-weight: 406;
  font-size: 9px;
  line-height: 16.9px;
  color: #686F7B;
  position: absolute;
  left: 0;
  top: 12.5px;
  padding: 10px;
  padding-top: 5px;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(-12px);
}

.errorInput {
  border: 1px solid #D70015;
}

.errorInput:focus{
  border:1px solid #D70015;
}

.resetshowPasswordEye {
  position: absolute;
  font-family: "Inter", sans-serif;
  transform: translate(372px, 16px);
  z-index: 10001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #686F7B;
}

.focusedPasswordEye {
  stroke: #030F23;
}

.resetPasswordFormValidation {
  font-size: 14px;
  margin-left: 10px;
  color: #D70015;
  margin-bottom: 5px;
}

.successValidation {
  color: #0FAF62;
}

.resetPasswordFormButton {
  padding: 10px 16px 10px 16px;
  font-family: "Inter", sans-serif;
  background-color: #12ABBF;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetPasswordFormButton:hover,
.resetPasswordFormButton:focus {
  background-color: #4ec1d0;
}

.resetPasswordFormButton:disabled{
  background: #b3e4eb;
  cursor:text;
}

.resetPasswordFormLogIn {
  margin-top: 10px;
  text-decoration: none;
  color: #12ABBF;
  align-self: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.resetPasswordFormLogIn:hover,
.resetPasswordFormLogIn:focus {
  color: #4ec1d0;
}

.resetPasswordFormLogIn:disabled{
  color: #b3e4eb;
}

.btnLoader{
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  animation: rotation infinite 3s linear;
}

@keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}

@media (max-width: 450px) {
  .resetPasswordForm{
    width: 300px;
  }

  .showPasswordEye {
    transform: translate(267px, 15px);
  }
}
